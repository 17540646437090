import React from "react";
const Ncha = () => {}
const columnsXPolGen = [
    
    {
        Header: 'Empresa',
        accessor: 'nombreCorto'
    },
    { 
        Header: "Subtotal",
        // accessor: 'Subtotal',
        accessor: (d) => {
            return '$' + d.Subtotal
        },
    },
    { 
        Header: "Iva",
        // accessor: 'Iva',
        accessor: (d) => {
            return '$' + d.Iva
        },
    },
    { 
        Header: "Neto",
        // accessor: 'Neto',
        accessor: (d) => {
            return '$' + d.Neto
        },
    },
    { 
        Header: "Retencion iva",
        // accessor: 'RetencionIva',
        accessor: (d) => {
            return '$' + d.RetencionIva
        },
    },
    { 
        Header: " ",
        accessor: "btnAction",
    },
    { 
        Header: "check All",
        accessor: 'cbx',
    }
    
  
]

const dataXP =[
  {  
    key:0,
    nombreCorto:'MECPIN',
    Subtotal:'$0:00',
    Iva:'$0.00',
    Neto:'$2,581,702.88',
    RetencionIva:'$0.00',

  },
  {  
    key:1,
    nombreCorto:'ASP',
    Subtotal:'$0:00',
    Iva:'$0.00',
    Neto:'$2,581,702.88',
    RetencionIva:'$0.00',

  },
  {  
    key:2,
    nombreCorto:'MSFR',
    Subtotal:'$0:00',
    Iva:'$0.00',
    Neto:'$2,581,702.88',
    RetencionIva:'$0.00',

  },
]
const columnsPolGen = [
    {
        Header: "N° Póliza",
        accessor: 'numeroPoliza',
    },
    {
        Header: 'Empresa',
        accessor: 'nombreCorto'
    },
    { 
        Header: "Abono",
        // accessor: 'AbonoFormat',
        accessor: (d) => {
            return '$' + d.AbonoFormat
        },
    },
    { 
        Header: "Cargo",
        // accessor: 'CargoFormat',
        accessor: (d) => {
            return '$' + d.CargoFormat
        },
    },
    { 
        Header: "Iva",
        // accessor: 'IvaFormat',
        accessor: (d) => {
            return '$' + d.IvaFormat
        },
    },
    { 
        Header: "Retencion",
        // accessor: 'RetencionFormat',
        accessor: (d) => {
            return '$' + d.RetencionFormat
        },
    }
   
    
    
  
]

const headboardColumns =[
    { 
        Header: "Fecha movimiento",
        accessor: 'fechaCabecero',
    },
    { 
        Header: "Tipo póliza",
        accessor: 'tipoPolizaCabecero',
    },
    { 
        Header: "Concepto",
        accessor: 'conceptoCabecero',
    },
    { 
        Header: "Importe",
        // accessor: 'Importe',
        accessor: (d) => {
            return '$' + d.Importe
        },
    } 
]
const columnsCabecero = [
    
    { 
        title: "Fecha movimiento",
        dataIndex: 'fechaCabecero',
    },
    { 
        title: "Tipo",
        dataIndex: 'tipoPolizaCabecero',
    },
    { 
        title: "Concepto",
        dataIndex: 'conceptoCabecero',
    },
    { 
        title: "Importe",
        dataIndex: 'importeCabecero',
    }   
]
const columnsDetalle = [
    // { 
    //     Header: "Fecha contable",
    //     accessor: 'fechaContable',
    // }, 
    { 
        Header: "Cuenta",
        accessor: 'auxiliar',
    },
    { 
        Header: "Concepto",
        accessor: 'descripcion',
    },
    { 
        Header: "cargo",
        // accessor: 'Cargo',
        accessor: (d) => {
            return '$' + d.Cargo
        },
    },
    { 
        Header: "Abono",
        // accessor: 'Abono',
        accessor: (d) => {
            return '$' + d.Abono
        },
    },
    { 
        Header: "Referencia",
        accessor: 'referencia',
        
    },

]

const columnsDetail = [
        {
            title: 'Cuenta',
            dataIndex: 'cuenta',
            width:170,

        },
        {
            title: 'Fecha contable',
            dataIndex: 'fechaMov',
            width:130,

        },
        { 
            title: "Concepto",
            dataIndex: 'Concepto',
            width:375,
            render: text => <input className='column-TxtExceed' value={text} onChange={Ncha} />
        },
        { 
            title: "Cargo",
            dataIndex: 'cargo',
            render: d =>  "$ "+ d
        },
        { 
            title: "Abono",
            dataIndex: 'abono',
            render: d =>  "$ "+ d

        },
        { 
            title: "Referencia",
            dataIndex: 'referencia',
            render: text => <input className='column-TxtExceed' value={text} onChange={Ncha} />
            
        
        },

]
// const columnsDetail = [
//     {  
//         title:'Detalle',
//         render(){
//             return {
//                 props: {
//                   style: { background: "green" }
//                 }
//             };
//         },
//         children: [
//         {
//             title: 'Cuenta',
//             dataIndex: 'cuenta',
//         },
//         { 
//             title: "Cargo",
//             dataIndex: 'cargo',
//         },
//         { 
//             title: "Abono",
//             dataIndex: 'abono',
//         },
//         { 
//             title: "Referencia",
//             dataIndex: 'referencia',
//             render: text => <input className='column-TxtExceed' value={text} onChange={Ncha} />
            
        
//         },
//     ]
//     }

// ]
const DataCabecero = [
    {
        fechaMovimiento : '01/01/2018',
        concepto : 'CONCEPTO 001',
        cargo : '23',
        abono: '120',
        
    }
  
]
const dataDetalle = [
    {
        cuenta : '1234-5678-9012-3456',
        fechaMov : '01/01/2020',
        Concepto : 'Generación nuevos procesos desde SIAN BANCO DE LA EMPRESA',
        cargo : '$787,678,134.88',
        abono : '$787,678,134.88',
        referencia : 'EDDEDEDEDEDE,EDEDEDEDEDEDEDED.DEDEDEDEDEDEDEDEDEDEDEDED.DEDEDEDEDEDEDEDEEDEDEDEDEDEDEDED',
  },
  {
    cuenta : '1234-5678-9012-3456',
    fechaMov : '10/01/2020',
    Concepto : 'Generación nuevos procesos desde SIAN BANCO DE LA EMPRESA',
    cargo : '$787,678,134.88',
    abono : '$787,678,134.88',
    referencia : 'EDDEDEDEDEDE,EDEDEDEDEDEDEDED.DEDEDEDEDEDEDEDEDEDEDEDED.DEDEDEDEDEDEDEDEEDEDEDEDEDEDEDED'
}
]
const columnsProcesos = [
    {
       Header: "Empresa",
       accessor: 'empresa'
    },
    { 
        Header: "Tipo de proceso",
        accessor: 'tipoProcesoPoliza',
    }, 
    { 
        Header: "Fecha envio",
        accessor: 'fechaAct',
    },
    { 
        Header: "Fecha inicio",
        accessor: 'fechaInicioProceso',
    },
    { 
        Header: "Fecha fin",
        accessor: 'fechaFinProceso',
    },
    { 
        Header: "Estatus",
        accessor: 'estatus',
    },
    {
        Header: "Observaciones",
        accessor: 'observaciones',
        
    }

]
const columnsConfig = [
    { 
        Header: "Proceso",
        accessor: 'tipoProcesoPoliza',
    },
    { 
        Header: "Tipo Póliza",
        accessor: 'tipopoliza',
    },
    { 
        Header: "Concepto",
        accessor: 'concepto',
    },

]
const dataConfig = [
    { 
        tipoProcesoPoliza: "Cobranza",
        tipopoliza: 'PUN',
        concepto: 'Generación nuevos procesos desde SIAN CLIENTE',
        status: 'Activo'
    },
    { 
        tipoProcesoPoliza: "Fecturacion",
        tipopoliza: 'PIN',
        concepto: 'Generación nuevos procesos desde SIAN ',
        status: 'Inactivo'

    },
    { 
        tipoProcesoPoliza: "Notas de Credito Compras",
        tipopoliza: 'PON',
        concepto: 'Generación nuevos procesos desde S',
        status: 'Activo'

    },

]
const dataTipoPol = [
    {label:'PID',value:0},
    {label:'PIN',value:1},
    {label:'PIW',value:2}
]
export {
    columnsPolGen,
    dataXP,
    columnsXPolGen,
    headboardColumns,
    columnsCabecero,
    DataCabecero,
    columnsDetalle,
    dataDetalle,
    columnsDetail,
    columnsProcesos,
    columnsConfig,
    dataConfig,
    dataTipoPol
}

