import React,{ Component } from "react";

class BtnViewPdfXML extends Component{
    render(){
        return(
            <button 
            type="button" 
            rel="tooltip" 
            className="button button-primary button-circle button-small"
            data-toggle="tooltip" 
            data-placement="top" 
            style={{marginLeft: '5px'}}
            title="Ver PDF y  XML">
                <i className="fa fa-file-pdf-o" alt="Ver PDF y  XML"></i>
            </button>
        )
    }
}

export default BtnViewPdfXML