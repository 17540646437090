//#region Administracion de Vistas
const inicializa = {
    title : 'Giros Empresas',
    breadcrum : [{nombre: 'Catalogos'},{nombre: 'Giros Empresas'}]
}
const tableColumns = [
    { 
        Header: "Giro",
        accessor: 'giro',
    },
    { 
        Header: "Valor Adicional",
        accessor: 'valorAdicional',
    }
]



const tableData = []

//#endregion



export { tableColumns, inicializa, tableData }