import React, { Fragment } from 'react';
import Select from 'react-select';
import Buttons from '../../../Components/Buttons/Buttons'
import { tableColumnsModal,tableData } from './resources'
import GeneralTable from '../../../Components/GeneralTable';
import '../../../Pages/Contabilidad/SeguimientoBuzonTributario/modalHistorico.css'

const dblClickComp = (originals, tableValues) => {
   
 
}
const ModalHistorico = ({
    clickClose,
    data,
    dataSaved,

}) => {

    return (
        <Fragment>
            <div className="modal fade" id="modalHistorico" tabIndex="-1" role="dialog" aria-labelledby="modalHistoricoLabel" aria-hidden="true">
                <div className="modal-dialog modal-xl modal-dialog-centered "  role="document" style={{ maxWidth: '1140px !important' }}>
                    <div className="modal-content">
                        <div className="modal-header mhead">
                            <div className="row">
                                <div className="col-md-10">
                                    <h5 className="modal-title text-center mi-xl" style={{ color: 'white' }} id="modalHistoricoLabel">Historico de modificaciones</h5>
                                </div>
                                <div className="col-md-2">
                                    <button onClick={clickClose} type="button" className="close text-white" data-dismiss="modal" aria-label="Close">
                                        <i className="fa fa-times-circle"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="modal-body" role="form">
                            <GeneralTable
                                columns={tableColumnsModal}
                                data={data}
                                action={undefined}
                                doubleClick={dblClickComp}
                            />
                        </div>
                        <div className="modal-footer">
                        <div className="col-md-12 col-sm-12 text-center" >
                                        <button type="button" rel="tooltip" className="btn btn-primary" data-placement="top" title="Historico" onClick={clickClose} >Cerrar</button>
                                    </div>
                        </div>
                    </div>
                </div>
            </div>

        </Fragment>
    );
};

export default ModalHistorico;