const endPoint = 'Catalogos/CatProductosServicios'
const endPointFilter = 'Catalogos/CatProductosServicios/Filter'
const endPointUrl = 'Catalogos/CatProductosServicios/Url'


const inicializa = {
    title : 'Productos y servicios',
    breadcrum : [{nombre: 'Catalogos'},{nombre: 'Productos y servicios'}]
}

const columsProduct = [
    {   id:'codigoProducto',
    Header: ({dt}) => (
        <div>
            Clave
        </div>
    ),
    accessor: 'codigoProducto',
    Cell: k =>{ 
        return  <span>{k.row.original.codigoProducto}</span>
        
    }
    },
    {   id:'producto',
    Header: ({dt}) => (
        <div>
            Descripción
        </div>
    ),
    accessor: 'producto',
    Cell: k =>{ 
        return <div className="txtdAEE"><span>{k.row.original.producto}</span></div> 
    }
    }
   
 
    ]
export{inicializa,columsProduct,endPoint,endPointFilter,endPointUrl}