import React from 'react'

const Header =({title, breadcrum}) => {
        return(
            <header className="main-header headerP">
                <div className="container brcS">
                    <h1 
                    className="page-title">{title}</h1>
                    <ol className="breadcrumb pull-right">
                        {/* <li><a href="#">UI Elements</a></li>
                        <li><a href="#">CSS</a></li>
                        <li className="active">Forms</li>  */}
                        
                        {
                            breadcrum.map((bread, index) =>{
                                let _href = "";
                                let _state = "";
                                let _nombre
                                _href = bread.href !== undefined ? bread.href : "#"
                                _state = bread.state !== undefined ? bread.state : false
                                _nombre = bread.nombre !== undefined ? bread.nombre : ""
                            return <li key={index}><a  href={_href} className={ _state ? "active" : ""}>{_nombre}</a></li>
                            })
                        }              
                    </ol>
                </div>
            </header>
        )
}

export default Header;