import React, { useState, useEffect, useRef } from 'react';
import Select from 'react-select';
import { ExisteSession, toggleLoader } from '../../../ApiRequests/general'
import Buttons from '../../../Components/Buttons/Buttons';
import { getInfo, saveInfo, updateInfo, deleteInfo, cargaArvhivo,cargaCertificados,getInfoGral } from '../../../ApiRequests/req_CRUD'
import GeneralTable from '../../../Components/GeneralTable';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import axios from 'axios';
import { fnNotification } from '../../../ApiRequests/Notification'
import { columsActiv, columsProduct, columsHist } from './resources'
import ModalConfirma from './ModalConfirma'


const ModalMantenimientoEmpresas = React.forwardRef(({ 
    saveDataUser,
    model, 
    actionClose,
    clickDelete, 
    selecctions, 
    setSelecction, 
    optionsSector, //Opciones combo Sector
    refreshClient,
    changeStatus,
    setRegistros,
    registroTributario,
    onChangeEmail,
    email,
    isTabActiv,
    cnahgeActive_,
    searchRFC,
    addProveedor,
    allStatus,
    statusEmpresa,
    changeLogo,
    logoTemp,
    show_Histori,
    showData_Secuencia,
    showTblHist,    
    showConfirmHist,
    dataAllHist,    
    dataHist,       
    configHist,     
},ref) => {
    const rRFC  = useRef(null)
    const refinputImg  = useRef(null)
    const tblHisto  = useRef(null)
    
    const [Register, setModel] = useState({
        ID_empresa: 0,
        nombreCorto: "",
        nombreLargo: "",
        usuario: "",
        ultAct: null,
        RFC: "",
        idCliente: 0,
        cliente: "",
        IdProv: 0,
        proveedor: "",
        IdMunicipio: 0,
        municipio: "",
        idEstado: 0,
        estado: "",
        direccion: "",
        colonia: "",
        codigoPostal: "",
        idZonaFiscal: 0,
        telefono: "",
        idRegimenFiscal: "",
        idTipoCompania: 0,
        tipoCompania: "",
        activo: false,
        esPagadora: false,
        manejaPersonal: false,
        idFormato: 0,
        formato: "",
        idFormatoRecibo: 0,
        formatoRecibo: "",
        idFormatoComplemento: 0,
        formatoComplemento: "",
        idGiro: 0,
        giro: "",
        idMarca: 0,
        codigoMarca: "",
        descripcion: "",
        fechaAct: null,
        macAddressAct: "",
        usuarioAct: "",
        idProducto: 0,
        inicio: false,
        folioRepse: "",
        urlRepse: "",
        email:'',
        archivoRepse: "",
        archivoRepsePublic: "",
        selloRepse: "",
        selloRepsePublic: "",
        objetoSocial: "",
        tipo: false,
        filtro_aplicacion: 38,
        filtro_ambiente: ExisteSession.getAmbiente().idAmbiente,
        idUsuarioAct: ExisteSession.getInfoUsuario().idUsuario,
        token: ExisteSession.getInfoUsuario().token,
        connection: ExisteSession.conexion()
    });
    const [ImageURL, setImageURL]                      = useState('');
    /* feature_DEV_ActividadesProductos  */
    const [actividadesTbl, setActividadesTbl]          = useState([]) //Llena la tabla de actividades
    const [productosTbl, setProductosTbl]              = useState([]) //Llena la tabla de productos
    const [productSave, setProductSaveTbl]              = useState([]) //Productos para guardar

    const [valueSector,  setValueSector]                        = useState([]) //valor del combo Sector
    const [opcionesSubsector, setOpcionesSubsector]             = useState([]) //Opciones combo Subsector
    const [valorSubSector, setValorSubsector]                   = useState([]) //valor Combo Subsector

    const [optionsRama,setOptionsRama]                          = useState([]) //Opciones combo Rama
    const [valueRama,setValueRama]                              = useState([]) //valor Combo Rama
         
    const [optionsSubRama,setOptionsSubRama]                   = useState([])  //Opciones combo Subrama
    const [valueSubrama,setValueSubrama]                       = useState([])  //valor Combo Subrama
             
    const [optionsActividad, setOptionsActividad]              = useState([]) //Opciones combo actividad económica
    const [valueActividad, setValueActividad]                  = useState([]) //valor del combo actividad económica 
             
    const [porcentaje, setPorcentaje]                          = useState(0)
    const [fechaVigenciaActiv, setFechaVigenciaActiv]          = useState(0)
    const [fechaVigenciaProd, setFechaVigenciaProd]            = useState(0)
    const [fechaActual, setFechaActual]                        = useState(0)
    const [totPorcentaje,setTotPorcentaje]                     = useState(0)    

    const [optionsClase, setOptionsClase]                      = useState([]) //Opciones combo clase
    const [valueClase, setValueClase]                          = useState([]) //valor del combo clase
            
    const [optionsProducts, setOptionsProducts]                = useState([]) //Opciones combo productos
    const [valueProduct, setValueProduct]                      = useState([]) //valor del combo productos
            
    const [actividadProduct, setActividadProduct]              = useState([]) //valor del combo productos

    const [listActividadesI,setListActividadesI]               = useState([])
    const [listActividadesU,setListActividadesU]               = useState([])
    const [relAuxID,setRelAuxID]                               = useState([])
    const porcent                                              = useRef(null);
    const fechaA                                               = useRef(null);
    const rEmpresa                                               = useRef(null);
    const [isTabEmpresaOk, setTabEmpresaOk]                    = useState(false)
    const [isTabActivOk, setTabActivOk]                        = useState(false)
    const [isTabProductOk, setTabProductOk]                    = useState(false)
    const [isTabCertifOk, setTaCertifOk]                        = useState(false)
    const [inActiv, setInActiv]                                = useState(false)
    const [showConfirm, setShowConfirm]                        = useState(false)
    const [classTabEmpresa, setClassTabEmpresa]                = useState('')
    const [classTabContEmpresa, setClassTabContEmpresa]        = useState('tab-pane active')
    const [classTabActividad, setClassTabActividad]            = useState('')
    const [classTabContActividad, setClassTabContActividad]    = useState('tab-pane')
    const [classTabProducto, setClassTabProducto]              = useState('')
    const [classTabContProducto, setClassTabContProducto]      = useState('tab-pane')
    const [classTabCertificado, setClassTabCertificado]        = useState('')
    const [classTabContCertificado, setClassTabContCertificado]= useState('tab-pane')
    const [nameTab, setNameTab]                                = useState('')
    const [namtempClient, setNamtempClient]                                = useState('')
                        

    // const [ImageURL, setImageURL] = useState();
    const [shown, setShown] = useState(false);
    const [isVisible,setIsVisible]=useState(true);
    const ValidationFormRegistro = (dataRegistro) => {
        var _mesage = "";
        
        
        if (dataRegistro.usuarioCuenta === "")
            _mesage += "Se requiere el nombre de Usuario de acceso.\n";
       
        if (dataRegistro.password === "")
            _mesage += "Se requiere contraseña de accesso.\n";

        if (dataRegistro.cerPath !== "" || dataRegistro.keyPath !== ""){

            if (dataRegistro.usuarioCuenta === "")
            _mesage += "Se requiere el nombre de Usuario de acceso.\n";

            if (dataRegistro.keyPassword === "")
            _mesage += "Se requiere contraseña de FIEL.\n";

            if (dataRegistro.fechaVigencia === "")
            _mesage += "Se requiere Fecha de Expiración.\n";        }

            if(dataRegistro.idTipoAutenticacion === "1"){
                if (dataRegistro.cerPath === "")
                    _mesage += "Se requiere un archivo .cer \n";
                if (dataRegistro.keyPath === "")
                    _mesage += "Se requiere un archivo .key\n";
            }
      
        return _mesage;
    }

    useEffect(() => {
        var moment = require('moment');
        // obtener la fecha de hoy en formato `DD/MM/YYYY`
        var now = moment().format("DD/MM/YYYY");
        let _fechaAct = now.split('/')[2]+'-'+now.split('/')[1]+'-'+now.split('/')[0]
        let today = new Date();
        let _now = today.toLocaleString();
        

        if (model && model.selectView) {
            setImageURL(null);
            setModel({
                ...Register,
                ID_empresa: model.selectView.ID_empresa,
                nombreCorto: model.selectView.nombreCorto,
                nombreLargo: model.selectView.nombreLargo,
                usuario: model.selectView.usuario,
                ultAct: _now,
                RFC: model.selectView.RFC,
                idCliente: model.selectView.idCliente,
                cliente: model.selectView.cliente,
                IdProv: model.selectView.IdProv,
                proveedor: model.selectView.proveedor,
                IdMunicipio: model.selectView.IdMunicipio,
                municipio: model.selectView.municipio,
                idEstado: model.selectView.idEstado,
                email:model.selectView.email,
                estado: model.selectView.estado,
                direccion: model.selectView.direccion,
                colonia: model.selectView.colonia,
                codigoPostal: model.selectView.codigoPostal,
                idZonaFiscal: model.selectView.idZonaFiscal,
                telefono: model.selectView.telefono,
                idRegimenFiscal: model.selectView.idRegimenFiscal,
                idTipoCompania: model.selectView.idTipoCompania,
                tipoCompania: model.selectView.tipoCompania,
                activo: model.selectView.activo,
                esPagadora: model.selectView.esPagadora,
                manejaPersonal: model.selectView.manejaPersonal,
                idFormato: model.selectView.idFormato,
                formato: model.selectView.formato,
                idFormatoRecibo: model.selectView.idFormatoRecibo,
                formatoRecibo: model.selectView.formatoRecibo,
                idFormatoComplemento: model.selectView.idFormatoComplemento,
                formatoComplemento: model.selectView.formatoComplemento,
                idGiro: model.selectView.idGiro,
                giro: model.selectView.giro,
                idMarca: model.selectView.idMarca,
                codigoMarca: model.selectView.codigoMarca,
                descripcion: model.selectView.descripcion,
                fechaAct: model.selectView.fechaAct,
                macAddressAct: model.selectView.macAddressAct,
                usuarioAct: model.selectView.usuarioAct,
                inicio: false,
                idProducto: model.selectView.idProducto ? model.selectView.idProducto : 0,
                folioRepse: model.selectView.folioRepse ? model.selectView.folioRepse : "",
                urlRepse: model.selectView.urlRepse ? model.selectView.urlRepse : "",
                archivoRepse: model.selectView.archivoRepse ? model.selectView.archivoRepse : "",
                archivoRepsePublic: model.selectView.archivoRepsePublic ? model.selectView.archivoRepsePublic : "",
                selloRepse: model.selectView.selloRepse ? model.selectView.selloRepse : "",
                selloRepsePublic: model.selectView.selloRepsePublic ? model.selectView.selloRepsePublic : "",
                tipo: model.selectView.tipo,
                objetoSocial: model.selectView.objetoSocial,
                

            });
            setSelecction({
                ...selecctions,
                idCliente: selecctions.idCliente = selecctions.Clientes.find(value => value.value === model.selectView.idCliente),
                IdProv: selecctions.IdProv = selecctions.Proveedores.find(value => value.value === model.selectView.IdProv),
                idEstado: selecctions.idEstado = selecctions.Estados.find(value => value.value === model.selectView.idEstado),
                IdMunicipio: selecctions.IdMunicipio = selecctions.Municipios.find(value => value.value === model.selectView.IdMunicipio),
                idZonaFiscal: selecctions.idZonaFiscal = selecctions.ZonasFiscales.find(value => value.value === model.selectView.idZonaFiscal),
                idTipoCompania: selecctions.idTipoCompania = selecctions.Companias.find(value => value.value === model.selectView.idTipoCompania),
                idMarca: selecctions.idMarca = model.selectView.idMarca ? selecctions.Marcas.find(value => value.value === model.selectView.idMarca) : null,
                idGiro: selecctions.idGiro = model.selectView.idGiro ? selecctions.Giros.find(value => value.value === model.selectView.idGiro) : null,
                idFormato: selecctions.idFormato = selecctions.Formatos.find(value => value.value === model.selectView.idFormato),
                idFormatoRecibo: selecctions.idFormatoRecibo = selecctions.FormatosRecibidos.find(value => value.value === model.selectView.idFormatoRecibo),
                idFormatoComplemento: selecctions.idFormatoComplemento = selecctions.FormatosComplementos.find(value => value.value === model.selectView.idFormatoComplemento),
            })


            setRegistros({
                ...registroTributario,
                cerPath:"",
                keyPath:"",
                keyPassword:"",
                password: "",
                usuarioCuenta:"",
                existeCertificados: false,
                urlPublica:"",
                idTipoAutenticacion : "0",
                fechaVigencia :"",
                activoR : true,
                cerPathPublic:"",
                keyPathPublic:""
            })

            var _search = {
                idEmpresa: model.selectView.ID_empresa,
                filtro_aplicacion: 38,
                filtro_ambiente: ExisteSession.getAmbiente().idAmbiente,
                idUsuarioAct: ExisteSession.getInfoUsuario().idUsuario,
                token: ExisteSession.getInfoUsuario().token,
                connection: ExisteSession.conexion(),
            }
            if (model.selectView.selloRepsePublic) {
                axios({
                    responseType: 'arraybuffer',
                    method: 'get',
                    url: model.selectView.selloRepsePublic,

                }).then(function (response) {


                    const arrayBuffer = response.data;
                    const uint8Array = new Uint8Array(arrayBuffer);
                    let binary = '';
                    for (let i = 0; i < uint8Array.length; i++) {
                    binary += String.fromCharCode(uint8Array[i]);
                    }
                    const base64String = btoa(binary);
                    //var _buff = Buffer.from(response.data, 'binary').toString('base64');
                    setImageURL(base64String)
                }).catch(function (error) {
                    console.log("peticion error", error)
                })
            }
            // getInfo("Catalogos/catEmpresaProducto", _search, (response) => Load(response, "descripción", "idProducto", "RelacionProducto", "RelacionProductoTbl", null, 1));
            // var moment = require('moment');
            // // obtener la fecha de hoy en formato `DD/MM/YYYY`
            // var now = moment().format("DD/MM/YYYY");
            // let _fechaAct = now.split('/')[2]+'-'+now.split('/')[1]+'-'+now.split('/')[0]
            setFechaVigenciaActiv(_fechaAct)
            setFechaVigenciaProd(_fechaAct)
            setFechaActual(_fechaAct)
            loadDataActividad()
            getInfo("Catalogos/catEmpresaProducto", _search, (response) => Load(response, "descripción", "idProducto", "RelacionProducto", "RelacionProductoTbl", null, 1));
            getInfo("Catalogos/buzonTributario", _search, (responseBT) => obtenerInfoTributario(responseBT));

        }
        else {
            setModel({
                ...Register,
                ID_empresa: 0,
                nombreCorto: "",
                nombreLargo: "",
                usuario: "",
                ultAct: _now,
                RFC: "",
                idCliente: 0,
                cliente: "",
                IdProv: 0,
                proveedor: "",
                IdMunicipio: 0,
                municipio: "",
                idEstado: 0,
                estado: "",
                direccion: "",
                colonia: "",
                codigoPostal: "",
                idZonaFiscal: 0,
                telefono: "",
                idRegimenFiscal: "",
                idTipoCompania: 0,
                tipoCompania: "",
                activo: false,
                esPagadora: false,
                manejaPersonal: false,
                idFormato: 0,
                formato: "",
                idFormatoRecibo: 0,
                formatoRecibo: "",
                idFormatoComplemento: 0,
                formatoComplemento: "",
                idGiro: 0,
                giro: "",
                idMarca: 0,
                codigoMarca: "",
                descripcion: "",
                fechaAct: null,
                macAddressAct: "",
                usuarioAct: "",
                folioRepse: "",
                urlRepse: "",
                archivoRepse: "",
                archivoRepsePublic: "",
                selloRepse: "",
                selloRepsePublic: "",
                id_entidad_federal: 0,
                idProducto: 0,
                inicio: false,
                tipo: false,
                objetoSocial:''
            });
            setSelecction({
                ...selecctions,
                idCliente: null,
                IdProv: null,
                idEstado: null,
                IdMunicipio: null,
                idZonaFiscal: null,
                idTipoCompania: null,
                idMarca: null,
                idGiro: null,
                idFormato: null,
                idFormatoRecibo: null,
                idFormatoComplemento: null
            });
            setImageURL(null);
            setRegistros({
                ...registroTributario,
                cerPath:"",
                keyPath:"",
                keyPassword:"",
                password: "",
                usuarioCuenta:"",
                existeCertificados: false,
                urlPublica:"",
                idTipoAutenticacion: "0",
                fechaVigencia :"",
                activoR : true,
                cerPathPublic:"",
                keyPathPublic:""
            })
        }
     
        window.$('#refTabEmpresa').click();
    }, [model])



    const miFuncion = () => {
        // Realiza alguna acción
        console.log("Ir a actividades.");
        setInActiv(true)
        // window.$('#tabActividades').click();
      };
      React.useImperativeHandle(ref, () => ({
        miFuncion
      }));
    const clickSave = () => saveDataUser(Register,configHist)
    const clickDeleteLocal = () => clickDelete(Register)
    const clickClose = () => {
        console.log(refinputImg.current)
        if(refinputImg.current) refinputImg.current.value=''
        actionClose()
        _clickClose(true)
        setNamtempClient('')
        setShowConfirm(false)
        setInActiv(false)
        setIsVisible(true)
    }
    const changeTab =(val)=>{
        setIsVisible(val);
    }

    const handleChange = (event) => {
        console.log(event.target.name)
        console.log(event.target.value)
        if(event.target.name === 'nombreCorto') setNamtempClient(event.target.value)
        setModel({ ...Register, [event.target.name]: Register[event.target.name] = event.target.value })
    }
    const handleChangeSelect = (event, _property, _value) => {
        console.log(event)
        console.log(_property)
        console.log(_value)
        if (event) {
            setModel({ ...Register, [_property]: Register[_property] = event.value });
            setSelecction({ ...selecctions, [_property]: selecctions[_property] = event });
        }
        else {
            setModel({ ...Register, [_property]: Register[_property] = 0 });
            setSelecction({ ...selecctions, [_property]: selecctions[_property] = null });
        }
        if (_value)
            if (event)
                setModel({ ...Register, inicio: Register.inicio = true });
            else
                setModel({ ...Register, inicio: Register.inicio = false });
        
        if(_property === 'idEstado' && event) setModel({ ...Register, estado:event.label}); 
        if(_property === 'IdMunicipio' && event) setModel({ ...Register, municipio:event.label}); 
        

    }
    const handleChangeChk = (event) => {
        setModel({ ...Register, [event.target.name]: Register[event.target.name] = event.target.checked })
    }


    const handleChangeRegistro = (event) => {
        setRegistros({ ...registroTributario, [event.target.name]: Register[event.target.name] = event.target.value })
    }
    const handleChangeRegistroChk = (event) => {
        setRegistros({ ...registroTributario, [event.target.name]: Register[event.target.name] = event.target.checked })
    }

    const actionBtn = (event) => {


        confirmAlert({
            title: 'Producto Empresa',
            message: 'Estas apunto de eliminar un producto de la empresa, ¿Estas seguro?',
            buttons: [
                {
                    label: 'Si',
                    onClick: () => {
                        var _delete = {
                            idEmpresa: selecctions.RelacionProductoTbl[0].idEmpresa,
                            idProducto: event.idProducto,
                            nombre_largo: event.nombre_largo,
                            descripción: event.descripción,
                            filtro_aplicacion: 38,
                            filtro_ambiente: ExisteSession.getAmbiente().idAmbiente,
                            idUsuarioAct: ExisteSession.getInfoUsuario().idUsuario,
                            token: ExisteSession.getInfoUsuario().token,
                            connection: ExisteSession.conexion(),
                        }
                        deleteInfo("Catalogos/catEmpresaProducto", _delete, (response) => responseSaveProduct(response));
                    }
                },
                {
                    label: 'No',
                    onClick: () => { }
                }
            ],
            overlayClassName: "zi-99999"
        });
    }
    const changeProduct = (_registro) => {

        var _save = {
            idEmpresa: Register.ID_empresa,
            idProducto: Register.idProducto,
            // descripción: selecctions.idProducto.label,
            filtro_aplicacion: 38,
            filtro_ambiente: ExisteSession.getAmbiente().idAmbiente,
            idUsuarioAct: ExisteSession.getInfoUsuario().idUsuario,
            token: ExisteSession.getInfoUsuario().token,
            connection: ExisteSession.conexion(),
        }
        saveInfo("Catalogos/catEmpresaProducto", _save, (response) => responseSaveProduct(response));
        // }
    }
   
    const responseSaveProduct = (response) => {
        setModel({ ...Register, idProducto: Register.idProducto = 0, inicio: false });
        setSelecction({ ...selecctions, idProducto: selecctions.idProducto = null });
        var _search = {
            idEmpresa: model.selectView.ID_empresa,
            filtro_aplicacion: 38,
            filtro_ambiente: ExisteSession.getAmbiente().idAmbiente,
            idUsuarioAct: ExisteSession.getInfoUsuario().idUsuario,
            token: ExisteSession.getInfoUsuario().token,
            connection: ExisteSession.conexion(),
        }
        getInfo("Catalogos/catEmpresaProducto", _search, (response) => Load(response, "descripción", "idProducto", "RelacionProducto", "RelacionProductoTbl", null, 1));

    }

    const Load = (response, _label, _value, _array, _table, _special, _identi, init,_search) => {

        var _load = [];
        if (response && response.length > 0) {
            for (let index = 0; index < response.length; index++) {
                _load.push({ value: response[index][_value], label: response[index][_label] });
            }
        }
        setSelecction({
            ...selecctions,
            [_array]: selecctions[_array] = _load
        });
        if (_identi) {
            if (response[0] && response[0].idProducto) {
                var _find = selecctions.Productos.find(c => c.value === response[0].idProducto);
                if (_find)
                    response[0].claveSat = _find.label.split("-")[2] ? _find.label.split("-")[2] : "";
            }
        }
        if (_table)
            setSelecction({
                ...selecctions,
                [_table]: selecctions[_table] = response
            });

        

    }
    const obtenerInfoTributario = (response) => {
        
        if(response.length > 0){

            let fechaVigenciaFormat = ""
            if(response[0].fechaVigencia !== undefined)
                fechaVigenciaFormat = response[0].fechaVigencia.split("T");
            let activo = false;
            if(response[0].activo !== undefined)
                activo = response[0].activo
            setRegistros({ ...registroTributario, 
                cerPath: registroTributario.cerPath =response[0].cerPath,
                keyPath: registroTributario.keyPath =response[0].keyPath,
                keyPassword: registroTributario.keyPassword =response[0].keyPassword,
                password: registroTributario.password =response[0].password,
                usuarioCuenta: registroTributario.usuarioCuenta =response[0].usuario,
                existeCertificados: registroTributario.existeCertificados = true,
                urlPublica: registroTributario.urlPublica ="",
                idTipoAutenticacion : registroTributario.idTipoAutenticacion = response[0].idTipoAutenticacion,
                fechaVigencia : registroTributario.fechaVigencia = fechaVigenciaFormat[0],
                activoR : registroTributario.activo = activo,
                cerPathPublic:registroTributario.idTipoAutenticacion = response[0].cerPathPublic,
                keyPathPublic:registroTributario.idTipoAutenticacion = response[0].keyPathPublic      
             });

        }else{
            setRegistros({
                ...registroTributario,
                cerPath:"",
                keyPath:"",
                keyPassword:"",
                password: "",
                usuarioCuenta:model.RFC,
                existeCertificados: false,
                urlPublica:"",
                idTipoAutenticacion: "0",
                activoR : true                ,
                cerPathPublic:"",
                keyPathPublic:"" 
            })

        }

    }
    

    const subirArchivos = e => {
        toggleLoader(true);
        var data = new FormData()
        data.append('files', e[0])
        data.append('cargar', JSON.stringify(
            {
                idUsuarioAct: ExisteSession.getInfoUsuario().idUsuario,
                token: ExisteSession.getInfoUsuario().token,
                connection: ExisteSession.conexion()
            }
        ))

        cargaArvhivo('Catalogos/catEmpresas', data, {
            idUsuarioAct: ExisteSession.getInfoUsuario().idUsuario,
            token: ExisteSession.getInfoUsuario().token,
            connection: ExisteSession.conexion()
        }, (respons) => {
            if (respons.urlPublicImage) {
                axios({
                    responseType: 'arraybuffer',
                    method: 'get',
                    url: respons.urlPublicImage,

                }).then(function (response) {

                    const arrayBuffer = response.data;
                    const uint8Array = new Uint8Array(arrayBuffer);
                    let binary = '';
                    for (let i = 0; i < uint8Array.length; i++) {
                    binary += String.fromCharCode(uint8Array[i]);
                    }
                    const base64String = btoa(binary);
                    //var _buff = Buffer.from(response.data, 'binary').toString('base64');
                    setImageURL(base64String)
                    // var _buff = Buffer.from(response.data, 'binary').toString('base64');
                    // setImageURL(_buff)

                }).catch(function (error) {
                    console.log("peticion error", error)
                })
            }
            if (respons.urlImage) {
                setModel({
                    ...Register,
                    selloRepse: model.selectView.selloRepse = respons.urlImage,
                })
            }
            document.getElementById("UploadFile").value = "";
            toggleLoader(false);
        })
    }

    const subirArchivosRepse = (e) => {
        toggleLoader(true);
        var data = new FormData()
        data.append('files', e[0])
        data.append('cargar', JSON.stringify(
            {
                idUsuarioAct: ExisteSession.getInfoUsuario().idUsuario,
                token: ExisteSession.getInfoUsuario().token,
                connection: ExisteSession.conexion()
            }
        ))
        cargaArvhivo('Catalogos/catEmpresas/PDF', data, {
            idUsuarioAct: ExisteSession.getInfoUsuario().idUsuario,
            token: ExisteSession.getInfoUsuario().token,
            connection: ExisteSession.conexion()
        }, (respons) => {

            if (respons) {
                cargaArvhivo('Catalogos/catEmpresas/PDFupload', data, {
                    idUsuarioAct: ExisteSession.getInfoUsuario().idUsuario,
                    token: ExisteSession.getInfoUsuario().token,
                    connection: ExisteSession.conexion()
                }, (respon) => {
                    if (respon) {
                        setModel({
                            ...Register,
                            urlRepse:  respons.urlRepse,
                            archivoRepse:  respon.urlDocument,
                            archivoRepsePublic:  respon.urlPublicDocument,
                        })
                    }
                    document.getElementById("ArchivoRepse").value = "";
                    toggleLoader(false);
                })
            }         
        })
    }

    const downloadFileRepse = (e) => {

        axios({
            // responseType: 'arraybuffer',
            responseType: 'blob',
            method: 'get',
            url: Register.archivoRepsePublic,

        }).then(function (response) {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'Repse_'+Register.nombreLargo +'.pdf');
            document.body.appendChild(link);
            link.click();
        }).catch(function (error) {
            console.log("peticion error", error)
        })
    } 
    const getSetData = (index, id, value) => {
        if(id === 'inputPorcent'){
            console.log(relAuxID)
            let newCollectionDet = [...actividadesTbl]
            newCollectionDet[index].porcentaje = value !== 0 ? parseFloat(value) : 0
            newCollectionDet[index].updateR = true;
            setActividadesTbl(newCollectionDet)    
            let total = 0;
            let auxF = false; 
            newCollectionDet.forEach((element,i) =>{    
                if(element.porcentaje !== element.porcentajeOriginal) auxF = true 
                total = total + parseFloat(element.porcentaje)
            })
            setTotPorcentaje(total)
            if(auxF){
                let auxdA = [...relAuxID]
                let indxA = auxdA.findIndex(x => x.codigoActividadEco === newCollectionDet[index].codigoActividadEco)
                console.log(indxA)
                if(indxA > -1){
                    auxdA[indxA].porcentaje = value
                    auxdA[indxA].idEstatus = true
                    setRelAuxID(auxdA)
                    
                    if(auxdA[indxA].id_Estatus){
                        let auxNU = [...listActividadesU]
                        let indxU = auxNU.findIndex(x => x.codigoActividadEco === newCollectionDet[index].codigoActividadEco)
                        auxNU[indxU].porcentaje = value
                        auxNU[indxU].idEstatus = true
                        setListActividadesU(auxNU)
                    }
                    else{
                        let auxNI = [...listActividadesI]
                        let indxI = auxNI.findIndex(x => x.codigoActividadEco === newCollectionDet[index].codigoActividadEco)
                        auxNI[indxI].porcentaje = value
                        auxNI[indxI].idEstatus = true
                        setListActividadesI(auxNI)
                    }
                }else{
                    console.log('model',model)
                    let relAux = {
                        idRelacion:newCollectionDet[index].idRelacion,
                        ListProducts:[], 
                        cliente_id: model.selectView.idCliente,
                        codigoActividadEco:newCollectionDet[index].codigoActividadEco ,
                        fechaVigencia: newCollectionDet[index].fechaVigencia,
                        porcentaje: value,
                        idEstatus:true,
                        id_Estatus:true,
                        idUsuarioAct: ExisteSession.getInfoUsuario().idUsuario,
                        connection: ExisteSession.conexion()
                    }
                    let auxsr = [...relAuxID]
                    let auxI = [...listActividadesU]
                    auxsr.push(relAux)
                    auxI.push(relAux)
                    setRelAuxID(auxsr)
                    setListActividadesU(auxI)
                }
            }
        }
    }
    const saveActiv = () => {
        if(totPorcentaje > 100){        
        fnNotification(['La suma de los porcentajes de las actividades económicas no puede superar el 100%'  , 'error', false])
        return false
        }
        if(relAuxID.length > 0){
            let prc =  totPorcentaje.toString()
            if(prc.split('.').length > 1){
                fnNotification(['Error: El valor del porcentaje debe de ser un número entero'  , 'error', false])
                return false
            }
            toggleLoader(true)
            let rowSave = [...relAuxID]
            let stRet = false;
            let listCod = ''
            let countL = 0;
            rowSave.forEach((element,i) =>{
                // if(!element.id_Estatus && element.ListProducts.length === 0){
                console.log(element)
            
                if(!element.id_Estatus && element.ListProducts.length === 0){
                    console.log(element)
                    // fnNotification(['La actividad "'+element.codigoActividadEco+ '", no cuenta con productos asignados '  , 'error', false])
                    stRet = true
                    listCod +=  element.codigoActividadEco + ","; 
                    countL = countL +1
                }
                if(element.countProducts === 0){
                    console.log(element)
                    // fnNotification(['La actividad "'+element.codigoActividadEco+ '", no cuenta con productos asignados '  , 'error', false])
                    listCod +=  element.codigoActividadEco + ","; 
                    stRet = true 
                    countL = countL +1

                }
                console.log(element.idRelacion)
            })
            if(stRet){
                toggleLoader(false); 
                let actividadesSin = countL > 1 ? 'Las actividades ' + listCod.substring(0, listCod.length -1) + ' no cuentan con' : 'La actividad ' + listCod.substring(0, listCod.length -1) + ' no cuenta con'
                setTimeout(() => {
                fnNotification([actividadesSin + ' productos asignados '  , 'error', false])
                }, 2);
                return false }
            else saveInfo("Catalogos/CatActividadesEconomicas", {
                listActividadesI:listActividadesI,
                listActividadesU:listActividadesU
            },
            (response,t,success) => responseSaveRel(response,t,success));        
        
        }else{            
            console.log('No se guarda')
            console.log('relAuxID',relAuxID)
        }
    }
    const responseSaveRel = (response,t,success) => {
        console.log(response)
        toggleLoader(false)
        if(success){
            setProductosTbl([])
            setProductSaveTbl([])
            setOpcionesSubsector([])
            setOptionsRama([])
            setOptionsSubRama([])
            setOptionsActividad([])
            setRelAuxID([])
            setListActividadesI([])
            setListActividadesU([])
            loadDataActividad()
            console.log(totPorcentaje)
            refreshClient(totPorcentaje)
            if(response.ListEroor){
                if(response.ListEroor.length > 0){
                        let msError = '';
                        response.ListEroor.forEach(el =>{
                            msError +=' '+el+ ' | -' 
                        })
                        setTimeout(() => {
                        toggleLoader(false)
                        fnNotification(['Error: Se detectaron los siguientes errores : '+ msError , 'error', false])
                        },5);

                }else if(totPorcentaje < 100){
                    setTimeout(() => {
                    fnNotification(['Nota.- la sumatoria de los porcentajes es menor a 100 ', 'warning', true])
                        
                    }, 6);
                }
               
            }
        }
        
    }
    const returnActiv = (r) => {
        if(productosTbl.length > 0){
        console.log('valueActividad.value',valueActividad.value)
        let auxL = [...relAuxID]
        console.log('auxL ',auxL)
        let indx = auxL.findIndex(r => r.codigoActividadEco === actividadProduct.value)
        auxL[indx].ListProducts = productSave
        auxL[indx].idEstatus = true
        setRelAuxID(auxL)
        console.log(auxL[indx])
        console.log(auxL[indx].idRelacion)
        if(auxL[indx].idRelacion > 0){
            console.log('Es U', auxL[indx])
        let auxU = [...listActividadesU]
        let indxU = auxU.findIndex(r => r.codigoActividadEco === actividadProduct.value)
        auxU[indxU].ListProducts = productSave
        auxU[indxU].idEstatus = true
        setListActividadesU(auxU)
        }else{
            console.log('Es I', auxL[indx])
            let auxI = [...listActividadesI]
            let indxI = auxI.findIndex(r => r.codigoActividadEco === actividadProduct.value)
            auxI[indxI].ListProducts = productSave
            auxI[indxI].idEstatus = true
            setListActividadesI(auxI)
        } 
        setProductSaveTbl([])
        window.$('#tabActivs').click();
        }else{
            fnNotification(['Error: Se requiere tener mínimo un producto para cada actividad ', 'error', false])
        }
    }
    const compTableBtn = (d) => {
        return(
        <div className="row">
            <div className="col-md-12 text-right">
                <button  type="button" className={productSave.length > 0 && productosTbl.length > 0 ? "btn btn-primary button-circle button-small":"btn btn-succes button-circle button-small disabled"} onClick={productSave.length > 0 && productosTbl.length > 0 ? returnActiv : () =>{}}  title='regresar'>
                    <i className="fa fa-arrow-left"></i>
                </button>
            </div>

        </div>
        )
    }
    const compTable = (totPorcentaje) => {
        return(
            
            <div className="row">
                <div className="col-md-6">
                </div>
                <div className="col-md-3">
                <Buttons btnSave={true} btnSaveAction={e => saveActiv('A')} btnSaveShowIcon={true} btnSaveText="Guarda Actividad" btnSaveClass={relAuxID.length > 0 ? 'btn btn-primary button-circle button-small': 'btn btn-succes button-circle button-small disabled' } /> 
                </div>
                <div className="col-md-3" style={totPorcentaje <= 100 ? {fontWeight: 'bold', color: '#337ab7'} : {fontWeight: 'bold', color: 'red'} }><span > Porcentaje </span>&nbsp;  <span>{totPorcentaje}</span> <span>&nbsp;&nbsp; %</span>   </div> 
                <br />
                <br />
                <br />
            </div>
        )
    }
    const addRowActividad = (status,k,_delete) =>{
        let aux = [...actividadesTbl]
        let newCollectionDet = [...actividadesTbl]
        let relAux = {
            idRelacion:status ? k.original.idRelacion : 0,
            ListProducts:[], 
            cliente_id: !status ? model.selectView.idCliente : 0,
            codigoActividadEco: !status ? actividadProduct.value : k.original.codigoActividadEco ,
            fechaVigencia: !status ? fechaVigenciaActiv : k.original.fechaVigencia,
            idEstatus: _delete ? false : true,
            porcentaje: porcentaje,
            id_Estatus:status,
            idUsuarioAct: ExisteSession.getInfoUsuario().idUsuario,
            connection: ExisteSession.conexion()
        }
        let auxsr = [...relAuxID]
        
        auxsr.push(relAux)
        setRelAuxID(auxsr)

        if(status){
            let auxSU = [...listActividadesU]
            auxSU.push(relAux)
            setListActividadesU(auxSU)
            aux.splice(k.index,1)
            let total = parseFloat(porcentaje);
            aux.forEach(element =>{            
                total = total + parseFloat(element.porcentaje)
            })
            setTotPorcentaje(total)
        }

        if(!status){
            let _newRow = {
                codigoActividadEco:valueActividad.value,
                actividadEconomica:valueActividad.descripcion,
                newRow:true,
                porcentaje:porcentaje,
            }
            aux.push(_newRow)
            let auxSI = [...listActividadesI]
            auxSI.push(relAux)
            setListActividadesI(auxSI)

            let total = parseFloat(porcentaje);
            newCollectionDet.forEach(element =>{            
                total = total + parseFloat(element.porcentaje)
            })
            setTotPorcentaje(total)
            setValueSector([])
            setValorSubsector([])
            setValueActividad([])
            setValueRama([])
            setValueSubrama([])
            setPorcentaje(0)
            setFechaVigenciaActiv(fechaActual)    
            window.$('#tabAProducts').click();
            loadDataProduct(0) 
            }
        setActividadesTbl(aux)
    }
    const addRowProducto = () =>{
        let aux = [...productosTbl]
        let auxS = [...productSave]
        let newRow = {
            fechaVigencia:fechaVigenciaProd,
            codigoProducto:valueProduct.value,
            productoServicio:valueProduct.descripcion,
            idEstatus:true,
            idUsuarioAct: ExisteSession.getInfoUsuario().idUsuario,
            connection: ExisteSession.conexion()
        }
        aux.push(newRow)
        auxS.push(newRow)
        setProductosTbl(aux) 
        setProductSaveTbl(auxS)
        setValueClase([])
        setValueProduct([])
        setFechaVigenciaProd(fechaActual)
        let auxAc = [...relAuxID]
        let _indx = auxAc.findIndex(r => r.codigoActividadEco === actividadProduct.value)
        if(_indx > -1){
            // auxS.push(newRow)
            // setProductSaveTbl(auxS)
            auxAc[_indx].countProducts = parseInt(auxAc[_indx].countProducts) + 1
            setRelAuxID(auxAc)
        }else{
            // auxS.splice(indx,1)
            // setProductSaveTbl(auxS)
        }
    }
    const changeFilterActiv = (event, _property, _value) => {
    switch(_property.name){
        case 'Sector':
            setValueSector(event)
            let dataM = {
                idTypeFilter : 2,
                codigoSector:event.value,
                nameSp:'Spc_Sat_SubsectorActividadTbl',
                idUsuarioAct: ExisteSession.getInfoUsuario().idUsuario,               
                connection: ExisteSession.conexion(),
                }
                getInfo("Catalogos/CatActividadesEconomicas/Filter", dataM, (_filters) =>{
                    console.log('_filters',_filters)
                    let auxS = _filters.map(s => {return {value : s.codigoSubsector, label:s.codigoSubsector +'-'+s.Subsector}})
                    setOpcionesSubsector(auxS)
                    setValorSubsector([])
                    setValueRama([])
                    setValueSubrama([])
                    setValueActividad([])
                    setPorcentaje(0)
                });

            break;
        case 'Subsector':
            setValorSubsector(event)
            let dataS = {
                idTypeFilter : 3,
                codigoSector:valueSector.value,
                codigoSubsector:event.value,
                nameSp:'Spc_Sat_RamaActividadTbl',
                idUsuarioAct: ExisteSession.getInfoUsuario().idUsuario,
                connection: ExisteSession.conexion(),
                }
                getInfo("Catalogos/CatActividadesEconomicas/Filter", dataS, (_Rama) =>{
                    let auxR = _Rama.map(R => {return {value : R.codigoRama, label:R.codigoRama+'-'+R.rama}})
                    setOptionsRama(auxR)
                    setValueRama([])
                    setValueSubrama([])
                    setValueActividad([])
                    setPorcentaje(0)
                });

            break;
        case 'rama':
            setValueRama(event)
            let dataR = {
                idTypeFilter : 4,
                codigoSector:valueSector.value,
                codigoSubsector:valorSubSector.value,
                codigoRama:event.value,
                nameSp:'Spc_Sat_SubramaActividadTbl',
                idUsuarioAct: ExisteSession.getInfoUsuario().idUsuario,
                connection: ExisteSession.conexion(),
                }
                getInfo("Catalogos/CatActividadesEconomicas/Filter", dataR, (_SubRama) =>{
                    console.log('_Rama',_SubRama)
                    let auxRama = _SubRama.map(s => {return {value : s.codigoSubrama, label:s.codigoSubrama+'-'+ s.subrama}})
                    setOptionsSubRama(auxRama)
                    setValueSubrama([])
                    setValueActividad([])
                    setPorcentaje(0)
                });

            break;
        case 'Subrama':
            setValueSubrama(event)
            let dataA = {
                codigoSector:valueSector.value,
                codigoSubsector:valorSubSector.value,
                codigoRama:valueRama.value,
                codigoSubrama:event.value,
                idUsuarioAct: ExisteSession.getInfoUsuario().idUsuario,
                connection: ExisteSession.conexion(),
                }
                getInfo("Catalogos/CatActividadesEconomicas", dataA, (_Actividades) =>{
                    console.log('_Actividades',_Actividades)
                    let actividades = _Actividades.map(a => {return {value : a.codigoActividadEco, label:a.codigoActividadEco+'-'+a.ActividadEco,descripcion:a.ActividadEco, fechaVigencia:a.fechaVigencia}})
                setOptionsActividad(actividades)
                setValueActividad([])
                setPorcentaje(0)

                });
            break;
        case 'Actividad':
            setValueActividad(event)
            setActividadProduct(event)
            fechaA.current.focus()
            break;

        default:
    }
    }
    const changeFilterProducto = (event, _property, _value) => {
    switch(_property.name){
        case 'clase':
            setValueClase(event)
            let datac = {
                codigoClase:event.value,
                idUsuarioAct: ExisteSession.getInfoUsuario().idUsuario,
                connection: ExisteSession.conexion(),
                }
                getInfo("Catalogos/CatProductosServicios", datac, (_Productos) =>{
                    let productos = _Productos.map(a => {return {value : a.codigoProducto, label:a.codigoProducto+'-'+a.producto,descripcion:a.producto, fechaVigencia:a.fechaVigencia}})
                    setOptionsProducts(productos)
                    setValueProduct([])
                    setFechaVigenciaProd(fechaActual)
                });
            break;
        case 'producto':
            setValueProduct(event)
            break;
        default:
    }
    }
    const loadDataActividad = () => {
        let dataM = {
            cliente_id:model.selectView.idCliente,
            idEstatus:true,
            idUsuarioAct: ExisteSession.getInfoUsuario().idUsuario,
            connection: ExisteSession.conexion(),
            }
        getInfo("Catalogos/CatActividadesEconomicas/RelActividadesCliente", dataM, (resp) =>{
            let auxP = 0
            resp.forEach(x =>{
                x.porcentajeOriginal = x.porcentaje
                x.updateR = false;
                auxP += x.porcentaje
            })
            setActividadesTbl(resp)
            setTotPorcentaje(auxP)
        } );
    } 
    const changePorcent = e => {
        setPorcentaje(e.target.value)
    }
    const changeDate = e => {
        e.target.name === 'FechaVA' && setFechaVigenciaActiv(e.target.value)
        e.target.name === 'FechaVP' && setFechaVigenciaProd(e.target.value)
    }
    const loadDataProduct = (idRelacionActividadCliente) => {
        toggleLoader(true)
            let dataF = {
                idTipoSP:4,
                nameSP:'Spc_Sat_claseProductoTbl',
                idRelacionActivCiente:idRelacionActividadCliente,
                idUsuarioAct: ExisteSession.getInfoUsuario().idUsuario,
                connection: ExisteSession.conexion(),
                }
            getInfo("Catalogos/CatProductosServicios/RelProductActividades",dataF,(_tipo) =>{
                let _claseAux = _tipo[0].map(c => {return {value : c.codigoClase, label:c.codigoClase+'-'+c.clase}})
                setOptionsClase(_claseAux)
                setProductosTbl(_tipo[1])
                toggleLoader(false)
            })
    }
    const cancelProduct = (k) => {
        let aux = [...productosTbl]
        let auxS = [...productSave]
        console.log(k)
        console.log('productosTbl',productosTbl)
        let indx = aux.findIndex(x => x.codigoProducto === k.original.codigoProducto)
        aux.splice(indx,1)
        setProductosTbl(aux)
        let newRow = {
            idRelacion:k.original.idRelacion,
            fechaVigencia:k.original.fechaVigencia,
            codigoProducto:k.original.codigoProducto,
            idEstatus:false,
            idUsuarioAct: ExisteSession.getInfoUsuario().idUsuario,
            connection: ExisteSession.conexion()
        }
        
     
        let auxAc = [...relAuxID]
        let _indx = auxAc.findIndex(r => r.codigoActividadEco === k.original.codigoActividadEco)
        if(_indx > -1){
            auxS.push(newRow)
            setProductSaveTbl(auxS)
            auxAc[_indx].countProducts = aux.length
            setRelAuxID(auxAc)
        }else{
            auxS.splice(indx,1)
            setProductSaveTbl(auxS)
        }
    }
    const toProduct = (k) => {
        window.$('#tabAProducts').click();
        setProductSaveTbl([])
        setActividadProduct({
            value:k.original.codigoActividadEco,
            descripcion:k.original.actividadEconomica
        })
        let aux = [...actividadesTbl]
        let indx = aux.findIndex(x => x.codigoActividadEco === k.original.codigoActividadEco)
        aux[indx].updateR = true;
        let auxsr = [...relAuxID]
        let auxsI = [...listActividadesI]
        let auxsU = [...listActividadesU]
        let indxA = auxsr.findIndex(x => x.codigoActividadEco === k.original.codigoActividadEco)
        if(indxA === -1){
            let relAux = {
                idRelacion:k.original.idRelacion ,
                ListProducts:[], 
                cliente_id: model.selectView.idCliente,
                codigoActividadEco:k.original.codigoActividadEco ,
                fechaVigencia:  k.original.fechaVigencia,
                porcentaje: k.original.porcentaje,
                idEstatus:true,
                id_Estatus:true,
                countProducts:null,
                idUsuarioAct: ExisteSession.getInfoUsuario().idUsuario,
                connection: ExisteSession.conexion()
            }
            auxsr.push(relAux)
            setRelAuxID(auxsr)  
            auxsU.push(relAux)
            setListActividadesU(auxsU)      
        }else{ 
            console.log(auxsr)
            if(listActividadesI.length > 0){
                auxsr[indxA].idEstatus = true
                auxsr[indxA].countProducts = null
                setRelAuxID(auxsr)            
                let indxI = auxsI.findIndex(x => x.codigoActividadEco === k.original.codigoActividadEco)
                auxsI[indxI].idEstatus = true
                auxsI[indxI].countProducts = null
                setListActividadesI(auxsr)                 
            }else{
                auxsr[indxA].countProducts = null
                setRelAuxID(auxsr)  
                loadDataProduct(k.original.idRelacion)

            }                 
        }
        setActividadesTbl(aux);
        loadDataProduct(k.original.idRelacion)
    }
    const fnDisabledActiv = (k) => addRowActividad(true,k,true)
    const fnCancelActiv = (k) => {
        let rowsAux = [...relAuxID]
        let aux = [...actividadesTbl]
        let total = 0;
        console.log(rowsAux)
        let indxA = rowsAux.findIndex(r => r.codigoActividadEco === k.original.codigoActividadEco)
        rowsAux.splice(indxA,1)
        setRelAuxID(rowsAux)
        if(k.original.newRow){
            setProductosTbl([])
            setProductSaveTbl([])
            // let auxt = [...actividadesTbl]
            console.log(k.original.codigoActividadEco)
            let indxt = aux.findIndex(x => x.codigoActividadEco === k.original.codigoActividadEco)
            console.log(indxt)
            aux.splice(indxt,1)
            setActividadesTbl(aux)
            let rowsAuxI = [...listActividadesI]
            let indxAI = rowsAuxI.findIndex(r => r.codigoActividadEco === k.original.codigoActividadEco)
            rowsAuxI.splice(indxAI,1)
            setListActividadesI(rowsAuxI)
        }else if(k.original.updateR){
            let indx = aux.findIndex(x => x.codigoActividadEco === k.original.codigoActividadEco)
            console.log(indx)
            aux[indx].updateR = false;
            aux[indx].porcentaje = aux[indx].porcentajeOriginal;
            setActividadesTbl(aux);      
            let rowsAuxU = [...listActividadesU]
            let indxAU = rowsAuxU.findIndex(r => r.codigoActividadEco === k.original.codigoActividadEco)
            rowsAuxU.splice(indxAU,1)
            setListActividadesU(rowsAuxU)     
        }
        aux.forEach((element,i) =>{    
            total = total + parseFloat(element.porcentaje)
        })
        setTotPorcentaje(total)
    }
    const subirArchivoCertificado = (e,v) => {
        // setArchivos(e);   
        toggleLoader(true);

        var data = new FormData()

        data.append('files', e[0])
        data.append('cargar', JSON.stringify(
            {
                idUsuarioAct: ExisteSession.getInfoUsuario().idUsuario,
                token: ExisteSession.getInfoUsuario().token,
                connection: ExisteSession.conexion(),
                RFC: model.RFC,
                tipoArchivo : v
            }
        ))

        
        cargaCertificados('Catalogos/catEmpresas', data, {
            idUsuarioAct: ExisteSession.getInfoUsuario().idUsuario,
            token: ExisteSession.getInfoUsuario().token,
            connection: ExisteSession.conexion(),
            RFC: model.RFC
        }, (respons) => {
            
            if (respons.urlImage) {
                

                if(v===0){
                    setRegistros({
                        ...registroTributario,
                        cerPath: registroTributario.cerPath = respons.urlImage,
                        cerPathPublic: registroTributario.cerPathPublic = respons.urlPublicImage
                    })
                }
                else{
                    setRegistros({
                        ...registroTributario,
                        keyPath: registroTributario.keyPath = respons.urlImage,
                        keyPathPublic: registroTributario.cerPathPublic = respons.urlPublicImage
                    }) 
                }

            }
           
            if(respons.urlImage === ""){
                if(v===0){
                    document.getElementById("UploadFileCER").value = "";
                }else{
                    document.getElementById("UploadFileKEY").value = "";
                }
            }

            toggleLoader(false);
        })
    }

    const guardaCertificados = () => {

        toggleLoader(true);
        let _validation = ValidationFormRegistro(registroTributario);
        if (!_validation) {

            var _save = {
                idEmpresa: Register.ID_empresa,
                cerPath:registroTributario.cerPath,
                keyPath:registroTributario.keyPath,
                keyPassword:registroTributario.keyPassword,
                password:registroTributario.password,
                usuarioCuenta:registroTributario.usuarioCuenta,
                idTipoAutenticacion: registroTributario.idTipoAutenticacion,
                fechaVigencia : registroTributario.fechaVigencia == "" ? null :registroTributario.fechaVigencia,
                activo : registroTributario.activoR,
                filtro_aplicacion: 38,
                filtro_ambiente: ExisteSession.getAmbiente().idAmbiente,
                idUsuarioAct: ExisteSession.getInfoUsuario().idUsuario,
                token: ExisteSession.getInfoUsuario().token,
                connection: ExisteSession.conexion()
            }
            console.log(_save)
            console.log(registroTributario)
            debugger
           
            if(!registroTributario.existeCertificados)
                saveInfo("Catalogos/buzonTributario", _save, (response,tipo, success) => responseSaveCertificado(response,tipo, success));
            else
                updateInfo("Catalogos/buzonTributario", _save, (response,tipo, success) => responseSaveCertificado(response,tipo, success));

        }else{
            fnNotification(['Error: ' + _validation, 'error', true])
            toggleLoader(false);
        }

    }

    const switchShown = () => setShown(!shown);

    const responseSaveCertificado = (response,tipo, success) => {
        if(success === true){
            setRegistros({
                ...registroTributario,
                existeCertificados: registroTributario.existeCertificados = true
            })

            
            document.getElementById("UploadFileCER").value = "";
            document.getElementById("UploadFileKEY").value = "";
        }
        toggleLoader(false);
    }

    
    const descargacertificado = (e,v) => {
        
        let urlFile ="";
        let name = "";
        if(v==0){
            urlFile = registroTributario.cerPath;
            name = model.RFC+".cer";
        }
        else{
            urlFile = registroTributario.keyPath;
            name = model.RFC+".key";

        }

        setRegistros({
            ...registroTributario,
            urlPublica: urlFile
        })

        var _save = {
            urlPublica: registroTributario.urlPublica
        }

        getInfoGral("Catalogos/buzonTributario/UrlCertificado", _save, (response) => cargaCertificasdoUrlPublica(response,name));


        
    }


    const cargaCertificasdoUrlPublica = (response,fileName) => {
       
        axios({
            responseType: 'blob',
            method: 'get',
            url: response.Message,

        }).then(function (response) {

            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download',fileName);
            document.body.appendChild(link);
            link.click();

        }).catch(function (error) {
            console.log("peticion error", error)
        })
        
    }


    const onValueChangeAutenticacion = (event) => {        

        console.log(event.target.value)
        let valorAutenticacion = event.target.value;
    
        setRegistros({ ...registroTributario, idTipoAutenticacion: registroTributario.idTipoAutenticacion = event.target.value })


        console.log({registroTributario})

    }
    const  cleanCredenciales = (event) => {
   

        setRegistros({ ...registroTributario, 
            cerPath: registroTributario.cerPath ='',
            keyPath: registroTributario.keyPath = '',
            fechaVigencia: registroTributario.fechaVigencia = null,
            cerPathPublic : registroTributario.cerPathPublic ='',
            keyPathPublic: registroTributario.keyPathPublic ='',
        })
        
       console.log(registroTributario)
        // updateInfo("Catalogos/buzonTributario", _save, (response,tipo, success) => responseSaveCertificado(response,tipo, success));


    }
    const NameParam = ({name,mandatory}) =><><label>{name}</label><span style={{color:'red'}}>{mandatory ? '*': ''}</span></> 
    const change_Tab = (tab) =>{
        // console.log(tab)
        // console.log(tab.target)
        // console.log(tab.target.id)
            // console.log('inActiv',inActiv)
            // console.log('isTabActiv',isTabActiv)
            // console.log('model.type',model.type)
            // console.log('cambio',nameTab)
            // console.log('tab.target.id',tab.target.id)



            if(tab.target.id !== 'tabActivs'){
                if((inActiv || isTabActiv) && !model.type) setShowConfirm(true)

            }
   
   

        switch(tab.target.id){
            case 'refTabEmpresa':
                setIsVisible(true)
                // setInActiv(false)
                break;
            case 'tabActivs':
                setIsVisible(false)
                setInActiv(true)
                setShowConfirm(false)
                break;
           
            case 'tabAProducts':
                    setIsVisible(false)
                    setShowConfirm(false)
                    setInActiv(true)

                    break;
            case 'tabCertificados':
                    setIsVisible(false)
                    // setInActiv(true)
                    break;
                default:
        }
        setNameTab(tab.target.id)


    }
    const _clickClose = (isCloceModal) => {
        console.log(isCloceModal)

        if(isCloceModal){
            
            console.log('se conserva empresa')
            setTimeout(() => {
                setClassTabActividad('disable')
            setClassTabEmpresa('active')
            setClassTabProducto('disable')
            setClassTabCertificado('disable')
    
            setClassTabContActividad('tab-pane')
            setClassTabContEmpresa('tab-pane active')
            setClassTabContProducto('tab-pane')
            setClassTabContCertificado('tab-pane')   
            cnahgeActive_()
            setInActiv(false)   
            setShowConfirm(false) 
            setTotPorcentaje(0)
            setPorcentaje(0)
            }, );
            

        }else{
            console.log('se conserva actividad')
            setShowConfirm(false)
            setIsVisible(false)
            setClassTabActividad('active')
            setClassTabEmpresa('disable')
            setClassTabProducto('disable')
            setClassTabCertificado('disable')
    
            setClassTabContActividad('tab-pane active')
            setClassTabContEmpresa('tab-pane')
            setClassTabContProducto('tab-pane')
            setClassTabContCertificado('tab-pane')

        }
        // window.$('#modalConfirmaAct').modal('hide');
        setActividadesTbl([])    
    }
    const clickOk = () => {
        console.log('entra',nameTab)
        // setTabEmpresaOk()
        // setTabEmpresaOk(false)
        // setTabProductOk(true)
        // setTaCertifOk(false)
        // if(nameTab === 'refTabEmpresa'){
        //     setClassTabContEmpresa('tab-pane active')
        //     setClassTabContCertificado('tab-pane')
        //     setClassTabContActividad('tab-pane')
        //     setClassTabEmpresa('active')

        // }
        // if(nameTab === 'Certificate'){
        //     setClassTabContCertificado('')
        //     setClassTabContEmpresa('')
        //     setClassTabContActividad('')


        // }
        // setClassTabActividad('')
        // setClassTabEmpresa('')
        // setClassTabProducto('')
        // setClassTabCertificado('')
        // setClassTabContActividad('')
        // setClassTabContEmpresa('')
        // setClassTabContProducto('')
        // setClassTabContCertificado('')  
     
        // setTimeout(() => {
            if(nameTab === 'refTabEmpresa'){
                show_Histori(Register.ID_empresa,true)
                setClassTabActividad('disable')
                setClassTabEmpresa('active')
                setClassTabProducto('disable')
                setClassTabCertificado('disable')
        
                setClassTabContActividad('tab-pane')
                setClassTabContEmpresa('tab-pane active')
                setClassTabContProducto('tab-pane')
                setClassTabContCertificado('tab-pane')   
                cnahgeActive_()
                setInActiv(false)   
                setShowConfirm(false) 
            }
            if(nameTab === 'tabAProducts'){
                setClassTabActividad('disable')
                setClassTabEmpresa('disable')
                setClassTabProducto('active')
                setClassTabCertificado('disable')
        
                setClassTabContActividad('tab-pane')
                setClassTabContEmpresa('tab-pane')
                setClassTabContProducto('tab-pane active')
                setClassTabContCertificado('tab-pane')  
            }
            if(nameTab === 'tabCertificados'){
                setClassTabActividad('disable')
                setClassTabEmpresa('disable')
                setClassTabProducto('disable')
                setClassTabCertificado('active')
        
                setClassTabContActividad('tab-pane')
                setClassTabContEmpresa('tab-pane')
                setClassTabContProducto('tab-pane')
                setClassTabContCertificado('tab-pane active')  
                cnahgeActive_()
                setInActiv(false)
                setShowConfirm(false)
            }  

        // });
        // setShowConfirm(false)
        // window.$('#modalConfirmaAct').modal('hide');
        
    }
    // const _showConfirm = () => {
    //     window.$('#modalConfirmaAct').modal('show');
    // }
    const changeRFC = (event) => {

        if(event.target.value.length > 11){
            searchRFC(event.target.value)
        }

    }
    const showHistori = (event) => show_Histori(Register.ID_empresa,true)
    const showDataSecuencia = (r) => showData_Secuencia(r)
    return (
        <div className="modal fade Emp-modal-lg" id="modalEmpresa" tabIndex="-1" role="dialog" aria-labelledby="modalEmpresaLabel" aria-hidden="true">
            <div className="modal-dialog modal-lg" role="document">
                <div className="modal-content">
                    <div className="modal-header mhead">
                        <button onClick={clickClose} type="button" className="close text-white" data-dismiss="modal" aria-label="Close">
                            {/* <span aria-hidden="true">&times;</span> */}
                            <i className="fa fa-times-circle"></i>
                        </button>
                        <span className="modal-title title-modal-custom-color title-modal-custom" id="modalEmpresaLabel">
                            {!model.type ? "Editar Empresa " : "Agregar Empresa"}
                        </span>
                    </div>
                    <div className="modal-body">
                        {
                        showConfirm ? <div className="mesajeCof">
                        <div>Las actividades y productos no se han configurado</div>
                        <div>¿desea abandonar el tab?</div>
                        </div> :
                        <>
                            <ul className="nav nav-pills nav-justified ar-nav-pills center-block margin-bottom">
                            <li onClick={e => change_Tab(e)} className={classTabEmpresa}  >
                                <a  id="refTabEmpresa" href="#Empresa" ref={rEmpresa} data-toggle="tab" aria-expanded="false">
                                <i className="fa fa-tasks"></i> Empresas
                                </a>
                            </li>
                             
                            {/* feature_DEV_ActividadesProductos  */}
                            <li onClick={e => change_Tab(e)}  className={classTabActividad} id='liActividades'>
                                        <a id='tabActivs' href="#Actividades" data-toggle="tab" aria-expanded="false">
                                            <i className="fa fa-tasks"></i> Actividades
                                        </a>
                            </li>
                            {
                                !model.type ?
                                <li onClick={e => change_Tab(e)} className={classTabProducto} title="">
                                        <a  id="tabAProducts" href="#Productos" data-toggle="tab" aria-expanded="false">
                                            <i className="fa fa-cubes"></i> Productos
                                            </a>
                                </li>
                             
                                  
                                    :
                                    <></>
                            }

                            {
                                !model.type && model.permisoCertificado?
                                    <li  className={classTabCertificado}>
                                            <a id="tabCertificados" href="#Certificados" data-toggle="tab" onClick={e => {change_Tab(e)}} aria-expanded="false">
                                            <i className="fa fa-tasks"></i> Certificados
                                        </a>
                                    </li>
                                :
                                <></>
                            }

                            </ul>
                            <div className="tab-content margin-top">
                                    <div className={classTabContEmpresa} id={'Empresa'}>
                                        <div className="container-fluid">
                                            <div className="col-md-12">
                                                <div className="row">
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label>ID</label>
                                                            <input
                                                                className="form-control"
                                                                id="ID_empresa"
                                                                name="ID_empresa"
                                                                value={Register.ID_empresa}
                                                                placeholder="ID Empresa"
                                                                maxLength="100"
                                                                disabled="disabled"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <NameParam name='Nombre corto:' mandatory={true}/>
                                                            <input
                                                                className="form-control"
                                                                id="nombreCorto"
                                                                name="nombreCorto"
                                                                onChange={handleChange}
                                                                value={Register.nombreCorto}
                                                                placeholder="Nombre Corto"
                                                                maxLength="100"
                                                            // disabled={!model.type}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                        <NameParam name='Empresa:' mandatory={true}/>
                                                            <input
                                                                className="form-control"
                                                                id="nombreLargo"
                                                                name="nombreLargo"
                                                                onChange={handleChange}
                                                                value={Register.nombreLargo}
                                                                placeholder="Empresa"
                                                                maxLength="100"
                                                            // disabled={!model.type}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                        <NameParam name='RFC:' mandatory={true}/>
                                                            <input
                                                                className="form-control"
                                                                id="RFC"
                                                                name="RFC"
                                                                onChange={handleChange}
                                                                value={Register.RFC}
                                                                placeholder="RFC"
                                                                maxLength="100"
                                                                onBlur={changeRFC}
                                                                ref={rRFC}
                                                            // disabled={!model.type}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <div className="row">
                                                    <div className="col-md-6">
                                                    {
                                                        !model.type ? <div className="form-group">
                                                        <NameParam name='Cliente:' mandatory={true}/>
                                                        <Select
                                                            className=""
                                                            id="idCliente"
                                                            isClearable
                                                            isSearchable
                                                            name="idCliente"
                                                            onChange={(event) => handleChangeSelect(event, "idCliente")}
                                                            options={selecctions.Clientes}
                                                            value={selecctions.idCliente}
                                                        />
                                                    </div>: 
                                                        addProveedor ?
                                                        <div className="form-group">
                                                            <NameParam name='Cliente:' mandatory={true}/>
                                                            <Select
                                                                className=""
                                                                id="idCliente"
                                                                isClearable
                                                                isSearchable
                                                                name="idCliente"
                                                                onChange={(event) => handleChangeSelect(event, "idCliente")}
                                                                options={selecctions.Clientes}
                                                                value={selecctions.idCliente}
                                                            />
                                                        </div> :
                                                        <div className="form-group">
                                                            <NameParam name='Cliente:' mandatory={true}/><br />
                                                            <Select
                                                                className=""
                                                                name="idCliente"
                                                                isDisabled={true}
                                                                onChange={(event) => {}}
                                                                options={[]}
                                                                value={Register.RFC.length > 11 ? {value:namtempClient,label:namtempClient}: []}
                                                            />
                                                            {/* <span>{namtempClient}</span> */}
                                                        </div>

                                                    }
                                                

                                                    </div>
                                                    <div className="col-md-6">
                                                    {
                                                        !model.type ?
                                                        <div className="form-group">
                                                            <NameParam name='Proveedor:' mandatory={true}/>
                                                            <Select
                                                                className=""
                                                                id="IdProv"
                                                                isClearable
                                                                isSearchable
                                                                name="IdProv"
                                                                onChange={(event) => handleChangeSelect(event, "IdProv")}
                                                                options={selecctions.Proveedores}
                                                                value={selecctions.IdProv}
                                                            />
                                                        </div> :
                                                        
                                                        addProveedor ?
                                                        <div className="form-group">
                                                            <NameParam name='Proveedor:' mandatory={true}/>
                                                            <Select
                                                                className=""
                                                                id="IdProv"
                                                                isClearable
                                                                isSearchable
                                                                name="IdProv"
                                                                onChange={(event) => handleChangeSelect(event, "IdProv")}
                                                                options={selecctions.Proveedores}
                                                                value={selecctions.IdProv}
                                                            />
                                                        </div> :
                                                        <div className="form-group">
                                                        <NameParam name='Proveedor:' mandatory={true}/><br />
                                                        <Select
                                                            className=""
                                                            name="IdProv"
                                                            isDisabled={true}
                                                            onChange={(event) => {}}
                                                            options={[]}
                                                            value={Register.RFC.length > 11 ? {value:namtempClient,label:namtempClient}: []}

                                                            

                                                        />
                                                        {/* <span>{namtempClient}</span> */}
                                                    </div>

                                                    }
                                                        
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <NameParam name='Estado:' mandatory={true}/>
                                                            <Select
                                                                className=""
                                                                id="idEstado"
                                                                isClearable
                                                                isSearchable
                                                                name="idEstado"
                                                                onChange={(event) => handleChangeSelect(event, "idEstado")}
                                                                options={selecctions.Estados}
                                                                value={selecctions.idEstado}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <NameParam name='Municipio:' mandatory={true}/>
                                                            <Select
                                                                className=""
                                                                id="IdMunicipio"
                                                                isClearable
                                                                isSearchable
                                                                name="IdMunicipio"
                                                                onChange={(event) => handleChangeSelect(event, "IdMunicipio")}
                                                                options={selecctions.Municipios}
                                                                value={selecctions.IdMunicipio}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <NameParam name='C. P.:' mandatory={true}/>
                                                            <label></label>
                                                            <input
                                                                className="form-control"
                                                                id="codigoPostal"
                                                                name="codigoPostal"
                                                                onChange={handleChange}
                                                                value={Register.codigoPostal}
                                                                placeholder="Codigo Postal"
                                                                maxLength="100"
                                                            // disabled={!model.type}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <NameParam name='Colonia:' mandatory={true}/>
                                                            <input
                                                                className="form-control"
                                                                id="colonia"
                                                                name="colonia"
                                                                onChange={handleChange}
                                                                value={Register.colonia}
                                                                placeholder="Colonia"
                                                                maxLength="100"
                                                            // disabled={!model.type} 
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <NameParam name='Dirección:' mandatory={true}/>
                                                            <input
                                                                className="form-control"
                                                                id="direccion"
                                                                name="direccion"
                                                                onChange={handleChange}
                                                                value={Register.direccion}
                                                                placeholder="Direccion"
                                                                maxLength="100"
                                                            // disabled={!model.type} 
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                        <NameParam name='Teléfono:' mandatory={true}/>
                                                            <input
                                                                className="form-control"
                                                                id="telefono"
                                                                name="telefono"
                                                                onChange={handleChange}
                                                                value={Register.telefono}
                                                                placeholder="Telefono"
                                                                maxLength="10"
                                                            // disabled={!model.type} 
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <NameParam name='Zona fiscal:' mandatory={true}/>
                                                            <Select
                                                                className=""
                                                                id="idZonaFiscal"
                                                                isClearable
                                                                isSearchable
                                                                name="idZonaFiscal"
                                                                onChange={(event) => handleChangeSelect(event, "idZonaFiscal")}
                                                                options={selecctions.ZonasFiscales}
                                                                value={selecctions.idZonaFiscal}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <NameParam name='Regimen fiscal:' mandatory={true}/>
                                                            <input
                                                                className="form-control"
                                                                id="idRegimenFiscal"
                                                                name="idRegimenFiscal"
                                                                onChange={handleChange}
                                                                value={Register.idRegimenFiscal}
                                                                placeholder="idRegimenFiscal"
                                                                maxLength="100"
                                                            // disabled={!model.type}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <NameParam name='Tipo compañia:' mandatory={true}/>
                                                            <Select
                                                                className=""
                                                                id="idTipoCompania"
                                                                isClearable
                                                                isSearchable
                                                                name="idTipoCompania"
                                                                onChange={(event) => handleChangeSelect(event, "idTipoCompania")}
                                                                options={selecctions.Companias}
                                                                value={selecctions.idTipoCompania}
                                                            />
                                                        </div>
                                                    </div>
                                                {/* se solicita retirar campo marca  */}
                                                    {/* 
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label>Marca:</label>
                                                            <Select
                                                                className=""
                                                                id="idMarca"
                                                                isClearable
                                                                isSearchable
                                                                name="idMarca"
                                                                onChange={(event) => handleChangeSelect(event, "idMarca")}
                                                                options={selecctions.Marcas}
                                                                value={selecctions.idMarca}
                                                            /> 
                                                        </div>
                                                    </div>*/}
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <NameParam name='Giro:' mandatory={true}/>
                                                            <Select
                                                                className=""
                                                                id="idGiro"
                                                                isClearable
                                                                isSearchable
                                                                name="idGiro"
                                                                onChange={(event) => handleChangeSelect(event, "idGiro")}
                                                                options={selecctions.Giros}
                                                                value={selecctions.idGiro}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                        <NameParam name='Correo:' mandatory={true}/>
                                                            <input
                                                            type="email"
                                                            className="form-control" 
                                                            id="email"
                                                            name="email"
                                                            placeholder="email"
                                                            value={email}
                                                            onChange={onChangeEmail}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <NameParam name="Estatus" /> 
                                                            <Select
                                                                className=""
                                                                id="eststusEmpresa"
                                                                // isClearable
                                                                // isSearchable
                                                                name="eststusEmpresa"
                                                                onChange={(event) => changeStatus(event, "eststusEmpresa")}
                                                                options={allStatus}
                                                                value={statusEmpresa}
                                                            />
                                                    </div>

                                                </div>
                                                <div className="row">
                                                    <div className="col-md-6">

                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                        <div className="border-checkbox-section">
                                                            {/* <div className="border-checkbox-group border-checkbox-group-success">
                                                                <input
                                                                    className="border-checkbox"
                                                                    type="checkbox"
                                                                    id="activo"
                                                                    name="activo"
                                                                    onChange={handleChangeChk}
                                                                    checked={Register.activo} />
                                                                <label className="border-checkbox-label" htmlFor="activo">Activo</label>
                                                            </div> */}
                                                            <div className="border-checkbox-group border-checkbox-group-success">
                                                                <input
                                                                    className="border-checkbox"
                                                                    type="checkbox"
                                                                    id="esPagadora"
                                                                    name="esPagadora"
                                                                    onChange={handleChangeChk}
                                                                    checked={Register.esPagadora} />
                                                                <label className="border-checkbox-label" htmlFor="esPagadora">Es pagadora</label>
                                                            </div>
                                                            <div className="border-checkbox-group border-checkbox-group-success">
                                                                <input
                                                                    className="border-checkbox"
                                                                    type="checkbox"
                                                                    id="manejaPersonal"
                                                                    name="manejaPersonal"
                                                                    onChange={handleChangeChk}
                                                                    checked={Register.manejaPersonal} />
                                                                <label className="border-checkbox-label" htmlFor="manejaPersonal">Maneja personal</label>
                                                            </div>
                                                        </div>

                                                        </div>
                                            

                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label>Formato:</label>
                                                            <Select
                                                                className=""
                                                                id="idFormato"
                                                                isClearable
                                                                isSearchable
                                                                name="idFormato"
                                                                onChange={handleChangeSelect}
                                                                options={selecctions.Formatos}
                                                                value={selecctions.idFormato}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label>Formato complemento:</label>
                                                            <Select
                                                                className=""
                                                                id="idFormatoComplemento"
                                                                isClearable
                                                                isSearchable
                                                                name="idFormatoComplemento"
                                                                onChange={handleChangeSelect}
                                                                options={selecctions.FormatosComplementos}
                                                                value={selecctions.idFormatoComplemento}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label>Formato recibo:</label>
                                                            <Select
                                                                className=""
                                                                id="idFormatoRecibo"
                                                                isClearable
                                                                isSearchable
                                                                name="idFormatoRecibo"
                                                                onChange={handleChangeSelect}
                                                                options={selecctions.FormatosRecibidos}
                                                                value={selecctions.idFormatoRecibo}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label>Folio repse:</label>
                                                            <input
                                                                className="form-control"
                                                                id="folioRepse"
                                                                name="folioRepse"
                                                                onChange={handleChange}
                                                                value={Register.folioRepse}
                                                                placeholder="folio Repse"
                                                                maxLength="100"
                                                            // disabled={!model.type}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label>URL Repse:</label>
                                                            <input
                                                                className="form-control"
                                                                id="urlRepse"
                                                                name="urlRepse"
                                                                onChange={handleChange}
                                                                value={Register.urlRepse}
                                                                placeholder="urlRepse"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6" style={{minHeight:78}}>
                                                        <div className="form-group">
                                                            <label>Archivo repse:</label>
                                                            <input type="file" name='files' id="ArchivoRepse"
                                                                onChange={(e) => subirArchivosRepse(e.target.files)}
                                                                accept="application/pdf" />
                                                                {
                                                                Register.archivoRepsePublic ?
                                                                <>  
                                                                <div className="col-md-12 text-center" style={{fontSize:25}}>
                                                                {/* <span>Descargar Archivo Repse</span> */}
                                                                <i className="fa fa-file" title="Descargar Archivo Repse" onClick={(e) => downloadFileRepse(e)} ></i></div>
                                                                </>
                                                                    :
                                                                    <></>
                                                            }
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label>Sello repse:</label>
                                                            <input type="file" name='files' id="UploadFile"
                                                                onChange={(e) => subirArchivos(e.target.files)}
                                                                accept="image/*" />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label>Objeto social:</label>
                                                            <textarea className="form-control" 
                                                            name="objetoSocial"
                                                            onChange={handleChange} 
                                                            value={Register.objetoSocial} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <label>Logotipo</label>
                                                            <input type="file" accept="image/*"  ref={refinputImg} name="logo" id="logo"
                                                                        onChange={(e) => changeLogo(e)}    

                                                            />
                                                        {
                                                            model.selectView &&<>
                                                            {
                                                                (logoTemp === "" && model.selectView.urlLogo === "" ) ? <></>:
                                                                <div style={{"width": "300px" ,"height": "200px", padding:'10px'}}>
                                                            {
                                                                logoTemp !== '' ?  <img  src={logoTemp} alt="Imagen" width="100%" height="100%" /> :
                                                                <img  src={model.selectView.urlLogo} alt="Imagen" width="100%" height="100%" />
                                                            }
                                                            </div>
                                                            }
                                                            </>
                                                        }
                                                    </div>
                                                </div>
                                                {
                                                ImageURL && 
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <div>&nbsp;</div>
                                                    <div className="row text-center">
                                                        <img className="mx-auto d-block mxw-50" src={"data:application/img;base64," + ImageURL} alt='' ></img>
                                                    </div>
                                                    </div>
                                                </div>
                                                } 
                                                <div className="row">
                                                    <div className="col-md-12 form-group" style={{ "height": "80px" }}></div>
                                                    <div className="col-md-6">
                                                        <span>Fecha modificación: {!model.type ?
                                                            Register.ultAct
                                                            :
                                                            ""
                                                        }</span>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <span>Usuario : {!model.type ?
                                                            Register.usuario
                                                            :
                                                            ""
                                                        }</span>
                                                    </div>
                                                    <div className="col-md-12" style={{ "height": "30px" }}></div>
                                                </div>
                                                <div className="row">
                                                    {
                                                        !model.type &&
                                                        <div className="col-md-12" >
                                                        <div onClick={(e) => showHistori(e)}>
                                                            <div className='styleHist'>Muestra historial</div>
                                                        </div>
                                                        <div style={{borderTop:'solid 1px #ccc',borderBottom:'solid 1px #ccc'}}>
                                                            {showConfirmHist && <div id='laderHist'>Cargando...</div>}
                                                            {showTblHist &&  
                                                            <div id='_tblHist'>
                                                                <div>
                                                                <GeneralTable
                                                                    columns={columsHist(showDataSecuencia)}
                                                                    data={dataHist}
                                                                    action={undefined}
                                                                    doubleClick={() => {}}
                                                                    // isTableEditable={true}
                                                                    isNotPagination={true}
                                                                    // isSampleclick={true}
                                                                    // getSetData={getSetData}
                                                                    // isCompTable={true}
                                                                    // compTbl={(compTable(totPorcentaje))}

                                                                />
                                                                </div>
                                                            </div>}
                                                        </div>
                                                    </div>
                                                    }
                                                </div>
                                                
                                            </div>

                                        </div>
                                    </div>
                                    <div className={classTabContActividad} id={'Actividades'}>
                                    <div className="row" >                            
                                    <div className="col-md-6">
                                    <label>Sector</label>
                                    <Select
                                                                className=""
                                                                // id="idFormatoRecibo"
                                                                //isClearable
                                                                isSearchable
                                                                name="Sector"
                                                                onChange={changeFilterActiv}
                                                                options={optionsSector}
                                                                value={valueSector}
                                                            />
                                    </div>    
                                    <div className="col-md-6">
                                    <label>Subsector</label>
                                    <Select
                                                                className=""
                                                                isSearchable
                                                                name="Subsector"
                                                                onChange={changeFilterActiv}
                                                                options={opcionesSubsector}
                                                                value={valorSubSector}
                                                            />
                                    </div>    
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6">
                                        <span>Rama</span>
                                            <Select
                                                    name="rama"
                                                    isSearchable
                                                    onChange={changeFilterActiv}
                                                    options={optionsRama}
                                                    value={valueRama}
                                                />
                                        </div>    

                                        <div className="col-md-6">
                                        <span>Subrama</span>
                                            <Select
                                                    name="Subrama"
                                                    isSearchable
                                                    onChange={changeFilterActiv}
                                                    options={optionsSubRama}
                                                    value={valueSubrama}
                                                />
                                        </div>    
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6">
                                        <label>Actividad económica</label>
                                            <Select
                                                className=""
                                                // id="idFormatoRecibo"
                                                // isClearable
                                                isSearchable
                                                name="Actividad"
                                                onChange={changeFilterActiv}
                                                options={optionsActividad}
                                                value={valueActividad}
                                            />

                                        </div>
                                        <div className="col-md-5">
                                            <div className='row'>
                                                <div className="col-md-6">
                                                    <label>Fecha vigencia</label>
                                                    <input type="date" ref={fechaA} name="FechaVA"  className="form-control" onChange={changeDate}  value={fechaVigenciaActiv}/>

                                                </div>
                                                <div className="col-md-6">
                                                    <label>Porcentaje</label>
                                                    <input type="number" ref={porcent} className="form-control" onChange={changePorcent} disabled={!valueActividad.value} value={porcentaje}/>

                                                </div>
                                            </div>
                                            
                                        </div>    
                                    
                                        <div className="col-md-1">
                                        <label>&nbsp;</label>
                                        
                                        {
                                            porcentaje > 0 ?
                                            <Buttons
                                            btnAdd={true}
                                            btnAddAction={() => addRowActividad(false)}
                                            btnAddShowIcon={true}
                                            btnAddClass={'btn btn-primary button-circle button-small' }
                                            /> :
                                            <Buttons
                                            btnAdd={true}
                                            btnAddAction={() => {}}
                                            btnAddShowIcon={true}
                                            btnAddClass={'btn btn-succes button-circle button-small disabled'}
                                            /> 
                                        }
                                        </div>                                                    
                                    </div>
                                    <GeneralTable
                                            columns={columsActiv(toProduct,fnDisabledActiv,fnCancelActiv)}
                                            data={actividadesTbl}
                                            action={undefined}
                                            doubleClick={() => {}}
                                            isTableEditable={true}
                                            isNotPagination={true}
                                            isSampleclick={true}
                                            getSetData={getSetData}
                                            isCompTable={true}
                                            compTbl={(compTable(totPorcentaje))}

                                        />         
                                    </div>
                                    <div className={classTabContProducto} id={'Productos'}>
                                        {
                                            actividadesTbl.length > 0 &&
                                            <>
                                                <div className="row titleProd">                                   
                                                    <div className="col-md-2">
                                                        <div className="lblTitleProd"><label>Clave:</label> <span>{actividadProduct.value}</span></div>
                                                    </div>
                                                    <div className="col-md-10">
                                                        <div className="lblTitleProd"><label>Actividad:</label> <span>{actividadProduct.descripcion}</span></div>
                                                    </div>                                       
                                                </div>
                                                <div className="row" >                                   
                                                    <div className="col-md-5">
                                                        <label>Clase</label>
                                                        <Select
                                                                className=""
                                                                isSearchable
                                                                name="clase"
                                                                onChange={changeFilterProducto}
                                                                options={optionsClase}
                                                                value={valueClase}
                                                            />
                                                    </div>
                                                    <div className="col-md-5">
                                                    <label>Producto</label>
                                                    <Select
                                                                className=""
                                                                isSearchable
                                                                name="producto"
                                                                onChange={changeFilterProducto}
                                                                options={optionsProducts}
                                                                value={valueProduct}
                                                            />
                                                    </div>
                                                    <div className="col-md-2">
                                                    <label>Fecha vigencia</label>
                                                    <input type="date" name="FechaVP"  className="form-control" onChange={changeDate} value={fechaVigenciaProd}/>
                                                    </div>
                                                    <div className="col-md-1">
                                                    <label>&nbsp;</label>
                                                        <Buttons
                                                            btnAdd={true} 
                                                            btnAddAction={valueProduct.value ?() => addRowProducto(true) : () =>{}}
                                                            btnAddShowIcon={true}
                                                            btnAddClass={ valueProduct.value  ? 'btn btn-primary button-circle button-small': 'btn btn-succes button-circle button-small disabled' }
                                                            /> 
                                                    </div>
                                                </div>                                
                                            </>
                                        }                            
                                        <div className="row">
                                            <div className="col-md-12">
                                            <GeneralTable
                                                    columns={columsProduct(cancelProduct)}
                                                    data={productosTbl}
                                                    action={undefined}
                                                    doubleClick={() => {}}
                                                    isTableEditable={true}
                                                    isNotPagination={true}
                                                    isSampleclick={true}
                                                    isCompTable={true}
                                                    compTbl={(compTableBtn(totPorcentaje))}
                                            />  
                                            </div>
                                        </div>
                                    </div>
                                    {
                                        !model.type && model.permisoCertificado?
                                            <div className={classTabContCertificado} id={"Certificados"}>
                                                <div className="container-fluid">
                                                    <div className="col-md-12">
                                                        <div className="row">
                                                            <div className="col-md-5">                                               
                                                                <div className="form-group">
                                                                    <label> Archivo .CER</label>
                                                                    <input type="file" name='files' id="UploadFileCER"
                                                                        onChange={(e) => subirArchivoCertificado(e.target.files,0)}
                                                                        accept="cer/*" />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-5">                                               
                                                                <div className="form-group">
                                                                    <label className='col-md-12'>&nbsp;</label>
                                                                    <a href={registroTributario.cerPathPublic} //onClick={(e) => descargacertificado(e,0)} 
                                                                    target='_blank'
                                                                    download={model.RFC+".cer"}
                                                                    className={registroTributario.existeCertificados && registroTributario.cerPath !== "" ? 'show' : 'hide'}> 
                                                                    {model.RFC+".cer"} 
                                                                    </a>


                                                                </div>
                                                            </div>

                                                            <div className="col-md-2">                                               
                                                                <div className="form-group">
                                                                <Buttons
                                                                            btnSave={true} btnSaveAction={() => guardaCertificados()} btnSaveText={"Guardar Certificados"}
                                                                            btnSaveClass={Register.inicio
                                                                                ? "btn btn-primary button-circle button-small "
                                                                                : "btn btn-primary button-circle button-small "}
                                                                            btnSaveShowIcon={true} typeSaved={model.type}
                                                                        />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-md-5">                                                
                                                                <div className="form-group">
                                                                    <label> Archivo .KEY</label>
                                                                    <input type="file" name='files' id="UploadFileKEY"
                                                                        onChange={(e,) => subirArchivoCertificado(e.target.files,1)}
                                                                        accept="key/*" />
                                                                </div>
                                                            </div>

                                                            <div className="col-md-7">                                               
                                                                <div className="form-group">
                                                                <label className='col-md-12'>&nbsp;</label>
                                                                    <a href={registroTributario.keyPathPublic} 
                                                                    target='_blank'
                                                                    download={model.RFC+".key"}
                                                                    className={registroTributario.existeCertificados && registroTributario.keyPath ? 'show' : 'hide'}> 
                                                                    {model.RFC+".key"} 
                                                                    </a>
                                                                </div>
                                                            </div>

                                                        </div>
                                                        <div className="row">
                                                            <div className="col-md-6">                                                
                                                                <div className="form-group">
                                                                <label> Usuario Acceso</label>
                                                                <input
                                                                    className="form-control"
                                                                    id="usuarioCuenta"
                                                                    name="usuarioCuenta"
                                                                    onChange={handleChangeRegistro}
                                                                    placeholder="Usuario"
                                                                    maxLength="100"
                                                                    type="text"
                                                                    value={registroTributario.usuarioCuenta}

                                                                />
                                                                </div>
                                                            </div>
                                                            <div className='col-md-6'>
                                                                <div className='form-group'>
                                                                    <label>Fecha Expiración</label>
                                                                    <input
                                                                    id="fechaVigencia"
                                                                    name="fechaVigencia"
                                                                    type="date"
                                                                    className="form-control" 
                                                                    value={registroTributario.fechaVigencia} 
                                                                    // onChange={(e) => searchDataTbl(e)}
                                                                    onChange={handleChangeRegistro}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-md-5">                                                
                                                                <div className="form-group">
                                                                    <label> Contraseña FIEL</label>
                                                                    <input
                                                                        className="form-control"
                                                                        id="keyPassword"
                                                                        name="keyPassword"
                                                                        onChange={handleChangeRegistro}
                                                                        value={registroTributario.keyPassword}
                                                                        placeholder="Contraseña"
                                                                        maxLength="50"
                                                                        type={shown ? 'text' : 'password'}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-5">                                                
                                                                <div className="form-group">
                                                                    <label> Contraseña ACCESO </label>
                                                                    <input
                                                                        className="form-control"
                                                                        id="password"
                                                                        name="password"
                                                                        onChange={handleChangeRegistro}
                                                                        value={registroTributario.password}
                                                                        placeholder="Contraseña"
                                                                        maxLength="50"
                                                                        type={shown ? 'text' : 'password'}
                                                                    // disabled={!model.type}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className='col-md-2'>
                                                                <div className="form-group">
                                                                    <label>&nbsp;</label>
                                                                    <button  className="btn btn-info button-circle button-small mt25"  onClick={switchShown}>
                                                                    {shown ? <i className="fa fa-eye"></i> : <i className="fa fa-eye-slash"></i>}
                                                                    </button>
                                                                </div>
                                                            </div>

                                                        </div>
                                                        <div className='row'>
                                                            <div className="col-md-12">                                                
                                                                <div className="form-group">
                                                                    <label> Tipo de Autenticación</label>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-4">
                                                                <input
                                                                type="radio"
                                                                name="TipoAutenticacion"
                                                                value="1"
                                                                checked={registroTributario.idTipoAutenticacion === "1"}
                                                                onChange={onValueChangeAutenticacion}
                                                                />
                                                                Archivo
                                                            </div>
                                                            <div className="col-md-4">
                                                                <input
                                                                type="radio"
                                                                name="TipoAutenticacion"
                                                                value="2"
                                                                checked={registroTributario.idTipoAutenticacion === "2"}
                                                                onChange={onValueChangeAutenticacion}
                                                                />
                                                                Contraseña
                                                            </div>
                                                            <div className='col-md-2'>
                                                                <div className="border-checkbox-section">
                                                                    <div className="border-checkbox-group border-checkbox-group-success">
                                                                        <input
                                                                            className="border-checkbox"
                                                                            type="checkbox"
                                                                            id="activoR"
                                                                            name="activoR"
                                                                            onChange={handleChangeRegistroChk}
                                                                            checked={registroTributario.activoR} />
                                                                        <label className="border-checkbox-label" htmlFor="activoR">Activo</label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {registroTributario.idTipoAutenticacion === "2" ?
                                                    <div className='col-md-2'>
                                                        <label> </label>
                                                        <button title='Limpiar certificados'  className="btn btn-info button-circle button-small"  onClick={cleanCredenciales}>
                                                            <i class="fa fa fa-hdd-o"></i> 
                                                        </button>
                                                    </div>
                                                    : null }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        :
                                        <></>
                                    }
                            </div>
                        
                           
                                             
                        </>
                        }
                       
                  </div>
                  {
                        !showConfirm ?  
                        <div className="modal-footer">
                            {
                            isVisible?
                            <Buttons
                                btnSave={true} btnSaveAction={clickSave} btnSaveText={!model.type ? " Modificar" : " Guardar"} btnSaveClass={"btn btn-primary button-circle button-small"} btnSaveShowIcon={true} typeSaved={model.type}
                                btnDelete={!model.type} btnDeleteAction={clickDeleteLocal} btnDeleteText={" Eliminar"} btnDeleteShowIcon={true}
                                btnCancel={true} btnCancelAction={clickClose} btnCancelText={" Cancelar"} btnCancelShowIcon={true} />
                                :
                                <Buttons                 
                                btnCancel={true} btnCancelAction={clickClose} btnCancelText={" Cancelar"} btnCancelShowIcon={true} />
                            }
                        </div> :
                        <div className="modal-footer">
                            <Buttons
                            btnSave={true} btnSaveAction={() => clickOk()} btnSaveText={"Confirmar"} btnSaveClass={"btn btn-primary button-circle button-small"} btnSaveShowIcon={true} btnSaveIconClass={'fa fa-check'}
                            btnCancel={true} btnCancelAction={() => _clickClose()} btnCancelText={" Cancelar"} btnCancelShowIcon={true} />
                        </div>

                    }
                </div>
            </div >
            {/* <ModalConfirma
                clickClose={_clickClose}
                clickOK={clickOk}
            /> */}
        </div>

    );
});

export default ModalMantenimientoEmpresas;