import React from "react";


const BtnAdd = ({ btnAddAction, btnAddClass, btnAddText, btnAddIconClass, btnAddShowIcon }) => {
    return (
        <button
            type="button"
            rel="tooltip"
            className={btnAddClass ? btnAddClass : "button button-primary button-circle button-small"}
            // data-toggle={showModal}
            onClick={btnAddAction}
            data-placement="top"
            title={btnAddText
                    ?
                    btnAddText
                    :
                    "Agregar"


                }
            >
            {btnAddShowIcon
                ?
                <i className={btnAddIconClass ? btnAddIconClass : "fa fa-plus"} alt=""></i>
                :
                <></>}
            {/* {btnAddText
                ?
                btnAddText
                :
                "Aceptar"} */}
        </button>
    )
}

export default BtnAdd
