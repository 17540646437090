import React,{Fragment} from 'react';
import Buttons from '../../../Components/Buttons/Buttons'
import GeneralTable from '../../../Components/GeneralTable'
import { tableColumnsError } from './resources'
const ModalErrores = ({clickClose, dataTblError }) => {
    return (
        <Fragment>
        <div className="modal fade" id="modalMovError" tabIndex="-1" role="dialog" aria-labelledby="modalMovErrorLabel" style={{ overflowY: 'auto', maxHeight: '700px' }} aria-hidden="true">
            <div className="modal-dialog " role="document">
                <div className="modal-content">
                    <div className="modal-header mhead">
                        <div className="row">
                            <div className="col-md-6">
                                <h5 className="modal-title" style={{color: 'white'}} id="modalFrontLabel">Detalles de error</h5>
                                
                            </div>
                            <div className="col-md-6">
                                <button onClick={clickClose} type="button" className="close text-white" data-dismiss="modal" aria-label="Close">
                                    {/* <span aria-hidden="true">&times;</span> */}
                                    <i className="fa fa-times-circle"></i>

                                </button>  
                            </div>
                        </div>
                    </div>
                    <div className="modal-body" role="form">
                        <div className="row">
                            <div className="col-md-12">
                           
                            <div className="text-center">
                             </div>
                                <GeneralTable
                                columns={tableColumnsError}
                                data={dataTblError}
                                action={undefined}
                             
                                doubleClick={(original,values) => {}} 
                                // doubleClick={(original,values) => {}} 
                                // isCpmpBtn={true}
                                // compnnt={compnntCarga}
                                />
                                
                            
                                
                            </div>

                        </div>
                    </div>
                    <div className="modal-footer">
                       
                            <Buttons 
                            btnCancel={true} btnCancelAction={clickClose} btnCancelText={" Cancelar"} btnCancelShowIcon={true}
                            /> 
                    </div>
                </div>
            </div>
        </div>
            
        </Fragment>
    );
};

export default ModalErrores;