import React from 'react'

function SelectSC(props){
    return(
        <select
        id={props.id}
        name={props.name}
        value={props.value}
        className="form-control  dtModal"
        onChange={ (e) =>props.actionChange(e)}
       
      >
          <option className="op1" value="0">Selecciona</option>
        {props.options.map((option,i) => (
          <option key={i} value={option.value} >{option.label}</option> 
        ))}
      </select>
      
         /*   <select
                onChange={ (e) =>props.actionChange(e)}
                id={props.ID} 
                name={props.name}
                className="form-control  dtModal"
                >
                <option className="op1" value="0">Selecciona</option>
                {
                    
                    props.options.map((x,i) => {
                        let resp = [];
                        resp = (x.value === props.value) ? 
                        <option key={i} value={x.value} selected>{x.label}</option> 
                        : <option key={i} value={x.value}>{x.label}</option>  
                        return resp
                        
                    })
                }
            </select>*/

    )
}
export default SelectSC;