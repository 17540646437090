const inicializa = {
    title : 'Alta de Usuarios',
    breadcrum : [{nombre: 'Administración'},{nombre: 'Usuarios'}]
}
const requestUs = {
    idUsuario : 0
}
const tableColumns = [
    { 
        Header: "Nombre",
        accessor: 'nombre',
    },
    { 
        Header: "Apellido Paterno",
        accessor: 'apellidoPat',
    },
    { 
        Header: "Apellido Materno",
        accessor: 'apellidoMat',
    },
    { 
        Header: "Tipo usuario",
        accessor: 'descrTipoUsuario',
    },
    { 
        Header: "Correo",
        accessor: 'correo',
    },
    { 
        Header: "Fecha alta",
        accessor: 'fechaAlta',
    },
    { 
        Header: "Estatus correo",
        accessor: 'estatusCorreo',
    },
    { 
        Header: "Usuario activo",
        accessor: 'activo',
    }
]
const calasTabs = {
    tabUser : 'active',
    tabVista : '',
    tabMotivosCorreo:'',
    tabContUser : 'tab-pane active',
    tabContVista : 'tab-pane',
    tabContMotivosCorreo:'tab-pane',
    tabUexpanded : 'true',
    tabVexpanded : 'false'
}
const swichTabs = {
    tabUser : '',
    tabVista : 'active',
    tabMotivosCorreo:'',
    tabContUser :  'tab-pane',
    tabContVista : 'tab-pane active',
    tabContMotivosCorreo: 'tab-pane',
    tabUexpanded : 'false',
    tabVexpanded : 'true'
}


const tableData = []




export { tableColumns, inicializa, tableData , requestUs,calasTabs,swichTabs}