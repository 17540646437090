import { getInfo, saveInfo, updateInfo, deleteInfo, getInfoGral } from '../../ApiRequests/req_CRUD'
import { ExisteSession, toggleLoader } from '../../ApiRequests/general'
import { fnNotification } from '../../ApiRequests/Notification'


const getDisponibilidad = (request, addCollection) => {
    
    
    request = prepareRequest(request)
    getInfo('Tesoreria/'+request.typeDisponibilidad ,request,(collection) =>  {        
        addCollection(collection)
    })
}
const getFiltros = (request, addVistaAccionHandler) => {
    
    request = prepareRequest(request)    
    getInfoGral('Tesoreria/FiltroDisponibilidad/'+request.typeFilter,request, (res) =>{
        addVistaAccionHandler(res.Data)
    })
}
const saveDisponibilidad = (request,addCollectionDisponibilidad) => {
    
    request = prepareRequest(request)
    request.idType ?
    saveInfo('Tesoreria/'+request.typeDisponibilidad ,request,(respons) =>  {
        addCollectionDisponibilidad(respons)
    }):
    updateInfo('Tesoreria/'+request.typeDisponibilidad ,request,(respons) =>  {
        addCollectionDisponibilidad(respons)
    })
    
    

}
const deleteDisponibilidad = (request,addCollecti) => {
    request = prepareRequest(request)
   
    deleteInfo('Tesoreria/'+request.typeDisponibilidad ,request,(respons) =>  {
        addCollecti(respons)
    })
    
    
    

}

const validaForm = (request,) => {

    let response = true;
    let t = ''; 
    if(request.empresa !== undefined && request.empresa !== null)
    {
        if(request.empresa.value === 0 || request.empresa.value === undefined || request.empresa.value === null){ 
            t = t === '' ? ` Empresa no puede ser vacio ` : t + ` Empresa no puede ser vacio \n`
            response = false;   
        }
    }else{
        t = t === '' ? ` Empresa no puede ser vacio ` : t + ` Empresa no puede ser vacio \n`
        response = false; 
    }
    if(request.cuenta !== undefined && request.cuenta !== null)
    {
        if(request.cuenta.value  === 0 || request.cuenta.value === undefined  || request.cuenta.value  === null){        
            t = t === '' ? ` Cuenta no puede ser vacio ` : t + ` Cuenta no puede ser vacio \n`
            response = false; 
        };
    }else{
        t = t === '' ? ` Cuenta no puede ser vacio ` : t + ` Cuenta no puede ser vacio \n`
        response = false; 
    }

    if(request.isSaved)
    {    
        if(request.importe !== undefined && request.importe !== null)
        {     
            if(parseInt(request.importe)  < 1  || request.importe === undefined  || request.importe === undefined  || request.importe  === null) {
                t = t === '' ? ` Disponible no puede ser menor a 1 ` : t + ` Disponible no puede ser menor a 1 \n`
                response = false; 
            }
        }else{
            t = t === '' ? ` Disponible no puede ser vacio` : t + ` Disponible no puede ser vacio `        
            response = false; 
        }
    }

    if(!request.isSaved)
    {
        if(request.nuevoImporte !== undefined && request.nuevoImporte !== null)
        {     
            if(parseInt(request.nuevoImporte)  < 1  || request.nuevoImporte === undefined  || request.nuevoImporte === undefined  || request.nuevoImporte  === null) {
                t = t === '' ? ` Nueva Disponibilidad no puede ser menor a 1 ` : t + ` Nueva Disponibilidad no puede ser menor a 1 \n`
                response = false; 
            }
        }else{
            t = t === '' ? ` Nueva Disponibilidad no puede ser vacio` : t + ` Nueva Disponibilidad no puede ser vacio `        
            response = false; 
        }
    }
    
    !response && fnNotification([`Error ` + t   , 'error', true])    
    
    return response
}

const prepareRequest = request => {
    request.idUsuarioAct = ExisteSession.getInfoUsuario().idUsuario
    request.token = ExisteSession.getInfoUsuario().token
    request.connection = ExisteSession.conexion()

    return request;
}

export { 
    getDisponibilidad,
    getFiltros,
    validaForm,
    saveDisponibilidad,
    deleteDisponibilidad
}