const inicializa = {
    title : 'Programacion de ingresos',
    breadcrum : [{nombre: 'Conf.GRAL'},{nombre: 'Tesorería'},{nombre: 'Programación de ingresos'}]
}
const columData = [
    {
        id:'Empresa',
        Header:'Empresa',
        accessor: 'compania'
    },
    {
        id:'Cuenta',
        Header:'Cuenta',
        accessor:'numeroDeCuenta'
    },
    {
        id:'Banco',
        Header:'Banco',
        accessor:'banco'
    },
    
    {
        id:'Fecha Ingreso',
        Header:'Fecha Ingreso',
        accessor:'FechaIngresoSt'
    },
    {
        id:'Importe',
        Header:'Importe',
        accessor:'importe'
    },
    
  


]
const columDataMasiva = [
    {
        id:'Empresa',
        Header:'Empresa',
        accessor: 'compania'
    },
    {
        id:'Cuenta',
        Header:'Cuenta',
        accessor:'numeroDeCuenta'
    },
    {
        id:'Banco',
        Header:'Banco',
        accessor:'banco'
    },
    
    {
        id:'Fecha Ingreso',
        Header:'Fecha Ingreso',
        accessor:'FechaIngresoSt'
    },
    {
        id:'Importe',
        Header:'Importe',
        accessor:'importe'
    },
    {
        id:'Estatus',
        Header:'Estatus',
        accessor:'estatus'
    },
    {
        id:'Error',
        Header:'Error',
        accessor:'mensajeError'
    },
  


]

export { inicializa, columData, columDataMasiva }  