import React, { Fragment, useEffect, useState } from 'react';
import GeneralTable from '../../../Components/GeneralTable';
import { getInfo, saveInfo, updateInfo, deleteInfo } from '../../../ApiRequests/req_CRUD'
import { ExisteSession, toggleLoader } from '../../../ApiRequests/general'
import { idOrigenWeb } from '../../../Constants/application'
import Header from '../../../Components/Header';
import { inicializa, tableColumns } from './resources'
import Modal from './ModalMantenimientoMarcas.jsx'
import Buttons from '../../../Components/Buttons/Buttons'
import Session from '../../../Application/session'
import { fnNotification } from '../../../ApiRequests/Notification'

const GiroEmpresa = () => {
    const [model, setModel] = useState({
        idMarca: 0,
        descripcion: "",
        codigoMarca: "",
        type: true,
        selectView: null,
        apps: 1,
        typeAction: 'inicia',
        filtro_aplicacion: 38,
        filtro_ambiente: ExisteSession.getAmbiente().idAmbiente,
        idOrigen: idOrigenWeb,
        idUsuarioAct: ExisteSession.getInfoUsuario().idUsuario,
        token: ExisteSession.getInfoUsuario().token,
        connection: ExisteSession.conexion(),
    });
    const [reload, setReload] = useState(true);

    const [dataTableUs, setDataTableUs] = useState([]);


    useEffect(() => {
        toggleLoader(true);
        getInfo("Catalogos/catMarcas", model, (response) => LoadTable(response));
    }, [reload])

    const LoadTable = (response) => {

        // console.log("datatable", response);
        if (response)
            setDataTableUs(response);
        toggleLoader(false);
    }
    const showModal = () => {
        setModel({
            ...model,
            selectView: null,
            type: true,
            idMarca: 0
        });
        window.$('#modalMarca').modal('show');
    }

    const handleDoubleClick = (originals, tableValues) => {
        // console.log("doble click",originals.idVista);
        setModel({
            ...model,
            selectView: originals,
            type: false,
            typeAction: '',
            idMarca: originals.idMarca
        });
        window.$('#modalMarca').modal('show');
    }

    const saveDataUser = dataNewUser => {
        toggleLoader(true);
        console.log("respuesta marca", dataNewUser);
        if (dataNewUser.codigoMarca && dataNewUser.descripcion) {
            if (model.type) {
                saveInfo("Catalogos/catMarcas", dataNewUser, (response) => responseSave(response));
            }
            else {
                updateInfo("Catalogos/catMarcas", dataNewUser, (response) => responseSave(response));
            }
        }
        else {
            fnNotification(['Error: ' + (!dataNewUser.codigoMarca ? " El codigo es necesario. " : "") + (!dataNewUser.descripcion ? " La descripcion es necesaria." : ""), 'error', true]);
            toggleLoader(false);
        }
    }
    const clickDelete = (dataNewUser) => {
        toggleLoader(true);
        deleteInfo("Catalogos/catMarcas", dataNewUser, (response) => responseDelete(response));
    }


    const responseSave = (response) => {
        setModel({
            ...model,
            selectView: null,
            type: true,
            idMarca: 0
        });
        setReload(!reload);
        window.$('#modalMarca').modal('hide');
        toggleLoader(false);
    }
    const responseDelete = (response) => {
        setModel({
            ...model,
            selectView: null,
            type: true,
            idMarca: 0
        });
        setReload(!reload);
        window.$('#modalMarca').modal('hide');
        toggleLoader(false);
    }

    const actionClose = () => {
        setModel({
            ...model,
            selectView: null,
            type: true,
            idMarca: 0
        });
        window.$('#modalMarca').modal('hide');
    }


    return (
        <Fragment>
            <Header title={inicializa.title + ' ' + Session.getInfoAmbiente()} breadcrum={inicializa.breadcrum} />

            <div className="lx-container container">
                <div className="panel panel-primary animated fadeInUp">
                    <div className="panel-heading">
                        <div className="row">
                            <div className="col-md-6">
                                <span>Mantenimiento Marcas</span>

                            </div>
                            <div className="col-md-6 btnF">
                                <Buttons btnAdd={true} btnAddAction={showModal} btnAddText={true} btnAddShowIcon={true} />

                            </div>

                        </div>
                    </div>
                    <div className="panel-body">
                        <GeneralTable
                            columns={tableColumns}
                            // updateSearch={updateTableSearch} 
                            data={dataTableUs}
                            action={undefined}
                            doubleClick={handleDoubleClick}
                        />
                    </div>

                </div>

            </div>
            <Modal model={model} saveDataUser={saveDataUser} actionClose={actionClose} clickDelete={clickDelete} />
        </Fragment>

    );
}

export default GiroEmpresa;