import React, { useState, useEffect } from 'react';
import { ExisteSession } from '../../../ApiRequests/general'
import Buttons from '../../../Components/Buttons/Buttons';

const ModalGiroEmpresa = ({ saveDataUser, model, actionClose, clickDelete }) => {

    const [Register, setModel] = useState({
        idGiro: 0,
        giro: "",
        valorAdicional: "",
        tipo: false,
        filtro_aplicacion: 38,
        filtro_ambiente: ExisteSession.getAmbiente().idAmbiente,
        idUsuarioAct: ExisteSession.getInfoUsuario().idUsuario,
        token: ExisteSession.getInfoUsuario().token,
        connection: ExisteSession.conexion(),
    });

    useEffect(() => {
        if (model && model.selectView) {
            setModel({
                ...Register,
                idGiro: model.selectView.idGiro,
                giro: model.selectView.giro,
                valorAdicional: model.selectView.valorAdicional,
                tipo: model.selectView.tipo
            });
        }
        else {
            setModel({
                ...Register,
                idGiro: 0,
                giro: "",
                valorAdicional: "",
                tipo: false
            });
        }
    }, [model])

    const clickSave = () => saveDataUser(Register)
    const clickDeleteLocal = () => clickDelete(Register)
    const clickClose = () => actionClose() 

    const handleChange = (event) => {
        setModel({ ...Register, [event.target.name]: event.target.value })
        // console.log("modelo Register", Register);
    }



    return (
        <div className="modal fade" id="modalGiro" tabIndex="-1" role="dialog" aria-labelledby="modalGiroLabel" aria-hidden="true">
            <div className="modal-dialog modal-lg" role="document">
                <div className="modal-content">
                    <div className="modal-header mhead">
                        <button onClick={clickClose} type="button" className="close text-white" data-dismiss="modal" aria-label="Close">
                            {/* <span aria-hidden="true">&times;</span> */}
                            <i className="fa fa-times-circle"></i>
                        </button>
                        <span className="modal-title title-modal-custom-color title-modal-custom" id="modalGiroLabel">
                            {!model.type ? "Editar Giro " : "Agregar Giro"}
                        </span>
                    </div>
                    <div className="modal-body">

                        {/* inicio */}
                         <div className="form-group">
                            <label>Giro</label>
                            <input
                                className="form-control"
                                id="giro"
                                name="giro"
                                onChange={handleChange}
                                value={Register.giro}
                                placeholder="Giro" 
                                maxLength="100"/>
                        </div>

                        <div className="form-group">
                            <label>Valor Adicional</label>
                            <input
                                className="form-control"
                                id="valorAdicional"
                                name="valorAdicional"
                                onChange={handleChange}
                                value={Register.valorAdicional}
                                placeholder="Valor Adicional"
                                maxLength="20" />
                        </div>
                       
                        {/* fin */}
                    </div>


                    <div className="modal-footer">
                        <Buttons
                            // btnAdd={true} btnAddAction={clickSave} btnAddText={!model.type ? " Modificar" : " Guardar"} btnAddClass={"btn btn-primary"} btnAddShowIcon={true}
                            btnSave={true} btnSaveAction={clickSave} btnSaveText={!model.type ? " Modificar" : " Guardar"} btnSaveClass={"btn btn-primary button-circle button-small"} btnSaveShowIcon={true} typeSaved={model.type}
                            btnDelete={!model.type} btnDeleteAction={clickDeleteLocal} btnDeleteText={" Eliminar"} btnDeleteShowIcon={true}
                            btnCancel={true} btnCancelAction={clickClose} btnCancelText={" Cancelar"} btnCancelShowIcon={true} />

                    </div>
                </div>
            </div>
        </div >
    );
};

export default ModalGiroEmpresa;