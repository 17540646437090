const inicializa = {
    title : 'Administración de Tipos de Componentes',
    breadcrum : [{nombre: 'Administrador'},{nombre: 'TiposComponentes'}]
}
const tableColumns = [
    { 
        Header: "IDTipoComp",
        accessor: 'idTipoComp',                    
    },
    { 
        Header: "Tipo Componente",
        accessor: 'descripcion',
        
    },    
    { 
        Header: "Estatus",
        accessor: 'estatus',       
        
    }

    
]



const tableData = []

export { tableColumns, inicializa, tableData }