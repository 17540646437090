import React, { Fragment, useEffect, useState } from 'react';
import GeneralTable from '../../../Components/GeneralTable';
import { getInfo, saveInfo, updateInfo, deleteInfo, getInfoGral } from '../../../ApiRequests/req_CRUD'
import { ExisteSession, toggleLoader } from '../../../ApiRequests/general'
import { idOrigenWeb } from '../../../Constants/application'
import Header from '../../../Components/Header';
import { inicializa, tableColumns } from './resources'
import Buttons from '../../../Components/Buttons/Buttons'
import Session from '../../../Application/session'
import Select from 'react-select'

const GeneracionArchivosDispersion = () => {
    const [model, setModel] = useState({
        idArchivo: 0,
        type: true,
        selectView: null,
        configuracionSelected: null,
        apps: 1,
        typeAction: 'inicia',
        filtro_aplicacion: 38,
        filtro_ambiente: ExisteSession.getAmbiente().idAmbiente,
        idOrigen: idOrigenWeb,
        idUsuarioAct: ExisteSession.getInfoUsuario().idUsuario,
        token: ExisteSession.getInfoUsuario().token,
        connection: ExisteSession.conexion(),
    });
    const [dataTableUs, setDataTableUs] = useState([]);
    const [reload, setReload] = useState(true);
    const [selecctions, setSelecction] = useState({
        tiposConfiguraciones: [],
        idConfiguracion: null,
        ListEmpresasFront: [],
        ListEmpresasTes: [],
        ListEmpresasDisp: [],
        idEmpresa: null,
        disponibilidad: 0
    });

    const tableColumnsPemisions = [
        {
            Header: "Tesorera",
            accessor: 'empresa',
        },
        {
            Header: "Banco",
            accessor: 'banco',
        },
        {
            Header: "Cuenta",
            accessor: 'numeroDeCuenta',
        },
        {
            Header: "Importe",
            accessor: 'importe',

        },
        {
            Header: "Seleccionar",
            Cell: k => (
                <div className="border-checkbox-section">
                    <div className="border-checkbox-group border-checkbox-group-success">
                        <input
                            onChange={e => handleChangeChkTable(k.row.original, e.target.checked, k.gotoPage)}
                            checked={k.row.original.checked}
                            className="border-checkbox"
                            type="checkbox"
                            id={"chkAPlicarPF" + k.row.original.idCuenta}
                        />
                        <label className="border-checkbox-label"
                            htmlFor={"chkAPlicarPF" + k.row.original.idCuenta}>&nbsp;</label>
                    </div>
                </div>)
        }]

    const tableColumns = [
        {
            Header: "Tesorera",
            accessor: 'empresa',
        },
        {
            Header: "Banco",
            accessor: 'banco',
        },
        {
            Header: "Cuenta",
            accessor: 'numeroDeCuenta',
        },
        {
            Header: "Importe",
            accessor: 'importe',

        }]

    const IsPermited = (typeBtn, strT) => {
        let response = false;
        let permisos = []
        ExisteSession.getPermisos().forEach(element => {
            if (element.idVista === ExisteSession.getView()) permisos.push(element)
        })
        if (typeBtn === 2) response = permisos.find(p => p.idAccion === 2).idEstatus
        if (typeBtn === 3) {
            if (false && permisos.find(p => p.idAccion === 2).idEstatus) response = true;
            if (!false && permisos.find(p => p.idAccion === 3).idEstatus) response = true;
        }
        if (typeBtn === 4) response = permisos.find(p => p.idAccion === 4).idEstatus
        return response;
    }
    useEffect(() => {
        // toggleLoader(true);
        getInfoGral("Facturacion/FiltroMatriz/TipoConfiguracion", {
            idUser: ExisteSession.getInfoUsuario().idUsuario,
            connection: ExisteSession.conexion(),
            idUsuarioAct: ExisteSession.getInfoUsuario().idUsuario,
            typeFilter: "TipoConfiguracion",
            tabla: 'conMatrizTbl',
            columna: 'idTipoConfiguracion'
        }, (response) => LoadSpecial(response.Data));

    }, [reload])

    const LoadSpecial = (response) => {
        console.log("respuesta", response)
        if (response && response[0]) {
            setSelecction({
                ...selecctions,
                tiposConfiguraciones: selecctions.tiposConfiguraciones = response[0].ListCatGenerales,
                ListEmpresasFront: selecctions.ListEmpresasFront = response[0].ListEmpresas.filter(E => E.idT === 1),
                ListEmpresasTes: selecctions.ListEmpresasTes = response[0].ListEmpresas.filter(E => E.idT === 2),
                ListEmpresasDisp: selecctions.ListEmpresasDisp = response[0].ListEmpresas.filter(E => E.idT === 5)
            });
        }
        toggleLoader(false);
    }

    const Load = (response, _label, _value, _array, _showsLabel) => {
        console.log("respuesta", response);
        // var _load = [];
        // if (response && response.length > 0) {
        //     if (_showsLabel) {
        //         for (let index = 0; index < response.length; index++) {
        //             let label = response[index][_label]
        //             _showsLabel.forEach(element => {
        //                 label += " - " + response[index][element]
        //             });
        //             _load.push({ value: response[index][_value], label: label });
        //         }
        //     }
        //     else {
        //         for (let index = 0; index < response.length; index++)
        //             _load.push({ value: response[index][_value], label: response[index][_label] });
        //     }
        // }
        // setSelecction({
        //     ...selecctions,
        //     [_array]: selecctions[_array] = _load
        // });
    }

    const showModal = () => {
        // if (saveButtonShow) {
        //     setModel({
        //         ...model,
        //         configuracionSelected: null,
        //         type: true,
        //         idArchivo: model.idArchivo
        //     });
        //     window.$('#modalDispersionConfiguracion').modal('show');
        // }
        // else {
        //     setModel({
        //         ...model,
        //         selectView: null,
        //         type: true,
        //         idArchivo: 0
        //     });
        //     window.$('#modalDispersion').modal('show');
        // }
        if (IsPermited(2, 'Add')) {
            console.log("add", dataTableUs)
        }
        else {
            console.log("dalse", dataTableUs)
        }
    }
    const handleChangeChkTable = (data, event) => {
        let _array = [];
        let _select = dataTableUs.findIndex(element => element.idCuenta === data.idCuenta);
        for (let index = 0; index < dataTableUs.length; index++) {
            _array.push(dataTableUs[index]);
        }
        _array[_select].checked = !_array[_select].checked;
        setDataTableUs(_array);
    }

    const handleDoubleClick = (originals, tableValues) => {
        // setModel({
        //     ...model,
        //     selectView: originals,
        //     type: false,
        //     typeAction: '',
        //     idArchivo: originals.idArchivo
        // });
        // window.$('#modalDispersion').modal('show');
    }

    const actionBtn = val => {
    }

    const handleChange = (event) => {
        setSelecction({ ...selecctions, [event.target.name]: selecctions[event.target.name] = event.target.value })
    }

    const tipoConfiguracion = (e) => {
        setSelecction({
            ...selecctions,
            idConfiguracion: e
        })
        // setTMatrizT(e.value === 1 ? 'Frentes' : 'Tesoreras')
    }
    const empresaSeleccion = (e) => {
        // setTMatrizT(e.value === 1 ? 'Frentes' : 'Tesoreras')
        setSelecction({
            ...selecctions,
            idEmpresa: selecctions.idEmpresa = e
        })

        getInfo("Tesoreria/CargaMasivaDisponibilidad", {
            idEmpresa: e.value,
            connection: ExisteSession.conexion(),
            idUsuarioAct: ExisteSession.getInfoUsuario().idUsuario,
        }, (response) => loadDisponibilidad(response));

        getInfo("Facturacion/GeneracionArchivoDispersion", {
            idEmpresa: e.value,
            connection: ExisteSession.conexion(),
            idUsuarioAct: ExisteSession.getInfoUsuario().idUsuario,
        }, (response) => loadTable(response));
        console.log("Empresa", e)
    }

    const loadDisponibilidad = (response) => {
        if (response && response[0]) {
            setSelecction({
                ...selecctions,
                disponibilidad: selecctions.disponibilidad = response[0].importe
            })
        }
    }
    const loadTable = (response) => {
        console.log("loadTable", response)
        if (response)
            setDataTableUs(response);
        else
            setDataTableUs([]);
    }

    return (
        <Fragment>
            <Header title={inicializa.title + ' ' + Session.getInfoAmbiente()} breadcrum={inicializa.breadcrum} />

            <div className="lx-container container">
                <div className="panel panel-primary animated fadeInUp">
                    <div className="panel-heading">
                        <div className="row">
                            <div className="col-md-6">
                                <span>Mantenimiento Archivos Dispersion</span>

                            </div>
                            <div className="col-md-6 btnF">
                                {/* <Buttons btnAdd={true} btnAddAction={showModal} btnAddText={true} btnAddShowIcon={true} /> */}

                            </div>

                        </div>
                    </div>
                    <div className="panel-body">
                        <div className="col-md-12">
                            <div className="row">
                                <div className="col-md-3">
                                    <Select
                                        name='tipoConfiguracion'
                                        onChange={e => tipoConfiguracion(e)}
                                        options={selecctions.tiposConfiguraciones}
                                        value={selecctions.idConfiguracion}
                                    />
                                </div>
                                <div className="col-md-3">
                                    <Select
                                        name='empresas'
                                        onChange={e => empresaSeleccion(e)}
                                        options={selecctions.idConfiguracion && selecctions.idConfiguracion.value == 1 ? selecctions.ListEmpresasFront : selecctions.idConfiguracion && selecctions.idConfiguracion.value == 2 ? selecctions.ListEmpresasTes : []}
                                        value={selecctions.idEmpresa}
                                    />
                                </div>
                                <div className="col-md-3">
                                    {/* <Buttons btnAdd={true} btnAddAction={showModal} btnAddIconClass={"fa fa-cog"} btnAddText={true} btnAddShowIcon={true} /> */}
                                    {/* <Buttons btnAdd={true} btnAddAction={showModal} btnAddIconClass={"fa fa-cog"} btnAddText={true} btnAddShowIcon={true} /> */}
                                    <button
                                        type="button"
                                        rel="tooltip"
                                        className={"button button-primary button-circle button-small"}
                                        onClick={showModal}
                                        data-placement="top"
                                        title={"Agregar"}>
                                        <i className={"fa fa-cog"} alt=""></i>
                                    </button>
                                </div>
                                <div className="col-md-3">
                                    <input
                                        className="form-control"
                                        id="disponibilidad"
                                        name="disponibilidad"
                                        onChange={handleChange}
                                        value={selecctions.disponibilidad}
                                        placeholder="disponibilidad"
                                        maxLength="100"
                                        disabled={IsPermited(2, 'Add') ?"":"disabled"} />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-12" style={{height:30}} ></div>
                        <div className="col-md-12" style={{ height: 30 }} ></div>
                        <GeneralTable
                            columns={tableColumns}
                            columns={IsPermited(2, 'Add') ? tableColumnsPemisions : tableColumns}
                            // updateSearch={updateTableSearch} 
                            data={dataTableUs}
                            doubleClick={handleDoubleClick}
                        />
                    </div>
                </div>
            </div>
        </Fragment>

    );
}

export default GeneracionArchivosDispersion;