import React from 'react';
import GeneralTable from './../../../Components/GeneralTable'
function TabVistas({dataRelPerfilVista,handleChangeChkAll1,handleChangeChk,handleChangeChkbx}) {
    const paramsCheckAll = {
        title: 'Todas',
        padding: '0 0 0 38%',
    }
    const columns = [
    
        { 
            Header: "Vista",
            accessor: 'vista',
        },
        { 
            Header: "Accion",
            accessor: 'accion',
        },
        {
            id: "CheckAll",
            Header: "CheckRow",
        }
   
    ]
    return (
        <div>
             <GeneralTable
                                columns={columns}
                                // updateSearch={updateTableSearch} 
                                data={dataRelPerfilVista} 
                                action={undefined} 
                                doubleClick={() =>{}}
                                handleChangeChkAll={handleChangeChkAll1}
                                handleChangeChkbx={handleChangeChkbx}
                                paramsCheckAll={paramsCheckAll}

                            />
        </div>
    );
}

export default TabVistas;