import React, { Fragment} from 'react';
import {columnsDetail,columnsCabecero} from './resources'
import { Table} from 'antd';
import 'antd/dist/antd.css'


const Detalle = ({dataCabecero,dtDetalle}) => {
    return (
        <Fragment>
            <div className="modal fade" id="modalDetalle" tabIndex="-1" role="dialog" aria-labelledby="modalDetalle" aria-hidden="true">
                <div className="modal-dialog custom-lg" role="document">
                <div className="modal-content">
                    <div className="modal-header mhead">
                    <div className="row">
                        <div className="col-md-6">
                        <h5 className="modal-title" id="modalDetalleLabel">Detalle</h5>
                        </div>
                        <div className="col-md-6">
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                        
                        </div>
                    </div>
                        
                    </div>
                    <div className="modal-body">
                            <div className="titleDetail">Cabecero</div>
                            <Table columns={columnsCabecero} dataSource={dataCabecero} pagination={false}/> 
                            {/* <Tableheadboard headboardColumns={headboardColumns} dataCabecero={dataCabecero} /> */}
                            {/* <TableModalDetail columnsDetalle={columnsDetalle} dtDetalle={dtDetalle} /> */}
                            <br />
                            <div className="titleDetail">Detalle</div>
                            <Table columns={columnsDetail} dataSource={dtDetalle} pagination={false} scroll={{ y: 240 }} />
                    </div>
                    <div className="modal-footer">
                    <button type="button" className="btn btn-secondary" data-dismiss="modal">Cerrar</button>
                    </div>
                </div>
                </div>
            </div>
        </Fragment>
    );
};


export default Detalle;