import { getInfo,getInfoErase, downloadHelp,cargaArvhivo,saveInfo,updateInfo, deleteInfo } from '../../../ApiRequests/req_CRUD'
import { ExisteSession, toggleLoader } from '../../../ApiRequests/general'
import { fnNotification } from '../../../ApiRequests/Notification'







const getInfoAnios = (request, addCollection) => {
    request = prepareRequest(request)
    getInfo('Catalogos/CatAnios',request, (res) =>{
        addCollection(res)
    })
}

const saveInfoAnios = (request, addCollection) => {
    request = prepareRequest(request)
    saveInfo("Catalogos/CatAnios",request,(response,type,success) =>{
        addCollection(response,type,success)
    })
}
const updateInfoAnios = (request,addCollection) => {
    request = prepareRequest(request)
    updateInfo("Catalogos/CatAnios",request,(response,type,success) =>{
        console.log(response,type,success)
        addCollection(response,type,success)
    })
}

const deleteInfoAnios = (request,addCollection) => {
    request = prepareRequest(request)
    deleteInfo("Catalogos/CatAnios",request,(response,type,success) =>{
        console.log(response,type,success)
        addCollection(response,type,success)
    })
}

const prepareRequest = request => {
    request.idUsuarioAct = ExisteSession.getInfoUsuario().idUsuario
    // request.token = ExisteSession.getInfoUsuario().token
    request.connection = ExisteSession.conexion()

    return request;
}
export { getInfoAnios, saveInfoAnios, updateInfoAnios, deleteInfoAnios}


