import Moment from 'react-moment'
const inicializa = {
    title: 'Buzón Tributario',
    breadcrum: [{ nombre: 'Buzón Tributario' }, { nombre: 'Seguimiento Buzon Tributario' }]
}


const getDateFormat = (fecha) => {
    var _split = fecha.split('T')
    return _split[0];
}
const cambioData = (_obj, event, propiedad, tipoFecha) => {
    if (tipoFecha) {
        console.log("event", event.target.value);
        _obj[propiedad] = event.target.value;
        _obj['editado'] = true;
    }
    else {
        _obj[propiedad] = event.target.value;
        _obj['editado'] = true;
    }
}
const tableColumns = [
    {
        Header: "Razón Social",
        accessor: 'razonSocial',
    },
    {
        Header: "Tipo Empresa",
        accessor: 'tipoEmpresa',
    },
    {
        Header: "Prioridad",
        accessor: 'nivelPrioridad',
    },
    {
        Header: "Descripción de requerimiento",
        Cell: k => (
            <div className="form-group">
                <input
                    onChange={(event) => { cambioData(k.row.original, event, 'descripcionRequerimiento') }}
                    className="form-control"
                    type="text"
                    id={"inpDescripcionRequerimiento-" + k.row.original.idSeguimiento}
                    Value={k.row.original.descripcionRequerimiento ? k.row.original.descripcionRequerimiento : null}
                />
                <label htmlFor={"inpDescripcionRequerimiento-" + k.row.original.idSeguimiento}>&nbsp;</label>
            </div>)
        // accessor: 'descripcionRequerimiento',
    },
    {
        Header: "Fecha de notificación",
        Cell: k => (
            <div className="form-group">
                <input
                    onChange={(event) => { cambioData(k.row.original, event, 'fechaNotificacion', true) }}
                    className="form-control"
                    type="date"
                    id={"inpFechaNotificacion-" + k.row.original.idSeguimiento}
                    defaultValue={k.row.original.fechaNotificacion ? getDateFormat(k.row.original.fechaNotificacion) : null}
                />
                <label htmlFor={"inpFechaNotificacion-" + k.row.original.idSeguimiento}>&nbsp;</label>
            </div>)
        // accessor: 'Fecha de notificación',
    },
    {
        Header: "Fecha de Lectura Notificación",
        Cell: k => (
            <div className="form-group">
                <input
                    onChange={(event) => { cambioData(k.row.original, event, 'fechaLecturaNotificacion', true) }}
                    className="form-control"
                    type="date"
                    id={"inpFechaLecturaNotificacion-" + k.row.original.idSeguimiento}
                    defaultValue={k.row.original.fechaLecturaNotificacion ? getDateFormat(k.row.original.fechaLecturaNotificacion) : null}
                />
                <label htmlFor={"inpFechaLecturaNotificacion-" + k.row.original.idSeguimiento}>&nbsp;</label>
            </div>)
        // accessor: 'fechaLecturaNotificacion',
    },
    {
        Header: "Fecha inicio de atención",
        Cell: k => (
            <div className="form-group">
                <input
                    onChange={(event) => { cambioData(k.row.original, event, 'fechaInicioAtencion', true) }}
                    className="form-control"
                    type="date"
                    id={"inpFechaInicioAtencion-" + k.row.original.idSeguimiento}
                    defaultValue={k.row.original.fechaInicioAtencion ? getDateFormat(k.row.original.fechaInicioAtencion) : null}
                />
                <label htmlFor={"inpFechaInicioAtencion-" + k.row.original.idSeguimiento}>&nbsp;</label>
            </div>)
        // accessor: 'fechaInicioAtencion',
    },
    {
        Header: "Plazo Limite",
        Cell: k => (
            <div className="form-group">
                <input
                    onChange={(event) => { cambioData(k.row.original, event, 'plazoLimite', true) }}
                    className="form-control"
                    type="date"
                    id={"inpPlazoLimite-" + k.row.original.idSeguimiento}
                    defaultValue={k.row.original.plazoLimite ? getDateFormat(k.row.original.plazoLimite) : null}
                />
                <label htmlFor={"inpPlazoLimite-" + k.row.original.idSeguimiento}>&nbsp;</label>
            </div>)
        // accessor: 'plazoLimite',
    },
    {
        Header: "Fecha de Termino",
        Cell: k => (
            <div className="form-group">
                <input
                    onChange={(event) => { cambioData(k.row.original, event, 'fechaTerminoAtencion', true) }}
                    className="form-control"
                    type="date"
                    id={"inpFechaTerminoAtencion-" + k.row.original.idSeguimiento}
                    defaultValue={k.row.original.fechaTerminoAtencion ? getDateFormat(k.row.original.fechaTerminoAtencion) : null}
                />
                <label htmlFor={"inpFechaTerminoAtencion-" + k.row.original.idSeguimiento}>&nbsp;</label>
            </div>)
        // accessor: 'fechaTerminoAtencion',
    },
    {
        Header: "Estatus",
        accessor: 'estatus',
    },
    {
        Header: "Comentarios de personal",
        Cell: k => (
            <div className="form-group">
                <input
                    onChange={(event) => { cambioData(k.row.original, event, 'comentario') }}
                    className="form-control"
                    type="text"
                    id={"inpComentario-" + k.row.original.idSeguimiento}
                    Value={k.row.original.comentario ? k.row.original.comentario : null}
                />
                <label htmlFor={"inpComentario-" + k.row.original.idSeguimiento}>&nbsp;</label>
            </div>)
        // accessor: 'comentario',
    },
    {
        Header: "Personal Asignado",
        accessor: 'personalAsignado',
    }
]

const tableDataLocal = [
    {
        'idSeguimiento': 10,
        'razonSocial': 'uno',
        'tipoEmpresa': 'dos',
        'prioridad': 'tres',
        'descripciondrequerimiento': 'cuatro',
        'fechadenotificacion': 'cinco',
        'fechaLecturaNotificacion': 'seis',
        'fechaInicioAtencion': 'siete',
        'plazoLimite': 'ocho',
        'fechaTermino': 'nueve',
        'estatus': 'diez',
        'comentariosPersonal': 'once',
        'personalAsignado': 'doce',
    }
]

const tableColumnsModal = [
    {
        Header: "Fecha Modificacion",
        accessor: 'fechaAct',
        Cell: (k) =>(
            k.row.original.fechaAct ? 
              <>
                <span>
                  <Moment format="YYYY-MM-DD HH:mm">
                    {k.row.original.fechaAct}
                  </Moment>
                </span>
              </> : 
              <>
                <span></span>
              </>
            ),
    },
    {
        Header: "Razón Social",
        accessor: 'nom_largo',
    },
    {
        Header: "Tipo Empresa",
        accessor: 'tipoEmpresa',
    },
    {
        Header: "Prioridad",
        accessor: 'NivelPrioridad',
    },
    {
        Header: "Comentarios de personal",
        accessor: "comentario",
      },
      {
        Header: "Personal Asignado",
        accessor: "persolAsignado",
      },
      {
        Header: "Estatus",
        accessor: "estatus",
      },
    {
        Header: "Descripción de requerimiento",
        accessor: 'descripcionRequerimiento',
    },
    {
        Header: "Fecha Inicio Atención",
        accessor: 'fechaInicioAtencion',
        Cell: (k) =>(
            k.row.original.fechaInicioAtencion ? 
              <>
                <span>
                  <Moment format="YYYY-MM-DD HH:mm">
                    {k.row.original.fechaInicioAtencion}
                  </Moment>
                </span>
              </> : 
              <>
                <span></span>
              </>
            ),
    },
    {
        Header: "Plazo Limite",
        accessor: 'fechaLimite',
        
        Cell: (k) =>(
            k.row.original.fechaLimite ? 
              <>
                <span>
                  <Moment format="YYYY-MM-DD HH:mm">
                    {k.row.original.fechaLimite}
                  </Moment>
                </span>
              </> : 
              <>
                <span></span>
              </>
            ),
    },
    {
        Header: "Fecha de Termino",
        accessor: 'fechaTerminoAtencion',
        Cell: (k) =>(
            k.row.original.fechaTerminoAtencion ? 
              <>
                <span>
                  <Moment format="YYYY-MM-DD HH:mm">
                    {k.row.original.fechaTerminoAtencion}
                  </Moment>
                </span>
              </> : 
              <>
                <span></span>
              </>
            ),
    }
]

const tableData = []

//#endregion



export { tableColumns, inicializa, tableData, tableColumnsModal, tableDataLocal }