import React,{Fragment,useEffect, useState} from 'react';
import {inicializa,tableColumns} from './resources'
import {fnNotification} from '../../../ApiRequests/Notification'
import Session from '../../../Application/session'
import Header from '../../../Components/Header';
import Container from '../../../Components/Container/ContainerInitial'
import GeneralTable from '../../../Components/GeneralTable';
import {getCuentas,_UpdateCuenta} from './service'
import ModalCuentaBancaria from './ModalCuentaBancaria'
import Buttons from '../../../Components/Buttons/Buttons';
import { toggleLoader } from '../../../ApiRequests/general';

const CatCuentasBancarias = () => {
    const [empresas, setEmpresas]      = useState([])
    const [empresaModal, setEmpresaModal]      = useState('')
    const [showModal, setShowModal]    = useState(false)
    const [isComodin, setIsComodin]    = useState(false)
    const [cuenta, setCuenta]          = useState()  
    const [cuentaSave, setCuentaSave]          = useState()  
    const [activo,setActivo]           = useState(0)
    const [isEdit,setIsEdit]           = useState(0)
    const [idCuenta,setIdCuenta]       = useState(0)
    const [pagadoras,setPagadoras]     = useState([]);
    const [cuentasAll,setCuentasAll]   = useState([]);
    const [cuentasEmpresa,setCuentasEmpresa]   = useState([]);


    useEffect(() =>{
        getCuentas({},respCuentas=>{   
            console.log('cuentas ',respCuentas)

                setCuentasAll(respCuentas)                       
                // console.log('auxCuentas',respCuentas)

            })
        // getEmpresas({
        //     esPagadora : 1
        // },resp=>{
        // toggleLoader(false)
        //     console.log('empresas',resp)
        //     let auxPagadora = []
        //     resp.forEach(p => auxPagadora.push({label:p.nombreCorto,value:p.ID_empresa}))
        //     setPagadoras(auxPagadora)
        //     setEmpresas(resp)
           

        // })
    },[])

    const onShowModal = (original,values,type) =>{
        if(type)
        {
            console.log(original,values)
            setIdCuenta(original.idCuenta)
            setCuenta(original.banco+"-"+original.numerodecuenta)
            setActivo(values.activo)
            setEmpresaModal(original.nombreCorto)
            setIsComodin(original.comodin)
            // let auxCuemta = cuentasAll.find(x => x.m)
            // setCuentasEmpresa()
        }else
        {
            setCuenta(0)
            setActivo(false)
        }
  
        setIsEdit(type)
        setShowModal(true)
    }
    const changeCheck = (e) => {
        setIsComodin(e.target.checked)

    }
    const onCloseModal = () =>  setShowModal(false)
    const updateCuenta = () =>{
        console.log('CuentaBancaria',)
        console.log('isComodin', isComodin)
        debugger
        _UpdateCuenta({
            idCuenta:idCuenta,
            comodin:isComodin
        },(response,t,succes) =>{
            console.log(t)
            console.log(response)
            console.log(succes)
            if(succes){
        setShowModal(false)

                getCuentas({},respCuentas=>{   
                    
                        setCuentasAll(respCuentas)                       
                        // console.log('auxCuentas',respCuentas)
        
                    })
            }
        })
    }
    const BtnAddRel = (e) => {
        return<>
        {/* <Buttons 
            btnAdd ={true} btnAddAction  = {(e) => onShowModal(cuenta,false,false)} btnAddText="Guardar" btnAddShowIcon={true} 
        />  */}
        </>
    }
    const ContBody = () => {
        return (
            <div className="row">
            <div className="col-md-2"> </div>
            <div className="col-md-8">
                <div className="mt-5">
                {
                <GeneralTable
                columns={tableColumns}
                data={cuentasAll}
                // action={undefined}
                doubleClick={(original,values) => onShowModal(original,values,true)} 
                // isNotPagination={true}
                // pageSize={500}
                isCpmpBtn={true}
                // compnnt={idTipoFormato === 2 && showTblSSM  ?  BtnConfirmSS : <div></div>}
                compnnt={BtnAddRel}
                // isCompTable={true}
                // compTbl={BtnConfirmSS()}

                />
            } 

            </div>

            </div>
            <div className="col-md-2"> </div>

</div> 
        )
    }
    return (
        <Fragment>
        <Header title={inicializa.title +' '+ Session.getInfoAmbiente()} breadcrum={inicializa.breadcrum}/>
        <Container title={inicializa.title} contBody={<ContBody />} />
        
        <ModalCuentaBancaria 
            show={showModal}
            updateCuenta={updateCuenta}
            onCloseModal={onCloseModal}
            // changeAnio={changeCuenta}
            empresaModal={empresaModal}
            changeCheck={changeCheck}
            cuenta={cuenta}
            isComodin={isComodin}
            isEdit={isEdit}
    /> 
    </Fragment>
    );
};

export default CatCuentasBancarias;