import React,{useState, useEffect, Fragment, cloneElement} from 'react';
import {tableColumns,inicializa} from './resources'
import {
    getMonitorNotificaciones,
    getTipoNotificaciones

} from './services'
import GeneralTable from '../../../Components/GeneralTable';
import Header from '../../../Components/Header';
import Session from '../../../Application/session'
import Buttons from '../../../Components/Buttons/Buttons'
import {fnNotification} from '../../../ApiRequests/Notification';
import SelectC from '../../../Components/Select/SelectSC';
import {ExisteSession, toggleLoader} from '../../../ApiRequests/general'

const MonitorNotificaciones = () => {
    const [dataSource,setDataSource]   = useState([])
    const [dataSourceOrigen,setDataSourceOrigen]             = useState([])
    const [idOrigen,setIdOrigen]                 = useState('')
    const [descripcion,setDescription]       = useState('')
    const [idEstatus,setIdEstatus]           = useState(false)
    const [idMotivo,setidMotivo]             = useState(0)
    
    useEffect(() => {
        toggleLoader(true);
        getTipoNotificaciones({tabla:'controlNotificacionesTbl'},collection=>{

            setDataSourceOrigen(collection);
            setTimeout(() => {
                toggleLoader(false);
               }, 500); 
        });
        
        
    },[])
    const handleChange = e =>{ 
        if(!isNullOrWhiteSpace(e.target.value)){

            setIdOrigen(e.target.value);
            if(e.target.value!="0"){
            toggleLoader(true);
            getMonitorNotificaciones({idOrigen:idOrigen}, collection=>{
                setDataSource(collection);
                setTimeout(() => {
                    toggleLoader(false);
                   }, 1000); 
            })
        }else{
            setDataSource([]);
            setTimeout(() => {
                toggleLoader(false);
               }, 1000); 
        }
           
        }else{
            setDataSource([]);
            setTimeout(() => {
                toggleLoader(false);
               }, 1000); 
        }
            
        // setModel({...model, [e.target.name] : e.target.value})
        // if(e.target.name === 'idPerfil'){
        //   console.log(e.target.name,e.target)
        //   getRelPVistaAccion({idPerfil: e.target.value},collection =>{
        //       setDataRelViewUs(collection)})
        // } 
      }

    const isNullOrWhiteSpace=(val)=>{
        var i=0;
      
        if(val==undefined)
        i++;
        if(val==null)
        i++;

        if(val!=null && val!=undefined){
            if(val.toString().trim()=='')
            i++;

        }

        if(i>0)
         return true;
        else
        return false;

    }
    return (
        <Fragment>
        <Header title={inicializa.title + ' ' + Session.getInfoAmbiente()} breadcrum={inicializa.breadcrum}/>
        <div className="lx-container container">
        <div className="panel panel-primary animated fadeInUp">
            <div className="panel-heading">
                <div className="row">
                    <div className="col-md-6">
                        <span>Monitor de Notificaciones</span>                   

                    </div>
                    <div className="col-md-6 btnF">
                        {/* <Buttons btnAdd={true} btnAddAction={showModal} btnAddText={'Agregar'} btnAddShowIcon={true}/> */}
                    </div>

                </div>
                </div>
                <div className="panel-body">
                    <div className="row">
                        <div className="col-md-3">
                            <div className='row'>
                                <div className="col-md-2">
                                <div className="form-group">
                                    <label>Origen</label>
                                    </div>
                                </div>
                                <div className="col-md-10">
                                    <div className="form-group">
                                        <SelectC
                                            actionChange={handleChange}
                                            ID="cmbOrigen"
                                            name="cmbOrigen"
                                            options={dataSourceOrigen}
                                            value={idOrigen}
                                        />
                                    </div>
                                </div>
                            </div>
                            
                           
                        </div>
                        <div className="col-md-3">
                            
                        </div>
                        <div className="col-md-3">
                            
                        </div>
                        <div className="col-md-3">
                            
                        </div>
                    </div>
                    <br/>
                    <GeneralTable
                        columns={tableColumns}
                          // updateSearch={updateTableSearch} 
                        data={dataSource} 
                        action={undefined} 
                        doubleClick={ e=>{}}
                    />
                </div>  
        </div>
      </div> 
        {/* <ModalMotivoCarreo
        isUpdate={isUpdate}
        clickClose={clickClose}
        clickDelete={clickDelete}
        clickSave={clickSave}
        handleChange={handleChange}
        idMotivo={idMotivo}
        titulo={titulo}
        descripcion={descripcion}
        idEstatus={idEstatus}
        /> */}
      </Fragment>
      
    );
};

export default MonitorNotificaciones;