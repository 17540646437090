import React from 'react';
import GeneralTable from './../../../Components/TableMotivosCorreoUsuario'
function TabMotivosCorreo({dataRelMotivosCorreo,handleChangeChkAllMtv,handleChangeChk,handleChangeChkbxMtv}) {
    const paramsCheckAll = {
        title: 'Todas',
        padding: '0 0 0 38%',
    }
    const columns = [
       
        { 
            Header: "Motivos",
            accessor: 'titulo',
        },
        {
            id: "CheckAll",
            Header: "CheckRow"
        }
   
    ]
    return (
        <div>
             <GeneralTable
                                columns={columns}
                                // updateSearch={updateTableSearch} 
                                data={dataRelMotivosCorreo} 
                                action={undefined} 
                                doubleClick={() =>{}}
                                handleChangeChkAllMtv={handleChangeChkAllMtv}
                                handleChangeChkbx={handleChangeChkbxMtv}
                                paramsCheckAll={paramsCheckAll}
                               

                            />
        </div>
    );
}

export default TabMotivosCorreo;