import React, {useEffect, useState} from 'react';
import Buttons from '../../../Components/Buttons/Buttons';

//#region Administracion de Vistas
const inicializa = {
    title : 'Mantenimiento Empresas',
    breadcrum : [{nombre: 'Catalogos'},{nombre: 'Mantenimiento Empresas'}]
}
const dataGroup = [{
    value:1,
    cve:'2408',
    descripcion:'Servicio de transporte terrestre de pasajeros a través de Internet, mediante plataformas tecnológicas, aplicaciones informáticas y similares',
    label:'Grupo 1'
},
{
    value:2,
    cve:'2409',
    descripcion:'Servicio de entrega de alimentos preparados a través de Internet, mediante plataformas tecnológicas, aplicaciones informáticas y similares.   ',
    label:'Grupo 2'

}
,
{
    value:3,
    cve:'2410',
    descripcion:'Servicios de hospedaje a través de Internet, mediante plataformas tecnológicas, aplicaciones nformáticas y similares',
    label:'Grupo 3'
}

]
const datasubGroup = [{
    value:1,
    cve:'2408',
    descripcion:'Servicio de transporte terrestre de pasajeros a través de Internet, mediante plataformas tecnológicas, aplicaciones informáticas y similares',
    label:'Subgrupo 1'
},
{
    value:2,
    cve:'2409',
    descripcion:'Servicio de entrega de alimentos preparados a través de Internet, mediante plataformas tecnológicas, aplicaciones informáticas y similares.   ',
    label:'Subgrupo 2'

}
,
{
    value:3,
    cve:'2410',
    descripcion:'Servicios de hospedaje a través de Internet, mediante plataformas tecnológicas, aplicaciones nformáticas y similares',
    label:'Subgrupo 3'
}

]
const dataActividad = [{
    value:1,
    cve:'2408',
    descripcion:'Servicio de transporte terrestre de pasajeros a través de Internet, mediante plataformas tecnológicas, aplicaciones informáticas y similares',
    label:'Actividad 1'
},
{
    value:2,
    cve:'2409',
    descripcion:'Servicio de entrega de alimentos preparados a través de Internet, mediante plataformas tecnológicas, aplicaciones informáticas y similares.   ',
    label:'Actividad 2'

}
,
{
    value:3,
    cve:'2410',
    descripcion:'Servicios de hospedaje a través de Internet, mediante plataformas tecnológicas, aplicaciones nformáticas y similares',
    label:'Actividad 3'
}

]
const dataProduct = [{
    value:1,
    cve:'715702',
    descripcion:'Servicio de carros blindados',
    label:'Actividad 1'
},
{
    value:2,
    cve:'915705',
    descripcion:'Servicio de transporte de dinero',
    label:'Actividad 2'

}
,
{
    value:3,
    cve:'915702',
    descripcion:'Servicio de almacenamiento de dinero',
    label:'Actividad 3'
}

]

const tableColumns = [
    { 
        Header: "Con FIEL",
        accessor: 'strFiel',
    },
    { 
        Header: "Nombre Corto",
        accessor: 'nombreCorto',
    },
    { 
        Header: "Nombre Largo",
        accessor: 'nombreLargo',
    },
    { 
        Header: "Usuario",
        accessor: 'usuario',
    },
    { 
        Header: "RFC",
        accessor: 'RFC',
    },
    { 
        Header: "Estatus",
        accessor: 'estatus',
        
    },
    { 
        Header: "Config. Actividades",
        accessor: 'estatusActividades',
        Cell: k =>{ 
            return <div><span>{k.row.original.estatusActividades}</span></div> 
        }
    },
    { 
        Header: "Cliente",
        accessor: 'cliente',
    },
    
    { 
        Header: "Proveedor",
        accessor: 'proveedor',
    },
   
    { 
        Header: "Estado",
        accessor: 'estado',
    },
    { 
        Header: "Direccion",
        accessor: 'direccion',
    },
    { 
        Header: "Colonia",
        accessor: 'colonia',
    },
    { 
        Header: "Codigo Postal",
        accessor: 'codigoPostal',
    },
    { 
        Header: "Tipo Compañia",
        accessor: 'tipoCompania',
    },
    { 
        Header: "Telefono",
        accessor: 'telefono',
    },
    { 
        Header: "Formato",
        accessor: 'formato',
    },
    { 
        Header: "Giro",
        accessor: 'giro',
    },
    { 
        Header: "Codigo Marca",
        accessor: 'codigoMarca',
    },
    { 
        id:'Objeto Social',
        Header: "Objeto Social",
        Cell: k =>{ 
            const [showPop, setShowPop] = useState(false);
            const [stLAst,setStLAst] = useState(false);
            const [isParF,setIsParf] = useState(false);
            const caraCt = k.row.original.objetoSocial.length;

            const onMoueFn = (status) => {
                console.log(caraCt)
                if(k.rows.length === parseInt(k.row.id) + 1){
                    setStLAst(true);
                }
                setShowPop(status);
                setIsParf(caraCt > 0 && caraCt >= 22 ? status : true);

            }
            useEffect(() =>{
                const caraCt1 = k.row.original.objetoSocial.length;
                let resp = false;
                resp = caraCt1 >= 22 ? false : true
                setIsParf(resp);
            },[k.row.original.objetoSocial])

            const showString = () =>{
                let resp = k.row.original.objetoSocial;
                return resp
            }

            const showStringS = () =>{
                let resp = k.row.original.objetoSocial.substring(0,17) + '...';
                return resp;
            }
            return (
                <div onMouseOver={() => onMoueFn(true)} onMouseOut={() => onMoueFn(false)} onFocus={() => onMoueFn(true)}  style={{position: 'relative'}}>
                <div >  
                    { !isParF ? 
                    <input type="text" style={{border: 'none', background:'transparent', height: 'max'}} value={showStringS()} onChange={() =>{}}/> 
                    :
                    <input type="text" style={{border: 'none', background:'transparent', height: 'max'}} value={showString()} onChange={() =>{}}/>}
                    {showPop && <GblTxt datText={k.row.original.objetoSocial} stLAst={stLAst} />}
                </div>
                </div>)
        },
        accessor: 'objetoSocial',
        
    },{
        Header:"Fecha vigencia Fiel",
        accessor:'sFechaVigencia'
    }
]

const GblTxt = ({datText,stLAst}) => {
    let isTxt = datText !== "" ? true : false;
    let txtSt = stLAst ? "clsGlast" : "clsGlb" 
    return(        
        isTxt ? datText.length >= 22 
        ?
        <div 
        style={
        datText.length > 159 && datText.length <= 477 && stLAst
        ?
        {left:'-300%',right:'30%'} 
        :
        datText.length > 477  && datText.length <= 1321 && stLAst
        ?
        {left:'-630%',right:'10%', fontSize:'12px'} 
        : 
        datText.length > 1321 && stLAst
        ?
        {left:'-650%',right:'8%', fontSize:'11px'} 
        :
        datText.length > 420 && !stLAst 
        ?
        {left:'-300%'} :
        {}}
        className={txtSt}>{datText}</div>  
        : 
        <></>  
        :
        <></>  

    )
}



const columsHist = (showDataSecuencia) => {
    return [
    { 
        Header: "Campo",
        Cell: k =>{ 
            return  <div>
                    {
                        k.row.original.isDetail ? 
                        <div>
                            {/* <label>-</label> */}
                            <span>{k.row.original.descripcion}</span>

                        </div> :<div  onClick={(e) => showDataSecuencia(k)}>
                            <label style={{cursor:'pointer'}}>{k.row.original.descripcion.replace(" ","")}</label>
                        </div>
                    }
                   
                
            </div>
            
        },
        accessor: 'descripcion',
    },
    { 
        Header: "Secuencia",
        Cell: k =>{
            return  <div>
                {
                    k.row.original.isDetail ?
                    <span>{k.row.original.secuencia}</span> :
                    <label>{k.row.original.secuencia}</label>
                }
            </div> 
        },
        accessor: 'secuencia',
    },
    { 
        Header: "valor anterior",
        Cell: k =>{
            return  <div>
                {
                    k.row.original.isDetail ?
                    <span>{k.row.original.valorAnterior}</span> :
                    <label>{k.row.original.valorAnterior}</label>
                }
            </div> 
        },
        accessor: 'valorAnterior',
    },
    { 
        Header: "valor nuevo",
        Cell: k =>{
            return  <div>
                {
                    k.row.original.isDetail ?
                    <span>{k.row.original.valorNuevo}</span> :
                    <label>{k.row.original.valorNuevo}</label>
                }
            </div> 
        },
        accessor: 'valorNuevo',
    },
    { 
        Header: "Fecha cambio",
        Cell: k =>{
            return  <div>
                {
                    k.row.original.isDetail ?
                    <span>{k.row.original.fechaAct}</span> :
                    <label>{k.row.original.fechaAct}</label>
                }
            </div> 
        },
        accessor: 'fechaAct',
    }

]}

/*Cambios feature_DEV_ActividadesProductos */

const columsActiv = (toProduct,fnDisabledActiv,fnCancelActiv) => {

    return [
        {   id:'cveid',
        Header: () => (<div> Clave </div>),
        accessor: 'codigoActividadEco',
        Cell: k =>{ 
            return  <span>{k.row.original.codigoActividadEco}</span>
            
        }
        },
        {   id:'actv',
        Header: ({dt}) => (
            <div>
                Actividad económica
            </div>
        ),
        accessor: 'actividadEconomica',
        Cell: k =>{ 
            return <div className="txtdAEE"><span>{k.row.original.actividadEconomica}</span></div> 
        }
        },
        {
            id: "inputPorcent",
            Header: ({dt}) => (
                <div>
                    Porcentaje  
                </div>
            ),
            accessor: 'porcentaje',
    
        },
        
        {
            id:'Accion',
            Header: (k) => {
                // console.log(k)
                return(
                    <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        Acción
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </div>
                )},
            Cell: k =>{
                return(
                    <div>
                        <div>
                            <Buttons
                                    btnSave={true} btnSaveAction={() =>  toProduct(k.row)} btnSaveText={"Ir a productos"} btnSaveShowIcon={true} btnSaveIconClass='fa fa-cubes'
                                    btnDelete={!k.row.original.newRow && !k.row.original.updateR} btnDeleteAction={() => fnDisabledActiv(k.row)} btnDeleteText={"Eliminar"} btnDeleteShowIcon={true}           
                                    btnCancel={k.row.original.newRow || k.row.original.updateR} btnCancelAction={() => fnCancelActiv(k.row)} btnCancelText={"eliminar"} btnCancelShowIcon={true}
                                />
                        </div> 
                    </div>
                )
            }
        }
        ]
} 
 
const columsProduct = (cancelProduct) => {
    return [
        {   id:'codigoProducto',
        Header: ({dt}) => (
            <div>
                Clave
            </div>
        ),
        accessor: 'codigoProducto',
        Cell: k =>{ 
            return  <span>{k.row.original.codigoProducto}</span>
            
        }
        },
        {   id:'productoServicio',
        Header: ({dt}) => (
            <div>
                Descripción
            </div>
        ),
        accessor: 'productoServicio',
        Cell: k =>{ 
            return <div className="txtdAEE"><span>{k.row.original.productoServicio}</span></div> 
        }
        },
        {
            id:'Acción',
            Header: 'Acción',
            Cell: k => (
                <div>
                    
                    <div>
                        <Buttons
                            // btnDelete={!k.row.original.isEditDetail || k.row.original.isNewRow} btnDeleteAction={() =>deleteUpdateDetail(k)} btnDeleteText={" Eliminar"} btnDeleteShowIcon={true}
                            btnDelete={true} btnDeleteAction={() =>  cancelProduct(k.row)} btnDeleteText={"Cancelar"} btnDeleteShowIcon={true}
                            />
                    </div> 
    
    
                </div>
            )
        }
        
        
        ]

}
const arrayCampoHist = [
    {
        idCampoH:1,
        descripConfig:'nom_corto',
        descrip_Modal:'nombreCorto',
        descripJson:   'Psnom_Corto' 
    },
    {
        idCampoH:2,
        descripConfig:'nom_largo',
        descrip_Modal:'nombreLargo',
        descripJson:   'Psnom_largo' 
        
    },
    {
        idCampoH:3,
        descripConfig:'RFC_empresa',
        descrip_Modal:'RFC',
        descripJson:   'PsRFC_empresa' 
    },
    {
        idCampoH:4,
        descripConfig:'cliente_id',
        descrip_Modal:'idCliente',
        descripJson:   'PsCliente_ID' 
    },
    {
        idCampoH:5,
        descripConfig:'prov_id',
        descrip_Modal:'IdProv',
        descripJson:   'PsProv_Id' 
    },
    {
        idCampoH:6,
        descripConfig:'EntidadFed_ID',
        descrip_Modal:'idEstado',
        descripJson:   'PsEntidadFed_ID' 
    },
    {
        idCampoH:7,
        descripConfig:'Municipio_ID',
        descrip_Modal:'IdMunicipio' ,
        descripJson:   'PsMunicipio_ID'
    },
    {
        idCampoH:8,
        descripConfig:'cod_postal',
        descrip_Modal:'codigoPostal',
        descripJson:   'PsCod_postal' 
    },
    {
        idCampoH:9,
        descripConfig:'colonia',
        descrip_Modal:'colonia',
        descripJson:   'PsColonia' 
    },
    {
        idCampoH:10,
        descripConfig:'direccion',
        descrip_Modal:'direccion',
        descripJson:   'PsDireccion' 
    },
    {
        idCampoH:11,
        descripConfig:'telefono',
        descrip_Modal:'telefono',
        descripJson:   'PsTelefono' 
    },
    {
        idCampoH:12,
        descripConfig:'zona_fiscal_id',
        descrip_Modal:'idZonaFiscal',
        descripJson:   'PsZona_Fiscal_id' 
    },
    {
        idCampoH:13,
        descripConfig:'SAT_idRegimenFiscal',
        descrip_Modal:'idRegimenFiscal',
        descripJson:   'PsSat_idRegimenFiscal' 
    },
    {
        idCampoH:14,
        descripConfig:'idTipoCompania',
        descrip_Modal:'idTipoCompania',
        descripJson:   'PsidTipoCompania' 
    },
    {
        idCampoH:15,
        descripConfig:'idGiro',
        descrip_Modal:'idGiro',
        descripJson:   'PsIdGiro' 
    },
    {
        idCampoH:16,
        descripConfig:'email',
        descrip_Modal:'email',
        descripJson:   'Psemail' 
    },
    {
        idCampoH:17,
        descripConfig:'activo',
        descrip_Modal:'activo',
        descripJson:   'PsActivo' 
    },
    {
        idCampoH:18,
        descripConfig:'esPagadora',
        descrip_Modal:'esPagadora',
        descripJson:   'PsEsPagadora' 
    },
    {
        idCampoH:19,
        descripConfig:'manejaPersonal',
        descrip_Modal:'manejaPersonal',
        descripJson:   'PsManejaPersonal' 
    },
    {
        idCampoH:20,
        descripConfig:'idFormato',
        descrip_Modal:'idFormato',
        descripJson:   'PsIdFormato' 
    },
    {
        idCampoH:21,
        descripConfig:'idFormatoComplemento',
        descrip_Modal:'idFormatoComplemento',
        descripJson:   'PsIdFormatoComplemnto' 
    },
    {
        idCampoH:22,
        descripConfig:'idFormatoRecibo',
        descrip_Modal:'idFormatoRecibo',
        descripJson:   'PsIdFormatoRecibo' 
    } ,
    {
        idCampoH:23,
        descripConfig:'folioRepse',
        descrip_Modal:'folioRepse',
        descripJson:   'PsFolioRepse' 
    },
    {
        idCampoH:24,
        descripConfig:'urlRepse',
        descrip_Modal:'urlRepse',
        descripJson:   'PsUrlRepse' 
    },
    {
        idCampoH:25,
        descripConfig:'archivoRepse',
        descrip_Modal:'archivoRepse',
        descripJson:   'PsArchivoRepse' 
    },
    {
        idCampoH:26,
        descripConfig:'selloRepse',
        descrip_Modal:'selloRepse',
        descripJson:   'PsSelloRepse' 
    },
    {
        idCampoH:27,
        descripConfig:'objetoSocial',
        descrip_Modal:'objetoSocial',
        descripJson:   'PsObjetoSocial' 
    },
    {
        idCampoH:28,
        descripConfig:'Usuario',
        descrip_Modal:'Usuario',
        descripJson:   'PsUsuario' 
    },
    {
        idCampoH:29,
        descripConfig:'idEstatus',
        descrip_Modal:'idEstatus',
        descripJson:   'PsIdEstatus' 
    },
    {
        idCampoH:30,
        descripConfig:'logo',
        descrip_Modal:'logo',
        descripJson:   'PsLogo' 
    }
]
 
/*Cambios feature_DEV_ActividadesProductos__ */

//#endregion



export { tableColumns,dataProduct, inicializa,arrayCampoHist, columsHist,columsActiv,dataGroup,datasubGroup ,dataActividad,columsProduct}