import React,{Fragment} from 'react';
import Header from '../../Components/Header'
import GeneralTable from '../../Components/GeneralTable'
import {inicializa,dataTable} from './resources'
import {ExisteSession,toggleLoader} from '../../ApiRequests/general'
import {getInfo} from '../../ApiRequests/req_CRUD'
const Configuracion = ({handleClickRefresh}) => {
    const refreshMenu = () => {
        toggleLoader(true)
        let dataInit = {
            IsMenu : true,
            idUsuarioAct: ExisteSession.getInfoUsuario().idUsuario,
            idUsuario: ExisteSession.getInfoUsuario().idUsuario,
            token: ExisteSession.getInfoUsuario().token,
            connection: ExisteSession.conexion(),
        }
        getInfo("Menu/Menu", dataInit, (response) => {
            // console.log(response);
            toggleLoader(false)
            if(response !== undefined && response !== null){
            // window.location.replace(`${process.env.PUBLIC_URL}/Configuracion`);            
            localStorage.setItem('Permisos',JSON.stringify(response[0]))
            localStorage.setItem('MenuSession',JSON.stringify(response[1]))            
            handleClickRefresh(Math.random())
        }
        });
    }
    const tableColumns = [
        {
        id: 'Nombre',
        Header: 'Nombre',
        accessor: 'nombre',
        },
        { 
            id: 'Accion',
            Header: "Refrescar",
            Cell: k => (
                <div>
                    <button onClick={() => refreshMenu()} className="button button-3d button-small button-Sian button-circle" >
                        <i className="fa fa-repeat"></i>
                    </button>
                </div>
            )
        },
    ]
    return (
        <Fragment>
            <Header title={inicializa.title} breadcrum={inicializa.breadcrum}/>
            <div className="lx-container  container">
                <div className="panel panel-primary animated fadeInUp">
                    <div className="panel-heading">
                        <div className="row">
                            <div className="col-md-6">
                                <span>Configuracion</span>   
                            </div>
                            <div className="col-md-6 btnF">

                            </div>
                        </div>
                    </div>
                    <div className="panel-body">
                            <GeneralTable
                                columns={tableColumns}
                                // updateSearch={updateTableSearch} 
                                data={dataTable} 
                                action={undefined} 
                                doubleClick={() =>{}}
                            />

                    </div>
                </div>
            </div>

        </Fragment>
    );
};

export default Configuracion;