import React,{useEffect,useState} from 'react';
import { Fragment } from 'react';
import Select from 'react-select';
import GeneralTable from '../../../Components/GeneralTable';
import Header from '../../../Components/Header';
import {inicializa,columsActiv,endPoint,endPointFilter,endPointUrl} from './resources'
import { ExisteSession, toggleLoader } from '../../../ApiRequests/general'
import Session from '../../../Application/session'
import Buttons from '../../../Components/Buttons/Buttons';
import {CSVLink,CSVDownload} from 'react-csv'

import {
    getDataTable,
    saveDataInfo,
    updateDataInfo,
    deleteRow,
    fnOpen
} from './Service'

const ActividadesEconomicas = () => {
    const [dataActividades,setDataActividades]     = useState([])
    const [dataNActividades,setDataNActividades]   = useState([])
    const [dataN_Actividades,setDataN_Actividades] = useState([])
    const [dataSector,setDataSector]               = useState([])
    const [sector,setSector]                       = useState([])
    const [dataSubsector,setDataSubsector]         = useState([])
    const [subsector,setSubsector]                 = useState([])
    const [url,setUrl]                             = useState('')
    const [dataRama,setDataRama]                   = useState([])
    const [rama,setRama]                           = useState([])
    const [dataSubRama,setDataSubRama]             = useState([])
    const [subRama,setSubRama]                     = useState([])


    const changeSector = e => {
        console.log('cambia sector', e)
        console.log('dataSector ', dataSector)

        if(e !== null){
            setSector(e)
            toggleLoader(true);
            getDataTable({
                endPoint: endPointFilter,
                idTypeFilter : 2,
                nameSp:'Spc_Sat_SubsectorActividadTbl',
                codigoSector:e.value,
            },_subSector=>{
                console.log(_subSector)
                let auxS = _subSector.map(s => {return {value : s.codigoSubsector, label: s.Subsector}})
                setDataSubsector(auxS)
                toggleLoader(false);

                let aux = dataActividades.filter(x => x.codigoSector === e.value && x.sector === e.label)
                setDataNActividades(aux)
                
                // setDataN_Actividades(aux)
            })
        }else{
            setSector([])
            setDataNActividades(dataActividades)
        }
        setSubsector([])
        setRama([])
        setSubRama([])
    }
    const changeSubsector = (e,n,a) => {
        console.log('cambia subsector', e)
        console.log('dataSubsector ', dataSubsector)
        if(e !== null){
        setSubsector(e)
        getDataTable({
            endPoint: endPointFilter,
            idTypeFilter : 3,
            codigoSector:sector.value,
            codigoSubsector:e.value,
            nameSp:'Spc_Sat_RamaActividadTbl',
        },_Rama=>{
            let auxSub = _Rama.map(s => {return {value : s.codigoRama, label: s.rama}})
            setDataRama(auxSub)
            toggleLoader(false);
            let aux = dataActividades.filter(x => x.codigoSubsector === e.value)
            setDataNActividades(aux)
            // setDataN_Actividades(aux)
        })
        }else{
            let aux = dataActividades.filter(x => x.codigoSector === sector.value)
            setDataNActividades(aux)
            setSubsector([])            
        }
        setRama([])
        setSubRama([])
    }
    const changeRama = (e,n,a) => {
        console.log('cambia rama', e)
        console.log('dataRama ', dataRama)
        if(e !== null){
        setRama(e)
        getDataTable({
            endPoint: endPointFilter,
            idTypeFilter : 4,
            codigoSector:sector.value,
            codigoSubsector:subsector.value,
            codigoRama:e.value,
            nameSp:'Spc_Sat_SubramaActividadTbl',
        },_SubRama=>{
            let auxRama = _SubRama.map(s => {return {value : s.codigoSubrama, label: s.subrama}})
            setDataSubRama(auxRama)
            toggleLoader(false);
            let aux = dataActividades.filter(x => x.codigoRama === e.value)
            setDataNActividades(aux)
            // setDataN_Actividades(aux)
        })
        }else{
            let aux = dataActividades.filter(x => x.codigoSubsector === subsector.value)
            setDataNActividades(aux)
            setRama([])

        }
        setSubRama([])

    }
    const changeSubRama = (e,n,a) => {
        console.log('cambia subsector', e)

        if(e !== null){
        setSubRama(e)
        let aux = dataActividades.filter(x => x.codigoSubrama === e.value)
        setDataNActividades(aux)

        }else{
            let aux = dataActividades.filter(x => x.codigoRama === rama.value)
            setDataNActividades(aux)
            setSubRama([])

        }
    }
    useEffect(() => {
        getDataTable({
            endPoint:endPoint,        
        },actividades =>{
            console.log(actividades)

            setDataActividades(actividades)
            setDataNActividades(actividades)
            getDataTable({
                endPoint : endPointFilter,
                idTypeFilter : 1,
                nameSp:'Spc_Sat_SectorActividadTbl',
            },dsector=>{

                let auxS = dsector.map(s => {return {value : s.codigoSector, label: s.sectorActividad}})
                setDataSector(auxS)
                console.log(auxS)
                getDataTable({
                    endPoint:endPointUrl,
                    id:312,
                    estatus:1,            
                },_url=>{
                    setUrl(_url[0].cadena)
                    toggleLoader(false)

                })
            })
        })


    },[])
    const compTable = (totPorcentaje) => {
        return(
            
            <div className="row">
                <div className="col-md-8">
                    <span >Para mayor información visite: <a href={url}>{url}</a> </span>

                </div>
                {/* <div className="col-md-3" style={{fontWeight: 'bold', color: '#337ab7'}}><span > Porcentaje </span>&nbsp;  <span>{totPorcentaje}</span> <span>&nbsp;&nbsp; %</span>   </div>  */}

            
            </div>
        )
    }
    // const download = () => {
    //     console.log('Descargar')
    //     exportToCsv()

    // }
    // const exportToCsv = e => {
    //     // Headers for each column
    //     let headers = ['Clave,Actividad económica']
    //     // Convert users data to a csv
    //     let activCsv = dataNActividades.reduce((acc, actividad) => {
    //       const { codigoActividadEco, ActividadEco } = actividad
    //       acc.push([codigoActividadEco, ActividadEco].join(','))
    //       return acc
    //     }, [])
    //     console.log('ActividadEco',activCsv)
    //     downloadFile({
    //       data: [...headers, ...activCsv].join('\n'),
    //       fileName: 'ActividadEconomica.csv',
    //       fileType: 'text/csv',
    //     })
    //   }
     
    //   const downloadFile = ({ data, fileName, fileType }) => {
    //     const blob = new Blob([data], { type: fileType })
      
    //     const a = document.createElement('a')
    //     a.download = fileName
    //     a.href = window.URL.createObjectURL(blob)
    //     const clickEvt = new MouseEvent('click', {
    //       view: window,
    //       bubbles: true,
    //       cancelable: true,
    //     })
    //     a.dispatchEvent(clickEvt)
    //     a.remove()
    //   }
    const cmpBtnFile = () => 
    <div className="row">
        <div className="col-md-12">
        <CSVLink data={dataNActividades} 
        headers={
            [
                { label: "Código sector"   , key: "codigoSector" },
                { label: "Sector"   , key: "sector" },
                { label: "Código subsector", key: "codigoSubsector" },
                { label: "Subsector", key: "subsector" },
                { label: "Código rama"     , key: "codigoRama" },
                { label: "Rama"     , key: "rama" },
                { label: "Código subrama"  , key: "codigoSubrama" },
                { label: "Subrama"  , key: "subrama" },
                { label: "Código actividad", key: "codigoActividadEco" },
                { label: "Actividad"       , key: "ActividadEco" }
            ]} 
            filename={'ActividadesEconomicas'}>
            <Buttons 
                btnExport= {true}  btnExportText={'Descargar'} btnExportShowIcon={true} />
            </CSVLink>
        </div>
    </div>
    return (
        <Fragment>
            <Header title={inicializa.title + ' ' + Session.getInfoAmbiente()} breadcrum={inicializa.breadcrum} />
            <div className="lx-container container">
            <div className="panel panel-primary animated fadeInUp">
                <div className="panel-heading">
                    <div className="row">
                        <div className="col-md-6">
                                <span>Catalogo de actividades económicas</span>
                        </div>
                    </div>
                </div>
                <div className="panel-body">
                    <div className="row">
                        <div className="col-md-3">
                        <span>Sector</span>
                        <Select
                                isClearable
                                onChange={changeSector}
                                options={dataSector}
                                value={sector}
                            />

                        </div>
                        <div className="col-md-3">
                        <span>Subsector</span>
                        <Select
                                isClearable
                                onChange={changeSubsector}
                                options={dataSubsector}
                                value={subsector}
                            />

                        </div>
                        <div className="col-md-3">
                        <span>Rama</span>
                        <Select
                                isClearable
                                onChange={changeRama}
                                options={dataRama}
                                value={rama}
                            />

                        </div>
                        <div className="col-md-3">
                        <span>Subrama</span>
                        <Select
                                isClearable
                                onChange={changeSubRama}
                                options={dataSubRama}
                                value={subRama}
                            />

                        </div>
                    </div>
                    

                    <div className="row">
                        <div className="col-md-12">
                        
                        </div>
                    </div>
                    <div className="row">
                        <div>&nbsp;</div>
                        <div className="col-md-12">
                                <GeneralTable
                                                    columns={columsActiv}
                                                    data={dataNActividades}
                                                    action={undefined}
                                                    doubleClick={() => {}}
                                                    isCompTable={true}
                                                    compTbl={(compTable())}
                                                    isCpmpBtn={true}
                                                    compnnt={cmpBtnFile}

                                                />   


                </div>
                </div>
                </div>

            </div>
        </div>
        </Fragment>
        
    );
};

export default ActividadesEconomicas;