import React from 'react';
// import { Modal, Container, Row, Col } from 'react-bootstrap'
import Buttons from '../../../Components/Buttons/Buttons';
const ModalConfigHist = ({
    show,
    parametro,
    changeCheck,
    status,
    onSave,
    onCloseModal
}) => {
    return (
        <div className="modal fade" id="modalConfigHist" tabIndex="-1" role="dialog" aria-labelledby="modalConfigHistLabel" aria-hidden="true">
        <div className="modal-dialog " role="document">
            <div className="modal-content">
                <div className="modal-header mhead">
                    <div className="row">
                        <div className="col-md-6">
                            <h5 className="modal-title" style={{color: 'white'}} id="modalConfigHistLabel">{ "Configura" }</h5>
                        </div>
                        <div className="col-md-6">
                            <button onClick={onCloseModal} type="button" className="close text-white" data-dismiss="modal" aria-label="Close">
                                <i className="fa fa-times-circle"></i>
                            </button>                    
                        </div>
                    </div>
                </div>
                <div className="modal-body">
                    <div className="row"> 
                        <div className="col-md-1"></div> 
                        <div className="col-md-10"> 
                            <div className="row">
                                <div className="col-md-3">
                                    <label>Campo</label>
                                </div>
                                <div className="col-md-9">
                                    <div className="form-group">
                                       <label>{parametro}</label>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-3">
                                    <label>Crea&nbsp;historial</label>
                                </div>
                                <div className="col-md-9">
                                    <div className="form-group">
                                    <div className="border-checkbox-section">
                                                        <div className="border-checkbox-group border-checkbox-group-success">
                                                            <input
                                                                onChange={e => changeCheck(e)}
                                                                checked={status}
                                                                className="border-checkbox"
                                                                type="checkbox"
                                                                id={"chkPrioritario"}
                                                            />
                                                            <label className="border-checkbox-label"
                                                                htmlFor={"chkPrioritario"}>{status ? 'SI': 'NO'}</label>
                                                        </div>
                                                    </div>
                                    </div>
                                </div>
                            </div>  
                                    
                        </div>                
                    </div>                       
                </div>
                <div className="modal-footer">
                <Buttons 
                btnSave ={true} btnSaveAction  = {(e) => onSave()} btnAddText="Guardar" btnSaveShowIcon={true} 
                btnCancel ={true}  btnCancelAction  = {e => onCloseModal()} btnCancelText="Cerrar" btnCancelShowIcon={true} 
                /> 
                </div>
            </div>
        </div>
    </div>
    );
};

export default ModalConfigHist;