
import React, { Fragment, useEffect, useState } from 'react';
import Header from '../../../Components/Header';
import {inicializa,tableColumns} from './resources'
import Buttons from '../../../Components/Buttons/Buttons'
import GeneralTable from '../../../Components/GeneralTable'
import {getInfo,saveInfo,updateInfo, deleteInfo} from '../../../ApiRequests/req_CRUD'
import { ExisteSession } from '../../../ApiRequests/general'
import {fnNotification} from '../../../ApiRequests/Notification'
import ModalAdministradorAcciones from './ModalAdministradorAcciones'




 const AdministradorAcciones = () => {
    const [dataModal,setDataModal] = useState({
        type:false,
    }) 
   
    // const [show,setShow] = useState(false)
    const [dataTableAcciones, setdataTableAcciones] = useState([])
    const [dataInicio] = useState({
        stateI : '',
        filtro_aplicacion :38,
        filtro_ambiente : ExisteSession.getAmbiente().idAmbiente,
        idUsuarioAct : ExisteSession.getInfoUsuario().idUsuario,
        token : ExisteSession.getInfoUsuario().token,
        connection : ExisteSession.conexion(),
     })

    useEffect(() =>{
        getInfo('Administrador/Acciones',dataInicio, (response) =>{
            setdataTableAcciones(response)
        })
    },[dataInicio])

    const showModal = () =>{
        // console.log('datos')
        // setShow(true)
        setDataModal({
            ...dataModal,
            type : true,
     
          })
        window.$('#modalAdministradorAcciones').modal('show')
    }

    const handleDoubleClick = (originals) => {
        console.log('originals ', originals)
        setDataModal({
            ...dataModal,
            type : false,
            idAccion:originals.idAccion,
            descripcion: originals.descripcion,
            
          })
        window.$('#modalAdministradorAcciones').modal('show')
    }

    const saveDataUser = data =>{
        console.log('Entras =================================')
        console.log('Entras ',data.descript)
        if(data.descript !== '' && data.descript !== undefined && data.descript !== null){
            let dataSave = {
                idAccion:data.idAccion,
                descripcion: data.descript,                
                filtro_aplicacion :38,
                filtro_ambiente : ExisteSession.getAmbiente().idAmbiente,
                idUsuarioAct : ExisteSession.getInfoUsuario().idUsuario,
                token : ExisteSession.getInfoUsuario().token,
                connection : ExisteSession.conexion(),
            }
            if(data.isType){                
                saveInfo('Administrador/Acciones',dataSave, (response) => {
                    console.log(response)
                    actionClose()
                    getInfo('Administrador/Acciones',dataInicio, (response) =>{
                        setdataTableAcciones(response)
                    })                  
                })
            }else{
                updateInfo('Administrador/Acciones',dataSave, (response) => {
                    console.log(response)
                    actionClose()
                    getInfo('Administrador/Acciones',dataInicio, (response) =>{
                        setdataTableAcciones(response)
                    })
                    
                })

            }
        }else{
            fnNotification(['Error: El campo descripción no puede ser vació' , 'error', true])
        }
            

    }

    const actionClose = () => {
        setDataModal({
            ...dataModal,
            type : false,
            descripcion: '',
            idEstatus: false,
     
          })
        window.$('#modalAdministradorAcciones').modal('hide');
    }

    const clickDelete = ({idAccion}) => {
        let dataSave = {
            idAccion: idAccion,
            filtro_aplicacion :38,
            filtro_ambiente : ExisteSession.getAmbiente().idAmbiente,
            idUsuarioAct : ExisteSession.getInfoUsuario().idUsuario,
            token : ExisteSession.getInfoUsuario().token,
            connection : ExisteSession.conexion(),
        }
        deleteInfo('Administrador/Acciones',dataSave, (response) => {
            console.log(response)
            actionClose()
            getInfo('Administrador/Acciones',dataInicio, (response) =>{
                setdataTableAcciones(response)
            })
            
        })

    }

    return (
        <Fragment>
             {/* <Header title={inicializa.title + ' ' + Session.getInfoAmbiente()} breadcrum={inicializa.breadcrum}/> */}
            <Header title={inicializa.title} breadcrum={inicializa.breadcrum}/>
            <div className="lx-container container">
                <div className="panel panel-primary animated fadeInUp">
                    <div className="panel-heading">
                        <div className="row">
                            <div className="col-md-6">
                                <span>Administración de Acciones</span>                   
                            </div>
                            <div className="col-md-6 btnF">
                                <Buttons btnAdd={true} btnAddAction={showModal} btnAddText={true} btnAddShowIcon={true}/>

                                {/* <Buttons btnAdd={true} btnAddAction={showModal} /> */}
                            </div>
                        </div>
                    </div>
                    <div className="panel-body">
                             <GeneralTable
                                columns={tableColumns}
                                // updateSearch={updateTableSearch} 
                                data={dataTableAcciones} 
                                action={undefined} 
                                doubleClick={handleDoubleClick}
                             />
                    </div> 
                </div>
            </div>
            <ModalAdministradorAcciones dataModal={dataModal} saveDataUser={saveDataUser} actionClose={actionClose} clickDelete={clickDelete}/>
        </Fragment>
    );
};

export default AdministradorAcciones;