import React,{Fragment} from 'react';
import Buttons from '../../../Components/Buttons/Buttons'
import GeneralTable from '../../../Components/GeneralTable'
import {tableColumns} from './resources'
const ModalErrores = ({clickClose, dataTblError,isConfirmatio,clickSend }) => {
    return (
        <Fragment>
        <div className="modal fade" id="modalMovError" tabIndex="-1" role="dialog" aria-labelledby="modalMovErrorLabel" aria-hidden="true">
            <div className="modal-dialog " role="document">
                <div className="modal-content">
                    <div className="modal-header mhead">
                        <div className="row">
                            <div className="col-md-6">
                                <h5 className="modal-title" style={{color: 'white'}} id="modalFrontLabel">Detalle de errores</h5>
                                
                            </div>
                            <div className="col-md-6">
                                <button onClick={clickClose} type="button" className="close text-white" data-dismiss="modal" aria-label="Close">
                                    {/* <span aria-hidden="true">&times;</span> */}
                                    <i className="fa fa-times-circle"></i>

                                </button>  
                            </div>
                        </div>
                    </div>
                    <div className="modal-body" role="form">
                        <div className="row">
                            <div className="col-md-12">
                            {isConfirmatio ?
                            <div className="text-center">
                            <p>Los datos que contiene este excel son de una fecha que ya ha sido cargada anteriormente</p>
                            <p>¿Estas seguro de que quieres sobreescribir la información?</p> 

                             </div>

                            
                                :
                                <GeneralTable
                                columns={tableColumns}
                                data={dataTblError}
                                action={undefined}
                                isNotPagination={true}

                                // doubleClick={(original,values) => {}} 
                                // isCpmpBtn={true}
                                // compnnt={compnntCarga}
                                />
                                
                            }
                                
                            </div>

                        </div>
                    </div>
                    <div className="modal-footer">
                        {
                        isConfirmatio ?
                            <Buttons 
                            btnSave={true} btnSaveAction={clickSend}btnSaveText={"Aceptar"} btnSaveShowIcon={true} 
                            btnCancel={true} btnCancelAction={clickClose} btnCancelText={" Cancelar"} btnCancelShowIcon={true}
                            /> 
                        :
                            <Buttons
                            btnCancel={true} btnCancelAction={clickClose} btnCancelText={" Cancelar"} btnCancelShowIcon={true}/>
                        }
                    </div>
                </div>
            </div>
        </div>
            
        </Fragment>
    );
};

export default ModalErrores;