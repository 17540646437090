import React from 'react';
import { Modal, Container, Row, Col } from 'react-bootstrap'
import Buttons from '../../../Components/Buttons/Buttons';

const ModalAnio = ({
    show,
    onCloseModal,
    onSaveAnio,
    changeAnio,
    anio,
    changeCheck,
    status,
    isEdit
}) => {
    console.log('anio',anio)
    return (
        <Modal size="xl"  show={show} animation={false} backdrop="static"  aria-labelledby="modalConfirmImp">
        <Modal.Header className="header" style={{background:'blue', color:'white'}}>
            <Row>
            <Col md="11" >
                <span>{isEdit ? 'Edita' : 'Crear' } año</span>
            </Col>

                    <Col md="1" >
                    {/* <div onClick={onCloseModal} className="btn btn-primary button-circle button-small" ><label style={{color:'white'}}>X</label></div> */}
                    </Col>
                   
            </Row>
        </Modal.Header>
        <Modal.Body >
            <Row>
            <Col md="3">
                <span>Año</span>
               
            </Col>
            <Col md="9">
            <div className="form-group"> 
            {
                isEdit ? <label>{anio}</label>:
                <input type="number"
                className="form-control"
                name='anio'
                min="1999"
                max="2100"
                onChange={(e) => changeAnio(e)} 
                value={anio} /> 

            }                           
            </div> 
               
            </Col>
            </Row>
            {
                isEdit && 
                <Row>
                <Col md="3">
                    <span>Activo</span>
                   
                </Col>
                <Col md="9">
                <div className="form-group">
                                                    <div className="border-checkbox-section">
                                                        <div className="border-checkbox-group border-checkbox-group-success">
                                                            <input
                                                                onChange={e => changeCheck(e)}
                                                                checked={status}
                                                                className="border-checkbox"
                                                                type="checkbox"
                                                                id={"chkPrioritario"}
                                                            />
                                                            <label className="border-checkbox-label"
                                                                htmlFor={"chkPrioritario"}>{status ? 'SI': 'NO'}</label>
                                                        </div>
                                                    </div>
                                                </div>    
                   
                </Col>
                </Row>
            }
          
          

          


       
        
        </Modal.Body>
        <Modal.Footer>
        <Buttons 
            btnSave ={true} btnSaveAction  = {(e) => onSaveAnio(anio,status)} btnAddText="Guardar" btnSaveShowIcon={true} 
            btnCancel ={true}  btnCancelAction  = {e => onCloseModal()} btnCancelText="Cerrar" btnCancelShowIcon={true} 
        /> 
        </Modal.Footer>
       
       </Modal>
    );
};

export default ModalAnio;