import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { ExisteSession } from '../../../ApiRequests/general'
import Buttons from '../../../Components/Buttons/Buttons';
import GeneralTable from '../../../Components/GeneralTable';
import { idOrigenWeb } from '../../../Constants/application'
import ModalDispersionConfiguracion from './ModalDispersionConfiguracion';

const ModalDispersion = ({ saveDataUser, model, actionClose, clickDelete, selecctions, setSelecction }) => {

    const [Register, setModel] = useState({
        idArchivo: 0,
        idBanco: 0,
        RutaArchivo: "",
        idEstatus: 0,
        idFormatoFecha: 0,
        idTipoExtension: 0,
        banco: "",
        codigoArchivo: "",
        nombreArchivo: "",
        posicionFija: false,
        separador: "",
        formatoFecha: "",
        rellenoCeros: false,
        puntoDecimal: false,
        estatus: "",
        tipoExtension: "",
        configuracionSelected: null,
        configurations: [],
        tipo: false,
        tipoConfiguracion: false,
        filtro_aplicacion: 38,
        filtro_ambiente: ExisteSession.getAmbiente().idAmbiente,
        idUsuarioAct: ExisteSession.getInfoUsuario().idUsuario,
        token: ExisteSession.getInfoUsuario().token,
        connection: ExisteSession.conexion(),
    });

    useEffect(() => {
        if (model && model.selectView) {
            setModel({
                ...Register,
                idArchivo: model.selectView.idArchivo,
                idBanco: model.selectView.idBanco,
                RutaArchivo: model.selectView.RutaArchivo,
                idEstatus: model.selectView.idEstatus,
                idFormatoFecha: model.selectView.idFormatoFecha,
                idTipoExtension: model.selectView.idTipoExtension,
                banco: model.selectView.banco,
                codigoArchivo: model.selectView.codigoArchivo,
                nombreArchivo: model.selectView.nombreArchivo,
                posicionFija: model.selectView.posicionFija,
                separador: model.selectView.separador,
                formatoFecha: model.selectView.formatoFecha,
                rellenoCeros: model.selectView.rellenoCeros,
                puntoDecimal: model.selectView.puntoDecimal,
                estatus: model.selectView.estatus,
                tipoExtension: model.selectView.tipoExtension,
                tipo: model.selectView.tipo
            });
            setSelecction({
                ...selecctions,
                idBanco: selecctions.idBanco = selecctions.Bancos.find(value => value.value === model.selectView.idBanco) ?  selecctions.Bancos.find(value => value.value === model.selectView.idBanco) : null,
                idEstatus: selecctions.idEstatus = selecctions.Estatus.find(value => value.value === model.selectView.idEstatus) ? selecctions.Estatus.find(value => value.value === model.selectView.idEstatus) : null,
                idFormatoFecha: selecctions.idFormatoFecha = selecctions.FormatoFecha.find(value => value.value === model.selectView.idFormatoFecha) ? selecctions.FormatoFecha.find(value => value.value === model.selectView.idFormatoFecha) : null,
                idTipoExtension: selecctions.idTipoExtension = selecctions.TipoArchivo.find(value => value.value === model.selectView.idTipoExtension) ? selecctions.TipoArchivo.find(value => value.value === model.selectView.idTipoExtension) : null,
                rutaArchivoId: selecctions.rutaArchivoId = selecctions.RutaArchivo.find(value => value.label === model.selectView.RutaArchivo) ? selecctions.RutaArchivo.find(value => value.label === model.selectView.RutaArchivo) : null,
                separadorId: selecctions.separadorId = selecctions.Separador.find(value => value.label === model.selectView.separador) ?  selecctions.Separador.find(value => value.label === model.selectView.separador) : null,
            })
        }
        else {
            setModel({
                ...Register,
                idArchivo: 0,
                banco: "",
                RutaArchivo: "",
                codigoArchivo: "",
                nombreArchivo: "",
                posicionFija: false,
                separador: "",
                formatoFecha: "",
                rellenoCeros: false,
                puntoDecimal: false,
                estatus: "",
                tipoExtension: "",
                tipo: false
            });
            setSelecction({
                ...selecctions,
                idBanco: selecctions.idBanco = null,
                idFormatoFecha: selecctions.idFormatoFecha = null,
                idTipoExtension: selecctions.idTipoExtension = null,
                idEstatus: selecctions.idEstatus = null,
                rutaArchivoId: selecctions.rutaArchivoId = null,
                separadorId: selecctions.separadorId = null
            });
        }
    }, [model])

    const clickSave = () => saveDataUser(Register)
    const clickDeleteLocal = () => clickDelete(Register)
    const clickClose = () => actionClose()

    const handleChange = (event) => {
        setModel({ ...Register, [event.target.name]: Register[event.target.name] = event.target.value })
    }
    const handleChangeSelect = (event, _property, _preperty2) => {
        if (event) {
            if (_preperty2)
                setModel({ ...Register, [_preperty2]: Register[_preperty2] = event.label });

            setModel({ ...Register, [_property]: Register[_property] = event.value });
            setSelecction({ ...selecctions, [_property]: selecctions[_property] = event });
        }
        else {
            if (_preperty2)
                setModel({ ...Register, [_preperty2]: Register[_preperty2] = "" });

            setModel({ ...Register, [_property]: Register[_property] = 0 });
            setSelecction({ ...selecctions, [_property]: selecctions[_property] = null });
        }
    }
    const handleChangeChk = (event) => {
        setModel({ ...Register, [event.target.name]: Register[event.target.name] = event.target.checked })
    }

    return (
        <>
            <div className="modal fade" id="modalDispersion" tabIndex="-1" role="dialog" aria-labelledby="modalDispersionLabel" aria-hidden="true">
                <div className="modal-dialog modal-lg " role="document">
                    <div className="modal-content">
                        <div className="modal-header mhead">
                            <button onClick={clickClose} type="button" className="close text-white" data-dismiss="modal" aria-label="Close">
                                {/* <span aria-hidden="true">&times;</span> */}
                                <i className="fa fa-times-circle"></i>
                            </button>
                            <span className="modal-title title-modal-custom-color title-modal-custom" id="modalDispersionLabel">
                                {!model.type ? "Editar Archivo Dispersion " : "Agregar Archivo Dispersion"}
                            </span>
                        </div>
                        <div className="modal-body">
                            {/* inicio */}
                            <div className="row">
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>idArchivo</label>
                                        <input
                                            className="form-control"
                                            id="idArchivo"
                                            name="idArchivo"
                                            onChange={() => { }}
                                            value={Register.idArchivo}
                                            placeholder="idArchivo"
                                            maxLength="100"
                                            disabled="disabled" />
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Banco:</label>
                                        <Select
                                            className=""
                                            id="idBanco"
                                            isClearable
                                            isSearchable
                                            name="idBanco"
                                            onChange={(event) => handleChangeSelect(event, "idBanco")}
                                            options={selecctions.Bancos}
                                            value={selecctions.idBanco}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Ruta Archivo:</label>
                                        {/* <input
                                            className="form-control"
                                            id="RutaArchivo"
                                            name="RutaArchivo"
                                            onChange={handleChange}
                                            value={Register.RutaArchivo}
                                            placeholder="Ruta Archivo" /> */}
                                        <Select
                                            className=""
                                            id="rutaArchivoId"
                                            isClearable
                                            isSearchable
                                            name="rutaArchivoId"
                                            onChange={(event) => handleChangeSelect(event, "rutaArchivoId", "RutaArchivo")}
                                            options={selecctions.RutaArchivo}
                                            value={selecctions.rutaArchivoId}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Codigo de Archivo</label>
                                        <input
                                            className="form-control"
                                            id="codigoArchivo"
                                            name="codigoArchivo"
                                            onChange={handleChange}
                                            value={Register.codigoArchivo}
                                            placeholder="Codigo de Archivo" />
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Nombre Archivo</label>
                                        <input
                                            className="form-control"
                                            id="nombreArchivo"
                                            name="nombreArchivo"
                                            onChange={handleChange}
                                            value={Register.nombreArchivo}
                                            placeholder="Nombre Archivo" />
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Separador:</label>
                                        {/* <input
                                            className="form-control"
                                            id="separador"
                                            name="separador"
                                            onChange={handleChange}
                                            value={Register.separador}
                                            placeholder="Separador" /> */}
                                        <Select
                                            className=""
                                            id="separadorId"
                                            isClearable
                                            isSearchable
                                            name="separadorId"
                                            onChange={(event) => handleChangeSelect(event, "separadorId", "separador")}
                                            options={selecctions.Separador}
                                            value={selecctions.separadorId}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Formato Fecha:</label>
                                        <Select
                                            className=""
                                            id="idFormatoFecha"
                                            isClearable
                                            isSearchable
                                            name="idFormatoFecha"
                                            onChange={(event) => handleChangeSelect(event, "idFormatoFecha")}
                                            options={selecctions.FormatoFecha}
                                            value={selecctions.idFormatoFecha}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="border-checkbox-section" style={{ paddingTop: 32 }}>
                                        <div className="border-checkbox-group border-checkbox-group-success">
                                            <input
                                                className="border-checkbox"
                                                type="checkbox"
                                                id="posicionFija"
                                                name="posicionFija"
                                                onChange={handleChangeChk}
                                                checked={Register.posicionFija} />
                                            <label className="border-checkbox-label" htmlFor="posicionFija">Posición fija</label>
                                        </div>
                                        <div className="border-checkbox-group border-checkbox-group-success">
                                            <input
                                                className="border-checkbox"
                                                type="checkbox"
                                                id="rellenoCeros"
                                                name="rellenoCeros"
                                                onChange={handleChangeChk}
                                                checked={Register.rellenoCeros} />
                                            <label className="border-checkbox-label" htmlFor="rellenoCeros">¿Rellena Ceros?</label>
                                        </div>
                                        <div className="border-checkbox-group border-checkbox-group-success">
                                            <input
                                                className="border-checkbox"
                                                type="checkbox"
                                                id="puntoDecimal"
                                                name="puntoDecimal"
                                                onChange={handleChangeChk}
                                                checked={Register.puntoDecimal} />
                                            <label className="border-checkbox-label" htmlFor="puntoDecimal">¿Punto Decimal?</label>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Tipo Archivo:</label>
                                        <Select
                                            className=""
                                            id="idTipoExtension"
                                            isClearable
                                            isSearchable
                                            name="idTipoExtension"
                                            onChange={(event) => handleChangeSelect(event, "idTipoExtension")}
                                            options={selecctions.TipoArchivo}
                                            value={selecctions.idTipoExtension}
                                        />
                                    </div>
                                </div>

                            </div>
                            <div className="row">
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Estatus:</label>
                                        <Select
                                            className=""
                                            id="idEstatus"
                                            isClearable
                                            isSearchable
                                            name="idEstatus"
                                            onChange={(event) => handleChangeSelect(event, "idEstatus")}
                                            options={selecctions.Estatus}
                                            value={selecctions.idEstatus}
                                        />
                                    </div>
                                </div>
                            </div>
                            {/* fin */}
                        </div>
                        <div className="modal-footer">
                            <Buttons
                                // btnAdd={true} btnAddAction={clickSave} btnAddText={!model.type ? " Modificar" : " Guardar"} btnAddClass={"btn btn-primary"} btnAddShowIcon={true}
                                btnSave={true} btnSaveAction={clickSave} btnSaveText={!model.type ? " Modificar" : " Guardar"} btnSaveClass={"btn btn-primary button-circle button-small"} btnSaveShowIcon={true} typeSaved={model.type}
                                btnDelete={!model.type} btnDeleteAction={clickDeleteLocal} btnDeleteText={" Eliminar"} btnDeleteShowIcon={true}
                                btnCancel={true} btnCancelAction={clickClose} btnCancelText={" Cancelar"} btnCancelShowIcon={true} />

                        </div>
                    </div>
                </div>
            </div >
            {/* <ModalDispersionConfiguracion model={model} saveDataUser={saveConfiguration} actionClose={closeConfiguration} clickDelete={deleteConfiguration} /> */}
        </>
    );
};

export default ModalDispersion;