import React, {useState, useEffect} from 'react';
import { idOrigenWeb } from '../../Constants/application'
import { ValidaLogin, getAmbientes,ValidaLogin2,getAmbientes2 } from '../../ApiRequests/login';
import {fnNotification} from '../../ApiRequests/Notification'
import {toggleLoader} from '../../ApiRequests/general'
import Cookies from 'universal-cookie';

const cookies = new Cookies();

const Login = ({validateUser}) => {
const [usuario,guardarUsuario] = useState({
    email: '',
    password: ''
})    
const [Status,saveStatus] = useState(false)
const [isChecked,saveChecked] = useState("")
const {email,password} = usuario;    
const notSeg = 1
const onChange = e =>{
    guardarUsuario({
        ...usuario,
        [e.target.name] : e.target.value
    })
} 
    const keyPressHandled = e =>{
    var keycode = e.keyCode || e.which;
    if (keycode === 13) {
        validar(notSeg);
     }
 }
 const validar = (type)=>{  
    if(type === 2){
        let oblLogin  = {
            user : email,
            password,
            idOrigen : idOrigenWeb
        }
        ValidaLogin2(oblLogin, _Callback2)

    }else{

     toggleLoader(true)   
    if(email !== "" && password !== ""){
        let oblLogin  = {
            user : email,
            password,
            idOrigen : idOrigenWeb
        }
        var patron=/^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i;
        
        if(email.search(patron)===0){           
            // console.log("entro loginSave validar",cookies.get('loginSave'))  
            if(cookies.get('loginSave')){
                cookies.set('login', email);
                cookies.set('password', password);
            }
            else{
                cookies.set('login', '');
                cookies.set('password', '');
            }
            ValidaLogin(oblLogin, _Callback)
        }
        else {
            toggleLoader(false)
            fnNotification(['Por favor ingresa un correo electrónico válido','error',true]) 
        }

    }else{
       email === "" ? fnNotification(['Por favor ingresa un correo','error',true]) : fnNotification(['Por favor ingresa contraseña','error',true])  ;
       toggleLoader(false)

    }
    }   
  
}
const _Callback = response =>{
  if(response[1] !== null && response[1] !== undefined){ 
    //   toggleLoader(false)
      getAmbientes(response[1], _CallbackAmbientes)
    }
}
const _Callback2 = response =>{
    console.log(response)
    console.log(response[0])
    if(response[1] !== null && response[1] !== undefined){ 
        //   toggleLoader(false)
      getAmbientes2(response[1], _CallbackAmbientes)
          
        }
}
const remember = (e)=>{     
    if(e.target.checked){
        cookies.set('loginSave', true);
        saveStatus(true)
        saveChecked(true);
    }
    else{
        cookies.set('loginSave', false);
        saveStatus(false)
        saveChecked("");
    }
}

useEffect(() => {
    // console.log("entro loginSave",cookies.get('loginSave'))
        saveStatus(cookies.get('loginSave'));
        var isTrueSet = (String(cookies.get('loginSave')).toLowerCase() === 'true');
        if(isTrueSet){
        // console.log("entro login",cookies.get('login'));
        // console.log("entro password",cookies.get('password'));        
            guardarUsuario({
                ...usuario,
                email : cookies.get('login'),
                password : cookies.get('password')
            });
            saveChecked(true);
    }
    else{
        // console.log("entro al aflso");     
        guardarUsuario({
            ...usuario,
            email : '',
            password : ''
        })
        saveChecked("");
     }
  }, []);

const _CallbackAmbientes = respAmbientes =>  setTimeout(function(){ window.location.replace(`${process.env.PUBLIC_URL}/Ambientes`); });

// const _CallbackAmbientes = respAmbientes =>  setTimeout(function(){ });
 return(
    <>
    <div className="featureSt">Maquila </div>
    <div className="login-form-null col-md-4 col-md-offset-4">
    <div className="fix-box">
        <br />
        <div className="text-center animated fadeInDown animation-delay-5">
            <img  style={{width:'275px'}} src={`${process.env.PUBLIC_URL}/assets/img/logoSIAN/logo171.png`} alt="SIAN"/>
        </div>
        <br />
    </div>
    <div className="transparent-div no-padding animated fadeInUp animation-delay-8">
        <ul className="nav nav-tabs nav-tabs-transparent">
            <li className="active"><a href="#home" data-toggle="tab">Login</a></li>
        </ul>
        <div className="tab-content">
            <div className="tab-pane active" id="home">
                <div>
                    <div className="form-group">
                        <div className="input-group login-input">
                            <span className="input-group-addon"><i className="fa fa-user"></i></span>
                            <input
                            type="email"
                            className="form-control" 
                            id="email"
                            name="email"
                            placeholder="Username"
                            value={email}
                            onChange={onChange}
                            />
                        </div>
                        <br />
                        <div className="input-group login-input">
                            <span className="input-group-addon"><i className="fa fa-lock"></i></span>
                            <input 
                            type="password" 
                            className="form-control" 
                            placeholder="Password"
                            id='password'
                            name='password'
                            onKeyPress={keyPressHandled}
                            value={password}
                            onChange={onChange}

                            />
                        </div>
                        <div className="checkbox">                         
                            <input type="checkbox" id="checkbox_remember" onChange={remember} checked={isChecked} />
                            <label htmlFor="checkbox_remember">Remember me</label>
                        </div>
                        <button onClick={() => validar(notSeg)} type="submit" className="btn btn-ar btn-primary pull-right">Login</button>
                        
                        <div className="clearfix"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
    </>
   
 )
}
export default Login;

