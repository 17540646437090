import React, { useEffect } from 'react'
import "./css/estilos.css"

const ListView = ({ tituloOrigen, Origen, tituloDestino, Destino, cambiaSeleccionado, clickChange, handleChange, clickPasar, checkAll,seleccionarOrigen,seleccionarDestino }) => {

    // const {

    // } = props;

    useEffect(() => {
        // console.log("propiedades", cambiaSeleccionado)
    }, [])
    return (
        <>
            <div className='row'>
                <div className='col-md-5'>
                    <div className='row'>
                        <div className='col-md-2'></div>
                        <div className='col-md-10'>
                            <>
                                <div className='col-md-12'>
                                    <div className='row'>
                                        <div className='col-md-12 text-center ListViewTitulo'>
                                            {tituloOrigen}
                                        </div>
                                        <div className='col-md-12 ListViewBuscar'> 
                                            <div className='row'>
                                                <div className='col-md-4'>
                                                    <div className="border-checkbox-section">
                                                        <div className="border-checkbox-group border-checkbox-group-success" onClick={e => { checkAll(true, e) }}>
                                                            <input                                                                
                                                                onChange={() => { }}
                                                                checked={seleccionarOrigen}
                                                                className="border-checkbox"
                                                                type="checkbox"
                                                            />
                                                            <label className="border-checkbox-label"> Seleccionar Todos</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='col-md-8'>
                                                    <div className="form-group">
                                                        {/* <label>Buscar</label> */}
                                                        <input
                                                            className="form-control"
                                                            // id="descripcionMenu"
                                                            // name="descripcionMenu"
                                                            onChange={e => { handleChange(true, e) }}
                                                            // value={Register.descripcionMenu}
                                                            placeholder="Buscar..." />
                                                    </div>
                                                </div>
                                                {/* <div className='col-md-2'></div> */}
                                            </div>
                                        </div>
                                        <div className='col-md-12 ListViewContainerPrincipal'>
                                            {
                                                Origen.data.map(registro => registro.mostrar && (
                                                    <div className='row ListViewRegistro' >
                                                        <div className='col-md-10 col-sm-10' >
                                                            <div className="border-checkbox-section">
                                                                <div className="border-checkbox-group border-checkbox-group-success" onClick={e => { cambiaSeleccionado(true, e, registro) }}>
                                                                    <input
                                                                        // onChange={e => {cambiaSeleccionado(true, e, registro)}}
                                                                        onChange={() => { }}
                                                                        checked={registro.seleccionado}
                                                                        className="border-checkbox"
                                                                        type="checkbox"
                                                                    />
                                                                    <label className="border-checkbox-label">{registro.empresa}</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='col-md-2 col-sm-2'>
                                                            <button
                                                                type="button"
                                                                rel="tooltip"
                                                                onClick={(e) => { clickChange(true, e, registro) }}
                                                                className={"btn btn-success button-circle button-small"}
                                                                data-placement="top"
                                                                title={"Agregar"}>
                                                                <i className={"fa fa-plus"} alt=""></i>
                                                            </button>
                                                        </div>
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    </div>
                                </div>
                            </>
                        </div>
                        {/* <div className='col-md-1'></div> */}
                    </div>
                </div>
                <div className='col-md-2'>
                    <div className='row' style={{ height: "140px" }}></div>
                    <div className='row'>
                        <div className='col-md-12 col-sm-6 text-center' style={{ paddingBottom: "10px", paddingTop: "10px" }}>
                            <button
                                type="button"
                                rel="tooltip"
                                className={"btn btn-info btn-lg"}
                                data-placement="top"
                                onClick={(e) => { clickPasar(true) }}
                                title={"Asignar disponibles"}>
                                {"> >"} &nbsp;
                            </button>
                        </div>
                        <div className='col-md-12 col-sm-6 text-center' style={{ paddingBottom: "10px", paddingTop: "10px" }}>
                            <button
                                type="button"
                                rel="tooltip"
                                className={"btn btn-info btn-lg"}
                                data-placement="top"
                                onClick={(e) => { clickPasar(false) }}
                                title={"Asignar disponibles"}>
                                {"< <"} &nbsp;
                            </button>
                        </div>
                    </div>
                </div>
                <div className='col-md-5'>
                    <div className='row'>
                        {/* <div className='col-md-1'></div> */}
                        <div className='col-md-10'>
                            <>
                                <div className='col-md-12'>
                                    <div className='row'>
                                        <div className='col-md-12 text-center ListViewTitulo'>
                                            {tituloDestino}
                                        </div>
                                        <div className='col-md-12 ListViewBuscar'>
                                            <div className='row'>
                                                <div className='col-md-4'>
                                                <div className="border-checkbox-section">
                                                        <div className="border-checkbox-group border-checkbox-group-success" onClick={e => { checkAll(false, e) }}>
                                                            <input                                                                
                                                                onChange={() => { }}
                                                                checked={seleccionarDestino}
                                                                className="border-checkbox"
                                                                type="checkbox"
                                                            />
                                                            <label className="border-checkbox-label"> Seleccionar Todos</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='col-md-8'>
                                                    <div className="form-group">
                                                        <input
                                                            className="form-control"
                                                            onChange={e => { handleChange(false, e) }}
                                                            placeholder="Buscar..." />
                                                    </div>
                                                </div>
                                                {/* <div className='col-md-2'></div> */}
                                            </div>
                                        </div>  <div className='col-md-12 ListViewContainerPrincipal'>
                                            {
                                                Destino.data.map(registro => registro.mostrar && (
                                                    <div className='row ListViewRegistro' >
                                                        <div className='col-md-10 col-sm-10' >
                                                            <div className="border-checkbox-section">
                                                                <div className="border-checkbox-group border-checkbox-group-success" onClick={e => { cambiaSeleccionado(false, e, registro) }}>
                                                                    <input
                                                                        onChange={() => { }}
                                                                        checked={registro.seleccionado}
                                                                        className="border-checkbox"
                                                                        type="checkbox"
                                                                    />
                                                                    <label className="border-checkbox-label">{registro.empresa}</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='col-md-2 col-sm-2'>
                                                            <button
                                                                type="button"
                                                                rel="tooltip"
                                                                className={"btn btn-danger button-circle button-small"}
                                                                onClick={(e) => { clickChange(false, e, registro) }}
                                                                data-placement="top"
                                                                title={"Agregar"}>
                                                                <i className={"fa fa-minus"} alt=""></i>
                                                            </button>
                                                        </div>
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    </div>
                                </div>
                            </>
                        </div>
                        <div className='col-md-2'></div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default ListView;