import React,{Fragment} from 'react'
import Header from '../Components/Header'
import FormFacturacionElectronica  from "../Components/FormFacturacionElectronica";

const FacturacionElectronica = () =>{
    const inicializa = {
        title : 'FACTURACION ELECTRÓNICA',
        breadcrum : [{nombre: 'Contenido'},{nombre: 'Facturación Electrónica'}]
    }

        return(
            <Fragment>
                <Header title={inicializa.title} breadcrum={inicializa.breadcrum}/>
                <div className="lx-container container">
                    <FormFacturacionElectronica />
                </div>
            </Fragment>
        )
}

export default FacturacionElectronica