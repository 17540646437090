import { getInfo,getInfoErase, downloadHelp,cargaArvhivo,saveInfo,updateInfo, deleteInfo } from '../../../ApiRequests/req_CRUD'
import { ExisteSession, toggleLoader } from '../../../ApiRequests/general'
import { fnNotification } from '../../../ApiRequests/Notification'


const getInfoMovimientos = (request, addVistaAccionHandler) => {
    request = prepareRequest(request)
    getInfo('Contabilidad/CargaMovimientosBancarios',request, (res) =>{
        addVistaAccionHandler(res)
    })
}

const insertDataTable = (request,archivo, addCollectionResp) => {
    request = prepareRequest(request)
    // saveInfo('Contabilidad/MovimientosBancarios' ,request,(respons) =>  {        
    //     addCollectionResp(respons)
    // })
    console.log('archivo',archivo)
    var data = new FormData()

    data.append('file', archivo.archivo[0])
    data.append('cargarXLSRequest', JSON.stringify(request))

    cargaArvhivo('Contabilidad/CargaMovimientosBancarios/Movimientos',data, request,(respons) =>  {        
        addCollectionResp(respons)
    })
}

const prepareRequest = request => {
    request.idUsuarioAct = ExisteSession.getInfoUsuario().idUsuario
    request.token = ExisteSession.getInfoUsuario().token
    request.connection = ExisteSession.conexion()

    return request;
}


const getValidaFecha = (request,archivo, addCollectionResp) => {
    request = prepareRequest(request)
    // saveInfo('Contabilidad/MovimientosBancarios' ,request,(respons) =>  {        
    //     addCollectionResp(respons)
    // })
    console.log('archivo',archivo)
    var data = new FormData()

    data.append('file', archivo.archivo[0])
    data.append('cargarXLSRequest', JSON.stringify(request))

    cargaArvhivo('Contabilidad/CargaMovimientosBancarios/ValidaFecha',data, request,(respons) =>  {        
        addCollectionResp(respons)
    })
}
export { 
    // getMasivaDisponibilidad,
    getInfoMovimientos,   
    insertDataTable, 
    getValidaFecha
}