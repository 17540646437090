import React,{Fragment,useEffect, useState} from 'react';
import {inicializa} from './resources'
import {fnNotification} from '../../../ApiRequests/Notification'
import Session from '../../../Application/session'
import Header from '../../../Components/Header';
import Container from '../../../Components/Container/ContainerInitial'
import GeneralTable from '../../../Components/GeneralTable';
import {tableColumns} from './resources'
import {getInfoAnios,saveInfoAnios, updateInfoAnios, deleteInfoAnios} from './service'
import ModalAnio from './ModalAnio'
import Buttons from '../../../Components/Buttons/Buttons';
import { toggleLoader } from '../../../ApiRequests/general';

const CatAnios = () => {
    const [catAnios, setCatAnios] = useState([])
    const [showModal, setShowModal] = useState(false)
    const [anio, setAnio] = useState(1999)
    const [activo,setActivo] = useState(0)
    const [isEdit,setIsEdit] = useState(0)

    useEffect(() =>{
        getInfoAnios({},
            respons =>{
                console.log(respons)
                setCatAnios(respons)
            })
    },[])
    const onShowModal = (original,values,type) =>{
        if(type)
        {
            console.log(original,values)
            setAnio(values.anio)
            setActivo(values.activo)
        }else
        {
            setAnio(0)
            setActivo(false)
        }
  
        setIsEdit(type)
        setShowModal(true)
    }
    const onCloseModal = () =>  setShowModal(false)
    const changeAnio = (e) =>{
        console.log(e)
        console.log(e.target)
        console.log(e.target.value)
        setAnio(e.target.value)

    }
    const changeCheck = (e) =>{
        console.log(e.target.checked)
        setActivo(e.target.checked ? 1 : 0)
    }
    const onSaveAnio = (_anio,estatus) => {
        if(anio < 1800){
            fnNotification(["El año es invalido", 'error', true])
            return null
        }
        if(isEdit){
            toggleLoader(true)
            updateInfoAnios({
                anio:_anio,
                activo:activo
            },(resp,t,succes)=>{
                console.log(resp,t,succes)
                if(succes){
                    setShowModal(false)
                    getInfoAnios({},resp => setCatAnios(resp))
                }
                toggleLoader(false)
            })
        }else{
            toggleLoader(true)
            saveInfoAnios({
                anio:_anio
            },(resp,t,succes)=>{
                if(succes){
                    setShowModal(false)
                    getInfoAnios({},resp => setCatAnios(resp))

                }
                toggleLoader(false)

            })
        }
        console.log(_anio)
        console.log(estatus)
    
    }

    const BtnAddAnio = (e) => {
        return<>
        <Buttons 
            btnAdd ={true} btnAddAction  = {(e) => onShowModal(anio,false,false)} btnAddText="Guardar" btnAddShowIcon={true} 
        /> 
        </>
    }

    const ContBody = () => {
        return (
            <div className="row">
            <div className="col-md-2">12233333 </div>
            <div className="col-md-8">
                <div className="mt-5">
                {
                <GeneralTable
                columns={tableColumns}
                data={catAnios}
                // action={undefined}
                doubleClick={(original,values) => onShowModal(original,values,true)} 
                // isNotPagination={true}
                // pageSize={500}
                isCpmpBtn={true}
                // compnnt={idTipoFormato === 2 && showTblSSM  ?  BtnConfirmSS : <div></div>}
                compnnt={BtnAddAnio}
                // isCompTable={true}
                // compTbl={BtnConfirmSS()}

                />
            } 

            </div>

            </div>
            <div className="col-md-2"> </div>

</div> 
        )
    }
    return (
        <Fragment>
            <Header title={inicializa.title +' '+ Session.getInfoAmbiente()} breadcrum={inicializa.breadcrum}/>
            <Container title={inicializa.title} contBody={<ContBody />} />
            
            <ModalAnio 
                show={showModal}
                onSaveAnio={onSaveAnio}
                onCloseModal={onCloseModal}
                changeAnio={changeAnio}
                changeCheck={changeCheck}
                anio={anio}
                status={activo}
                isEdit={isEdit}
        />
        </Fragment>
        
    );
};

export default CatAnios;