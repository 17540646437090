import React,{Fragment} from 'react';
import Buttons from '../../../Components/Buttons/Buttons'

const ModalConceptos = (
    {
        clickClose,
        clickSaveConcepto,
        clickDeleteConcepto,
        isSavedConcepto,
        textTypeSavedConcepto,
        changeInfoConcepto,
        dataConcepto,
        descripcionConcepto,
    }) => {
    return (
        <Fragment>
        <div className="modal fade" id="modalConceptos" tabIndex="-1" role="dialog" aria-labelledby="modalConceptos" aria-hidden="true">
            <div className="modal-dialog " role="document">
                <div className="modal-content">
                    <div className="modal-header mhead">
                        <div className="row">
                            <div className="col-md-6">
                                <h5 className="modal-title" style={{color: 'white'}} id="modalFrontLabel">{!isSavedConcepto? "Edita" + textTypeSavedConcepto : "Agrega " + textTypeSavedConcepto }</h5>
                                
                            </div>
                            <div className="col-md-6">
                                <button onClick={clickClose} type="button" className="close text-white" data-dismiss="modal" aria-label="Close">
                                    {/* <span aria-hidden="true">&times;</span> */}
                                    <i className="fa fa-times-circle"></i>

                                </button>  
                            </div>
                        </div>
                    </div>
                    <div className="modal-body" role="form">

                        <div className="form-group">
                            <label htmlFor="inputDescripcion" className="col-sm-2 control-label">Descripción</label>
                            <div className="col-sm-10">
                            <input 
                            type="text"
                            className="form-control" 
                            value={descripcionConcepto}
                            onChange={(e) => changeInfoConcepto(e.target.value,'descripcionConcepto')}
                            />
                            </div>
                        </div>
                        
                    </div>
                    <div className="modal-footer">
                        <Buttons
                            btnSave={true} btnSaveAction={() => clickSaveConcepto()} btnSaveText={!isSavedConcepto ? "Guardar cambios" : " Guardar"}  btnSaveShowIcon={true} typeSaved={!isSavedConcepto}
                            btnDelete={!isSavedConcepto} btnDeleteAction={clickDeleteConcepto} btnDeleteText={" Eliminar"} btnDeleteShowIcon={true}
                            btnCancel={true} btnCancelAction={clickClose} btnCancelText={" Cancelar"} btnCancelShowIcon={true}/>
                    </div>
                </div>
            </div>
        </div>
            
        </Fragment>
    );
};

export default ModalConceptos;