import React,{Fragment} from 'react';
import Buttons from '../../../Components/Buttons/Buttons'

const ModalMovimientos = ({clickClose,clickSave,clickDelete,isSaved,textTypeSaved,changeInfo,descripcion}) => {
    return (
        <Fragment>
        <div className="modal fade" id="modalMovimientos" tabIndex="-1" role="dialog" aria-labelledby="modalMovimientosLabel" aria-hidden="true">
            <div className="modal-dialog " role="document">
                <div className="modal-content">
                    <div className="modal-header mhead">
                        <div className="row">
                            <div className="col-md-6">
                                <h5 className="modal-title" style={{color: 'white'}} id="modalFrontLabel">{!isSaved? "Edita" + textTypeSaved : "Agrega " + textTypeSaved }</h5>
                                
                            </div>
                            <div className="col-md-6">
                                <button onClick={clickClose} type="button" className="close text-white" data-dismiss="modal" aria-label="Close">
                                    {/* <span aria-hidden="true">&times;</span> */}
                                    <i className="fa fa-times-circle"></i>

                                </button>  
                            </div>
                        </div>
                    </div>
                    <div className="modal-body" role="form">
                        <div className="form-group">
                            <label htmlFor="inputEmpresas" className="col-sm-2 control-label">descripción</label>
                            <div className="col-sm-10">
                            <input 
                            type="text"
                            className="form-control" 
                            value={descripcion}
                            onChange={(e) => changeInfo(e.target.value,'descripcion')}
                            />

                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <Buttons
                            btnSave={true} btnSaveAction={() => clickSave()} btnSaveText={!isSaved ? "Guardar cambios" : " Guardar"}  btnSaveShowIcon={true} typeSaved={!isSaved}
                            btnDelete={!isSaved} btnDeleteAction={clickDelete} btnDeleteText={" Eliminar"} btnDeleteShowIcon={true}
                            btnCancel={true} btnCancelAction={clickClose} btnCancelText={" Cancelar"} btnCancelShowIcon={true}/>
                    </div>
                </div>
            </div>
        </div>
            
        </Fragment>
    );
};

export default ModalMovimientos;