const inicializa = {
    title : 'Buzón Tributario',
    breadcrum : [{nombre: 'Buzón Tributario'},{nombre: 'Mantenimiento Relación de Usuarios - Empresa'}]
}
const tableColumns = [
    { 
        Header: "Giro",
        accessor: 'giro',
    },
    { 
        Header: "Valor Adicional",
        accessor: 'valorAdicional',
    }
]

const tableColumnsError = [
    {
        Header: "idError",
        accessor: 'idError',
    },
    { 
        Header: "Empresa",
        accessor: 'empresa',
    },
    {
        Header: "Mensaje Error",
        accessor: 'descripcion',
    },
    
    
]


const tableData = []

//#endregion



export { tableColumns, inicializa, tableData,tableColumnsError };