import React from "react";


const BtnUpload = ({ btnUploadAction, btnUploadClass, btnUploadText, btnUploadIconClass, btnUploadShowIcon }) => {
    return (
        <button
            type="button"
            rel="tooltip"
            className={btnUploadClass ? btnUploadClass : "btn btn-info button-circle button-small"}            
            onClick={btnUploadAction}
            data-placement="top"
            title=
                {btnUploadText
                    ?
                    btnUploadText
                    :
                    "Subir Archivo"}
            >
            {btnUploadShowIcon
                ?
                <i className={btnUploadIconClass ? btnUploadIconClass : "fa fa-upload"} alt=""></i>
                :
                <></>}            
        </button>
    )
}

export default BtnUpload