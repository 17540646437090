import React,{} from "react";
import BtnGeneraFactura from './BtnGeneraFactura'
import BtnViewPdfXML from "./BtnViewPdfXML";
import BtnCopiaPDFXML from "./BtnCopiaPDFXML";
import BtnSendMail from "./BtnSendMail";
import BtnCliente from "./BtnCliente";
import BtnAdd from "./BtnAdd";
import BtnSave from './BtnSave'
import BtnCancel from "./BtnCancel";
import BtnDelete from "./BtnDelete";
import BtnExport from "./BtnExport";
import BtnAyuda from "./BtnAyuda";
import BtnUpload from "./BtnUpload";
import BtnHistory from "./BtnHistory";
import BtnViewPdf from "./BtnViewPdf";
import {ExisteSession} from "../../ApiRequests/general"
// import {IsPermited} from './Permisos'

const Buttons = (props) => {
 
    const {
        btnFactura,
        btnView,
        bntCopy,
        btnMail,
        btnClientes,
        // spaceCount,        
        // nameSpace,

        // btnConsulta,
        // btnConsultaAction,

        //#region btnAdd
        btnAdd,
        btnAddAction,
        btnAddClass,
        btnAddText,
        btnAddIconClass,
        btnAddShowIcon,
        //#endregion
        //#region btnSave
        btnSave,
        btnSaveAction,
        btnSaveClass,
        btnSaveText,
        btnSaveIconClass,
        btnSaveShowIcon,
        typeSaved,
        //#endregion
        //#region btnCancel
        btnCancel,
        btnCancelId,
        btnCancelAction,
        btnCancelClass,
        btnCancelText,
        btnCancelIconClass,
        btnCancelShowIcon,
        //#endregion
        //#region btnDelete
        btnDelete,
        btnDeleteAction,
        btnDeleteClass,
        btnDeleteText,
        btnDeleteIconClass,
        btnDeleteShowIcon,
        //#endregion
        //#region btnExport
        btnExport,
        btnExportAction,
        btnExportClass,
        btnExportText,
        btnExportIconClass,
        btnExportShowIcon,
        //#endregion
        //#region btnAyuda
        btnAyuda,
        btnAyudaAction,
        btnAyudaClass,
        btnAyudaText,
        btnAyudaIconClass,
        btnAyudaShowIcon,
        //#endregion
        //#region btnUpload
        btnUpload,
        btnUploadAction,
        btnUploadClass,
        btnUploadText,
        btnUploadIconClass,
        btnUploadShowIcon,
        //#endregion  

        //#region btnHistory
        btnHistory,
        btnHistoryAction,
        btnHistoryClass,
        btnHistoryText,
        btnHistoryIconClass,
        btnHistoryShowIcon,
        isLoading,


        
        //#region btnViewPdf
        btnViewPdf,
        btnViewPdfAction, 
        btnViewPdfClass, 
        btnViewPdfText, 
        btnViewPdfIconClass, 
        btnViewPdfShowIcon, 
        btnViewdisabled
        //#endregion   btnViewPdf

    } = props;

const IsPermited  = (typeBtn,strT) => {
    let response = false;
    let permisos = []
    ExisteSession.getPermisos().forEach(element => { 
        if(element.idVista === ExisteSession.getView()) permisos.push(element)
    })
    if(typeBtn === 2) response = permisos.find(p => p.idAccion === 2).idEstatus    
    if(typeBtn === 3) {
        if(typeSaved && permisos.find(p => p.idAccion === 2).idEstatus) response = true;
        if(!typeSaved && permisos.find(p => p.idAccion === 3).idEstatus) response = true;        
    }
    if(typeBtn === 4) response = permisos.find(p => p.idAccion === 4).idEstatus    
    return response;
}
    return (
        <div className="col-md-12">

            {btnFactura  && <BtnGeneraFactura />}
            {btnView && <BtnViewPdfXML />}
            {bntCopy && <BtnCopiaPDFXML />}
            {btnMail && <BtnSendMail />}
            {btnClientes && <BtnCliente />}
            
            {/* //#region btnAdd */}
            {
                btnAdd && IsPermited(2, 'Add') && 
                <>
                <BtnAdd
                btnAddAction={btnAddAction}
                btnAddClass={btnAddClass}
                btnAddText={btnAddText}
                btnAddIconClass={btnAddIconClass}
                btnAddShowIcon={btnAddShowIcon}
                />
                <span>&nbsp;</span>
                
                </>

                }
            {/* //#endregion */}
            {/* //#region btnSave */}
             
            {btnSave  && IsPermited(3, 'Save') &&
            <> 
            <BtnSave
                btnSaveAction={btnSaveAction}
                btnSaveClass={btnSaveClass}
                btnSaveText={btnSaveText}
                btnSaveIconClass={btnSaveIconClass}
                btnSaveShowIcon={btnSaveShowIcon} 
                />
                <span>&nbsp;</span>
                </>}
            {/* //#endregion */}
            {/* //#region btnCancel */}
            {btnCancel &&
            <> 
            <BtnCancel 
            btnCancelAction={btnCancelAction} 
            btnCancelId={btnCancelId}
            btnCancelClass={btnCancelClass}
            btnCancelText={btnCancelText}
            btnCancelIconClass={btnCancelIconClass}
            btnCancelShowIcon={btnCancelShowIcon}  
            />
            <span>&nbsp;</span>
            </>
            }
            {/* //#endregion */}
            {/* //#region btnDelete */}
            {btnDelete  && IsPermited(4, 'Save') && <BtnDelete 
            btnDeleteAction={btnDeleteAction} 
            btnDeleteClass={btnDeleteClass} 
            btnDeleteText={btnDeleteText} 
            btnDeleteIconClass={btnDeleteIconClass} 
            btnDeleteShowIcon={btnDeleteShowIcon} 
            />}
            {/* //#endregion */}
            {/* //#region btnExport */}
            {btnExport &&
            <> 
            <BtnExport 
            btnExportAction={btnExportAction}             
            btnExportClass={btnExportClass}
            btnExportText={btnExportText}
            btnExportIconClass={btnExportIconClass}
            btnExportShowIcon={btnExportShowIcon} 
            />
            <span>&nbsp;</span>
            </>
             }
            {/* //#endregion */}
            {/* //#region btnAyuda */}
            {btnAyuda &&
            <> 
            <BtnAyuda 
            btnAyudaAction={btnAyudaAction} 
            btnAyudaClass={btnAyudaClass}
            btnAyudaText={btnAyudaText}
            btnAyudaIconClass={btnAyudaIconClass}
            btnAyudaShowIcon={btnAyudaShowIcon}  />
            <span>&nbsp;</span>

            </>
            }
            {/* //#endregion */}
            {/* //#region btnUpload */}
            {btnUpload && <BtnUpload 
            btnUploadAction={btnUploadAction} 
            
            btnUploadClass={btnUploadClass}
            btnUploadText={btnUploadText}
            btnUploadIconClass={btnUploadIconClass}
            btnUploadShowIcon={btnUploadShowIcon}  />}
            {/* //#endregion */}

            {/* //#region btnHistory  */}
            {btnHistory &&
            <> 
            <BtnHistory 
            btnHistoryAction={btnHistoryAction} 
            btnHistoryClass={btnHistoryClass}
            btnHistoryText={btnHistoryText}
            disabled={isLoading} // Aquí deshabilitamos el botón basado en alguna condición, como isLoading
            btnHistoryIconClass={btnHistoryIconClass}
            btnHistoryShowIcon={btnHistoryShowIcon}  />
            <span>&nbsp;</span>

            </>
            }
            {/* //#endregion btnHistory  */}

            {/* //#region btnHistory  */}
            {btnViewPdf &&
            <> 
            <BtnViewPdf 
            btnViewPdfAction={btnViewPdfAction} 
            btnViewPdfClass={btnViewPdfClass}
            btnViewPdfText={btnViewPdfText}
            btnViewdisabled={btnViewdisabled} // Aquí deshabilitamos el botón basado en alguna condición, como isLoading
            btnViewPdfIconClass={btnViewPdfIconClass}
            btnViewPdfShowIcon={btnViewPdfShowIcon}  />
            <span>&nbsp;</span>

            </>
            }
            {/* //#endregion btnHistory  */}

     
        
        </div>


    )

}
export default Buttons;