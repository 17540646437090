//#region Administracion de Vistas
const inicializa = {
    title : 'Catalogo años',
    breadcrum : [{nombre: 'Catalogos'},{nombre: 'Catalogo años'}]
}
const tableColumns = [
    { 
        Header: "Año",
        accessor: 'anio',
    },
    { 
        Header: "Activo",
        Cell: k => (

                k.row ?
                  <span>
                    {k.row.original.activo === 1 ? 'SI' : 'NO'}
                </span> 
                :
                <span></span>
        
        ),
        accessor: 'activo',
    },
    
]



const tableData = []

//#endregion



export { tableColumns, inicializa, tableData }