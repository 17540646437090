import React from "react";
const BtnCancel = ({ btnCancelAction, btnCancelId, btnCancelClass, btnCancelText, btnCancelIconClass, btnCancelShowIcon }) => {
    return (
        <button
            id={btnCancelId}
            className={btnCancelClass ? btnCancelClass : "btn btn-info button-circle button-small"}
            onClick={btnCancelAction}
            title= {btnCancelText
                ?
                btnCancelText
                :
                "Cancelar"}
        >
            {btnCancelShowIcon
                ?
                <i className={btnCancelIconClass ? btnCancelIconClass : "fa fa-ban"} alt=""></i>
                :
                <></>}
           
        </button>
    )
}
export default BtnCancel