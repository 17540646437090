import { getInfo, saveInfo, updateInfo, deleteInfo } from '../../../ApiRequests/req_CRUD'
import { ExisteSession } from '../../../ApiRequests/general'

const getMonitorNotificaciones = (request,addCollection) => {
    request = parametersReuest(request)
    getInfo("Catalogos/monitor",request,(collection) =>{
        addCollection(collection)
    })

}
const getTipoNotificaciones = (request,addCollection) => {
    request = parametersReuest(request)
    getInfo("Catalogos/catGenerales",request,(collection) =>{
        let options = []
        collection.forEach(element =>{
        options.push({'value':element.valor,'label':element.descripcion})
       })
        addCollection(options)
    })

}

const parametersReuest = request =>{
    request.idUsuarioAct = ExisteSession.getInfoUsuario().idUsuario
    request.token = ExisteSession.getInfoUsuario().token
    request.connection = ExisteSession.conexion()
    return request;
}
export{
    getMonitorNotificaciones,
    getTipoNotificaciones
}