//#region Administracion de Vistas
const inicializa = {
    title : 'Mantenimiento Marcas',
    breadcrum : [{nombre: 'Catalogos'},{nombre: 'Mantenimiento Marcas'}]
}
const tableColumns = [
    { 
        Header: "Codigo Marca",
        accessor: 'codigoMarca',
    },
    { 
        Header: "Descripción",
        accessor: 'descripcion',
    }
]



const tableData = []

//#endregion



export { tableColumns, inicializa, tableData }