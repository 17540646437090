const inicializa = {
    title: 'Carga movimientos bancarios',
    breadcrum: [{ nombre: 'Contabilidad' }, { nombre: 'Carga movimientos bancarios' }]
}
const tableColumns = [
    {
        Header: "idError",
        accessor: 'consecutivoError',
    },
    {
        Header: "Mensaje Error",
        accessor: 'mensaje',
    },
    
]
const tableColumnsConcepto = [
    {
        Header: "Fecha",
        accessor: 'fechaStr',
    },
    {
        Header: "Ingreso",
        accessor: 'ingresoStr',
    },
    { 
        Header: "Egreso",
        accessor: "egresoStr",
    },
    { 
        Header: "Beneficiario",
        accessor: "beneficiario",
    },
    { 
        Header: "Concepto",
        accessor: "concepto",
    },
    { 
        Header: "Empresa",
        accessor: "empresa",
    },
    { 
        Header: "Cuenta",
        accessor: "cuenta",
    },
    { 
        Header: "Banco",
        accessor: "banco",
    }
]
export { tableColumns, inicializa, tableColumnsConcepto}