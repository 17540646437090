import React from "react";


const BtnAyuda = ({ btnAyudaAction, btnAyudaClass, btnAyudaText, btnAyudaIconClass, btnAyudaShowIcon }) => {
    return (
        <button
            type="button"
            rel="tooltip"
            className={btnAyudaClass ? btnAyudaClass : "btn btn-info button-circle button-small"}            
            onClick={btnAyudaAction}
            data-placement="top"
            title=
                {btnAyudaText
                    ?
                    btnAyudaText
                    :
                    "Ayuda"}
            >
            {btnAyudaShowIcon
                ?
                <i className={btnAyudaIconClass ? btnAyudaIconClass : "fa fa-question-circle"} alt=""></i>
                :
                <></>}            
        </button>
    )
}

export default BtnAyuda