import React from 'react'
import { Link } from 'react-router-dom';

const ItemLi = ({ href, nombre, onClickItem, idView }) => <li><Link to={"/" + href} onClick={() => onClickItem()} ><span>{nombre}</span></Link></li>
const ItemMenuListMenu = ({ menu }) => {
    const { subMenu, href, nombre, idEstatus } = menu
    const numSubMenu = subMenu.length
    if (idEstatus) {
        if (numSubMenu === 0) {
            const onClickItem = () => localStorage.setItem('idView', menu.id)
            return <ItemLi href={href} nombre={nombre} onClickItem={onClickItem} />
        }

        return (
            <li className="dropdown-submenu">
                <a href="#!" onClick={e => e.preventDefault()} className="has_children">{nombre}</a>
                <ul className="dropdown-menu dropdown-menu-left">
                    {
                        subMenu.map(x => {
                            return <ItemMenuListMenu key={x.id} menu={x} />
                        })
                    }
                </ul>
            </li>
        )
    }
    return ("")
}
const ItemMenuNav = ({ menu }) => {
    const { subMenu, href, nombre, idEstatus } = menu
    const numSubMenu = subMenu.length
    if (idEstatus) {
        if (numSubMenu === 0) return <ItemLi href={href} nombre={nombre} />
        return (
            <li className="dropdown">
                <a href="#!" onClick={e => e.preventDefault()} className="dropdown-toggle" data-toggle="dropdown" data-hover="dropdown">{nombre}</a>
                <ul className="dropdown-menu dropdown-menu-left animated-2x animated fadeIn">
                    {
                        subMenu.map(x => {
                            return <ItemMenuListMenu key={x.id} menu={x} />
                        })
                    }
                </ul>
            </li>

        )
    }
    return ("")
}


export default ItemMenuNav
