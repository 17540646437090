import React,{Fragment,useState, useEffect} from 'react';

const inicializa = {
    title : 'Configuración de historico de empresas',
    breadcrum : [{nombre: 'Conf.GRAL'},{nombre: 'Configuración de historico'}]
}
const columData = (handleChangeCbx) => {
return [
  
    {
        id:'descripcion',
        Header:'Campo',
        accessor: 'descripcionFront'
    },
    {
        id:'statusH',
        Header:'Guarda historial',
        // Cell: k =>(
        //     <div className="border-checkbox-section">
        //         <div className="border-checkbox-group border-checkbox-group-success">
        //             <input
        //                 onChange={(e) => handleChangeCbx(k.row.original, e.target.checked, k)}
        //                 checked={k.row.original.check}
        //                 className="border-checkbox"
        //                 type="checkbox"
        //                 id={"chkSolM" + k.row.original.idParametro}
        //             />
        //             <label className="border-checkbox-label"
        //                 htmlFor={"chkSolM" + k.row.original.idParametro}></label>
        //         </div>
        //     </div>),
        Cell: k =>(
                <div>{k.row.original.estatusH}</div>
        ),
        accessor:'estatusH'
        
    },
]
}
export { inicializa, columData }  