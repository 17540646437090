import React, {useState, useEffect} from 'react';
import Buttons from '../../../Components/Buttons/Buttons';

function ModalAdministradorTiposComponentes({ saveDataUser, dataModal, actionClose, clickDelete }) {
    const [descript,setdescript] = useState('')
    const [isActivo,setIsActivo] = useState(false)    
    const [idTipoComp,setIdTipoComp] = useState(0)
    const clickClose = () => {
        actionClose()
        setIsActivo(false)        
        setdescript('')
    }
    const handleChange = e => {
        // debugger;
        console.log(e.target.value)
        setdescript(e.target.value)
    }

    const handlechecked = e => {setIsActivo(e.target.checked)}
    
    const clickSave = () => {
        // debugger;
        let isType = dataModal.type
        //saveDataUser({idAccion,descript,isType})
        saveDataUser({idTipoComp,descript,isActivo,isType})
    }
    const clickDeleteLocal = () => {clickDelete({idTipoComp})}
    console.log(dataModal)
    console.log(dataModal.type)
    useEffect(() => {
        if(!dataModal.type){
            setIdTipoComp(dataModal.idTipoComp)
            setIsActivo(dataModal.idEstatus)            
            setdescript(dataModal.descripcion)
        }
    },[dataModal])
    return (
        <div className="modal fade" id="modalAdministradorTiposComponentes" tabIndex="-1" role="dialog" aria-labelledby="modalAdministradorTiposComponentes" aria-hidden="true">
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                <div className="modal-header mhead">
                    <button onClick={clickClose} type="button" className="close text-white" data-dismiss="modal" aria-label="Close">
                            {/* <span aria-hidden="true">&times;</span> */}
                            <i className="fa fa-times-circle"></i>
                        </button>
                        <span className="modal-title title-modal-custom-color title-modal-custom" id="modalAdministradorVistaLabel">
                            {!dataModal.type  ? "Editar Tipo de Componente " : "Agregar Tipo de Componente"}
                        </span>                       
                    </div>                    
                    <div className="modal-body">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Descripción</label>
                                        <input
                                        className="form-control"
                                        id="descripcion"
                                        name="descripcion"
                                        onChange={handleChange}
                                        value={descript}                                        
                                        placeholder="Descripción" />
                                    </div>                        
                                </div>
                                {!dataModal.type ? 
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Estatus</label>
                                        <div className="border-checkbox-section">
                                            <div className="border-checkbox-group border-checkbox-group-success">
                                                    <input
                                                        onChange={e => {handlechecked(e)}}
                                                        checked={isActivo}
                                                        className="border-checkbox"
                                                        type="checkbox"
                                                        id="isChecked"
                                                    />
                                                    <label className="border-checkbox-label"
                                                        htmlFor={"isChecked"}>&nbsp;</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                :
                                <></>
                            }                               
                            </div>
                        </div>
                        
                        
                    </div>
                    <div className="modal-footer">


                        <Buttons
                            btnSave={true} btnSaveAction={clickSave} btnSaveText={!dataModal.type ? "Guardar cambios" : " Guardar"}  btnSaveShowIcon={true} typeSaved={dataModal.type}
                            btnDelete={!dataModal.type} btnDeleteAction={clickDeleteLocal} btnDeleteText={" Eliminar"} btnDeleteShowIcon={true}
                            btnCancel={true} btnCancelAction={clickClose} btnCancelText={" Cancelar"} btnCancelShowIcon={true}/>
                            
                        {/* {!model.type
                            ?
                            <button type="button" onClick={clickDeleteLocal} className="btn btn-danger" data-dismiss="modal">Elminar</button>
                            :
                            <></>
                        }
                        <button type="button" onClick={clickClose} className="btn btn-secondary" data-dismiss="modal">Cerrar</button>
                        <button type="button" className="btn btn-primary" onClick={clickSave}>{!model.type ? "Modificar" : "Guardar"}</button> */}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ModalAdministradorTiposComponentes;