import React from "react";


const BtnExport = ({ btnExportAction, btnExportClass, btnExportText, btnExportIconClass, btnExportShowIcon }) => {
    return (
        <button
            type="button"
            rel="tooltip"
            className={btnExportClass ? btnExportClass : "btn btn-info button-circle button-small"}            
            onClick={btnExportAction}
            data-placement="top"
            title=
                {btnExportText
                    ?
                    btnExportText
                    :
                    "Exportar"}
            >
            {btnExportShowIcon
                ?
                <i className={btnExportIconClass ? btnExportIconClass : "fa fa-file-excel-o"} alt=""></i>
                :
                <></>}            
        </button>
    )
}

export default BtnExport