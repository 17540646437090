import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { ExisteSession } from '../../../ApiRequests/general'
import Buttons from '../../../Components/Buttons/Buttons';
import GeneralTable from '../../../Components/GeneralTable';

const ModalAdministradorVista = ({ saveDataUser, model, actionClose, clickDelete, acciones, modAccions, btnClick , relTableComponentes, hDblClickComp,allViewP}) => {

    const [Register, setModel] = useState({
        idVista: 0,
        descripcion: "",
        descripcionCorta: "",
        descripcionMenu: "",
        descripcionTooltip: "",
        vistaPadre: 0,
        vistaPadreSelect: null,
        ArrayAcciones: [],
        orden: 0,
        url: "",
        idEstatus: true,
        tipo: false,
        filtro_aplicacion: 38,
        filtro_ambiente: ExisteSession.getAmbiente().idAmbiente,
        idUsuarioAct: ExisteSession.getInfoUsuario().idUsuario,
        token: ExisteSession.getInfoUsuario().token,
        connection: ExisteSession.conexion(),
    });
    // let act =[];
    // const [act,setAcc] = useState([]);
    
    // const vpa = localStorage.getItem('VistaPadre');
    useEffect(() => {
        // let vistas = JSON.parse("[" + vpa + "]")[0];
        let vistas = allViewP;
        if (model && model.selectView) {
            // console.log("36 Vistas padre",JSON.parse("[" + vpa + "]")[0]);
            console.log("36 Vistas padre",allViewP);
            
            setModel({
                ...Register,
                idVista: model.selectView.idVista,
                descripcion: model.selectView.descripcion,
                descripcionCorta: model.selectView.descripcionCorta,
                descripcionMenu: model.selectView.descripcionMenu,
                descripcionTooltip: model.selectView.descripcionTooltip,
                vistaPadre: model.selectView.vistaPadre,
                vistaPadreSelect: vistas.find(value => value.value === model.selectView.vistaPadre),
                orden: model.selectView.orden,
                url: model.selectView.url,
                idEstatus: model.selectView.idEstatus,
                tipo: model.selectView.tipo,
                ArrayAcciones: acciones
            });
        }
        else {
            setModel({
                ...Register,
                idVista: 0,
                descripcion: "",
                descripcionCorta: "",
                descripcionMenu: "",
                descripcionTooltip: "",
                vistaPadre: 0,
                vistaPadreSelect: null,
                orden: 0,
                url: "",
                idEstatus: true,
                tipo: false,
                ArrayAcciones: acciones
            });
        }
        //     console.log("antes",acciones);
        //     acciones.forEach(element => {
        //         //  setAcc(...act,act.push(element));
        //        setAcc(act.concat(element));
        //     });
        //    console.log(act);
    }, [model])

    const clickSave = () => saveDataUser(Register)
    const clickDeleteLocal = () => clickDelete(Register)
    const clickClose = () => actionClose()
    const handleChange = (event) => {
        setModel({ ...Register, [event.target.name]: event.target.value })
        // console.log("modelo Register", Register);
    }
    const handleChangeChk = (event) => {
        if(event.target.name === 'tipo'){
            setModel({ ...Register, [event.target.name]: !event.target.checked })
        }else setModel({ ...Register, [event.target.name]: event.target.checked })

        // console.log("evento", { [event.target.name]: event.target.checked });
    }
    const handleChangeChkTable = (data, event) => {
        // console.log("seleccionar data", data);
        // console.log("seleccionar evento", event);
        // console.log("seleccionar page",page);
        // modAccions(null);
        let _array = [];
        let _select = acciones.findIndex(element => element.idAccion === data.idAccion);
        for (let index = 0; index < acciones.length; index++) {
            _array.push(acciones[index]);
        }
        _array[_select].IsStatus = !_array[_select].IsStatus;
        _array[_select].cambio = !_array[_select].cambio;
        modAccions(_array);
        setModel({ ...Register, ArrayAcciones: acciones });
    }
    const handleChangeSelect = (event) => {
        if (event) {
            setModel({ ...Register, vistaPadre: event.value, vistaPadreSelect: event })
        }
        else {
            setModel({ ...Register, vistaPadre: 0, vistaPadreSelect: null })
        }
        // console.log("modelo evento", event);
    }

    const handleChangeChkAll = (event) => {
        let _array = [];
        // let _select = acciones.findIndex(element => element.idAccion === data.idAccion);
        for (let index = 0; index < acciones.length; index++) {
            _array.push(acciones[index]);
        }
        for (let index = 0; index < _array.length; index++) {
            if (_array[index].IsStatus !== event)
                _array[index].cambio = !_array[index].cambio;
            _array[index].IsStatus = event;
        }
        modAccions(_array);
        setModel({ ...Register, ArrayAcciones: acciones });
        // console.log("seleccionar todo", event);
    }
    // const [Descripcion,
    //     DescripcionCorta,
    //     DescripcionMenu,
    //     Vista,
    //     VistaPadre,
    //     Orden,
    //     Url,
    //     Menu] = Register;
    const paramsCheckAll = {
        title: 'Todas',
        padding: '0 0 0 44%',
        /*
        classDivSection: 'border-checkbox-section',
        classDivCbxGroup: 'border-checkbox-group border-checkbox-group-success',
        classCbx: 'border-checkbox'
        */
    }
    const paramButton = {
        title: 'Agregar Componente',
        classBtn:'button button-primary  button-small',
    }
    const buttonClick = () => {
        console.log('Agrega componente')
        btnClick()
    } 
    const dblClickComp = (originals,tableValues) => {
        hDblClickComp(originals,tableValues)

    }
    
    return (
        <div className="modal fade" id="modalAdministradorVista" tabIndex="-1" role="dialog" aria-labelledby="modalAdministradorVistaLabel" aria-hidden="true">
            <div className="modal-dialog modal-lg" role="document">
                <div className="modal-content">
                    <div className="modal-header mhead">
                        <button onClick={clickClose} type="button" className="close text-white" data-dismiss="modal" aria-label="Close">
                            {/* <span aria-hidden="true">&times;</span> */}
                            <i className="fa fa-times-circle"></i>
                        </button>
                        <span className="modal-title title-modal-custom-color title-modal-custom" id="modalAdministradorVistaLabel">
                            {!model.type ? "Editar Vista " : "Agregar Vista"}
                        </span>
                    </div>
                    <div className="modal-body">

                        {/* inicio */}



                            <ul className="nav nav-pills nav-justified ar-nav-pills center-block margin-bottom">
                                <li className="active">
                                    <a href="#Vista" data-toggle="tab" aria-expanded="true">
                                        <i className="fa fa-code"></i> Vista
                                    </a>
                                </li>
                                <li className="">
                                    <a href="#Acciones" data-toggle="tab" aria-expanded="false">
                                        <i className="fa fa-tasks"></i> Acciones
                                    </a>
                                </li>
                                {model.idVista != 0 ?
                                <li class="">
                                    <a href="#Componentes" data-toggle="tab" aria-expanded="false">
                                        <i class="fa fa-codepen"></i> Componentes
                                    </a>
                                </li>
                                : ""}
                            </ul>

                            <div className="tab-content margin-top">
                                <div className="tab-pane active" id="Vista">
                                    <div className="container-fluid"> 
                                        <div className="row">
                                            <div className="col-md-1"></div>
                                            <div className="col-md-10">
                                                <div className="form-group">
                                                    <label>Descripción</label>
                                                    <input
                                                        className="form-control"
                                                        id="descripcion"
                                                        name="descripcion"
                                                        onChange={handleChange}
                                                        value={Register.descripcion}
                                                        placeholder="Descripción" />
                                                </div>
                                                <div className="form-group">
                                                    <label>Descripción Corta</label>
                                                    <input
                                                        className="form-control"
                                                        id="descripcionCorta"
                                                        name="descripcionCorta"
                                                        onChange={handleChange}
                                                        value={Register.descripcionCorta}
                                                        placeholder="Descripción Corta" />
                                                </div>
                                                <div className="form-group">
                                                    <label>Descripción Menu</label>
                                                    <input
                                                        className="form-control"
                                                        id="descripcionMenu"
                                                        name="descripcionMenu"
                                                        onChange={handleChange}
                                                        value={Register.descripcionMenu}
                                                        placeholder="Descripción Menu" />
                                                </div>
                                                <div className="form-group">
                                                    <label>Vista</label>
                                                    <input
                                                        className="form-control"
                                                        id="descripcionTooltip"
                                                        name="descripcionTooltip"
                                                        onChange={handleChange}
                                                        value={Register.descripcionTooltip}
                                                        placeholder="Vista" />
                                                </div>
                                                <div className="form-group">
                                                    <label>VistaPadre</label>
                                                    {/* <input
                                            className="form-control"
                                            id="vistaPadre"
                                            name="vistaPadre"
                                            onChange={handleChange}
                                            value={Register.vistaPadre}
                                            placeholder="VistaPadre" /> */}
                                                    <Select
                                                        className=""
                                                        id="vistaPadre"
                                                        isClearable
                                                        isSearchable
                                                        name="vistaPadre"
                                                        onChange={handleChangeSelect}
                                                        // options={JSON.parse("[" + vpa + "]")[0]}
                                                        options={allViewP}
                                                        value={Register.vistaPadreSelect}
                                                    />
                                                </div>
                                                <div className="form-group">
                                                    <label>Orden</label>
                                                    <input
                                                        type="number"
                                                        className="form-control"
                                                        id="orden"
                                                        name="orden"
                                                        onChange={handleChange}
                                                        value={Register.orden}
                                                        placeholder="Orden" />
                                                </div>
                                                <div className="form-group">
                                                    <label>Url</label>
                                                    <input
                                                        className="form-control"
                                                        id="url"
                                                        name="url"
                                                        onChange={handleChange}
                                                        value={Register.url}
                                                        placeholder="Url" />
                                                </div>
                                                {!model.type ?
                                                    <div className="div-Check-Pl">
                                                        <div className="border-checkbox-section">
                                                            <div className="border-checkbox-group border-checkbox-group-success">

                                                                <input
                                                                    className="border-checkbox"
                                                                    type="checkbox"
                                                                    id="idEstatus"
                                                                    name="idEstatus"
                                                                    onChange={handleChangeChk}
                                                                    checked={Register.idEstatus}
                                                                />
                                                                <label className="border-checkbox-label" htmlFor="idEstatus">Estatus</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    :
                                                    <></>
                                                }
                                                <div className="div-Check-Pl">
                                        <div className="border-checkbox-section">
                                            <div className="border-checkbox-group border-checkbox-group-success">

                                                <input
                                                    className="border-checkbox"
                                                    type="checkbox"
                                                    id="tipo"
                                                    name="tipo"
                                                    onChange={handleChangeChk}
                                                    checked={!Register.tipo}
                                                />
                                                <label className="border-checkbox-label" htmlFor="tipo">Menu</label>
                                            </div>
                                        </div>
                                    </div>
                                            </div>
                                            <div className="col-md-1"></div>
                                        </div>
                                    </div>
                                </div>
                                <div className="tab-pane" id="Acciones">
                                    <GeneralTable
                                        columns={
                                            [
                                                {
                                                    Header: 'Accion',
                                                    accessor: 'Accion'
                                                },
                                                {
                                                    Header: "CheckAll",
                                                    label:"Activo ",
                                                    Cell: k => (
                                                        <div className="border-checkbox-section">
                                                            <div className="border-checkbox-group border-checkbox-group-success">
                                                                <input
                                                                    onChange={e => handleChangeChkTable(k.row.original, e.target.checked, k.gotoPage)}
                                                                    checked={k.row.original.IsStatus}
                                                                    className="border-checkbox"
                                                                    type="checkbox"
                                                                    id={"chkAPlicar" + k.row.original.idAccion}
                                                                />
                                                                <label className="border-checkbox-label"
                                                                    htmlFor={"chkAPlicar" + k.row.original.idAccion}>&nbsp;</label>
                                                            </div>
                                                        </div>)
                                                }

                                            ]
                                        }
                                        // updateSearch={updateTableSearch} 
                                        data={acciones}
                                        // action={undefined} 
                                        // doubleClick={handleDoubleClick}
                                        // actionBtn={actionBtn}
                                        // getModulo={getModulo}
                                        handleChangeChkAll={handleChangeChkAll}
                                        paramsCheckAll={paramsCheckAll}

                                    />
                                </div>
                                <div className="tab-pane" id="Componentes">
                                    
                                    <GeneralTable
                                    columns={[
                                        { 
                                            Header: "Tipo componente",
                                            accessor: 'descripcion',
                                        },
                                        { 
                                            Header: "Componente",
                                            accessor: 'componente',
                                        },
                                        
                                    ]}
                                    // updateSearch={updateTableSearch} 
                                    data={relTableComponentes} 
                                    action={undefined} 
                                    doubleClick={dblClickComp}
                                    paramButton={paramButton}
                                    buttonClick={buttonClick}
                                />
                                </div>
                            </div>

                        {/* fin */}
                        </div>


                    <div className="modal-footer">
                        <Buttons
                            btnSave={true} btnSaveAction={clickSave} btnSaveText={!model.type ? " Modificar" : " Guardar"} btnSaveClass={"btn btn-primary button-circle button-small"} btnSaveShowIcon={true} typeSaved={model.type}
                            btnDelete={!model.type} btnDeleteAction={clickDeleteLocal} btnDeleteText={" Eliminar"} btnDeleteShowIcon={true}
                            btnCancel={true} btnCancelAction={clickClose} btnCancelText={" Cancelar"} btnCancelShowIcon={true} />

                    </div>
                </div>
            </div>
        </div >
    );
};

export default ModalAdministradorVista;