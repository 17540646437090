
import React, { Fragment, useEffect, useState } from 'react';
import Header from '../../../Components/Header';
import {inicializa,tableColumns} from './resources'
import Buttons from '../../../Components/Buttons/Buttons'
import GeneralTable from '../../../Components/GeneralTable'
import {getInfo,saveInfo,updateInfo, deleteInfo} from '../../../ApiRequests/req_CRUD'
import { ExisteSession, toggleLoader } from '../../../ApiRequests/general'
import { fnNotification } from '../../../ApiRequests/Notification'
import ModalAdministradorTiposComponentes from './ModalAdministradorTiposComponentes'




 const AdministradorTiposComponentes = () => {
    const [dataModal,setDataModal] = useState({
        type:false,
    }) 
   
    // const [show,setShow] = useState(false)
    const [dataTableTiposComponentes, setdataTableTiposComponentes] = useState([])
    const [dataInicio] = useState({
        stateI : '',
        filtro_aplicacion :38,
        filtro_ambiente : ExisteSession.getAmbiente().idAmbiente,
        idUsuarioAct : ExisteSession.getInfoUsuario().idUsuario,
        token : ExisteSession.getInfoUsuario().token,
        connection : ExisteSession.conexion(),
     })

    useEffect(() =>{
        getInfo('Administrador/TiposComponentes',dataInicio, (response) =>{
            setdataTableTiposComponentes(response)
        })
    },[dataInicio])

    const showModal = () =>{
        console.log('datos')
        // setShow(true)
        setDataModal({
            ...dataModal,
            type : true,
     
          })
        window.$('#modalAdministradorTiposComponentes').modal('show')
    }

    const handleDoubleClick = (originals) => {
        console.log('originals ', originals)
        setDataModal({
            ...dataModal,
            type : false,
            idTipoComp:originals.idTipoComp,
            descripcion: originals.descripcion,
            idEstatus: originals.idEstatus,
            
          })
        window.$('#modalAdministradorTiposComponentes').modal('show')
    }

    const saveDataUser = data =>{
        if(data.descript !== '' && data.descript !== undefined && data.descript !== null){
            toggleLoader(true)
            let dataSave = {
                idTipoComp:data.idTipoComp,
                descripcion: data.descript,
                idEstatus: data.isActivo,               
                idUsuarioAct : ExisteSession.getInfoUsuario().idUsuario,
                connection : ExisteSession.conexion(),
            }
            if(data.isType){                
                saveInfo('Administrador/TiposComponentes',dataSave, (response,t,success) => {
                    console.log(dataSave)
                    console.log(response)
                    toggleLoader(false)

                    if(success){
                        actionClose()
                        // let indx = dataTableTiposComponentes.findIndex(x => x.idTipoComp === dataSave.idTipoComp)
                        let aux = [...dataTableTiposComponentes]
                        aux.push(response)
                        setdataTableTiposComponentes(aux)
                    }
                })
            }else{
                console.log('dataSave +++',dataSave)
                updateInfo('Administrador/TiposComponentes',dataSave, (response,t,success) => {
                    console.log(dataSave)
                    console.log(response)
                    toggleLoader(false)
                  
                    if(success){
                        dataSave.estatus = dataSave.idEstatus ? 'Activo' : 'Inactivo'
                        let indx = dataTableTiposComponentes.findIndex(x => x.idTipoComp === dataSave.idTipoComp)
                        let aux = [...dataTableTiposComponentes]
                        aux[indx] = dataSave
                        setdataTableTiposComponentes(aux)
                        actionClose()

                    }
                     
                })
            }
        }else fnNotification(['Error: El campo de descripcion no puede ser vacio' , 'error', false])   

    }

    const actionClose = () => {
        setDataModal({
            ...dataModal,
            type : false,
            descripcion: '',
            idEstatus: false,
     
          })
        window.$('#modalAdministradorTiposComponentes').modal('hide');
    }

    const clickDelete = ({idTipoComp}) => {
        let dataSave = {
            idTipoComp: idTipoComp,
            filtro_aplicacion :38,
            filtro_ambiente : ExisteSession.getAmbiente().idAmbiente,
            idUsuarioAct : ExisteSession.getInfoUsuario().idUsuario,
            token : ExisteSession.getInfoUsuario().token,
            connection : ExisteSession.conexion(),
        }
        deleteInfo('Administrador/TiposComponentes',dataSave, (response) => {
            console.log(response)
            actionClose()
            getInfo('Administrador/TiposComponentes',dataInicio, (response) =>{
                setdataTableTiposComponentes(response)
            })
            
        })

    }

    return (
        <Fragment>
             {/* <Header title={inicializa.title + ' ' + Session.getInfoAmbiente()} breadcrum={inicializa.breadcrum}/> */}
            <Header title={inicializa.title} breadcrum={inicializa.breadcrum}/>
            <div className="lx-container  container">
                <div className="panel panel-primary animated fadeInUp">
                    <div className="panel-heading">
                        <div className="row">
                            <div className="col-md-6">
                                <span>Administración de Tipos de Componentes</span>                   
                            </div>
                            <div className="col-md-6 btnF">
                                <Buttons btnAdd={true} btnAddAction={showModal} btnAddText={true} btnAddShowIcon={true}/>

                                {/* <Buttons btnAdd={true} btnAddAction={showModal} /> */}
                            </div>
                        </div>
                    </div>
                    <div className="panel-body">
                             <GeneralTable
                                columns={tableColumns}
                                // updateSearch={updateTableSearch} 
                                data={dataTableTiposComponentes} 
                                action={undefined} 
                                doubleClick={handleDoubleClick}
                             />
                    </div> 
                </div>
            </div>
            <ModalAdministradorTiposComponentes dataModal={dataModal} saveDataUser={saveDataUser} actionClose={actionClose} clickDelete={clickDelete}/>
        </Fragment>
    );
};

export default AdministradorTiposComponentes;