const inicializa = {
    title : 'Perfiles de vistas',
    breadcrum : [{nombre: 'Administración'},{nombre: 'PerfilesVistas'}]
}
const tableColumns = [
    { 
        Header: "IDPerfil",
        accessor: 'idPerfil',
    },
    { 
        Header: "Perfil",
        accessor: 'descripcion',
    },
    { 
        Header: "Estatus",
        accessor: 'estatus',
    }
]

const columnsRelVistaAccion = [
    
    { 
        Header: "Vista",
        accessor: 'vista',
    },
    { 
        Header: "Estatus",
        accessor: 'estatus',
    }
]

const tableData = []

export { tableColumns, inicializa, tableData, columnsRelVistaAccion }