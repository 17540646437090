import React from 'react';
import Buttons from '../../../Components/Buttons/Buttons';

const ModalConfirma = ({clickClose,clickOK}) => {
    return (
        <div>
            <div className="modal fade Emp-modal-lg" id="modalConfirmaAct" tabIndex="-1" role="dialog" aria-labelledby="modalConfirmaActLabel" aria-hidden="true">
            <div className="modal-dialog modal-lg" role="document">
                <div className="modal-content">
                    <div className="modal-header mhead">
                        <button onClick={clickClose} type="button" className="close text-white" data-dismiss="modal" aria-label="Close">
                            {/* <span aria-hidden="true">&times;</span> */}
                            <i className="fa fa-times-circle"></i>
                        </button>
                        <span className="modal-title title-modal-custom-color title-modal-custom" id="modalConfirmaActLabel">
                            {"Advertencia"}
                        </span>
                    </div>
                    <div className="modal-body">
                        <div>Las actividades no se han configurado, ¿desea abandonar el tab?</div>

                     
                    </div>
                    <div className="modal-footer">
                        <Buttons
                            btnSave={true} btnSaveAction={() => clickOK()} btnSaveText={"Confirmar"} btnSaveClass={"btn btn-primary button-circle button-small"} btnSaveShowIcon={true} 
                            btnCancel={true} btnCancelAction={clickClose} btnCancelText={" Cancelar"} btnCancelShowIcon={true} />
                    </div>
                </div>
            </div >
        </div>
        </div>
    );
};

export default ModalConfirma;