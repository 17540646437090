const inicializa = {
    title : 'Tipo de compañias',
    breadcrum : [{nombre: 'Catalogos'},{nombre: 'Tipo de compañias'}]
}


const tableColumns = [
    { 
        Header: "Código",
        accessor: 'codigoTipoCompania',
    },
    { 
        Header: "Descripción",
        accessor: 'descripcion',
    },
    { 
        Header: "Estatus",
        accessor: 'Estatus',
    },

    
]

export{
    tableColumns,
    inicializa
}