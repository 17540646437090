import React, {Fragment,useState,useEffect} from 'react';
import Header from '../../../Components/Header'
import Session from '../../../Application/session'
import Buttons from '../../../Components/Buttons/Buttons'
import GeneralTable from '../../../Components/GeneralTable';
import ModalMatriz from './ModalMatriz'
import Select from 'react-select'
import{ExisteSession, toggleLoader } from '../../../ApiRequests/general'
import{fnNotification } from '../../../ApiRequests/Notification'
import{inicializa, columnsMatrizTbl,columnsMatrizTblT} from './resources'
import{getMatriz,getFiltros,saveMatriz,saveNDetail,deleteMatriz,validaForm,errorRow,succesRow} from './service'

const ConfiguracionMatricez = ()  => {
    const [selectTypeMatriz, setSelectTypeMatriz]  = useState([])
    const [valTypeMatriz, setValTypeMatriz]        = useState([])
    const [typeMatriz, setTypeMatriz]              = useState(0)
    const [tMatrizT,setTMatrizT]                   = useState('')
    const [isDetail,setIsDetail]                   = useState(false)
    const [calssTabPrincipal,setClassTabPrincipal] = useState('active')
    const [classTabContP,setClassTabContP]         = useState('tab-pane active')
    const [calssTabDet,setClassTabDet]             = useState('')
    const [classTabContDet,setClassTabContDet]     = useState('tab-pane')
    const [dataMatrizDetTbl, setDataMatrizDetTbl]  = useState([])
    const [dataMatrizDelete, setDataMatrizDelete]  = useState([])
    const [arrayIndex, setArrayIndex]              = useState([])
    const [dataTableMatriz,setDataTableMatriz]     = useState([])
    const [listEmpresasFront,setListEmpresasFront] = useState([])
    const [listEmpresasTes,setListEmpresasTes]     = useState([])
    const [listEmpresasDisp,setListEmpresasDisp]   = useState([])
    const [listCuentas,setListCuentas]             = useState([])
    const [listCuentasC,setListCuentasC]           = useState([])
    const [listLayouts,setListLayouts]             = useState([])
    const [showTab,setShowTab]                     = useState(false)
    const [showTabP,setShowTabP]                     = useState(true)
    const [isEmpresa,setIsEmpresa]                 = useState(false)
    const [totPorcentaje,setTotPorcentaje]         = useState(0)    
    const [dataSaved,setDatasSaved]                = useState({
                                                                idMatriz:0,
                                                                empresa:[],
                                                                empresaValue:0,
                                                                cuenta:[],
                                                                cuentaValue:0,
                                                                layout:[],
                                                                layoutValue:0,
                                                                status:false,
                                                                prorrateo:0,
                                                                isPrincipal: true,
                                                                isSaved:true,

                                                            })
    

    

    const colourStyles = {
        control: styles => ({
            ...styles,
            backgroundColor: 'white',
            border: 'none',
            textAlign: 'center',
            background: 'none',

        }),
    }

    const columsMatrizDetTblT = [
    {
        id:'cbxDynamic',
        // Header: typeMatriz === 1 ? "Tesorera"  : "Dispersora",
        Header: () =>(
            typeMatriz ? 1 && <div>
                    {
                        dataMatrizDetTbl.length === 0 ?
                        <span>Tesorera </span> :
                        <span>Tesorera&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>

                    }
                </div> 
            : 
            <div>
                <span>Dispersora</span>                
            </div> 
        ),
        Cell: k => (
            <div title={k.row.original.newOptionEmpresa.label}>
                {k.row.original.isNewRow ?
                <Select
                // isMulti
                // isDisabled={isEmpresa}
                name='empresa'
                onChange={e => handleChangeDet(e,'empresa',k)}
                styles={colourStyles}
                options={tMatrizT === 'Frentes' ? listEmpresasFront : tMatrizT === 'Tesoreras' ? listEmpresasTes : listEmpresasDisp}
                value={k.row.original.newOptionEmpresa}
                
                />
                :
                <span>{k.row.original.nombre_largo}</span>
                }
            </div>
        )
    },
    {
        id:'Banco',
        Header: ({dt}) => (
            <div>
                Banco&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </div>
        ),
        Cell: k => (
            <div title={k.row.original.newOptionCuenta.label}>
                <Select
                    // isMulti
                    // isDisabled={isEmpresa}
                    name='cuenta'
                    onChange={e => handleChangeDet(e,'cuenta',k)}
                    styles={colourStyles}
                    options={listCuentas.filter(c => c.idT === k.row.original.idEmpresaTes)}
                    value={k.row.original.newOptionCuenta}
                />
            </div>
           

            
        )
    },
    { 
        id: "Giro",
        Header: "Giro",
        accessor: 'giro',
        Cell: k => (
            <input  value={k.row.original.giro} disabled={true} style={{border:'none'}} title={k.row.original.giro} />
        )
    },
    { 
        id: "Marca",
        Header: "Marca",
        Cell: k => (
            <input  value={k.row.original.descripcionMarca} disabled={true} style={{border:'none'}} title={k.row.original.descripcionMarca}/>
        )
    },
    {   id:'span',
        Header: ({dt}) => (
            <div>
                Cuenta
            </div>
        ),
        accessor: 'numerodecuenta',
    },
    {
        id: "inputPorcent",
        Header: ({dt}) => (
            <div>
                Prorrateo  
            </div>
        ),
        accessor: 'factor',

    },
    {
        id:'FechaVigencia',
        Header: ({dt}) => (
            <div>
                FechaVigencia&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </div>
        ),
        Cell: k => (
            <span>{k.row.original.fecha}</span>

        )
    },
    {
        id:'Acción',
        Header: 'Acción',
        Cell: k => (
            <div>
                
                <div>
                    <Buttons
                        btnDelete={!k.row.original.isEditDetail || k.row.original.isNewRow} btnDeleteAction={() =>deleteUpdateDetail(k)} btnDeleteText={" Eliminar"} btnDeleteShowIcon={true}
                        btnCancel={k.row.original.isEditDetail && !k.row.original.isNewRow} btnCancelAction={() =>  cancelUpdateDetail(k.row.id)} btnCancelText={"Cancelar"} btnCancelShowIcon={true}
                        />
                </div> 


            </div>
        )
    }
    ]

    useEffect(() => {
        toggleLoader(true)
        getFiltros({
            idUser:ExisteSession.getInfoUsuario().idUsuario,
            typeFilter:"TipoConfiguracion",
            tabla:'conMatrizTbl',
            columna:'idTipoConfiguracion'
            },
            collection =>{
                console.log("collection",collection);
                // console.log("collection[0].ListCatGenerales",collection.Data[0].ListCatGenerales);
            toggleLoader(false)
            if(collection !== undefined && collection !== null && collection.length > 0){
                setSelectTypeMatriz(collection[0].ListCatGenerales)
                setListEmpresasFront(collection[0].ListEmpresas.filter(E => E.idT === 1))
                setListEmpresasTes(collection[0].ListEmpresas.filter(E => E.idT === 2))
                setListEmpresasDisp(collection[0].ListEmpresas.filter(E => E.idT === 5))
                setListLayouts(collection[0].ListLayouts)
            }
            getFiltros({
                idUser:ExisteSession.getInfoUsuario().idUsuario,
                typeFilter:"Cuentas",
                }, collection =>{
                toggleLoader(false)
                setListCuentas(collection)
            })


        })

    },[])
    const cancelUpdateDetail = (idRow) => {
        let cancelCollectionDet = [...dataMatrizDetTbl]
        let newarrayIndex = [...arrayIndex]
        cancelCollectionDet[idRow].newOptionCuenta = {'value':cancelCollectionDet[idRow].idCuenta,'label': cancelCollectionDet[idRow].nombreBanco + '-'+ cancelCollectionDet[idRow].cuentaBefore}
        cancelCollectionDet[idRow].numerodecuenta = cancelCollectionDet[idRow].cuentaBefore
        cancelCollectionDet[idRow].factor = cancelCollectionDet[idRow].factorBefore + ' '
        cancelCollectionDet[idRow].isEditDetail = false

        let indx = newarrayIndex.findIndex(x => x.rowIndex === parseFloat(idRow))
        newarrayIndex.splice(indx,1)

        setArrayIndex(newarrayIndex)
        setDataMatrizDetTbl(cancelCollectionDet)
    }
    const deleteUpdateDetail = (allData)=>{

        
        if(allData.row.original.isNewRow){
            let deletDetail = [...dataMatrizDetTbl]
            deletDetail.splice(allData.row.id, 1)
            setDataMatrizDetTbl(deletDetail)
            let newarrayIndex = [...arrayIndex]    
            let indx = newarrayIndex.findIndex(x => x.rowIndex === parseFloat(allData.row.id))
            newarrayIndex.splice(indx,1)
            setArrayIndex(newarrayIndex)
            if(deletDetail.length === 0) setShowTabP(true)  
            else setShowTabP(false)
            
        }else{
            let newCollectionDet = [...dataMatrizDetTbl]
            let newdtAux = newCollectionDet[allData.row.id]
            
            let newCollectionDelete = dataMatrizDelete            
            newCollectionDelete.push({'idMatriz':newdtAux.idMatriz,'idEmpresa':newdtAux.idEmpresaTes})
            setDataMatrizDelete(newCollectionDelete)

            newCollectionDet.splice(allData.row.id, 1)
            setDataMatrizDetTbl(newCollectionDet)
            let total = 0;
            newCollectionDet.forEach(element =>{            
                total = total + parseFloat(element.factor)
            })
            setTotPorcentaje(total)
            if(total !== 100) setShowTabP(false)
            else setShowTabP(true)  
            
            if(newCollectionDet.length === 0) setShowTabP(true)  
            else setShowTabP(false)
        }
        




    }
    const handleChangeMatriz = e => {
        toggleLoader(true)
        setShowTab(false)
        setTypeMatriz(e.value)
        setValTypeMatriz(e)
        setTMatrizT(e.value === 1 ? 'Frentes' : 'Tesoreras')
        setClassTabPrincipal('active')
        setClassTabContP('tab-pane active')
        setClassTabDet('')
        setClassTabContDet('tab-pane')
        getMatriz({
            typeMatriz: 'ConfiguraMatriz',
            idTipoConfiguracion:e.value
        },
        collection =>{
            collection.forEach(element => {
                element.Estatus = element.idEstatus === 1 ? 'activo' : 'no activo'
                // element.optionSelect.push({'value':element.idCuenta,'label': element.nombreBanco})

            })
            setDataTableMatriz(collection)
            toggleLoader(false)


        })
        setIsDetail(false)

    }
    const actionBtn = val => {
        toggleLoader(true)
        setTMatrizT(typeMatriz === 1  ?  'Tesoreras' : 'Dispersion')
        setIsDetail(true)
        setIsEmpresa(false)
        getMatriz({
            typeMatriz: 'ConfiguraMatrizDet',
            idMatriz: val.btnAction
        },
        collection =>{

            collection.forEach(element => {
                // element.idEmpresaBefore = element.idEmpresaTes
                // element.idCuentaBefore = element.idCuenta
                // element.factorBefore = element.factor
                element.newOptionEmpresa = {'value':element.idEmpresaTes,'label': element.nombre_largo}
                element.newOptionCuenta = {'value':element.idCuenta,'label': element.nombreBanco + '-'+ element.numerodecuenta}
                element.cuentaBefore = element.numerodecuenta
                element.factorBefore = element.factor            
                element.isNewRow = false
                element.isEditDetail = false

            })
            setDataMatrizDetTbl(collection)
            setDatasSaved({
                ...dataSaved,
                idMatriz:val.btnAction,
            })
            setShowTab(true)
            setClassTabPrincipal('')
            setClassTabContP('tab-pane')
            setClassTabDet('active')
            setClassTabContDet('tab-pane active')

            getFiltros({
                idUser:ExisteSession.getInfoUsuario().idUsuario,
                typeFilter:"CuentasToId",
                listDetailMatriz: collection
                }, collection =>{
                toggleLoader(false)
                setListCuentas(collection)
                toggleLoader(false)

            })

            let total = 0;
            collection.forEach(element =>{            
                total = total + parseFloat(element.factor)
            })
            setTotPorcentaje(total)

            if(collection.length > 0){
                if(total !== 100) setShowTabP(false)
                else setShowTabP(true)
            } 
            
            

        })
    }
    const showModalUpdate = (matrizT,original,values) =>{
                let IdEmpresa = isDetail ? original.idEmpresaTes : original.idEmpresa
                let optionEmpresa = matrizT === "Frentes" ? listEmpresasFront.find(E => E.value === IdEmpresa) :
                                    matrizT === 'Tesoreras' ? listEmpresasTes.find(E => E.value === IdEmpresa)   :
                                    listEmpresasDisp.find(E => E.value === IdEmpresa)
                setIsEmpresa(true)

        setDatasSaved({
                ...dataSaved,
                idMatriz:original.idMatriz,
                empresa:optionEmpresa,
                empresaValue:original.idEmpresa,
                cuenta:listCuentas.find(C => C.value === original.idCuenta),
                cuentaValue:original.idCuenta,
                layout:listLayouts.find(L => L.value === original.idArchivo),
                layoutValue:original.idArchivo,
                status:original.Estatus === "activo" ? true : false,
                prorrateo:original.factor,
                isPrincipal: true,
                isSaved:false,

            })
            window.$('#modalMatrizFront').modal('show')
            getFiltros({
                idUser:ExisteSession.getInfoUsuario().idUsuario,
                typeFilter:"Cuentas",
                idCompania:  isDetail ?  original.idEmpresaTes : original.idEmpresa
                }, collection =>{
                toggleLoader(false)
                // setIsEmpresa(true)
                setListCuentasC(collection)
            })
    }
    const showModalAdd = () =>{
            setIsEmpresa(false)
            setDatasSaved({
                ...dataSaved,
                idMatriz: isDetail ? dataSaved.idMatriz : 0,
                empresa:[],
                empresaValue:0,
                cuenta:[],
                cuentaValue:0,
                layout:[],
                layoutValue:0,
                status:false,
                prorrateo:0,
                isPrincipal: true,
                isSaved:true,

            })
            setListCuentasC([])
            window.$('#modalMatrizFront').modal('show')
    }
    const handleChange = (e,name) => {
        setDatasSaved({
            ...dataSaved,
            [name] : name === 'prorrateo' ? e.target.value : e 
            
        })
        if(name === 'empresa'){
            toggleLoader(true)
            setDatasSaved({
                ...dataSaved,
                cuenta: null,
                empresa: e
            })
            getFiltros({
                idUser:ExisteSession.getInfoUsuario().idUsuario,
                typeFilter:"Cuentas",                
                idCompania: e.value
                }, collection =>{
                toggleLoader(false)
                // setIsEmpresa(true)                 
                setListCuentasC(collection)
               
            })
        }

    }
    const handleChangeDet = (e,name,allData) => {
        setDatasSaved({
            ...dataSaved,
            [name] : name === 'prorrateo' ? e.target.value : e

        })

        let newArrayIndex = [...arrayIndex]
        let valArray = newArrayIndex.findIndex(x => x.rowIndex ===  parseInt(allData.row.id))
        if(valArray === -1){
            newArrayIndex.push({rowIndex:parseInt(allData.row.id)})
            setArrayIndex(newArrayIndex)
        }
        let newCollectionDet = [...dataMatrizDetTbl]

        switch(name) {
            case 'empresa':
                newCollectionDet[allData.row.id].newOptionEmpresa = e
                newCollectionDet[allData.row.id].idEmpresaTes = e.value
                newCollectionDet[allData.row.id].isEditDetail = true
                newCollectionDet[allData.row.id].typeSaved = newCollectionDet[allData.row.id].isNewRow 

                setDataMatrizDetTbl(newCollectionDet)

                toggleLoader(true)
                getFiltros({
                    idUser:ExisteSession.getInfoUsuario().idUsuario,
                    typeFilter:"CuentasToId",
                    listDetailMatriz: dataMatrizDetTbl
                    }, collection =>{
                    toggleLoader(false)
                    setListCuentas(collection)
                    toggleLoader(false)

                })

                break;
            case 'cuenta':
                newCollectionDet[allData.row.id].newOptionCuenta = e
                newCollectionDet[allData.row.id].numerodecuenta = e.label.split('-')[1]
                newCollectionDet[allData.row.id].isEditDetail = true
                newCollectionDet[allData.row.id].typeSaved = newCollectionDet[allData.row.id].isNewRow 

                setDataMatrizDetTbl(newCollectionDet)
                
               

                break
            // case 'prorrateo':
            //         newCollectionDet[allData.row.id].isEditDetail = true
            //         setDataMatrizDetTbl(newCollectionDet)
            //         break
                default:
        }

    }   
    const handlechecked = (e) => {
        setDatasSaved({
            ...dataSaved,
            status: e.target.checked,

        })
    }
    const clickSave = () => {
        if(validaForm(dataSaved,isDetail))
        {
            toggleLoader(true)
                saveMatriz({
                    idTypeSaved:                 dataSaved.isSaved,
                    typeMatriz:             isDetail ? 'ConfiguraMatrizDet' : 'ConfiguraMatriz',
                    idTipoConfiguracion:    tMatrizT === 'Frentes' ? 1 : tMatrizT === 'Tesoreras' ? 2 : 5,
                    idMatriz:               dataSaved.idMatriz,
                    idEmpresa :             dataSaved.empresa.value,
                    idEmpresaTes :          dataSaved.empresa.value,
                    idCuenta :              dataSaved.cuenta.value,
                    idArchivo :             isDetail ? 0 : dataSaved.layout.value,
                    idEstatus :             dataSaved.status ? 1 : 0,
                    factor:                 dataSaved.prorrateo,
                },response =>{

                        getMatriz({
                            typeMatriz:          isDetail ? 'ConfiguraMatrizDet' : 'ConfiguraMatriz',
                            idTipoConfiguracion: tMatrizT === 'Frentes' ? 1 : tMatrizT === 'Tesoreras' ? 2 : 5,
                            idMatriz:            isDetail ? dataSaved.idMatriz : 0,
                        }, collection =>{
                                collection.forEach(element => {
                                    if(!isDetail) element.Estatus = element.idEstatus === 1 ? 'activo' : 'no activo'
                                })
                                isDetail ? setDataMatrizDetTbl(collection) : setDataTableMatriz(collection)
                                toggleLoader(false)
                                clickClose()

                            })
                })
        }

    }
    

    const clickDelete = () => {
        toggleLoader(true)
        deleteMatriz({
            typeMatriz: isDetail ? 'ConfiguraMatrizDet' : 'ConfiguraMatriz',
            idMatriz: dataSaved.idMatriz,
            idEmpresa :  isDetail ?  dataSaved.empresa.value : 0,
            idEmpresaTes : isDetail ?  dataSaved.empresa.value : 0,

        },response=>{
            getMatriz({
                typeMatriz:          isDetail ? 'ConfiguraMatrizDet' : 'ConfiguraMatriz',
                idTipoConfiguracion: tMatrizT === 'Frentes' ? 1 : tMatrizT === 'Tesoreras' ? 2 : 5,
                idMatriz:            isDetail ? dataSaved.idMatriz : 0,
            }, collection =>{
                    collection.forEach(element => {
                    element.Estatus = element.idEstatus === 1 ? 'activo' : 'no activo'
                    })
                    isDetail ? setDataMatrizDetTbl(collection) : setDataTableMatriz(collection)
                })
                toggleLoader(false)

                clickClose()


        })
    }

    const changeTMatriz = typeM => {
        if(typeM){
            setClassTabPrincipal('')
            setClassTabContP('tab-pane')
            setClassTabDet('active')
            setClassTabContDet('tab-pane active')
        }else{
            setClassTabPrincipal('active')
            setClassTabContP('tab-pane active')
            setClassTabDet('')
            setClassTabContDet('tab-pane')

        }



        setIsDetail(typeM)
        !typeM ?
        setTMatrizT(typeMatriz === 1  ?  'Frentes' : 'Tesoreras')
        :
        setTMatrizT(typeMatriz === 1  ?  'Tesoreras' : 'Dispersion')


    }
    const clickClose = ()  =>  window.$('#modalMatrizFront').modal('hide')
    const clickAdd = () =>{
        let newCollectionM = [...dataMatrizDetTbl]
        newCollectionM.push({
            descripcionTipoCompania:'',
            factor:0,
            fechaVigencia:'',
            idCuenta:0,
            idEmpresaTes:0,
            idEstatus: false,
            idMatriz: dataSaved.idMatriz,  
            idUsuarioAct: ExisteSession.getInfoUsuario().idUsuario,
            newOptionEmpresa:[],
            newOptionCuenta:[],
            nombreBanco:'',
            nombre_largo:'',
            numerodecuenta:'',
            isNewRow:true,
            typeSaved : true


        })
        setDataMatrizDetTbl(newCollectionM)
        let newArrayIndex = [...arrayIndex]
        let valArray = newArrayIndex.findIndex(x => x.rowIndex ===  parseInt(newCollectionM.length - 1))

        if(valArray === -1){
            newArrayIndex.push({rowIndex: parseInt(newCollectionM.length - 1) })
            setArrayIndex(newArrayIndex)
        }
        if(newCollectionM.length === 1) setShowTabP(false)  
    

    }
    const getSetData = (index, id, value) => {
        if(id === 'inputPorcent'){
            let newCollectionDet = [...dataMatrizDetTbl]
            newCollectionDet[index].isEditDetail = true
            newCollectionDet[index].typeSaved = newCollectionDet[index].isNewRow
            newCollectionDet[index].factor = value !== 0 ? parseFloat(value) : 0
            setDataMatrizDetTbl(newCollectionDet)

            let newArrayIndex = [...arrayIndex]
            let valArray = newArrayIndex.findIndex(x => x.rowIndex ===  parseInt(index))

            if(valArray === -1){
                newArrayIndex.push({rowIndex: parseInt(index) })
                setArrayIndex(newArrayIndex)
            }
            let total = 0;
            newCollectionDet.forEach(element =>{            
                total = total + parseFloat(element.factor)
            })
            setTotPorcentaje(total)
            if(total !== 100) setShowTabP(false)
            else setShowTabP(true)
        }

    }

    const clickSaveDetail = () => {
        let saveDataM = []
        let errorDataM = []
        let errorMenssage = ''
        let menssageError = ''
        let menssageSave = ''
        if(totPorcentaje === 100 || dataMatrizDetTbl.length === 0){
            
            arrayIndex.forEach(element => {

                let newColl = dataMatrizDetTbl[element.rowIndex]

                newColl.rowIndex = element.rowIndex
                saveDataM.push(newColl)
                if(newColl.newOptionEmpresa.length === 0){
                    errorDataM.push({'index':newColl.rowIndex,'title':'Empresa'})
                    errorMenssage += ' Empresa del row ' + newColl.rowIndex + ' No puede ser vacio'
                }
                if(newColl.newOptionCuenta.length === 0){
                    errorDataM.push({'index':newColl.rowIndex,'title':'Cuenta'})
                    errorMenssage += ' Cuenta del row ' + newColl.rowIndex + ' No puede ser vacio '
                }
                if(newColl.factor === 0){
                    errorDataM.push({'index':newColl.rowIndex,'title':'Prorrateo'})
                    errorMenssage += ' Prorrateo del row ' + newColl.rowIndex + ' No puede ser cero '
                }

            })
            if(errorDataM.length > 0){
                errorRow(errorMenssage)
            }else{
                    saveNDetail({
                        typeMatriz:             'ConfiguraMatrizDet' ,
                        insertDetail:           true,
                        dataSetMatriz:          {saveDataM, dataMatrizDelete}

                    }, response => {
                        let isTypeResp = false;
                        response[0].DataListD.forEach(element => {
                            if(element.status){ 
                                menssageSave +=  element.MessageList
                            }
                            else{
                                menssageError += " - " +  element.MessageList
                                isTypeResp = true;
                            }
                        })
                        response[0].DataListI.forEach(element => {
                            if(element.status) {
                                menssageSave +=  element.MessageList
                            }
                            else{
                                menssageError += " - " +  element.MessageList
                                isTypeResp = true;
                            } 
                        })
                        response[0].DataListU.forEach(element => {
                            if(element.status){
                                menssageSave += element.MessageList
                            }
                            else{
                                menssageError += " - " +  element.MessageList
                                isTypeResp = true;
                            }
                        })
                        let dataNe = {
                            idMatriz : saveDataM.length > 0 ? saveDataM[0].idMatriz : 0,
                            btnAction : saveDataM.length > 0 ? saveDataM[0].idMatriz : 0,

                        }


                        if(isTypeResp){
                            if(menssageSave !== "")  fnNotification([menssageSave    , 'success', true]); 
                            setTimeout(() => fnNotification([menssageError    , 'error', true]),10);                             
                            if(saveDataM.length > 0) actionBtn(dataNe)
                            setDataMatrizDelete([])
                            setArrayIndex([]) 
                        }else{
                            fnNotification([menssageSave    , 'success', true])
                            if(saveDataM.length > 0) actionBtn(dataNe)
                            setDataMatrizDelete([])
                            setArrayIndex([])  
                        }
                    })


            }
        }else errorRow('Es necesario sumar el 100 % de prorrateo para guardar')
    }
    const compTable = (totPorcentaje) => {
        return(
            
            <div className="row">
                <div className="col-md-8">

                </div>
                <div className="col-md-3" style={{fontWeight: 'bold', color: '#337ab7'}}><span > Prorrateo </span>&nbsp;  <span>{totPorcentaje}</span> <span>&nbsp;&nbsp; %</span>   </div> 

                <br />
                <br />
                <br />
            </div>
        )
    }  
    return (
        <Fragment>
            <Header title={inicializa.title + ' ' + Session.getInfoAmbiente()} breadcrum={inicializa.breadcrum}/>
            <div className="lx-container container">
                <div className="panel panel-primary animated fadeInUp">
                    <div className="panel-heading">
                        <div className="row">
                            <div className="col-md-6">
                                <span>Configuración Matriz</span>
                            </div>
                            <div className="col-md-6 btnF">
                               {/* {tMatrizT !== '' ? <Buttons btnAdd={true} btnAddAction={e => showModal(tMatrizT,true)} btnAddText='Agregar' btnAddShowIcon={true}/> : null} */}
                                {typeMatriz > 0 ?
                                <Buttons
                                    btnAdd={true} btnAddAction={e => isDetail ? clickAdd() : showModalAdd()} btnAddText='Agregar' btnAddShowIcon={true}
                                    btnSave={isDetail} btnSaveAction={() => clickSaveDetail()} btnSaveShowIcon={true} btnSaveClass={'btn btn-primary button-circle button-small Btn-Save-Space'}

                                    /> :
                                null}
                            </div>
                        </div>
                        </div>
                        <div className="panel-body">
                        <div className="col-md-2">
                        <span className="" text="center">Tipo de Matriz</span>
                        </div>

                        <div className="col-md-10">

                                    <div className=''>
                                            <Select
                                            // isMulti
                                            isDisabled={!showTabP}
                                            name='matrizT'
                                            onChange={e => handleChangeMatriz(e)}
                                            options={selectTypeMatriz}
                                            value={valTypeMatriz}
                                            />

                                    </div>
                                    <br />
                                    <br />
                        </div>


                        <div className="col-md-12">
                                {
                                    typeMatriz > 0 ?
                                    <Fragment>
                                        <ul className="nav nav-pills nav-justified ar-nav-pills center-block margin-bottom">
                                                {

                                                showTabP &&
                                                <li className={calssTabPrincipal} onClick={e => changeTMatriz(false)}>
                                                    <a href="#Frentes" data-toggle="tab" aria-expanded="true">
                                                        <i className="fa fa-building"></i> {typeMatriz === 1  ?  'Frentes' : 'Tesoreras'}
                                                    </a>
                                                </li>
                                                }
                                                {
                                                showTab &&
                                                <li className={calssTabDet} onClick={e => changeTMatriz(true)}>
                                                    <a href="#Tesoreras" data-toggle="tab" aria-expanded="false">
                                                        <i className="fa fa-building"></i> {typeMatriz === 1 ? 'Tesoreras' : 'Dispersoras'}
                                                    </a>
                                                </li>
                                                }
                                        </ul>

                                        <div className="tab-content margin-top">
                                            <div className={classTabContP} id="Frentes">
                                                <GeneralTable
                                                    columns={typeMatriz === 1  ?  columnsMatrizTblT : columnsMatrizTbl}
                                                    data={dataTableMatriz}
                                                    action={undefined}
                                                    actionBtn={actionBtn}
                                                    doubleClick={(original,values) => showModalUpdate(tMatrizT,original,values)}
                                                />
                                            </div>
                                            <div className={classTabContDet} id="Tesoreras">
                                            <GeneralTable
                                                    columns={columsMatrizDetTblT}
                                                    // columns={typeMatriz === 1 ? columsMatrizDetTblT : columsMatrizDetTbl}
                                                    // updateSearch={updateTableSearch}
                                                    data={dataMatrizDetTbl}
                                                    action={undefined}
                                                    // doubleClick={(original,values) => showModalUpdate(tMatrizT,original,values)}
                                                    doubleClick={() => {}}
                                                    isTableEditable={true}
                                                    isNotPagination={true}
                                                    isSampleclick={true}
                                                    getSetData={getSetData}
                                                    isCompTable={true}
                                                    compTbl={(compTable(totPorcentaje))}

                                                />                                             
                                            </div>
                                        </div>

                                    </Fragment> :<></>
                                }
                        </div>
                    </div>

                    </div>
                </div>

                <ModalMatriz
                    listEmpresas={tMatrizT === 'Frentes' ? listEmpresasFront : tMatrizT === 'Tesoreras' ? listEmpresasTes : listEmpresasDisp}
                    listCuentas={listCuentasC}
                    listLayouts={listLayouts}
                    dataSaved={dataSaved}
                    isEmpresa={isEmpresa}
                    handleChangeFront={handleChange}
                    handlechecked={handlechecked}
                    clickSave={clickSave}
                    clickDelete={clickDelete}
                    clickClose={clickClose}
                    isDetail={isDetail}
                    textTypeSaved={isDetail ? " detalle de matriz "+( typeMatriz === 1 ? "Frente": "Tesorera") : " matriz principal "+ ( typeMatriz === 1 ? "Frente": "Tesorera")}

                />

        </Fragment>
    );
}

export default ConfiguracionMatricez;