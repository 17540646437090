import React, { Fragment, useEffect, useState} from 'react';
import Select from 'react-select';
import {dataTipoPol} from './resources'
import Buttons from '../Buttons/Buttons'


const ModalSetting = ({modalType,pocesos,saveConfig,dtModal}) =>{
    const [modalData,setModalData] = useState({
        procesoContable : [],
        tipoPoliza : [],
        concepto : '',
        
    })
    
    const cancel = () =>{
        window.$('#modalCRUD').modal('hide')
        setModalData({
            procesoContable : [],
            tipoPoliza : [],
            concepto : ''
        })
    }
    const saveCf = () => {
        saveConfig(modalData)

    }
    const {procesoContable,tipoPoliza,concepto} = modalData
    useEffect(()=>{
        modalType ?  
        setModalData({
            procesoContable : dtModal.tipoProcesoPoliza,
            tipoPoliza : dtModal.tipopoliza,
            concepto : dtModal.concepto
        }):
        setModalData({
            procesoContable : [],
            tipoPoliza : [],
            concepto : ''
        })
    },[dtModal,modalType])
    return(
        <Fragment>
            <div className="modal fade" id="modalCRUD" tabIndex="-1" role="dialog" aria-labelledby="modalCRUD" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header mhead">
                            <div className="row">
                                <div className="col-md-6">
                                    {modalType && <h5 className="modal-title" id="modalDetalleLabel">Actualizar configuración</h5>}
                                    {!modalType && <h5 className="modal-title" id="modalDetalleLabel">Configurar</h5> }
                                </div>
                                <div className="col-md-6">
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="modal-body"> 
                                        <label >Proceso contable</label>
                                        <div className="mb-3">
                                        {
                                            !modalType ?
                                            <Select 
                                            name='cbProcesoContable'
                                            onChange={e => setModalData({...modalData, procesoContable : e})}
                                            options={pocesos}
                                            value={procesoContable}
                                        /> :
                                        <span>{procesoContable}</span>
                                        }    
                                        
                                        </div>
                                        <label>Tipo póliza</label>
                                        <div className="mb-3">
                                            {
                                                !modalType ?
                                                <Select 
                                                name='cbTipoPoliza'
                                                onChange={e => setModalData({...modalData, tipoPoliza : e})}
                                                options={dataTipoPol}
                                                value={tipoPoliza}
                                            /> :
                                                <span>{tipoPoliza}</span>
                                            }
                                            
                                        </div>
                                        <label>Concepto</label>
                                        <div className="mb-3">
                                            <input 
                                                name='concept'
                                                className="form-control"
                                                onChange={e => setModalData({...modalData, concepto : e.target.value})}
                                                value={concepto}
                                            />
                                        </div>                            
                        </div>  
                        <div className="modal-footer">
                            <Buttons 
                            btnSave
                            btnSaveAction={saveCf} 
                            btnCancel
                            btnCancelId='btnCancelar'
                            btnCancelAction={cancel}
                            btnDelete={modalType}
                            />
                        </div>  
                    </div>    
                </div>    
            </div>    
        </Fragment>
    )
}
export default ModalSetting;