import React ,{useState, useEffect, Fragment} from "react";
import {fechas} from '../../ApiRequests/general'
import Moment from 'react-moment'
import Select from 'react-select'
import {fnNotification} from '../../ApiRequests/Notification'
import {columnsPolGen,columnsProcesos,columnsConfig,dataConfig} from './resources'
import {ExisteSession,toggleLoader} from '../../ApiRequests/general'
import { idOrigenWeb } from '../../Constants/application' 
import { getInfoPoliza,saveInfo, updateInfo, getPreviewReport, getInfoGral } from '../../ApiRequests/req_CRUD' 
import GeneralTable from "../GeneralTable";
import './FormPolizas.css'
import Detalle from "./Detalle"
import ModalSetting from "./ModalSetting"
import ModalViewer from "./ModalViewer";
// import { notification, Table } from 'antd';



const FormPolizas = () =>{
        const [dataPoliza, setDataPoliza] = useState({
            cheCkAll : true,
            columna : 'idEstatus',
            concepto: '',
            datosConfiguracion : [],
            fechaInicio : null,
            fechaFin : null,
            idEstatus : '',
            idEmpresa : 0,
            Id_ProcesoPoliza : 0,
            isTypePol : 'inicializa',
            modalType : false,
            moduloId : '',
            modulIdDetalle : '',
            moduloIdFiltro : '',
            moduloIdGenera : '',
            procesoContable: '',
            procesoFiltros: '',
            procesoProcesos: '',
            stylebtnConsulta : 'btnConsultaDes',
            tabla : 'calcProcesosPolizasTbl',
            tipoproceso: '',
            tipoPoliza:'',
            typeReport : 0,
            isRptDetalle : 0,
            typeCall : 'get',
            typeTab : 0,
            valEmpresas : [],
            //Datos Generales 
            connection : ExisteSession.conexion(),   
            filtro_aplicacion :38,
            filtro_ambiente : ExisteSession.getAmbiente().idAmbiente,
            idOrigen : idOrigenWeb,
            idUsuarioAct : ExisteSession.getInfoUsuario().idUsuario,
            token : ExisteSession.getInfoUsuario().token,
        })
        const extensionesExcel = ['xlsx','xls'];
        const [dataDocto,setDataDocto]= useState(null);

        /**
         * Hook [stPoliza, setStPoliza]
         * description:
         * Hook para mostrar de inicio solo el tab de Filtros y
         * posterior  a hacer una consulta mostrar los otros tabs 
         */
        const [stPoliza, setStPoliza] = useState(true)

        /**
         * Hook procesos
         * description:
         * Hook para llenar el combo de procesos contables
         * en tab filtros y en tab procesos 
         */
        const [pocesos, setProcesos] = useState([])

        /**
         * Hook Empresas
         * description:
         * Hook para llenar el combo de empresas dependiendo de 
         * el proceso contable seleccionado
         */
        const [empresas, setEmpresas] = useState([])

        /**
         * Hook Detalle
         * description:
         * Hook para mostrar el detalle de la póliza seleccionada
         */        
        const [dataDetalle,setDataDetalle] = useState([]) 
        const [dataCabecero,setDataCabecero] = useState([])
        /**
         * Hook Tabs
         * description:
         * Hook para cambiar automaticamente los tab
         */
        const [dataTabs, setDataTabs] = useState({
            tabFiltro : 'active',
            tabXGenerar : '',
            tabGeneradas : '',
            tabProcesos : '',
            tabConfig : '',
            contenTabFiltro : 'tab-pane active',
            contenTabXGenerar : 'tab-pane',
            contenTabGeneradas : 'tab-pane',
            contenTabProcesos : 'tab-pane',
            contenTabConfig: 'tab-pane',
            btnUpdateProc : 'btnHide',
            btnUpdateGenerate : 'btnHide',
            btnSaveConfig : 'btnHide'
        

        })
        
        /**
         * Hook Pólizas por generar
         * description:
         * Hook para llenar tabla de pólizas por generarr
         */        
        const [datxGenerar, setDatxGenerar] = useState([])
        
        /**
         * Hook Pólizas Generadas
         * description:
         * Hook para llenar tabla de pólizas generadas
         */ 
        const [datGeneradas, setDatGeneradas] = useState([])

        /**
         * Hook Boton Generar Pólizas
         * description:
         * Hook para mostrar el boton que genera las pólizas
         */
        const [btnGenerate,setbtnGenerate] = useState('btnGenerate')

         /**
         * Hook Boton Generar Reporte
         * description:
         * Hook para mostrar el boton que genera los reportes de pólizas
         */
        const [btnGenerateReport,setbtnGenerateReport] = useState('btnGenerate')

     

        /**
         * Hook cbEstatus
         * description:
         * Hook para llenedo de combo estatus en el 
         * tab procesos 
         */
        const [cbEstatus,setCbEstatus] = useState([])

        /**
         * Hook dataProcesos
         * description:
         * Hook para llenar tabla de procesos
         */
        const [dataProcesos,setDataProcesos] = useState([])

      
        
        /*Reestructuración de Estado*/
        const {
            fechaInicio,
            fechaFin,
            procesoContable,
            typeTab,
            isRptDetalle,
            moduloIdFiltro,
            moduloIdGenera,
            cheCkAll,
            isTypePol,
            stylebtnConsulta,
            valEmpresas,
            modalType,
            dtModal,
            typeCall
        } = dataPoliza
        // const {dtModalD,dtDetalle} = dataDetalle
        const {
            tabFiltro,
            tabXGenerar,
            tabGeneradas,
            tabProcesos,
            contenTabFiltro,
            contenTabXGenerar,
            contenTabProcesos,
            contenTabGeneradas,
            btnUpdateProc,
            btnUpdateGenerate,
            btnSaveConfig,
            contenTabConfig
        } = dataTabs
       /************************************************************************************************************** */ 
       const obtenerExtension=(nombreArchivo)=> {
        return nombreArchivo.slice((nombreArchivo.lastIndexOf('.') - 1 >>> 0) + 2);
      }
       /*Inicio Hok useEffect*/
       
        useEffect(() => {
            // console.log(typeCall)
            setDataDocto(null);
            if(isTypePol !== ''){
                toggleLoader(true);
                typeCall === 'get' && getInfoGral('Contabilidad/GeneracionPolizas/'+isTypePol,dataPoliza,(listReturn) =>{toggleLoader(false); returnFn(listReturn);});
                typeCall === 'update' && updateInfo('Contabilidad/GeneracionPolizas',dataPoliza,(response) => {toggleLoader(false); returnSave(response);})
                typeCall === 'save' && saveInfo('Contabilidad/GeneracionPolizas',dataPoliza,(response) => {toggleLoader(false);returnSave(response)})
                typeCall === 'getReportDet' && getInfoGral('Reportes/get',dataPoliza,(listReturn) => {toggleLoader(false);downloadReport(listReturn)});
            } 
            const downloadReport = (data) => {
               
                if(data!=null){
                    const {Code,Data}=data;
                    if(Data!=null){
                        if(Data.length>0){
                            var file=Data[0].fileName;
                           
                            file=obtenerExtension(file);
                            if(extensionesExcel.includes(file)){
                                const linkSource = Data[0].mime;
                                const downloadLink = document.createElement("a");
                                const fileName = Data[0].fileName;
                            
                                downloadLink.href = linkSource;
                                downloadLink.download = fileName;
                                downloadLink.click();
                            }else{
                                setDataDocto(data);
                                window.$('#modalViewer').modal('show')
                            }
                            
                        }
                    }
                }
            }
            const returnFn = listReturn => {             
            if(listReturn !== null && listReturn !== undefined){
                isTypePol === 'inicializa' ? fnSetDataProcesos(listReturn) :
                isTypePol === 'GetEmpresa' ? fnSetEmpresas(listReturn) :
                isTypePol === 'ConsultaPendientes' ? returnFnConsultaXGen(listReturn) :
                isTypePol === 'ConsultaGeneradas' ? returnFnConsultaGenradas(listReturn.Data,typeTab) :
                isTypePol === 'getDetalle' ? returnFnConsultaD(listReturn) :
                isTypePol === 'Generate' ? returnFnGenerate(listReturn) : 
                // isTypePol === 'cbProcesos' ? returnFnCbProcesos(listReturn) :
                isTypePol === 'cbEstatus' ?  returnFnCbEstatus(listReturn)  :
                isTypePol === 'getProcesos' ? returnFnProcesos(listReturn)  :
                isTypePol === 'getReporte' ? fnCallbacReport(listReturn)  :
                // isTypePol === 'getExcel' ? fnCallbacReport(listReturn) :
                isTypePol === 'reporte' ? fnCallbacReport(listReturn) : 

                isTypePol === 'modalConfig' ? window.$('#modalCRUD').modal('show') :
                console.log('--')
            }
            }
            /*Funciones de retorno al llamado de la api */

            const returnSave = listConsult =>{
                setDataPoliza({
                    ...dataPoliza,
                    isTypePol : '',
                    typeCall : 'get'
                        
                    }) 
                    window.$('#btnCancelar').click();
            }
            const fnSetDataProcesos = listConsult =>{
                if(listConsult.Code === 200){
                    setProcesos(listConsult.Data) 
                setDataPoliza({
                    ...dataPoliza,
                    isTypePol : 'cbEstatus',
                    typeCall : 'get'
                    })
                }else{
                    fnNotification([listConsult.Message,'error',true]) 
                    toggleLoader(false)
                }
                
                
                
            }
            const fnSetEmpresas = listReturn =>{
                if(listReturn.Code === 200) setEmpresas(listReturn.Data)
                else fnNotification([listReturn.Message,'error',true]) 
            }
            const returnFnConsultaXGen = (listConsult) =>{
                let listPol = []
                if(listConsult.Data !== null && listConsult.Data !== undefined) listPol = listConsult.Data;
                if(listConsult.length > 0 ) listPol[0].CBXAll = true
                else if(listConsult.Code !== 200){
                toggleLoader(false)
                fnNotification([listConsult.Message,'error',true]) 

                }

                setDatxGenerar(listPol)  
                setDataPoliza({
                ...dataPoliza,
                isTypePol : 'ConsultaGeneradas',
                typeCall: 'get'
                    
                }) 

            }
            const returnFnConsultaGenradas = (listConsult,type) =>{
                toggleLoader(false)
                let listPol = []
                if(listConsult !== null && listConsult !== undefined) listPol = listConsult;
                // console.log("tabla asignar",listConsult );
                setDatGeneradas(listPol)
                    setStPoliza(false)
                    toggleLoader(false)
                    if(type === 0){
                        setDataTabs({
                            ...dataTabs,
                            tabFiltro : '',
                            tabXGenerar : 'active',
                            contenTabFiltro : 'tab-pane',
                            contenTabXGenerar : 'tab-pane active',
                            contenTabGeneradas : 'tab-pane',
                            contenTabProcesos : 'tab-pane',  
                            contenTabConfig : 'tab-pane',     
                            btnUpdateProc : 'btnHide btnGenerate',
                            btnUpdateGenerate : 'btnHide btnGenerate',
                            btnSaveConfig : 'btnHide btnGenerate'

                         })
                       setbtnGenerate('btnShow btnGenerate')
                       setbtnGenerateReport('btnShow stylePdf')
                     }else{
                        setDataTabs({
                            ...dataTabs,
                            tabFiltro : '',
                            tabXGenerar : '',
                            tabGeneradas : 'active',
                            contenTabFiltro : 'tab-pane',
                            contenTabXGenerar : 'tab-pane',
                            contenTabGeneradas : 'tab-pane  active',
                            contenTabProcesos : 'tab-pane',
                            contenTabConfig : 'tab-pane',     
                            btnUpdateProc : 'btnHide btnGenerate',
                            btnUpdateGenerate : 'btnShow btnGenerate',
                            btnSaveConfig : 'btnHide btnGenerate'

                         })
                         setbtnGenerate('btnHide btnGenerate')
                         setbtnGenerateReport('btnHide stylePdf')
                        
                     }
                   
            }
            const returnFnConsultaD = (returnDetail) =>{
            let returnDetalle = returnDetail.Data;
            let newDate = [] 
            // let importeTotal = 0;
            if(returnDetail.Code === 200){
                returnDetalle.forEach(element => {
                let fecha = element.fechaMov.split(' ')[0].split('-')[0] +
                "/" + element.fechaMov.split(' ')[0].split('-')[1] + "/" +
                element.fechaMov.split(' ')[0].split('-')[2].split('T')[0];
                newDate.push(new Date(fecha))
                });
                // returnDetalle.forEach(element => {
                //     importeTotal += element.importe;
                // });
                //  console.log(importeTotal)
                let newFechaCabecero = returnDetalle[0].fechaCabecero.split('T')[0].split('-')[2]+'/' + returnDetalle[0].fechaCabecero.split('T')[0].split('-')[1] +'/'+returnDetalle[0].fechaCabecero.split('T')[0].split('-')[0]
                let cabecero = [
                {
                    key: 0,
                    fechaCabecero : newFechaCabecero,
                    tipoPolizaCabecero: returnDetalle[0].tipoPolizaCabecero,
                    conceptoCabecero : returnDetalle[0].conceptoCabecero,
                    // importeCabecero : returnDetalle[0].importeCabecero
                    importeCabecero : '$' + new Intl.NumberFormat('es-MX').format(returnDetalle[0].importeCabecero) 
                }
                ]
                setDataCabecero(cabecero)
                let newDataDetalle = []
                for (let i = 0; i < returnDetalle.length; i++) {
                    let newDate =  returnDetalle[i].fechaMov.split('T')[0].split('-')
                    newDataDetalle.push({
                    key: i,
                    cuenta: returnDetalle[i].auxiliar,
                    fechaMov: newDate[2]+'/'+newDate[1]+'/'+newDate[0],
                    Concepto: returnDetalle[i].descripcion,
                    cargo: returnDetalle[i].Cargo,
                    abono: returnDetalle[i].Abono,
                    referencia: returnDetalle[i].referencia
                    });
                } 

                setDataDetalle(newDataDetalle)
                
                window.$('#modalDetalle').modal('show')
                toggleLoader(false)
                }else{
                    fnNotification([returnDetail.Message,'error',false])
                    toggleLoader(false)

                }

            } 
            
            const returnFnGenerate = listConsult =>{
                toggleLoader(false)
             
                if(listConsult.Code === 200){

                    fnNotification([listConsult.Message,'success',true])
                    setDataTabs({
                        ...dataTabs,
                        tabXGenerar : '',
                        tabGeneradas : 'active',
                        contenTabFiltro : 'tab-pane',
                        contenTabXGenerar : 'tab-pane',
                        contenTabGeneradas : 'tab-pane active',
                        btnUpdateGenerate : 'btnShow btnGenerate',
                        btnUpdateProc : 'btnHide btnGenerate',
                        btnSaveConfig : 'btnHide btnGenerate'

                     })
                    setbtnGenerate('btnHide btnGenerate')
                    setbtnGenerateReport('btnHide stylePdf')
                    setDataPoliza({
                        ...dataPoliza,
                        isTypePol : 'ConsultaPendientes',
                        moduloId : "",
                        moduloIdGenera : "",
                        typeTab : 1,
                        typeCall : 'get'
                        
                        })
                    
                }else fnNotification([listConsult.Message,'error',true]) 
            }
        
            const returnFnCbEstatus = listConsult =>{
                setCbEstatus(listConsult.Data)
                setDataPoliza({
                    ...dataPoliza,
                    isTypePol : 'getProcesos',
                    typeCall : 'get'
                    
                    })
            }
            const returnFnProcesos = listConsult =>{
                setDataProcesos(listConsult.Data)
                // console.log(listConsult.Code)

                if(listConsult.Code === 200) setDataProcesos(listConsult.Data) 
                else{
                // fnNotification([listConsult.Message,'error',true]) 
                setDataProcesos([])

               }

            }
            const fnCallbacReport = (resp) =>{
                // console.log(resp)
                toggleLoader(false)
                if(resp.Code === 200){   
                    toggleLoader(false)             
                // console.log(dataPoliza.moduloIdGenera)
                if(dataPoliza.moduloIdGenera !== ''){
                    toggleLoader(false)
                        // console.log(dataPoliza.moduloIdGenera)
                        setDataPoliza({
                            ...dataPoliza,
                            typeCall : 'getReportDet',
                            moduloId : dataPoliza.moduloIdGenera, 
                            isTypePol : 'report',
                            typeReport : isRptDetalle
                        })
                }else{
                    fnNotification(['No se ha seleccionado ninguna póliza','error',true]) 
                }


                
                }else{
                    fnNotification([resp.Message,'error',false]) 
                }
            }
        },[dataPoliza,isTypePol,typeTab,typeCall,isRptDetalle,moduloIdGenera])

        /*Termino Hok useEffect*/
       /************************************************************************************************************** */ 

        const handleChange = e =>{
           if(procesoContable !== ""){
                if(e.target.name === "fechaInicio" && fechaFin !== ""){
                    setDataPoliza({
                        ...dataPoliza,
                       [e.target.name] : e.target.value,
                        
                        isTypePol : 'GetEmpresa',
                        stylebtnConsulta : 'btnConsultaPol',
                        typeTab : 0,
                        typeCall : 'get'


                    })
                }else if(e.target.name === "fechaFin" && fechaInicio !== ""){
                    setDataPoliza({
                        ...dataPoliza,
                       [e.target.name] : e.target.value,
                        isTypePol : 'GetEmpresa',
                        stylebtnConsulta : 'btnConsultaPol',
                        typeTab : 0,
                        typeCall : 'get'

                    })
                }else{
                    setDataPoliza({
                    ...dataPoliza,
                    [e.target.name] : e.target.value,
                    isTypePol : '',
                    typeTab : 0,
                    typeCall : 'get'

                    })
                }
        }else {
                setDataPoliza({
                    ...dataPoliza,
                    [e.target.name] : e.target.value,
                    isTypePol : '',
                    typeTab : 0

                    
                })
            }

        }
        const changeProceso = e =>{
            // console.log('Proceso para filtros ', e)
            if(fechaInicio !== "" && fechaFin !== ""){
                  setDataPoliza({
                ...dataPoliza,
                procesoFiltros : e.value,
                procesoContable : e.value,
                isTypePol : 'GetEmpresa',
                stylebtnConsulta : 'btnConsultaPol',
                valEmpresas : [],
                typeTab : 0,
                typeCall : 'get'

            })
                
            }else{
                setDataPoliza({
                    ...dataPoliza,
                procesoFiltros : e.value,
                procesoContable : e.value,
                isTypePol : '',
                valEmpresas : [],
                typeTab : 0,
                typeCall : 'get'


                })
                fnNotification(["Seleccionar fechas",'error',true])
            }  
        }
        const changeProcesoT = e =>{
            // console.log('Proceso para procesos ',e)
            if(e !== null && e !== undefined){
                setDataPoliza({
                    ...dataPoliza,
                    Id_ProcesoPoliza : e.value.substring(0,1),
                    isTypePol : '',
                    procesoProcesos : e.value
                })

            }else{
                setDataPoliza({
                    ...dataPoliza,
                    Id_ProcesoPoliza : 0,
                    isTypePol : '',
                    procesoProcesos : ''
                })

            }
            
           

        }
        const changeStatus = e =>{
            let estatus = ""
            e !== null ?(
                  e.forEach(element=>{
                      let val = element.value + ","; 
                      estatus += val; 
    
                })
            )
            : estatus = ""
              
            estatus = estatus.substring(0,estatus.length-1) 
              setDataPoliza({
                ...dataPoliza,
                idEstatus : estatus,
                isTypePol : ''
              })

        }
       const handleEmpresa = e =>{
      
        let _IdEmpresa = "";
        e !== null ?(
            
              e.forEach(element=>{
                  let val = element.value + ","; 
                  _IdEmpresa += val; 

            })
        )
        : _IdEmpresa = ""
          
         _IdEmpresa = _IdEmpresa.substring(0,_IdEmpresa.length-1) 
          setDataPoliza({
            ...dataPoliza,
            // moduloId : _IdEmpresa, 
            moduloIdFiltro : _IdEmpresa, 
            valEmpresas : e,
            isTypePol : ''
          })
         
        
       }
        const handleConsult = e =>{
            toggleLoader(true)

          cheCkAll ? 
          (
            setDataPoliza({
                ...dataPoliza,
                isTypePol : 'ConsultaPendientes',
                moduloIdGenera : '',
                typeCall : 'get'

            })
          ) : 
          moduloIdFiltro !== "" ? (
            setDataPoliza({
                ...dataPoliza,
                isTypePol : 'ConsultaPendientes',
                moduloIdGenera : '',
                typeCall : 'get'

            })

          ) : fnErrorL()
          
        }
        const fnErrorL = () =>{
            toggleLoader(false)
           fnNotification(["Error : Seleccionar alguna empresa",'error',true])
        }
        const handelCosultaProc = () =>{
            setDataPoliza({
                ...dataPoliza,
                isTypePol : 'getProcesos',
                typeCall : 'get'
                    
                }) 
        }
        const changeCheck = e =>{
            setDataPoliza({
                ...dataPoliza,
                [e.target.name] : e.target.checked,
                moduloId : '',
                isTypePol : '',
                typeTab : 0,
                valEmpresas : []
            })
        } 
        const handleDoubleClick = () =>{

        }
        const generatePol = () =>{
            if(moduloIdGenera !== ''){
                toggleLoader(true)
                // console.log("generate_new103-$")
                setDataPoliza({
                    ...dataPoliza,
                    moduloId : dataPoliza.moduloIdGenera, 
                    isTypePol : 'Generate',
                    typeCall : 'get'
                })
                // setbtnGenerate('btnGenerateClick')
                }else{
                fnNotification(['No se ha seleccionado ninguna póliza','error',true]) 
                }
            
        }
        const getReport = (type) =>{
            toggleLoader(true)
            if(moduloIdGenera !== ""){
                setDataPoliza({
                    ...dataPoliza,
                    isTypePol : 'getReporte',
                    moduloId : dataPoliza.moduloIdGenera,
                    isRptDetalle : type,  
                    typeCall : 'get'
                    })

            }else{
                fnNotification(['No se ha seleccionado ninguna póliza','error',true])
                toggleLoader(false)

            }
            

            // if(moduloIdGenera !== ''){
            //     // toggleLoader(true)
            //         console.log(dataPoliza.moduloIdGenera)
            //         setDataPoliza({
            //             ...dataPoliza,
            //             typeCall : 'getReportDet',
            //             moduloId : dataPoliza.moduloIdGenera, 
            //             isTypePol : 'report',
            //             typeReport : type
            //         })
            // }else{
            //     fnNotification(['No se ha seleccionado ninguna póliza','error',true]) 
            // }
        }
        
        const SetModuloId = dtChecked =>{
            const moduloID =  dtChecked.rowVal.moduloId;
            let modulId = "";
            
            if(moduloIdGenera !== ""){
                modulId = moduloIdGenera + "," + String(moduloID)
            }else{
                modulId = String(moduloID);
            }
            if(dtChecked.statusRow){
                setDataPoliza({
                ...dataPoliza,
                moduloIdGenera: modulId,
                isTypePol : ''
            })
            }else{
                let newModulId = "";
                    if(moduloIdGenera.indexOf(moduloID) === 0){
                        if(moduloIdGenera.indexOf(",") === -1) newModulId = moduloIdGenera.replace(moduloID,"")
                        else newModulId = moduloIdGenera.replace(moduloID+",","")
                    } 
                    else newModulId = moduloIdGenera.replace(","+moduloID,"")
                setDataPoliza({
                    ...dataPoliza,
                    moduloIdGenera: newModulId,
                    isTypePol : ''
                })
            }
                    
        }
        const handleChangeChk = (rowVal,statusRow) =>{
            console.log('>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>')
            console.log('>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>')

            console.log(rowVal,statusRow)
            let ModuloIdS = String(rowVal.moduloId)
            let collectionPolXGen = [...datxGenerar]
            const indx = collectionPolXGen.findIndex(p => p.moduloId === ModuloIdS)
            collectionPolXGen[indx].StatusCBX = statusRow
            console.log(ModuloIdS)
            console.log(collectionPolXGen)
            console.log(indx)
            console.log(rowVal,statusRow)
            console.log('>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>')
            console.log('>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>')

            SetModuloId({rowVal,statusRow})
           
        }
        const handleChangeChkAll = statusAll =>{
            let collectionPol = []
            let collModuloId = ''
            datxGenerar.forEach(element =>{
                  element.StatusCBX = statusAll
                  collectionPol.push(element) 
                  collModuloId += element.moduloId + "," 
            })
            collModuloId = statusAll ? collModuloId.substring(0, collModuloId.length -1) : ''
            setDatxGenerar(collectionPol)
            setDataPoliza({
                ...dataPoliza,
                moduloId: collModuloId,
                moduloIdGenera : collModuloId,
                isTypePol : ''

            })

        }
        const actionBtn = val =>{
            toggleLoader(true)
            setDataPoliza({
                ...dataPoliza,
                modulIdDetalle : val.btnAction.split('-')[1],
                isTypePol : 'getDetalle',
                typeCall : 'get'
            })  

        }
        const changeBtn = (e,isSbtn) =>{
            switch (e.target.name){
                case"Filtros":
                setDataTabs({
                    ...dataTabs,
                    tabFiltro: 'active',
                    tabXGenerar : '',                    
                    contenTabXGenerar : 'tab-pane',
                    contenTabGeneradas : 'tab-pane',
                    contenTabProcesos : 'tab-pane',
                    contenTabConfig : 'tab-pane',
                    btnUpdateGenerate :'btnHide btnGenerate', 
                    btnUpdateProc : 'btnHide btnGenerate',
                    btnSaveConfig : 'btnHide btnGenerate'

                })
                setbtnGenerate('btnHide btnGenerate')
                setbtnGenerateReport('btnHide stylePdf')

                break;
                case"XGenerar":
                setDataTabs({
                    ...dataTabs,
                    tabXGenerar : 'active',                    
                    contenTabFiltro : 'tab-pane',
                    contenTabGeneradas : 'tab-pane',
                    contenTabProcesos : 'tab-pane',
                    contenTabConfig : 'tab-pane',
                    btnUpdateGenerate :'btnHide btnGenerate',
                    btnUpdateProc : 'btnHide btnGenerate',
                    btnSaveConfig :'btnHide btnGenerate'

                })
                setbtnGenerate('btnShow btnGenerate')
                setbtnGenerateReport('btnShow stylePdf')
                break;
                case"Generadas":
                setDataTabs({
                    ...dataTabs,
                    tabGeneradas : 'active',
                    contenTabFiltro : 'tab-pane',
                    contenTabXGenerar : 'tab-pane',
                    contenTabProcesos : 'tab-pane',
                    contenTabConfig : 'tab-pane',
                    btnUpdateGenerate :'btnShow btnGenerate',
                    btnUpdateProc : 'btnHide btnGenerate',
                    btnSaveConfig : 'btnHide btnGenerate'
                })
                setbtnGenerate('btnHide btnGenerate')
                setbtnGenerateReport('btnHide stylePdf')
                break;
                case"ProcesossCont":
                setDataTabs({
                    ...dataTabs,
                    tabFiltro: '',
                    tabGeneradas : '',
                    tabProcesos : 'active',
                    contenTabFiltro : 'tab-pane',
                    contenTabGeneradas : 'tab-pane',
                    contenTabXGenerar : 'tab-pane',
                    contenTabProcesos : 'tab-pane active',
                    contenTabConfig : 'tab-pane',
                    btnUpdateGenerate :'btnHide btnGenerate',
                    btnUpdateProc : 'btnShow btnGenerate',
                    btnSaveConfig : 'btnHide btnGenerate'
                })
                setbtnGenerate('btnHide btnGenerate')
                setbtnGenerateReport('btnHide stylePdf')
                break;
                case"Configuracion":
                setDataTabs({
                    ...dataTabs,
                    tabFiltro: '',
                    tabGeneradas : '',
                    tabProcesos : '',
                    tabConfig: 'active',
                    contenTabFiltro : 'tab-pane',
                    contenTabGeneradas : 'tab-pane',
                    contenTabXGenerar : 'tab-pane',
                    contenTabProcesos : 'tab-pane',
                    contenTabConfig : 'tab-pane active',
                    btnUpdateGenerate :'btnHide btnGenerate',
                    btnUpdateProc : 'btnHide btnGenerate',
                    btnSaveConfig : 'btnShow btnGenerate'
                })
                setbtnGenerate('btnHide btnGenerate')
                setbtnGenerateReport('btnHide stylePdf')   
                break;
                default:
                    break
            }
        }
        const updateGenerate = () =>{
            toggleLoader(true)
            setDataPoliza({
                ...dataPoliza,
                typeTab : 1,
                isTypePol : 'ConsultaGeneradas',
                typeCall : 'get'   
                }) 
        }
        const updateProc = () =>{
            setDataPoliza({
                ...dataPoliza,
                isTypePol : 'getProcesos',
                typeCall : 'get'
                }) 
        }
       

            const getModalCrud = () =>{
                // window.$('#modalCRUD').modal('show')
                setDataPoliza({
                    ...dataPoliza,
                    isTypePol : 'modalConfig',
                    modalType: false
                        
                    }) 

            }

            const saveConfig = dataModal =>{
                // console.log(dataModal)

                setDataPoliza({
                    ...dataPoliza,
                    isTypePol : 'saveSetting',
                    typeCall : modalType ? 'update' : 'save',
                    tipoproceso : dataModal.procesoContable,
                    tipoPoliza : dataModal.tipoPoliza,
                    concepto : dataModal.concepto   
                    }) 
            }
            const handleUpdate = (originals, tableValues) =>{
              
                setDataPoliza({
                    ...dataPoliza,
                    isTypePol : 'modalConfig',
                    modalType: true,
                    dtModal: tableValues
                    }) 
            }
          
         return (
             <Fragment>
            <div className="panel panel-primary animated fadeInUp">
                <div className="panel-heading">
                    &nbsp; 
                    <div className="col-md-2 pull-right">
                        Fecha: &nbsp;                        
                        <strong>
                        <Moment format="DD/MM/YYYY">
                            {fechas.current()}
                        </Moment>
                        </strong>
                    </div>
                </div>
                <div className="panel-body">
                {
                !stPoliza ? 
                <ul className="nav nav-pills">
                    <li className={tabFiltro}><a href="#Tab-Filtros" name="Filtros" data-toggle="tab" onClick={(e) => changeBtn(e)} >Filtros</a></li>
                    <li className={tabXGenerar} ><a href="#PolizasGenerar" name="XGenerar"  data-toggle="tab" onClick={(e) => changeBtn(e)}> Polizas por generar </a></li>
                    <li className={tabGeneradas}><a href="#PolizasGeneradas" name="Generadas"  data-toggle="tab" onClick={(e) => changeBtn(e)} > Polizas generadas </a></li>
                    <li className={tabProcesos}><a href="#ProcesosContables" name="ProcesossCont"  data-toggle="tab" onClick={(e) => changeBtn(e)} > Procesos </a></li>
                    {/* <li className={tabConfig}><a href="#Configuracion" name="Configuracion" data-toggle="tab" onClick={e => changeBtn(e)}>Configuración</a></li> */}
                    <div className="divstylePdf">
                        <div className="row">
                        <div className="col-md-5"></div>
                            {/* <div className="col-md-1">
                                <div className={btnGenerateReport} onClick={() => getReport(2)} title="Reporte detallado">
                                    <img src={`${process.env.PUBLIC_URL}/assets/img/Exeldownload.png`} alt="excel"  />
                                </div>                                
                            </div> */}
                            <div className="col-md-1">
                                <div className={btnGenerateReport} onClick={() => getReport(0)} title="Reporte detallado">
                                    <img src={`${process.env.PUBLIC_URL}/assets/img/Exeldownload.png`} alt="excel"  />
                                </div>   
                            </div>
                            <div className="col-md-1">
                                <div className={btnGenerateReport} onClick={() => getReport(1)} title="Reporte">
                                    <img src={`${process.env.PUBLIC_URL}/assets/img/PdfDownload.png`} alt=""  />
                                </div>                                
                            </div>                           
                            <div className="col-md-5">
                                <div className={btnGenerate}  onClick={generatePol} id="btnGenerate" >Exportar póliza</div>
                                <div className={btnUpdateProc}  onClick={updateProc}  id="btnUpdateProc">Actualiza</div>
                                <div className={btnUpdateGenerate}  onClick={updateGenerate}  id="btnActualiza" >Actualiza</div>
                                <div className={btnSaveConfig} onClick={getModalCrud}>Agregar</div>
                            </div>
                        </div>
                    </div>
                </ul>
                : 
                <ul className="nav nav-pills">
                    <li className={tabFiltro}><a href="#Tab-Filtros" name="Filtros" data-toggle="tab" onClick={(e) => changeBtn(e)} >Filtros</a></li>
                    <li className={tabProcesos}><a href="#ProcesosContables" name="ProcesossCont"  data-toggle="tab" onClick={(e) => changeBtn(e)} > Procesos </a></li>
                    <div className="divUpdate">
                        <div className={btnUpdateProc}  onClick={updateProc}  id="btnUpdateProc" >Actualiza</div>
                    </div>
                </ul>
                }
                <div className="tab-content st-bodyP">
                    <div className={contenTabFiltro} id="Tab-Filtros">
                        <div className="row">
                        </div> 
                        <div className="row">
                                <div className="col-md-4">
                                    <label>Fecha inicio</label>
                                    <input 
                                    type="date"
                                    className="form-control" 
                                    id="nombre" 
                                    name="fechaInicio"
                                    onChange={handleChange}
                                //  defaultValue={model.nombre}
                                    value={fechaInicio}
                                />
                                </div>
                                <div className="col-md-4">
                                    <label>Fecha Fin</label>
                                    <input 
                                    type="date"
                                    className="form-control" 
                                    id="nombre" 
                                    name="fechaFin"
                                    onChange={handleChange}
                                //  defaultValue={model.nombre}
                                    value={fechaFin}
                                />
                                </div>
                                <div className="col-md-4">
                                <label>Procesos contables</label>
                                <Select
                                    name='ProcesoContable'
                                    onChange={changeProceso}
                                    options={pocesos}
                                    />
                                </div>
                                
                            </div>
                        <div className="row">
                            <div className="col-md-4">
                                    <label>Empresas</label>
                                    <Select
                                    isMulti
                                    isDisabled={cheCkAll}
                                    name='Empresas'
                                    onChange={handleEmpresa}
                                    options={empresas}
                                    value={valEmpresas}
                                     />                           
                            </div>
                            <div className="col-md-4">
                            <div className="div-Sp">.</div>
                            <div className="div-Check-Pl"> 
                                <div className="border-checkbox-section">
                                        <div className="border-checkbox-group border-checkbox-group-success">

                                            <input 
                                            className="border-checkbox" 
                                            type="checkbox" 
                                            id="cheCkAll" 
                                            name="cheCkAll"
                                            checked={cheCkAll}
                                            
                                            onChange={changeCheck}
                                            />
                                            <label className="border-checkbox-label" htmlFor="cheCkAll">Todos</label>

                                        </div>
                                </div> 
                            </div>
                            </div>  
                            
                            <div className="col-md-4">
                                <div className="div-Sp">.</div>
                                <div className="btn-Pl text-center">
                                    <div className={stylebtnConsulta} onClick={handleConsult}>Generar pólizas</div> 
                                </div>

                            </div>


                            </div>
                            




                    </div>
                    <div className={contenTabXGenerar} id="PolizasGenerar">
                        <div className="row">
                        <div className="col-md-12">
                        {/* <Table 
                            columns={columnsXP} 
                            dataSource={datxGenerar} 
                            rowSelection={{ ...rowSelection, checkStrictly }}
                            rowClassName={() => 'editable-row'}
                             /> */}
                            <GeneralTable
                                    columns={
                                        [
                                        {
                                            Header: 'Empresa',
                                            accessor: 'nombreCorto'
                                        },
                                        { 
                                            Header: "Subtotal",
                                            // accessor: 'SubtotalFormat',
                                            accessor: (d) => {
                                                return '$' + d.SubtotalFormat
                                            }, 
                                        },
                                        { 
                                            Header: "Iva",
                                            // accessor: 'IvaFormat',
                                            accessor: (d) => {
                                                return '$' + d.IvaFormat
                                            },
                                        },
                                        { 
                                            Header: "Neto",
                                            // accessor: 'NetoFormat',
                                            accessor: (d) => {
                                                return '$' + d.NetoFormat
                                            },
                                        },
                                        { 
                                            Header: "Retencion iva",
                                            accessor: 'RetencionIvaFormat',
                                            accessor: (d) => {
                                                return '$' + d.RetencionIvaFormat
                                            },
                                        },
                                        { 
                                            Header: " ",
                                            accessor: "btnAction",
                                        },   
                                        // { 
                                        //     Header: "Concepto",
                                        //     Cell: k =>(
                                        //         <div>
                                        //             <input 
                                        //                 className={k.row.original.StatusCBX ? "inptOn" : "inptOff"}
                                        //                 id={"concept-"+k.row.original.moduloId}
                                        //                 onChange={(e) =>handleConcept(e,k.row.original)}
                                        //                 defaultValue=""
                                        //                 disabled={!k.row.original.StatusCBX}
                                        //             />
                                        //         </div>
                                        //     )
                                        // },                                     
                                        {
                                        Header: "CheckAll",
                                            Cell: k =>(
                                                <div className="border-checkbox-section">
                                                    <div className="border-checkbox-group border-checkbox-group-success">
                                                        <input
                                                            onChange={e => handleChangeChk(k.row.original, e.target.checked, k.gotoPage)}
                                                            checked={k.row.original.StatusCBX}
                                                            className="border-checkbox"
                                                            type="checkbox"
                                                            id={"chkAPlicar" + k.row.original.moduloId}
                                                        />
                                                        <label className="border-checkbox-label"
                                                            htmlFor={"chkAPlicar" + k.row.original.moduloId}>&nbsp;</label>
                                                    </div>
                                                </div>)
                                        }
                                        
                                    ]
                                }
                                    // updateSearch={updateTableSearch} 
                                    data={datxGenerar} 
                                    action={undefined} 
                                    doubleClick={handleDoubleClick}
                                    actionBtn={actionBtn}
                                    // getModulo={getModulo}
                                    handleChangeChkAll={handleChangeChkAll}
                                />
                        </div>
                    </div>

                  </div>
                    <div className={contenTabGeneradas} id="PolizasGeneradas">
                         <GeneralTable
                                columns={columnsPolGen}
                                // updateSearch={updateTableSearch} 
                                data={datGeneradas} 
                                action={undefined} 
                                doubleClick={handleDoubleClick}
                                actionBtn={actionBtn}
                             />
                   </div>
                    <div className={contenTabProcesos} id="ProcesosContables">
                        <div className="row">
                            <div className="col-md-3" >
                                <label>Proceso contable</label>
                                <Select
                                    name='cbProcesoContable'
                                    onChange={changeProcesoT}
                                    options={pocesos}
                                    isClearable
                                    />
                            </div>
                            <div className="col-md-3" >
                            <label>Estatus</label>
                                <Select
                                    isMulti 
                                    name='estatusProcesos'
                                    onChange={changeStatus}
                                    options={cbEstatus}
                                    />
                            </div>
                            <div className="col-md-3 offset-3">
                            <div className="div-Sp">.</div>
                                <div className="btn-Pl text-center">
                                   <div onClick={handelCosultaProc} className='btnConsultaProc'>Generar pólizas</div> 
                                </div>
                           </div>

                       </div>
                       <br />
                       <div className="row">
                       <GeneralTable
                                columns={columnsProcesos}
                                // updateSearch={updateTableSearch} 
                                data={dataProcesos} 
                                action={undefined} 
                                // doubleClick={handleDoubleClick}
                                // actionBtn={actionBtn}
                             />
                       </div>
                   </div>  
                    <div className={contenTabConfig} id="Configuracion">
                        
                        <div>
                            <GeneralTable 
                                columns={columnsConfig}
                                data={dataConfig}
                                action={undefined}
                                doubleClick={handleUpdate}
                            />
                        </div>
                    </div>
                        
                </div>
                        
                </div>
            </div>
            <ModalSetting
            modalType={modalType}
            pocesos={pocesos}
            saveConfig={saveConfig}
            dtModal={dtModal}
             />
             <ModalViewer
             docTo={dataDocto}
            
             />
            <Detalle dataCabecero={dataCabecero} dtDetalle={dataDetalle}/>
            </Fragment>
        )
}

export default FormPolizas;