import React, { Fragment, useState } from "react";
import "../PDFPreview/modalPDFPreview.css";

const PdfPreview = ({ url, clickClose, showmodal }) => {
  const googleDocsUrl = url;
  
  /*`https://docs.google.com/viewer?url=${encodeURIComponent(
    url
  )}&embedded=true`;*/
  const [iframeLoaded, setIframeLoaded] = useState(false);
  const [iframeError, setIframeError] = useState(false);
  const handleIframeLoad = () => {
    setIframeLoaded(true);
    showmodal();
  };

  const handleIframeError = () => {
    setIframeError(true);
  };

  return (
    <Fragment>
      <div
        className="modal fade"
        id="modalPDFPreview"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="modalHistoricoLabel"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-xl modal-dialog-centered "
          role="document"
          style={{  overflowY: 'auto', maxWidth: "1140px !important" }} 
        >
          <div className="modal-content">
            <div className="modal-header mhead">
              <div className="row">
                <div className="col-md-10">
                  <h5
                    className="modal-title text-center mi-xl"
                    style={{ color: "white" }}
                    id="modalHistoricoLabel"
                  >
                    Vista Previa de PDF
                  </h5>
                </div>
                <div className="col-md-2">
                  <button
                    onClick={clickClose}
                    type="button"
                    className="close text-white"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <i className="fa fa-times-circle"></i>
                  </button>
                </div>
              </div>
            </div>
            <div className="modal-body" role="form">
              {!iframeLoaded && !iframeError && <p>Cargando PDF...</p>}
              {iframeError && (
                <p>Error al cargar el PDF. Por favor, inténtalo de nuevo.</p>
              )}

              <iframe
                title="PDF Preview"
                src={googleDocsUrl}
                width="100%"
                height="500px"
                style={{ border: "none" }}
                onLoad={handleIframeLoad}
                onError={handleIframeError}
              />
            </div>
            <div className="modal-footer">
              <div className="col-md-12 col-sm-12 text-center">
                <button
                  type="button"
                  rel="tooltip"
                  className="btn btn-primary"
                  data-placement="top"
                  title="Historico"
                  onClick={clickClose}
                >
                  Cerrar
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default PdfPreview;
