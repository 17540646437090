const inicializa = {
    title: 'Tipos de movimientos',
    breadcrum: [{ nombre: 'Contabilidad' }, { nombre: 'Tipos de movimientos' }]
}
const tableColumns = [
    {
        Header: "ID Tipo movimiento",
        accessor: 'idTipoMovimiento',
    },
    {
        Header: "Descripción",
        accessor: 'descripcion',
    },
    { 
        Header: "Detalle",
        accessor: "btnAction",
    },
    
]

const tableColumnsDet = [
    {
        Header: "ID Concepto",
        accessor: 'idConceptoMovBancario',
    },
    {
        Header: "Descripción",
        accessor: 'DescripcionConcepto',
    },
   
    
]


export { tableColumns, inicializa,tableColumnsDet }