import React, { Fragment, useEffect, useState } from 'react';
import GeneralTable from '../../../Components/GeneralTable';
import { getInfo, saveInfo, updateInfo, deleteInfo,cargaArvhivo } from '../../../ApiRequests/req_CRUD'
import { ExisteSession, toggleLoader } from '../../../ApiRequests/general'
import { fnNotification } from '../../../ApiRequests/Notification'
import { idOrigenWeb } from '../../../Constants/application'
import Header from '../../../Components/Header';
import { inicializa, tableColumns,arrayCampoHist } from './resources'
import Modal from './ModalMantenimientoEmpresas.jsx'
import Buttons from '../../../Components/Buttons/Buttons'
import Session from '../../../Application/session'


const MantenimientoEmpresas = () => {
    const [model, setModel] = useState({
        ID_empresa: -1,
        type: true,
        selectView: null,
        apps: 1,
        typeAction: 'inicia',
        filtro_aplicacion: 38,
        filtro_ambiente: ExisteSession.getAmbiente().idAmbiente,
        idOrigen: idOrigenWeb,
        idUsuarioAct: ExisteSession.getInfoUsuario().idUsuario,
        token: ExisteSession.getInfoUsuario().token,
        connection: ExisteSession.conexion(),
        RFC : "",
        permisoCertificado : false
    });

    const [selecctions, setSelecction] = useState({
        Clientes: [],
        idCliente: null,
        Proveedores: [],
        IdProv: null,
        Estados: [],
        idEstado: null,
        Municipios: [],
        IdMunicipio: null,
        ZonasFiscales: [],
        idZonaFiscal: null,
        Companias: [],
        idTipoCompania: null,
        Marcas: [],
        idMarca: null,
        Giros: [],
        idGiro: null,
        Formatos: [],
        idFormato: null,
        FormatosRecibidos: [],
        idFormatoRecibo: null,
        FormatosComplementos: [],
        idFormatoComplemento: null,
        Productos: [],
        idProducto: null,
        cargas: false,
        RelacionProducto: [],
        // idRelacionProducto: null,
        RelacionProductoTbl: [],

    });


    const [reload, setReload] = useState(true);

    const [dataTableEmp, setDataTableEmp]           = useState([]);
    const [selectTipo,setSelectTipo]                = useState([])
    const [selectDivision,setSelectDivision]        = useState([])
    const [dataSector,setDataSector]                = useState([])
    const [dataSubsector,setDataSubsector]          = useState([])
    const [selectClase,setSelectClase]              = useState([])
    const [giroInicial,setGiroInicial]              = useState([])
    const [giroNuevo,setGiroNuevo]                  = useState([])
    const [clientID, setClientID]                   = useState(0);
    const [dataActividades, setDataActividades]     = useState([]);
    const [dataAEstatus, setDataEstatus]            = useState([]);
    const [statusEmpresa, setStatusEmpresa]         = useState([]);
    const [dataTableUs, setDataTableUs] = useState([]);
    const [updateTableSearch, setUpdateTableSearch] = useState(false);
    const [isTabActiv, setIsTabActiv]               = useState(false);
    const [email, setEmail]                         = useState('');
    const [typeLogo, setTypeLogo]                   = useState('');
    const [nameLogo, setNameLogo]                   = useState('');
    const [logotipo, setLogotipo]                     = useState('');
    const [logoTemp, setLogoTemp]                     = useState('');
    const [addProveedor, setAddProveedor]           = useState(false);
    const [showTblHist, setShowtblHist]                        = useState(false)
    const [showConfirmHist, setShowConfirmHist]                = useState(false)
    const [dataAllHist, setDataAllHist]                         = useState([])
    const [dataHist, setDataHist]                              = useState([])
    const [configHist, setConfigHist]                              = useState([])
    const [originalData, setOriginalData]                              = useState([])
    const [registroTributario, setRegistros]        = useState({
        cerPath:"",
        keyPath:"",
        keyPassword:"",
        password: "",
        usuarioCuenta:"",
        existeCertificados: false,
        urlPublica:"",
        idTipoAutenticacion : "0",
        fechaVigencia :"",
        cerPathPublic:"",
        keyPathPublic:""
    });
    const childRef = React.createRef();

    useEffect(() => {
        toggleLoader(true);
        // getInfo("Catalogos/catGiros", model, (response) => LoadGiros(response));

        getInfo("Catalogos/catGiros", model, (response) => Load(response, "giro", "idGiro", "Giros"));
        getInfo("Catalogos/catMarcas", model, (response) => Load(response, "descripcion", "idMarca", "Marcas"));
        getInfo("Catalogos/catProveedor", model, (response) => Load(response, "nombre", "prov_id", "Proveedores"));
        getInfo("Catalogos/catEstados", model, (response) => Load(response, "Nombre", "EntidadFed_ID", "Estados"));
        getInfo("Catalogos/catTipoCompanias", model, (response) => Load(response, "descripcion", "idTipoCompania", "Companias"));
        getInfo("Catalogos/catZonaFiscal", model, (response) => Load(response, "nombre", "zona_fiscal_id", "ZonasFiscales"));
        getInfo("Catalogos/catMunicipios", model, (response) => Load(response, "Nombre", "Municipio_ID", "Municipios"));
        getInfo("Catalogos/catClientes", model, (response) => Load(response, "nombre", "cliente_id", "Clientes"));
        getInfo("Catalogos/catProductos", model, (response) => Load(response, "desc_corta", "prod_id", "Productos", false, 1));



        getInfo("Catalogos/catEmpresas", model, (response) => LoadTable(response));
    }, [reload])

    const LoadTable = (response) => {
        debugger
        if (response){ 
            console.log(response);
        debugger

            response.forEach(e => e.estatusActividades = e.idEstatusActividades === 1 ? "Configuración completa" : "Configuración incompleta");
            setDataTableEmp(response);
            setUpdateTableSearch(true)
        }
        toggleLoader(false);
    }
    const showModal = () => {
        setEmail('')
        setDataHist([])
        setShowConfirmHist(false)
        toggleLoader(true)
        let dataM = {
            idTypeFilter : 1,
            tabla:'Catalogo_empresas',
            idUsuarioAct: ExisteSession.getInfoUsuario().idUsuario,
            connection: ExisteSession.conexion(),
            }
        getInfo("Catalogos/catGenerales", dataM, (respGen) =>{
            console.log(respGen)
            let aux = respGen.map(e => {return{value:e.valor,label:e.descripcion}})
            setDataEstatus(aux)
            console.log(aux)
           
            toggleLoader(false)
           
            });
        setModel({
            ...model,
            selectView: null,
            type: true,
            ID_empresa: -1
        });
        window.$('#modalEmpresa').modal('show');
       

    }

    const handleDoubleClick = (originals, tableValues) => {
        toggleLoader(true);

        getInfo("Catalogos/Google",{ruta:originals.selloRepse},(x)=>{
            if(x!=null){
               if(x.length>0){
                    var rutaSello=x[0];
                    originals.selloRepsePublic=rutaSello.url;

               } 
            }

            getInfo("Catalogos/Google",{ruta:originals.archivoRepse},(x)=>{
                if(x!=null){
                   if(x.length>0){
                        var rutaArchivo=x[0];
                        originals.archivoRepsePublic=rutaArchivo.url;
    
                   } 
                }
                setModel({
                    ...model,
                    selectView: originals,
                    type: false,
                    typeAction: '',
                    ID_empresa: originals.ID_empresa,
                    RFC: originals.RFC
                });
                console.log(model)
                console.log(originals)
                
            });
            
        });

        let respO = []
        // respO.push(originals)
        // console.log(originals)
        show_Histori(originals.ID_empresa,false)

        console.log(childRef)
        setEmail('')
        setOriginalData(originals)
        setStatusEmpresa([])
        // console.log(childRef.current.miFuncion())
        

        toggleLoader(true)
        setGiroInicial(originals.idGiro)
        setGiroNuevo(originals.idGiro)
        // console.log(dataAEstatus)

        // let sts = dataAEstatus.find(x => x.value === originals.originals.idEstatus)
        // console.log(sts)
        // setStatusEmpresa(sts)
        setModel({
            ...model,
            selectView: originals,
            type: false,
            typeAction: '',
            ID_empresa: originals.ID_empresa,
            RFC: originals.RFC
        });
        
        setClientID(originals.idCliente)
        let dataM = {
        idTypeFilter : 1,
        nameSp:'Spc_Sat_SectorActividadTbl',
        cliente_id:originals.idCliente,
        tabla:'Catalogo_empresas',
        idUsuarioAct: ExisteSession.getInfoUsuario().idUsuario,
        connection: ExisteSession.conexion(),
        }
        getInfo("Catalogos/catGenerales", dataM, (respGen) =>{
        console.log(respGen)
        console.log(originals)
        console.log(originals.email)
        setEmail(originals.email)
        let aux = respGen.map(e => {return{value:e.valor,label:e.descripcion}})
        setDataEstatus(aux)
        console.log(aux)
        console.log('idEstatus',originals.idEstatus) 
        if(originals.idEstatus > -1)
        {
            console.log('dataAEstatus',aux)
            let statusS = aux.find(s => s.value === originals.idEstatus)
            if(statusS) setStatusEmpresa(statusS)
        }
        toggleLoader(false)
        console.log('dataM',dataM)
        //deb
        getInfo("Catalogos/CatActividadesEconomicas/Filter", dataM, (objResp) =>{
            let auxS = objResp.map(s => {return {value : s.codigoSector, label:s.codigoSector+'-'+ s.sectorActividad}})
            setDataSector(auxS)
            window.$('#modalEmpresa').modal('show');
            });
        });
    }

    const saveDataUser = (dataNewUser,configHist) => {
        let auxJson = ''
        dataNewUser.email = email
        dataNewUser.logotipo = logotipo
        dataNewUser.typeLogo = typeLogo
        dataNewUser.nameLogo = nameLogo
        console.log(statusEmpresa)
        dataNewUser.idEstatus = statusEmpresa.value;
        console.log(dataNewUser)
        console.log(childRef.current)
        console.log('logotipo',logotipo)
        console.log('originalData',originalData)
        console.log('dataNewUser',dataNewUser)
        console.log('configHist',configHist)
        let jsona = '[{'
        for(var arr = 0; arr < configHist.length; arr++){
            console.log(configHist[arr])   
            console.log(arrayCampoHist)   
            debugger         
            let aux = arrayCampoHist.find(x => x.idCampoH === configHist[arr].idConfigResp)
            console.log(aux)
            debugger
            if(aux){
                // dataNewUser.createHist = true
                let datoAntiguo = originalData[aux.descrip_Modal]
                datoAntiguo = originalData[aux.descrip_Modal] ? originalData[aux.descrip_Modal] : '';
                if(originalData[aux.descrip_Modal] !== dataNewUser[aux.descrip_Modal] && dataNewUser[aux.descrip_Modal] !== undefined && dataNewUser[aux.descrip_Modal] !== ''){
                    jsona += '"'+aux.descripConfig +'":"' + datoAntiguo  +'","' + aux.descripJson +'":"'+   dataNewUser[aux.descrip_Modal] +'",'
                }
            }
        }
        debugger
        if(jsona.length > 2){
            dataNewUser.createHist = true
            jsona = jsona.substring(0,jsona.length-1) + '}]'

        }  else dataNewUser.createHist = false
        console.log(jsona)
        debugger


        
        dataNewUser.JsonHistorial = jsona
        console.log(dataNewUser.email)
        toggleLoader(true);
        let _validation = ValidationForm(dataNewUser);
        console.log(_validation)
        let proveedorCliente = validateClienteProveedor(dataNewUser); 
        if(proveedorCliente !== ''){
            toggleLoader(false);
            fnNotification(['Error: El '+proveedorCliente+' no es valido. Para poder editar la empresa se requiere modificarlo' , 'error', true])
            return false;
        }

        if (!_validation) {
        if (model.type){
                console.log('datos',dataNewUser)
                dataNewUser.usuario = 'VANTEC'
                debugger
                saveInfo("Catalogos/catEmpresas", dataNewUser, (response,t,succes) => responseSave(response,t,succes,true,dataNewUser.idGiro));
            }
            else
            {

                // console.log(dataNewUser)
                // console.log('dataNewUser.idGiro',dataNewUser.idGiro)
                // console.log('giroInicial',giroInicial)
                console.log('nameLogo',nameLogo)
        debugger
              
                setGiroNuevo(dataNewUser.idGiro)
                updateInfo("Catalogos/catEmpresas", dataNewUser, (response,t,succes) => responseSave(response,t,succes,false,dataNewUser.idGiro));

            }
          
        }
        else {
            fnNotification(['Error: ' + _validation, 'error', true])
            toggleLoader(false);
        }
    }
    const searchRFC = (rfc) => {
        console.log('selecctions',selecctions)
        console.log('Clientes',selecctions.Clientes)
        console.log('Proveedores',selecctions.Proveedores)
        let client = selecctions.Clientes.find(c => c.rfc.replace(' ','') === rfc)
        let proveedor = selecctions.Proveedores.find(p => p.rfc.replace(' ','') === rfc)
        console.log('client',client)
        console.log('proveedor',proveedor)
        if(client && proveedor){
            setAddProveedor(true)
            setSelecction({
                ...selecctions,
                IdProv: proveedor,
                idCliente:client
            });
        }
        else setAddProveedor(false)
        
        // getInfo("Catalogos/catEmpresas/validateProvedCliente", dataEmpresa, (response) => {
        //     console.log(response);
        // });

    }
    const clickDelete = (dataNewUser) => {
        toggleLoader(true);
        deleteInfo("Catalogos/catEmpresas", dataNewUser, (response) => responseDelete(response));
    }


    const responseSave = (response,t,succes,isSave,idGiro) => {
        
        if(succes){
            console.log(childRef.current)
            
            // 
            if(isSave){
                setModel({
                    ID_empresa: 0,
                type: true,
                selectView: null,
                apps: 1,
                typeAction: 'inicia',
                filtro_aplicacion: 38,
                filtro_ambiente: ExisteSession.getAmbiente().idAmbiente,
                idOrigen: idOrigenWeb,
                idUsuarioAct: ExisteSession.getInfoUsuario().idUsuario,
                token: ExisteSession.getInfoUsuario().token,
                connection: ExisteSession.conexion(),
                RFC : "",
                permisoCertificado : false
                    // ...model,
                    // selectView: null,
                    // type: true,
                    // ID_empresa: 0
                });
                
                setReload(!reload);
                setDataTableUs([]);
                window.$('#modalEmpresa').modal('hide');
                getInfo("Catalogos/catEmpresas", 
                {
                    ID_empresa: 0,
                    type: true,
                    selectView: null,
                    apps: 1,
                    typeAction: 'inicia',
                    filtro_aplicacion: 38,
                    filtro_ambiente: ExisteSession.getAmbiente().idAmbiente,
                    idOrigen: idOrigenWeb,
                    idUsuarioAct: ExisteSession.getInfoUsuario().idUsuario,
                    token: ExisteSession.getInfoUsuario().token,
                    connection: ExisteSession.conexion(),
                    RFC : "",
                    permisoCertificado : false
                }
                , (response) => {LoadTable(response)
                    
                    toggleLoader(false);
                });
                // setModel({
                //     ...model,
                //     selectView: null,
                //     type: true,
                //     RFC : "",
                //     ID_empresa: -1
                // });

                // window.$('#modalEmpresa').modal('hide');
                // setReload(!reload);

            }else{
                console.log('giroInicial',giroInicial)
                console.log('giroNuevo',idGiro)

                if(giroInicial !== idGiro){
                 toggleLoader(false);
                 
                 setTimeout(() => {
                    window.$('#tabActivs').click();
                    setIsTabActiv(true)
                    console.log(childRef.current)
                    getInfo("Catalogos/catEmpresas", 
                    {
                        ID_empresa: 0,
                        type: true,
                        selectView: null,
                        apps: 1,
                        typeAction: 'inicia',
                        filtro_aplicacion: 38,
                        filtro_ambiente: ExisteSession.getAmbiente().idAmbiente,
                        idOrigen: idOrigenWeb,
                        idUsuarioAct: ExisteSession.getInfoUsuario().idUsuario,
                        token: ExisteSession.getInfoUsuario().token,
                        connection: ExisteSession.conexion(),
                        RFC : "",
                        permisoCertificado : false
                    }
                    , (response) => {LoadTable(response)
                        
                        toggleLoader(false);
                    });

    
                }, );

                }else{
                    setModel({
                        ID_empresa: 0,
                    type: true,
                    selectView: null,
                    apps: 1,
                    typeAction: 'inicia',
                    filtro_aplicacion: 38,
                    filtro_ambiente: ExisteSession.getAmbiente().idAmbiente,
                    idOrigen: idOrigenWeb,
                    idUsuarioAct: ExisteSession.getInfoUsuario().idUsuario,
                    token: ExisteSession.getInfoUsuario().token,
                    connection: ExisteSession.conexion(),
                    RFC : "",
                    permisoCertificado : false
                        // ...model,
                        // selectView: null,
                        // type: true,
                        // ID_empresa: 0
                    });
                    
                    setReload(!reload);
                    setDataTableUs([]);
                    window.$('#modalEmpresa').modal('hide');
                    getInfo("Catalogos/catEmpresas", 
                    {
                        ID_empresa: 0,
                        type: true,
                        selectView: null,
                        apps: 1,
                        typeAction: 'inicia',
                        filtro_aplicacion: 38,
                        filtro_ambiente: ExisteSession.getAmbiente().idAmbiente,
                        idOrigen: idOrigenWeb,
                        idUsuarioAct: ExisteSession.getInfoUsuario().idUsuario,
                        token: ExisteSession.getInfoUsuario().token,
                        connection: ExisteSession.conexion(),
                        RFC : "",
                        permisoCertificado : false
                    }
                    , (response) => {LoadTable(response)
                        
                        toggleLoader(false);
                    });
                    // setModel({
                    //     ...model,
                    //     selectView: null,
                    //     type: true,
                    //     RFC : "",
                    //     ID_empresa: -1
                    // });
                    // window.$('#modalEmpresa').modal('hide');
                    // setReload(!reload);
                }

            }
           // if(logotipo) subirArchivoImage(logotipo,response[0])

        }else toggleLoader(false)
       
        // toggleLoader(false);

    }
    const subirArchivoImage = (e,dataEmpresa) => {
        toggleLoader(true);
        debugger

        var data = new FormData()
        data.append('files', e[0])
        data.append('cargar', JSON.stringify(
            {
                // ID_empresa:dataEmpresa.ID_empresa,
                // IdMunicipio:dataEmpresa.IdMunicipio,
                // idEstado:dataEmpresa.idEstado,

                idUsuarioAct: ExisteSession.getInfoUsuario().idUsuario,
                token: ExisteSession.getInfoUsuario().token,
                connection: ExisteSession.conexion()
            }
        ))
        cargaArvhivo('Catalogos/catEmpresas/Logo', data, {
            idUsuarioAct: ExisteSession.getInfoUsuario().idUsuario,
            token: ExisteSession.getInfoUsuario().token,
            connection: ExisteSession.conexion()
        }, (respons) => {

            // if (respons) {
            //     cargaArvhivo('Catalogos/catEmpresas/PDFupload', data, {
            //         idUsuarioAct: ExisteSession.getInfoUsuario().idUsuario,
            //         token: ExisteSession.getInfoUsuario().token,
            //         connection: ExisteSession.conexion()
            //     }, (respon) => {
            //         if (respon) {
            //             setModel({
            //                 ...Register,
            //                 urlRepse:  respons.urlRepse,
            //                 archivoRepse:  respon.urlDocument,
            //                 archivoRepsePublic:  respon.urlPublicDocument,
            //             })
            //         }
            //         document.getElementById("ArchivoRepse").value = "";
            //         toggleLoader(false);
            //     })
            // }         
        })
    }
    const responseDelete = (response,t,succes) => {
        setModel({
            ...model,
            selectView: null,
            type: true,
            ID_empresa: -1
        });
        setReload(!reload);
        setDataTableUs([]);
        window.$('#modalEmpresa').modal('hide');
        getInfo("Catalogos/catEmpresas", 
        {
            ID_empresa: 0,
            type: true,
            selectView: null,
            apps: 1,
            typeAction: 'inicia',
            filtro_aplicacion: 38,
            filtro_ambiente: ExisteSession.getAmbiente().idAmbiente,
            idOrigen: idOrigenWeb,
            idUsuarioAct: ExisteSession.getInfoUsuario().idUsuario,
            token: ExisteSession.getInfoUsuario().token,
            connection: ExisteSession.conexion(),
            RFC : "",
            permisoCertificado : false
        }
        , (response) => {LoadTable(response)
           
            toggleLoader(false);
        });
        // toggleLoader(false);
    }

    const actionClose = () => {
        console.log('selectView',model)
        setLogoTemp('')
        setLogotipo('')
        toggleLoader(true);
        setModel({
            ...model,
            selectView: null,
            type: true,
            ID_empresa: -1
        });
        setDataTableUs([]);
        window.$('#modalEmpresa').modal('hide');
        getInfo("Catalogos/catEmpresas", 
        {
            ID_empresa: 0,
            type: true,
            selectView: null,
            apps: 1,
            typeAction: 'inicia',
            filtro_aplicacion: 38,
            filtro_ambiente: ExisteSession.getAmbiente().idAmbiente,
            idOrigen: idOrigenWeb,
            idUsuarioAct: ExisteSession.getInfoUsuario().idUsuario,
            token: ExisteSession.getInfoUsuario().token,
            connection: ExisteSession.conexion(),
            RFC : "",
            permisoCertificado : false
        }
        , (response) => {LoadTable(response)
           
            toggleLoader(false);
        });
        
    }

    const ValidationForm = (dataNewUser) => {
        console.log(dataNewUser)
        var _mesage = "";
       

        var validarEmail = emailValido(); 
        if (!dataNewUser.nombreCorto)
            _mesage += "Se requiere Nombre corto. "
        if (!dataNewUser.nombreLargo)
            _mesage += "Se requiere el campo Empresa. "
        var validarfc = rfcValido(dataNewUser.RFC); //Recordar que este es el método para publicar 
        if (!validarfc)
            _mesage += "RFC no valido. ";    
        if (!dataNewUser.idEstado)
            _mesage += "Se requiere un Estado. ";   
        if (!dataNewUser.IdMunicipio)
            _mesage += "Se requiere un Municipio. ";       
        if (!dataNewUser.codigoPostal)
            _mesage += "Se requiere un C.P. "; 
        if (!dataNewUser.colonia)
            _mesage += "Se requiere el dato Colonia. "; 
        if (!dataNewUser.direccion)
            _mesage += "Se requiere el dato Direccion. ";

        if (!dataNewUser.telefono)
            _mesage += "Se requiere el dato telefono. "; 
        else if (dataNewUser.telefono.length !== 10)
            _mesage += "El teléfono tiene que ser de 10 dígitos. "; 
        
        if (!dataNewUser.idZonaFiscal)
            _mesage += "Se requiere una zona fiscal. ";
        if (!dataNewUser.idRegimenFiscal)
            _mesage += "Se requiere el dato Regimen Fiscal. "; 
        if (!dataNewUser.idTipoCompania)
            _mesage += "Se requiere un Tipo de compañia. ";
        // if (!dataNewUser.objetoSocial)
        //     _mesage += "Objeto social no puede ser vacío. ";
        if(!validarEmail)
            _mesage += "Correo no valido. ";  
        if (!dataNewUser.idGiro)
            _mesage += "Se requiere un Giro. ";
        return _mesage;
    }
    const validateClienteProveedor = (datos) => {
        console.log('datos',datos)
        console.log('datos',datos.RFC)
        console.log('IdProv',datos.IdProv)
        console.log('idCliente',datos.idCliente)
        console.log('selecctions',selecctions)
        let respons = "";

        let rfclient = datos.idCliente > 0 ? selecctions.Clientes.find(x => x.value === datos.idCliente).rfc.replace(' ','') : ""
        let rfcproveed = datos.IdProv > 0 ? selecctions.Proveedores.find(x => x.value === datos.IdProv).rfc.replace(' ',''): ""
        console.log('rfclient',rfclient)
        console.log('rfcproveed',rfcproveed)

        respons = datos.idCliente > 0 ? rfclient   !== datos.RFC ? 'Cliente'   : "" : ""
        respons = datos.IdProv    > 0 ? rfcproveed !== datos.RFC ? 'Proveedor' : "" : ""

        return respons

        
    }
   
    const rfcValido = (rfc, aceptarGenerico = true) => {
        const re = /^([A-ZÑ&]{3,4}) ?(?:- ?)?(\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])) ?(?:- ?)?([A-Z\d]{2})([A\d])$/;
        var validado = rfc.match(re);

        if (!validado)
            return false;

        const digitoVerificador = validado.pop(),
            rfcSinDigito = validado.slice(1).join(''),
            len = rfcSinDigito.length,

            diccionario = "0123456789ABCDEFGHIJKLMN&OPQRSTUVWXYZ Ñ",
            indice = len + 1;
        var suma,
            digitoEsperado;

        if (len === 12) suma = 0
        else suma = 481;

        for (var i = 0; i < len; i++)
            suma += diccionario.indexOf(rfcSinDigito.charAt(i)) * (indice - i);
        digitoEsperado = 11 - suma % 11;

        if (digitoEsperado === 11) digitoEsperado = 0;
        else if (digitoEsperado === 10) digitoEsperado = "A";
       
        if(typeof digitoEsperado === 'string'){
            if ((digitoVerificador !== digitoEsperado)
                && (!aceptarGenerico || rfcSinDigito + digitoVerificador !== "XAXX010101000"))
                return false;
            else if (!aceptarGenerico && rfcSinDigito + digitoVerificador === "XEXX010101000")
                return false;
        }else{

            if ((parseInt(digitoVerificador) !== digitoEsperado)
                && (!aceptarGenerico || rfcSinDigito + digitoVerificador !== "XAXX010101000"))
                return false;
            else if (!aceptarGenerico && rfcSinDigito + digitoVerificador === "XEXX010101000")
                return false;
        }
        return rfcSinDigito + digitoVerificador;
    }
    const emailValido =() => {
        // console.log(email)
        // toggleLoader(true)   
        if(email !== "" && email !== undefined){
            var patron=/^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i;
            if(email.search(patron)===0) return true
            else return false 
    
        }else return false   
    
        
    }

    const Load = (response, _label, _value, _array, _table, _special) => {
        console.log(response)
        console.log(_array)
        console.log(_table)
        console.log(_special)
        
        var _load = [];
        if (response && response.length > 0) {
            for (let index = 0; index < response.length; index++) {
                if (_special && _special === 1)
                    _load.push({ value: response[index][_value], label: response[index]["prod_id"] + " - " + response[index][_label] + (response[index]["claveSat"] ? " - " + response[index]["claveSat"] : "") });
                else{
                    if(_array === 'Clientes' || _array === 'Proveedores')
                    _load.push({ value: response[index][_value], label: response[index][_label], rfc:response[index].rfc });
                    else
                    _load.push({ value: response[index][_value], label: response[index][_label] });

                }
            }
        }
        setSelecction({
            ...selecctions,
            [_array]: selecctions[_array] = _load
        });
        if (_table)
            setSelecction({
                ...selecctions,
                [_table]: selecctions[_table] = response
            });

        setRegistros({
            ...registroTributario,
            cerPath:"",
            keyPath:"",
            keyPassword:"",
            password: "",
            usuarioCuenta:"",
            existeCertificados: false,
            urlPublica:"",
            idTipoAutenticacion : "0",
            fechaVigencia :"",
            cerPathPublic:"",
            keyPathPublic:""
        })

        // console.log(ExisteSession.getPermisos())
        let collectionPermisosEmpresas = ExisteSession.getPermisos().filter(x=>x.idVista == 17 && x.accion == 'AgregaCertificados' && x.idEstatus == true )
        if(collectionPermisosEmpresas.length > 0){
            setModel({
                ...model,
                permisoCertificado : true
            })
        }
    }


   const refreshClient = (totPorcent) => {
    setUpdateTableSearch(true)
    let tblEmp = [...dataTableEmp]
    let rowE = tblEmp.find(x => x.ID_empresa === model.ID_empresa)
    let indxE = tblEmp.findIndex(x => x.ID_empresa === model.ID_empresa)
    tblEmp[indxE].estatusActividades = totPorcent < 100 ? "Configuración incompleta" : "Configuración completa"
    setDataTableEmp(tblEmp)
    setTimeout(() => {
    setUpdateTableSearch(false)
    setDataTableEmp(tblEmp)
    console.log(tblEmp)
         
    }, );
    }

    const onChangeEmail = (e) =>{
        setEmail(e.target.value);
    }
    const changeStatus= (e) =>{
        setStatusEmpresa(e)

    }
    const changeLogo = (event) =>{
        setTypeLogo(event.target.files[0].type)
        setNameLogo(event.target.files[0].name)
        const file = event.target.files[0];
            if (file) {
              const reader = new FileReader();
              reader.onload = (e) => {
                const base64String = e.target.result;
                setLogoTemp(base64String)
                setLogotipo(base64String.split(',')[1])

              };
        
              reader.readAsDataURL(file);
            }

        
    }
    const show_Histori = (idEmpresa,isShow) => {
            let filter = {
                idEmpresa :  idEmpresa,
                idUsuarioAct: ExisteSession.getInfoUsuario().idUsuario,
                token: ExisteSession.getInfoUsuario().token,
                connection: ExisteSession.conexion()
            }
            getInfo("Catalogos/catEmpresas/Historial", filter, (response) => {
                console.log('response',response)
                if(response){

                    if(isShow){
                        setShowConfirmHist(false)
                        setShowtblHist(true)
                        console.log('response',response)

                        if(response.length > 0 && response[0]){
                            if(response[0].errorCode > 0){
                                fnNotification([response[0].mensaje  , 'error', false])
                                
                            }else{
                            let auxResp = response[0];
                            var nuevoArray    = []
                            var arrayTemporal = []
    
                            /*Ordenar por secuencia */
                            auxResp.sort((a, b) => a.idSecuencia - b.idSecuencia);
                            for(var i=0; i<auxResp.length; i++){
                                auxResp[i].idHistTemp = i + 1;
                                auxResp[i].isDetail = true;
                                auxResp[i].showDetail = false;
    
                            }
                            /*Ordenar por idHistTemp */
                            console.log('auxResp',auxResp)
                            /*Extraer cabecero*/
                            for(var r=0; r<auxResp.length; r++){
                                arrayTemporal = nuevoArray.filter(resp => resp["idSecuencia"] == auxResp[r]["idSecuencia"])
                                if(arrayTemporal.length>0){
                                    // nuevoArray[nuevoArray.indexOf(arrayTemporal[0])]["Profesionales"].push(response[i]["Nombre"])
                                    
                                }else{
                                    auxResp[r].isDetail = false;
                                    nuevoArray.push(auxResp[r])
                                }
    
                            }
                            setDataAllHist(auxResp)
                            setDataHist(nuevoArray)
                            console.log('auxResp',auxResp)
                        }
    
                        }


                    }else{
                        setShowtblHist(false)
                        setShowConfirmHist(false)
                    }
                    

                   
                    if(response.length > 0 && response[1]){
                        if(response[1].errorCode > 0){
                            fnNotification([response[1].mensaje  , 'error', false])
                            
                        }else{
                            let auxConfig = []
                            for(var c = 0; c < response[1].length; c++){
                                response[1][c].idConfigResp = c + 1;
                                if(response[1][c].idEstatus == 1){
                                    console.log(response[1][c].descripcion, response[1][c].idEstatus)
                                    auxConfig.push(response[1][c])
                                    //'[{"nom_corto":"RALP_U","Psnom_Corto":"RALP_U","nom_largo":"CORPORATIVO PROFESIONAL RALP, S.A. DE C.V.","Psnom_largo":"CORPORATIVO PROFESIONAL RALP, S.A. DE C.V.","usuario":"CBERNAL","PsUsuario":"CBERNAL","RFC_empresa":"CPR110907UB3","PsRFC_empresa":"CPR110907UB3","cliente_ID":149,"PnCliente_ID":149,"prov_id":317,"PnProv_Id":317,"Municipio_ID":829,"PnMunicipio_ID":829,"EntidadFed_ID":16,"PnEntidadFed_ID":16,"direccion":"BLVD. MANUEL AVILA CAMACHO 1903, 600 A","PsDireccion":"BLVD. MANUEL AVILA CAMACHO 1903, 600 A","colonia":"CIUDAD SATÉLITE","PsColonia":"CIUDAD SATÉLITE","cod_postal":53100,"PnCod_postal":53100,"zona_fiscal_id":1,"PnZona_Fiscal_id":1,"telefono":"55 5555 5555","SAT_idRegimenFiscal":601,"PnSat_idRegimenFiscal":601,"idTipoCompania":1,"PnidTipoCompania":1,"activo":1,"PnActivo":1,"esPagadora":0,"PnEsPagadora":0,"manejaPersonal":1,"PnManejaPersonal":1,"PnIdFormato":0,"PnIdFormatoRecibo":0,"PnIdFormatoComplemento":0,"idGiro":18,"PnIdGiro":18,"PnIdMarca":0,"PsFolioRepse":" ","PsUrlRepse":" ","selloRepse":"SIAN_PRUEBAS\/SELLOS\/hero-image-autumn-leaves-on-flat-blue-background-2-free-photo-1080x720.jpg","PsSelloRepse":"SIAN_PRUEBAS\/SELLOS\/hero-image-autumn-leaves-on-flat-blue-background-2-free-photo-1080x720.jpg","PsArchivoRepse":" ","objetoSocial":"ACTUALIZACION DE OBJETO SOCIAL\nKSADJKASSS JSAKDDDDDDDDDD KSAJDKL","PsObjetoSocial":"ACTUALIZACION DE OBJETO SOCIAL\nKSADJKASSS JSAKDDDDDDDDDD KSAJDKL"}]',
                                } 
                            }
                            console.log(auxConfig)
                            console.log(originalData)
                            setConfigHist(auxConfig)

                        }
                    }
                    
                }
            })
        // else{
        //     setShowtblHist(false)
        //     setShowConfirmHist(false)
        // }
    }
    const showData_Secuencia = (r) => {
        let auxHistShow = [...dataHist];
        let indx = auxHistShow.findIndex(x => x.idHistTemp === r.row.original.idHistTemp)
        auxHistShow[indx].showDetail = !r.row.original.showDetail;
        setDataHist(auxHistShow)
        let auxRow =   auxHistShow[indx]
        let auxHisTT = []
        if(r.row.original.showDetail){
            let auxHist = dataAllHist.filter(x => x.idSecuencia === r.row.original.idSecuencia && x.idHistTemp !== r.row.original.idHistTemp)
            auxHisTT = dataHist.concat(auxHist)
        }else{
            let auxHistTrue = dataHist.filter(x => x.idSecuencia !== r.row.original.idSecuencia)
            auxHistTrue.push(auxRow)
            auxHisTT = auxHistTrue
        }
        console.log(auxHisTT)
        auxHisTT.sort((a, b) => a.idHistTemp - b.idHistTemp);
        setDataHist(auxHisTT)
    }
    return (
        <Fragment>
            <Header title={inicializa.title + ' ' + Session.getInfoAmbiente()} breadcrum={inicializa.breadcrum} />

            <div className="lx-container container">
                <div className="panel panel-primary animated fadeInUp">
                    <div className="panel-heading">
                        <div className="row">
                            <div className="col-md-6">
                                <span>Mantenimiento Empresa</span>

                            </div>
                            <div className="col-md-6 btnF">
                                <Buttons btnAdd={true} btnAddAction={showModal} btnAddText={true} btnAddShowIcon={true} />

                            </div>

                        </div>
                    </div>
                    <div className="panel-body">
                        <GeneralTable
                            columns={tableColumns}
                            updateSearch={updateTableSearch} 
                            data={dataTableEmp}
                            action={undefined}
                            doubleClick={handleDoubleClick}
                        />
                    </div>

                </div>

            </div>
            {/* {
                selecctions.cargas ?
                    <Modal model={model} saveDataUser={saveDataUser} actionClose={actionClose} clickDelete={clickDelete} selecctions={selecctions} setSelecction={setSelecction} />
                    :
                    ""

            } */}
            <Modal ref={childRef}
            model={model}
            saveDataUser={saveDataUser}
            actionClose={actionClose}
            clickDelete={clickDelete} 
            selecctions={selecctions}
            setSelecction={setSelecction}
            refreshClient={refreshClient}
            optionsSector={dataSector}
            clientID={clientID}
            dataActividadesEco={dataActividades}
            registroTributario={registroTributario} setRegistros={setRegistros}
            onChangeEmail={onChangeEmail}
            email = {email}
            isTabActiv = {isTabActiv}
            cnahgeActive_={() => setIsTabActiv(false)}
            searchRFC={searchRFC}
            addProveedor={addProveedor}
            allStatus={dataAEstatus}
            changeStatus={changeStatus}
            statusEmpresa={statusEmpresa}
            changeLogo={changeLogo}
            logoTemp={logoTemp}
            show_Histori={show_Histori}
            showData_Secuencia={showData_Secuencia}
            showTblHist       = {showTblHist}     
            showConfirmHist   = {showConfirmHist}
            dataAllHist       = {dataAllHist}    
            dataHist          = {dataHist}       
            configHist        = {configHist}
            />

        </Fragment>

    );
}

export default MantenimientoEmpresas;