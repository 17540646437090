const inicializa = {
    title : 'Relación empresa cuenta',
    breadcrum : [{nombre: 'Catalogos'},{nombre: 'Relación empresa cuenta'}]
}
const tableColumns = [
    { 
        Header: "Banco",
        accessor: 'banco',
    },
    { 
        Header: "Cuenta",
        accessor: 'numerodecuenta',
    },
    { 
        Header: "Es comodin",
        Cell: k => (

            k.row ?
              <span>
                {k.row.original.comodin === true ? 'SI' : 'NO'}
            </span> 
            :
            <span></span>
    
    ),
        accessor: 'comodin',
    },
   
    
]



const tableData = []

export { tableColumns, inicializa, tableData }