import React from 'react';
import GeneralTable from './../../../../Components/GeneralTable'


function TabComponentes({dataComponente,changeChkComp}) {
    const columns = [
    
        { 
            Header: "Vista",
            accessor: 'vista',
        },
        { 
            Header: "Componente",
            accessor: 'componente',
        },
        { 
            Header: "Estatus",
            Cell: k =>(
                <div className="border-checkbox-section">
                    <div className="border-checkbox-group border-checkbox-group-success">
                        <input
                            onChange={e => changeChkComp(k.row.original, e.target.checked, k)}
                            checked={k.row.original.idEstatus}
                            className="border-checkbox"
                            type="checkbox"
                            id={"chkAPlicar" + k.row.original.idVista + k.row.original.idAccion}
                        />
                        <label className="border-checkbox-label"
                            htmlFor={"chkAPlicar" + k.row.original.idVista + k.row.original.idAccion}>&nbsp;</label>
                    </div>
                </div>)
        }
    ]
    return (
        <div>
            <GeneralTable
                                columns={columns}
                                // updateSearch={updateTableSearch} 
                                data={dataComponente} 
                                action={undefined} 
                                doubleClick={() =>{}}
                            />
        </div>
    );
}

export default TabComponentes;