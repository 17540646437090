import React, {Component} from 'react'

class BtnCopiaPDFXML extends Component{
    render(){
        return(
            <button 
            type="button" 
            rel="tooltip" 
            className="button button-primary button-circle button-small"
            data-toggle="tooltip" 
            data-placement="top" 
            style={{marginLeft: '5px'}}
            title="Copiar PDF y  XML">
                <i className="fa fa-copy" alt="Copiar PDF y  XML"></i>
            </button>
        )
    }
}

export default BtnCopiaPDFXML