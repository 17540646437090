import React from "react";


const BtnHistory = ({ btnHistoryAction, btnHistoryClass, btnHistoryText, btnHistoryIconClass, btnHistoryShowIcon, disabled }) => {
    return (
        <button
            type="button"
            rel="tooltip"
            className={btnHistoryClass ? btnHistoryClass : "btn btn-info button-circle button-small"}            
            onClick={btnHistoryAction}
            disabled={disabled} // Aquí se añade la prop disabled
            data-placement="top"
            title=
                {btnHistoryText
                    ?
                    btnHistoryText
                    :
                    "Historial"}
            >
            {btnHistoryShowIcon
                ?
                <i className={btnHistoryIconClass ? btnHistoryIconClass : "fa fa-history"} alt=""></i>
                :
                <></>}            
        </button>
    )
}

export default BtnHistory;