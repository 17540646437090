import React,{useEffect,useState,Fragment} from 'react';
import Select from 'react-select';
import GeneralTable from '../../../Components/GeneralTable';
import Header from '../../../Components/Header';
import {inicializa,columsProduct,endPoint,endPointFilter,endPointUrl} from './resources'
import Session from '../../../Application/session'
import { ExisteSession, toggleLoader } from '../../../ApiRequests/general'
import Buttons from '../../../Components/Buttons/Buttons';
import {
    getDataTable,
} from './Service'
import {CSVLink,CSVDownload} from 'react-csv'


const ProductosServicios = () => {
    const [dataProductos,setDataProductos]   = useState([])
    const [dataAProductos,setDataAProductos] = useState([])
    const [selectTipo,setSelectTipo]         = useState([])
    const [selectDivision,setSelectDivision] = useState([])
    const [selectGrupo,setSelectGrupo]       = useState([])
    const [selectClase,setSelectClase]       = useState([])
    const [tipo,setTipo]                     = useState([])
    const [division,setDivision]             = useState([])
    const [grupo,setGrupo]                   = useState([])
    const [clase,setClase]                   = useState([])
    const [url,setUrl]                       = useState('')

    const changeFilter = (e,n,m) => {
        let aux = []
        switch(n.name){
            case 'tipo':
                toggleLoader(true)
                setTipo(e)
                if(e !== null){
                    aux = dataAProductos.filter(x => x.idTipoProducto === e.value)
                    getDataTable({
                        endPoint : endPointFilter,
                        idTipoSP:2,
                        nameSP:'Spc_Sat_divisionProductoTbl',
                        idTipoProducto:e.value,                        
                    },_filter =>{
                    let auxf = _filter.map(d => {return {value : d.codigoDivision, label:d.division}})
                    setSelectDivision(auxf)
                    toggleLoader(false)
                    })
                }else{
                    toggleLoader(false)
                    aux = dataAProductos
                    
                }
                setSelectDivision([])
                    setDivision([])
                    setSelectGrupo([])
                    setGrupo([])
                    setSelectClase([])
                    setClase([])
               
                break;
            case 'division':
                toggleLoader(true)
                setDivision(e)
                if(e !== null){
                    aux = dataAProductos.filter(x => x.codigoDivision === e.value)
                    getDataTable({
                        endPoint : endPointFilter,
                        idTipoSP:3,
                        nameSP:'Spc_Sat_grupoProductoTbl',
                        idTipoProducto:tipo.value,
                        codigoDivision:e.value
                    },_filter =>{
                    let auxf = _filter.map(d => {return {value : d.codigoGrupo, label:d.grupo}})
                    setSelectGrupo(auxf)
                    toggleLoader(false)
                    })
                }else{
                    toggleLoader(false)
                    aux = dataAProductos.filter(x => x.idTipoProducto === tipo.value)
                }
                setSelectGrupo([])
                setGrupo([])
                setSelectClase([])
                setClase([])
                break;
            case 'grupo':
                toggleLoader(true)
                setGrupo(e)
                if(e !== null){
                    aux = dataAProductos.filter(x => x.codigoGrupo === e.value)
                    getDataTable({
                        endPoint : endPointFilter,
                        idTipoSP:4,
                        nameSP:'Spc_Sat_claseProductoTbl',
                        idTipoProducto:tipo.value,
                        codigoDivision:division.value,
                        codigoGrupo:e.value
                    },_filter =>{
                    console.log('_filter',_filter)
                    let auxf = _filter.map(d => {return {value : d.codigoClase, label:d.clase}})    
                    setSelectClase(auxf)    
                    toggleLoader(false)
                    })
                }else{
                    toggleLoader(false)
                    aux = dataAProductos.filter(x => x.codigoDivision === division.value)
                }
                setSelectClase([])
                setClase([]) 
                break;
            case 'clase':
                toggleLoader(true)
                setClase(e)
                if(e !== null){
                    toggleLoader(false)
                    aux = dataAProductos.filter(x => x.codigoClase === e.value)
                }else{
                    toggleLoader(false)
                    aux = dataAProductos.filter(x => x.codigoGrupo === grupo.value)
                }
                break;
            default:
        }
        setDataProductos(aux)
    }
  
    useEffect(() => {
        toggleLoader(true)
        debugger
        getDataTable({
            endPoint : endPoint,
        },products =>{
            console.log(products)
            setDataProductos(products)
            setDataAProductos(products)
            getDataTable({
                endPoint : endPointFilter,
                idTipoSP:1,
                nameSP:'Spc_Sat_claseProductoTbl',
            },_filter =>{
            console.log(_filter)
            // let auxf = _filter.map(d => {return {value : d.codigoClase, label:d.clase}})    
            setSelectTipo(_filter)
                getDataTable({
                    endPoint:endPointUrl,
                    id:311,
                    estatus:1,            
                },_url=>{
                    setUrl(_url[0].cadena)
                    toggleLoader(false)

                })


            })
        })  
    },[])
    const compTable = (totPorcentaje) => {
        return(
            
            <div className="row">
                <div className="col-md-8">
                    <span >Para mayor información visite: <a href={url}>{url}</a> </span>
                </div>
                {/* <div className="col-md-3" style={{fontWeight: 'bold', color: '#337ab7'}}><span > Porcentaje </span>&nbsp;  <span>{totPorcentaje}</span> <span>&nbsp;&nbsp; %</span>   </div>  */}

            
            </div>
        )
    }
    const download = () => {
        console.log('Descargar')
        exportToCsv()

    }
    const exportToCsv = e => {
        // Headers for each column
        let headers = ['Clave,Descripcion']
        // Convert users data to a csv
        let productCsv = dataAProductos.reduce((acc, product) => {
          const { codigoProducto, producto } = product
          acc.push([codigoProducto, producto].join(','))
          return acc
        }, [])
        console.log('productCsv',productCsv)
        downloadFile({
          data: [...headers, ...productCsv].join('\n'),
          fileName: 'Productos.csv',
          fileType: 'text/csv',
        })
      }
     
      const downloadFile = ({ data, fileName, fileType }) => {
        const blob = new Blob([data], { type: fileType })
      
        const a = document.createElement('a')
        a.download = fileName
        a.href = window.URL.createObjectURL(blob)
        const clickEvt = new MouseEvent('click', {
          view: window,
          bubbles: true,
          cancelable: true,
        })
        a.dispatchEvent(clickEvt)
        a.remove()
      }
    const cmpBtnFile = () => 
    <div className="row">
        
        <div className="col-md-12">
        <CSVLink data={dataProductos} 
            headers={
                [
                    { label: "ID tipo", key: "idTipoProducto" },
                    { label: "Tipo", key: "tipoProducto" },
                    { label: "Código divición", key: "codigoDivision" },
                    { label: "Divición", key: "division" },
                    { label: "Código grupo", key: "codigoGrupo" },
                    { label: "Grupo", key: "grupo" },
                    { label: "Código clase", key: "codigoClase" },
                    { label: "Clase", key: "clase" },
                    { label: "Código producto", key: "codigoProducto" },
                    { label: "Producto", key: "producto" },
                ]
            } 
            filename={'Productos'}>
            <Buttons 
                btnExport= {true}  btnExportText={'Descargar'} btnExportShowIcon={true} />
            </CSVLink>
        </div>
    </div>
    return (
        <Fragment>
        <Header title={inicializa.title + ' ' + Session.getInfoAmbiente()} breadcrum={inicializa.breadcrum} />
        <div className="lx-container container">
        <div className="panel panel-primary animated fadeInUp">
            <div className="panel-heading">
                <div className="row">
                    <div className="col-md-6">
                            <span>Catalogo de productos y sevicios</span>
                    </div>
                </div>
            </div>
            <div className="panel-body">
                <div className="row">
                    <div className="col-md-3">
                    <span>Tipo</span>
                    <Select
                                                    className=""
                                                    // id="idFormatoRecibo"
                                                    isClearable
                                                    isSearchable
                                                    name="tipo"
                                                    onChange={changeFilter}
                                                    options={selectTipo}
                                                    value={tipo}
                                                />

                    </div>
                    <div className="col-md-3">
                    <span>División</span>
                    <Select
                                                    className=""
                                                    // id="idFormatoRecibo"
                                                    isClearable
                                                    isSearchable
                                                    name="division"
                                                    onChange={changeFilter}
                                                    options={selectDivision}
                                                    value={division}
                                                />

                    </div>
                    <div className="col-md-3">
                    <span>Grupo</span>
                    <Select
                                                    className=""
                                                    // id="idFormatoRecibo"
                                                    isClearable
                                                    isSearchable
                                                    name="grupo"
                                                    onChange={changeFilter}
                                                    options={selectGrupo}
                                                    value={grupo}
                                                />

                    </div>
                    <div className="col-md-3">
                    <span>Clase</span>
                    <Select
                                                    className=""
                                                    // id="idFormatoRecibo"
                                                    isClearable
                                                    isSearchable
                                                    name="clase"
                                                    onChange={changeFilter}
                                                    options={selectClase}
                                                    value={clase}
                                                />

                    </div>
                </div>
                <div>&nbsp;</div>
                <div className="row">
                    <div className="col-md-12">
                            <GeneralTable
                                                columns={columsProduct}
                                                data={dataProductos}
                                                action={undefined}
                                                doubleClick={() => {}}
                                                isCompTable={true}
                                                compTbl={(compTable())}
                                                isCpmpBtn={true}
                                                compnnt={cmpBtnFile}

                                            />   
            </div>
                </div>

                </div>

            
          

        </div>
    </div>
    </Fragment>
    );
};

export default ProductosServicios;