import React,{Fragment,useState, useEffect} from 'react';
import GeneralTable from '../../../Components/GeneralTable';
import {inicializa, columData} from './resources'
import {getParams, updateParams} from './service'
import Header  from '../../../Components/Header';
import Container  from '../../../Components/Container/ContainerInitial';
import Session from '../../../Application/session'
import Modal from './ModalConfigHist'
import { toggleLoader } from '../../../ApiRequests/general';

const ConfigHistoricoEmpresas = () => {
    const [allParams,setAllParams]         = useState([])  
    const [idEstatus,setIdEstatus]       = useState(false)  
    const [show,setShow]                   = useState(false)  
    const [parametro,setParametro]         = useState(false)  
    const [idParametro,setIdParametro]     = useState(0)  

    useEffect(() => {
        getParams({

        },resp =>{
            console.log(resp)
            resp.forEach(e => {
               e.estatusH = e.idEstatus ? 'SI' : 'NO'
            });  
            setAllParams(resp)

        })
    },[])

    const handleChangeCbx =(row,status,alldt) => {
        console.log(row)
        console.log(status)
        console.log(alldt)
        // setIsSaveHist(e.target.checked)
    }
    const showModalUpdate = (row,values) => {
        console.log(row)
        console.log(values)
        window.$('#modalConfigHist').modal('show')
        setParametro(row.descripcion)
        setIdEstatus(row.idEstatus)
        setIdParametro(row.idCampo)


    }
    const changeCheck = (e) => {
        setIdEstatus(e.target.checked)
    }
    const onSave = () => {
        toggleLoader(true)
        console.log('guarda',idParametro,parametro, idEstatus)
        let jsonSend = JSON.stringify([{idCampo:idParametro,idEstatus:idEstatus ? 1 : 0}])
        updateParams({
            json:jsonSend

        },(resp,t,succes) =>{
           console.log(resp,t,succes)
        toggleLoader(false)
           if(succes){
                getParams({
                    
                },resp =>{
                window.$('#modalConfigHist').modal('hide')
                resp.forEach(e => {
                    e.estatusH = e.idEstatus ? 'SI' : 'NO'
                }); 
                console.log(resp)
                setAllParams(resp)
        
                })
           }
            
        })

    }
    const onCloseModal = () => window.$('#modalConfigHist').modal('hide')
    const contBody = () =>
    <div className="col-md-12">
        <div>
        <GeneralTable
                    columns={columData(handleChangeCbx)}
                    data={allParams}
                    action={undefined}
                    doubleClick={(original,values) => showModalUpdate(original,values)} 
                    // doubleClick={(original,values) => {}} 
                    // isCpmpBtn={true}
                    // compnnt={cmpBtnAdd}

            />
        </div>
    </div>

    return (<>
        <Fragment>
            <Header title={inicializa.title + ' ' + Session.getInfoAmbiente()} breadcrum={inicializa.breadcrum}/>
            <Container 
                title={inicializa.title}
                // btnH={}
                contBody={contBody()}
                spaceTitle='6'
                spaceBtn='6'
            />
         

        

        </Fragment>
        <Modal
        show={show}
        parametro={parametro}
        changeCheck={changeCheck}
        status={idEstatus}
        onSave={onSave}
        onCloseModal={onCloseModal} 
    />
</>
    );
};

export default ConfigHistoricoEmpresas;