import React from "react";
const BtnDelete = ({ btnDeleteAction, btnDeleteClass, btnDeleteText, btnDeleteIconClass, btnDeleteShowIcon }) => {
    return (
        <button
            className={btnDeleteClass ? btnDeleteClass : "btn btn-danger button-circle button-small"}
            onClick={btnDeleteAction}
            title= {btnDeleteText
                ?
                btnDeleteText
                :
                "Eliminar"}
        > {btnDeleteShowIcon
            ?
            <i className={btnDeleteIconClass ? btnDeleteIconClass : "fa fa-trash"} alt=""></i>
            :
            <></>}
           
        </button>
    )
}
export default BtnDelete