const inicializa = {
    title : 'Administración de Acciones',
    breadcrum : [{nombre: 'Administrador'},{nombre: 'Acciones'}]
}
const tableColumns = [
    { 
        Header: "IDAccion",
        accessor: 'idAccion',                    
    },
    { 
        Header: "Accion",
        accessor: 'descripcion',
        
    }
    
]



const tableData = []

export { tableColumns, inicializa, tableData }