import React, { Fragment, useEffect, useState } from 'react';
import GeneralTable from '../../../Components/GeneralTable';
import { getInfo, saveInfo, updateInfo, deleteInfo } from '../../../ApiRequests/req_CRUD'
import { ExisteSession, toggleLoader } from '../../../ApiRequests/general'
import { idOrigenWeb } from '../../../Constants/application'
import Header from '../../../Components/Header';
import { inicializa, tableColumns } from './resources'
import Modal from './ModalGiroEmpresa.jsx'
import Buttons from '../../../Components/Buttons/Buttons'
import Session from '../../../Application/session'


const GiroEmpresa = () => {
    const [model, setModel] = useState({
        idGiro: 0,
        giro: "",
        valorAdicional: "",
        type: true,
        selectView: null,
        apps: 1,
        typeAction: 'inicia',
        filtro_aplicacion: 38,
        filtro_ambiente: ExisteSession.getAmbiente().idAmbiente,
        idOrigen: idOrigenWeb,
        idUsuarioAct: ExisteSession.getInfoUsuario().idUsuario,
        token: ExisteSession.getInfoUsuario().token,
        connection: ExisteSession.conexion(),
    });
    const [reload, setReload] = useState(true);

    const [dataTableUs, setDataTableUs] = useState([]);


    useEffect(() => {
        toggleLoader(true);
        getInfo("Catalogos/catGiros", model, (response) => LoadTable(response));
    }, [reload])

    const LoadTable = (response) => {

        // console.log("datatable", response);
        if(response)
        setDataTableUs(response);
        toggleLoader(false);
    }
    const showModal = () => {
        setModel({
            ...model,
            selectView: null,
            type: true,
            idGiro: 0
        });
        window.$('#modalGiro').modal('show');
    }

    const handleDoubleClick = (originals, tableValues) => {
        // console.log("doble click",originals.idVista);
        setModel({
            ...model,
            selectView: originals,
            type: false,
            typeAction: '',
            idGiro: originals.idGiro
        });
        window.$('#modalGiro').modal('show');
    }

    const saveDataUser = dataNewUser => {
        toggleLoader(true);
        if (model.type) {
            saveInfo("Catalogos/catGiros", dataNewUser, (response) => responseSave(response));
        }
        else {
            updateInfo("Catalogos/catGiros", dataNewUser, (response) => responseSave(response));
        }
    }
    const clickDelete = (dataNewUser) => {
        toggleLoader(true);
        deleteInfo("Catalogos/catGiros", dataNewUser, (response) => responseDelete(response));
    }


    const responseSave = (response) => {
        setModel({
            ...model,
            selectView: null,
            type: true,
            idGiro: 0
        });
        setReload(!reload);
        window.$('#modalGiro').modal('hide');
        toggleLoader(false);
    }
    const responseDelete = (response) => {
        setModel({
            ...model,
            selectView: null,
            type: true,
            idGiro: 0
        });
        setReload(!reload);
        window.$('#modalGiro').modal('hide');
        toggleLoader(false);
    }

    const actionClose = () => {
        setModel({
            ...model,
            selectView: null,
            type: true,
            idGiro: 0
        });
        window.$('#modalGiro').modal('hide');
    }
   

    return (
        <Fragment>
            <Header title={inicializa.title + ' ' + Session.getInfoAmbiente()} breadcrum={inicializa.breadcrum} />

            <div className="lx-container container">
                <div className="panel panel-primary animated fadeInUp">
                    <div className="panel-heading">
                        <div className="row">
                            <div className="col-md-6">
                                <span>Giro Empresa</span>

                            </div>
                            <div className="col-md-6 btnF">
                                <Buttons btnAdd={true} btnAddAction={showModal} btnAddText={true} btnAddShowIcon={true} />

                            </div>

                        </div>
                    </div>
                    <div className="panel-body">
                        <GeneralTable
                            columns={tableColumns}
                            // updateSearch={updateTableSearch} 
                            data={dataTableUs}
                            action={undefined}
                            doubleClick={handleDoubleClick}
                        />
                    </div>

                </div>

            </div>
            <Modal model={model} saveDataUser={saveDataUser} actionClose={actionClose} clickDelete={clickDelete} />
        </Fragment>

    );
}

export default GiroEmpresa;