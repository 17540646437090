import React from 'react';

const ContainerInitial = ({title,btnH,contBody,spaceTitle,spaceBtn}) => {
    return (
        <div className="lx-container container">
        <div className="panel panel-primary animated fadeInUp">
            <div className="panel-heading">
                <div className="row">
                    <div className={"col-md-"+spaceTitle}>
                        <span>{title}</span>                   
                    </div>
                    <div className={"btnF col-md-"+spaceBtn}>                                
                        {btnH}
                    </div>

                </div>
            </div>
            <div className="panel-body">
                    {contBody}
            </div>
            
        </div>
    </div>
    );
};

export default ContainerInitial;