import React, { Fragment } from 'react';
import SelectC from '../../../Components/Select/SelectSC'
const TabUsuario = ({ model, datUser, handleChange, handlechecked, status, dataPerfiles, itemPerfil, isStatus }) => {
    const { correo, nombre, apeP, apeM, idPerfil } = model
    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-md-1"></div>
                <div className="col-md-10">
                    {model.type &&
                        <div className="row">
                            <div className="col-md-3">
                                <label>Correo</label>
                            </div>
                            <div className="col-md-9">
                                <div className="form-group">
                                    <input
                                        type="email"
                                        className="form-control"
                                        id="correo"
                                        name="correo"
                                        onChange={handleChange}
                                        value={correo}
                                        placeholder="Correo" />
                                </div>
                            </div>
                        </div>
                    }
                    {!model.type &&
                        <div className="row">
                            <div className="col-md-3">
                                <label>Correo</label><br />
                            </div>
                            <div className="col-md-9">
                                <div className="form-group">
                                    <input
                                        type="email"

                                        className="form-control"
                                        disabled={true}
                                        value={correo}
                                    />
                                </div>
                            </div>
                        </div>

                    }

                    {model.type ?
                        <div className="row">
                            <div className="col-md-3">
                                <label>Nombre</label>
                            </div>
                            <div className="col-md-9">
                                <div className="form-group">
                                    <input
                                        className="form-control"
                                        id="nombre"
                                        name="nombre"
                                        onChange={handleChange}
                                        value={nombre}
                                        placeholder="nombre" />
                                </div>
                            </div>
                        </div>
                        :
                        <div className="row">
                            <div className="col-md-3">
                                <label>Nombre</label>
                            </div>
                            <div className="col-md-9">
                                <div className="form-group">
                                    <input
                                        className="form-control"
                                        disabled={true}
                                        value={nombre} />
                                </div>
                            </div>
                        </div>
                    }

                    {model.type ?
                        <div className="row">
                            <div className="col-md-3">
                                <label>Apellido paterno</label>
                            </div>
                            <div className="col-md-9">
                                <div className="form-group">
                                    <input
                                        className="form-control"
                                        id="apeP"
                                        name="apeP"
                                        onChange={handleChange}
                                        value={apeP}
                                        placeholder="Apellido paterno"
                                    />
                                </div>
                            </div>
                        </div>
                        :
                        <div className="row">
                            <div className="col-md-3">
                                <label>Apellido paterno</label>
                            </div>
                            <div className="col-md-9">
                                <div className="form-group">
                                    <input
                                        className="form-control"
                                        disabled={true}
                                        value={apeP}
                                    />
                                </div>
                            </div>
                        </div>
                    }
                    {model.type ?
                        <div className="row">
                            <div className="col-md-3">
                                <label>Apellido materno</label>
                            </div>
                            <div className="col-md-9">
                                <div className="form-group">
                                    <input
                                        className="form-control"
                                        id="apeM"
                                        name="apeM"
                                        onChange={handleChange}
                                        value={apeM}
                                        placeholder="Apellido Materno"
                                    />
                                </div>
                            </div>
                        </div>
                        :
                        <div className="row">
                            <div className="col-md-3">
                                <label>Apellido materno</label>
                            </div>
                            <div className="col-md-9">
                                <div className="form-group">
                                    <input
                                        className="form-control"
                                        disabled={true}
                                        value={apeM}
                                    />
                                </div>
                            </div>
                        </div>
                    }
                    <div className="row">
                        <div className="col-md-3">
                            <label>Perfil vista</label>
                        </div>
                        <div className="col-md-9">
                            <div className="form-group">
                                <SelectC
                                    actionChange={handleChange}
                                    ID="idPerfil"
                                    name="idPerfil"
                                    options={dataPerfiles}
                                    value={idPerfil}
                                />
                            </div>
                        </div>
                    </div>
                    {!model.type &&
                        <Fragment>
                            <div className="row">
                                <div className="col-md-3">
                                    <label>Estatus</label>
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <div className="border-checkbox-section">
                                            <div className="border-checkbox-group border-checkbox-group-success">
                                                <input
                                                    onChange={e => { handlechecked(e) }}
                                                    checked={isStatus}
                                                    className="border-checkbox"
                                                    type="checkbox"
                                                    id="chkEstatus"
                                                />
                                                <label className="border-checkbox-label"
                                                    htmlFor={"chkEstatus"}>&nbsp;</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Fragment>
                    }
                </div>
                <div className="col-md-1"></div>

            </div>
        </div>
    );
};

export default TabUsuario;