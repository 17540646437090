import React, { Fragment, useEffect, useState } from "react";
import Select from "react-select";
import GeneralTable from "../../../Components/GeneralTable";
import {
  getInfo,
  saveInfo,
  updateInfo,
  deleteInfo,
  getInfoGral,
} from "../../../ApiRequests/req_CRUD";

import { ExisteSession, toggleLoader } from "../../../ApiRequests/general";
import { idOrigenWeb } from "../../../Constants/application";
import Header from "../../../Components/Header";
import { inicializa, tableData } from "./resources";
import Buttons from "../../../Components/Buttons/Buttons";
import ExportExcel from "../../../Components/ExportExcel/ExportExcel";
import Session from "../../../Application/session";
import { fnNotification } from "../../../ApiRequests/Notification";
import ModalHistorico from "../SeguimientoBuzonTributario/modalHistorico";
import Moment from "react-moment";
import moment from "moment";
import PdfPreview from "../../PDFPreview/OpenPdfButton";

const MonitorSeguimientoBuzones = () => {
  const [history, setHistoryReload] = useState(false);
  const [pdfFileUrl, setPDFReload] = useState("");
  const baseFileName = "MonitorBuzones";
  const [isDisabledBtnAsignacion, setisDisabledBtnAsignacion] = useState(true);
  const [updateSearch, setupdateSearch] = useState(false);
  const generateFileName = (baseName) => {
    const formattedDate = moment().format("DDMMYY_HHmmss");
    return `${baseName}_${formattedDate}`;
  };
  
  const fileName = generateFileName(baseFileName);

  const ShowModalPDF = () => {
    window.$("#modalPDFPreview").modal("show");
    toggleLoader(false);
  };
  const tableColumns = [
    {
      Header: "Seleccionar",
      label: "Activo ",
      Cell: (k) => (
        <div className="border-checkbox-section">
          <div className="border-checkbox-group border-checkbox-group-success">
            <input
              onChange={(event) => {
                cambioData(k.row.original, event, k);
              }}
              checked={k.row.original.seleccionado}
              className="border-checkbox"
              type="checkbox"
              id={"chkAPlicar-" + k.row.original.idSeguimiento}
            />
            <label
              className="border-checkbox-label"
              htmlFor={"chkAPlicar-" + k.row.original.idSeguimiento}
            >
              &nbsp;
            </label>
          </div>
        </div>
      ),
    },
    {
      Header: "Documento",
      accessor: "tienePDF",
      Cell: (k) =>
        k.row.original.tienePDF ? (
          <>
            <span>Si</span>
          </>
        ) : (
          <>
            <span>No</span>
          </>
        ),
    },
    {
      Header: "Razón Social",
      accessor: "razonSocial",
    },
    {
      Header: "Tipo Empresa",
      accessor: "tipoEmpresa",
    },
    {
      Header: "Prioridad",
      accessor: "nivelPrioridad",
    },
    {
      Header: "Tipo Notificación",
      accessor: "tipoNotificacion",
    },
    {
      Header: "Descripción de Requerimiento",
      accessor: "descripcionRequerimiento",
    },
    {
      Header: "Fecha de notificación",
      accessor: "fechaNotificacion",
      Cell: (k) =>
        k.row.original.fechaNotificacion ? (
          <>
            <span>
              <Moment format="YYYY-MM-DD">
                {k.row.original.fechaNotificacion}
              </Moment>
            </span>
          </>
        ) : (
          <>
            <span></span>
          </>
        ),
    },
    {
      Header: "Fecha de Lectura Notificación",
      accessor: "fechaLecturaNotificacion",
      Cell: (k) =>
        k.row.original.fechaLecturaNotificacion ? (
          <>
            <span>
              <Moment format="YYYY-MM-DD">
                {k.row.original.fechaLecturaNotificacion}
              </Moment>
            </span>
          </>
        ) : (
          <>
            <span></span>
          </>
        ),
    },
    {
      Header: "Fecha Inicio Atención",
      accessor: "fechaInicioAtencion",
      Cell: (k) =>
        k.row.original.fechaInicioAtencion ? (
          <>
            <span>
              <Moment format="YYYY-MM-DD">
                {k.row.original.fechaInicioAtencion}
              </Moment>
            </span>
          </>
        ) : (
          <>
            <span></span>
          </>
        ),
    },
    {
      Header: "Plazo Limite",
      accessor: "plazoLimite",
      Cell: (k) =>
        k.row.original.plazoLimite ? (
          <>
            <span>
              <Moment format="YYYY-MM-DD">{k.row.original.plazoLimite}</Moment>
            </span>
          </>
        ) : (
          <>
            <span></span>
          </>
        ),
    },
    {
      Header: "Fecha de Termino",
      accessor: "fechaTerminoAtencion",
      Cell: (k) =>
        k.row.original.fechaTerminoAtencion ? (
          <>
            <span>
              <Moment format="YYYY-MM-DD">
                {k.row.original.fechaTerminoAtencion}
              </Moment>
            </span>
          </>
        ) : (
          <>
            <span></span>
          </>
        ),
    },
    {
      Header: "Estatus",
      accessor: "estatus",
    },
    {
      Header: "Comentarios de Personal",
      accessor: "comentario",
    },
    {
      Header: "Personal Asignado",
      accessor: "personalAsignado",
    },
  ];

  const [model, setModel] = useState({
    usuarioSelect: null,
    idEstatus: 1,
    filtro_aplicacion: 38,
    filtro_ambiente: ExisteSession.getAmbiente().idAmbiente,
    idOrigen: idOrigenWeb,
    idUsuarioAct: ExisteSession.getInfoUsuario().idUsuario,
    token: ExisteSession.getInfoUsuario().token,
    connection: ExisteSession.conexion(),
  });

  const [filter, setFilter] = useState({
    idEmpresa: 0,
    fechaInicioAtencion: null,
    fechaTerminoAtencion: null,
    nivelPrioridad: "",
    idEstatus: 0,
    filtro_aplicacion: 38,
    filtro_ambiente: ExisteSession.getAmbiente().idAmbiente,
    idOrigen: idOrigenWeb,
    idUsuarioAct: ExisteSession.getInfoUsuario().idUsuario,
    token: ExisteSession.getInfoUsuario().token,
    connection: ExisteSession.conexion(),
  });

  const [filtro, setFiltro] = useState({
    idUsuario: 0,
    usuario: null,
    usuarioList: [],
  });


  const tableColumnsUsuarios = [
    {
      Header: "Razón Social",
      accessor: "razonSocial",
    },
    {
  
      Header: "Responsable Actual",
      accessor: "personalAsignado",
    },
    {
      Header: "Usuario de Reasignacion",
      accessor: "nuevoNombreUsuario",
    },
    {
      Header: "Estatus",
      accessor: "estatus",
    },
  ];

  const [guardaResponsable, setGuardaResponsable] = useState({
    idSeguimiento: 0,
    idEstatus: 0,
    idUsuarioResponsable: 0,
    personalAsignado: "",
    filtro_aplicacion: 38,
    filtro_ambiente: ExisteSession.getAmbiente().idAmbiente,
    idOrigen: idOrigenWeb,
    idUsuarioAct: ExisteSession.getInfoUsuario().idUsuario,
    token: ExisteSession.getInfoUsuario().token,
    connection: ExisteSession.conexion(),
  });
  const [dtOrigenSeg, setdtOrigenSeg] = useState([] );
  
  const [dtDataTable, setDTDataTable] = useState([]);

  const lisColumnaVisible = [
    { key: 'razonSocial', header: 'Razón Social' },
    { key: 'tipoEmpresa', header: 'Tipo Empresa' },
    { key: 'nivelPrioridad', header: 'Prioridad' },
    { key: 'tipoNotificacion', header: 'Tipo Notificación' },
    { key: 'descripcionRequerimiento', header: 'Descripción de Requerimiento' },
    { key: 'fechaNotificacion', header: 'Fecha de Notificación' },
    { key: 'fechaLecturaNotificacion', header: 'Fecha de Lectura Notificación' },
	  { key: 'fechaInicioAtencion', header: 'Fecha Inicio Atención' },
	  { key: 'plazoLimite', header: 'Plazo Limite' },
	  { key: 'fechaTerminoAtencion', header: 'Fecha de Termino' },
	  { key: 'estatus', header: 'Estatus' },
	  { key: 'comentario', header: 'Comentarios de Personal' },
	  { key: 'personalAsignado', header: 'Personal Asignado' }	
  ];
 

  const [dtUsuarios, setDTUsuarios] = useState([]);
  const [dtRazonSocial, setDTRazonSocial] = useState([]);
  const [dtPrioridad, setDTPrioridad] = useState([]);
  const [dtEstatus, setDTEstatus] = useState([]);
  const [reload, setReload] = useState(true);
  const [dtHistorico, setDTHistorico] = useState([]);

  useEffect(() => {
    toggleLoader(true);
    getInfo("Catalogos/catEmpresas", model, (response) =>
      LoadCmbRazonSocial(response)
    );

    getInfo(
      "Catalogos/catCriterios",
      {
        criterio: "prioridadBuzon",
        filtro_ambiente: ExisteSession.getAmbiente().idAmbiente,
        idUsuarioAct: ExisteSession.getInfoUsuario().idUsuario,
        token: ExisteSession.getInfoUsuario().token,
        connection: ExisteSession.conexion(),
      },
      (response) => LoadCmbPrioridad(response)
    );

    getInfo(
      "Catalogos/catGenerales",
      {
        tabla: "contSeguimientoBuzonesTbl",
        columna: "idEstatus",
        filtro_ambiente: ExisteSession.getAmbiente().idAmbiente,
        idUsuarioAct: ExisteSession.getInfoUsuario().idUsuario,
        token: ExisteSession.getInfoUsuario().token,
        connection: ExisteSession.conexion(),
      },
      (response) => LoadCmbEstatus(response)
    );

    getInfo("Usuario", model, (response) => LoadCmbUsuario(response));

    getInfo("seguimientoBuzones", filter, (response) =>
      LoadDTDataTable(response)
    );
  }, [reload]);

  const MostrarHistorico = () => {
    var _list = dtDataTable;
    var _index = _list.findIndex((c) => c.seleccionado === true);
    var count = _list.filter((c) => c.seleccionado === true).length;
    if (count > 1) {
      fnNotification([
        "Debe seleccionar un solo registro para visualizar el historico.",
        "info",
        true,
      ]);
    } else if (count === 1) {
      toggleLoader(true);
      var _filter = {
        idSeguimiento: _list[_index].idSeguimiento,
        filtro_ambiente: ExisteSession.getAmbiente().idAmbiente,
        idUsuarioAct: ExisteSession.getInfoUsuario().idUsuario,
        token: ExisteSession.getInfoUsuario().token,
        connection: ExisteSession.conexion(),
      };
      getInfoGral("seguimientoBuzones/getHistorico", _filter, (response) => {
        const sortedBitacoras = response.Data.sort((a, b) => new Date(b.fechaAct) - new Date(a.fechaAct));
        setDTHistorico(sortedBitacoras);
        window.$("#modalHistorico").modal("show");
        toggleLoader(false);
      });
    } else {
      fnNotification([
        "No hay registro seleccionado para visualizar el historico.",
        "info",
        true,
      ]);
    }
  };

  const dblClickComp = (originals, tableValues) => {};

  const LoadCmbRazonSocial = (response) => {
    if (response) {
      setDTRazonSocial(response);
    }
    setTimeout(() => {
      toggleLoader(false);
    }, 5000);
  };
  const LoadCmbPrioridad = (response) => {
    if (response) {
      setDTPrioridad(response);
    }
  };
  const LoadCmbEstatus = (response) => {
    if (response) {
      setDTEstatus(response);
    }
  };
  const LoadCmbUsuario = (response) => {
    if (response) {
      setDTUsuarios(response);
      var _list = response.reduce((acc, item) => {
        acc.push({ label: item.usuario, value: item.idUsuario });
        return acc;
      }, []);
      setFiltro({ ...filtro, usuarioList: _list });
    }
  };
  const LoadDTDataTable = (response, cerrar) => {
    if (response) {
      response.forEach((c) => {
        c.seleccionado = false;
      });
      setDTDataTable(response);
    }
    if (cerrar) toggleLoader(false);
  };

  const DisabledCheckBtnAsignacion = (item) => {
      let idEstatus= item.idEstatus;
   if(filtro.usuario){
    let idUsuario= filtro.usuario.value?filtro.usuario.value:0;
    let idUsuarioResponsable= item.idUsuarioResponsable? item.idUsuarioResponsable:0;
    if(idUsuario === idUsuarioResponsable){
      setisDisabledBtnAsignacion(true);
      setFiltro({ ...filtro, usuario: null, idUsuario: 0 });
      setGuardaResponsable({ ...guardaResponsable, idUsuarioResponsable: 0 });
      fnNotification([
        "El usuario reasignado no podrá ser igual al usuario actual",
        "error",
        true,
       ]);
return;
    }
   }


     
     /*
      if(item.idUsuarioResponsable){
        let idUsuarioResponsable= item.idUsuarioResponsable? item.idUsuarioResponsable:0;
        if(idUsuario === idUsuarioResponsable){
          setisDisabledBtnAsignacion(true);
          setFiltro({ ...filtro, usuario: null, idUsuario: 0 });
          setGuardaResponsable({ ...guardaResponsable, idUsuarioResponsable: 0 });
          fnNotification([
            "El usuario reasignado no podrá ser igual al usuario actual",
            "error",
            true,
           ]);
  return;
        }
      }*/

        if(idEstatus===3 ){
          fnNotification([
            "No se puede reasignar un seguimiento con estatus En Decisión",
            "error",
            true,
           ]);
          setisDisabledBtnAsignacion(true);
          return;
        }



      if(idEstatus===7 && item.fechaTerminoAtencion){
        fnNotification([
          "No se puede reasignar un seguimiento con estatus Cerrado",
          "error",
          true,
         ]);
        setisDisabledBtnAsignacion(true);
      }else{
        setisDisabledBtnAsignacion(false);
      } 
    
  }

  const DisabledBtnAsignacion = (subLista, event) => {
    if (subLista.length === 1) {
      let item = subLista[0];
      let idEstatus= item.idEstatus;
      let idUsuario= event.value;
     
      if(item.idUsuarioResponsable){
        let idUsuarioResponsable= item.idUsuarioResponsable? item.idUsuarioResponsable:0;
        if(idUsuario === idUsuarioResponsable){
          setisDisabledBtnAsignacion(true);
          setFiltro({ ...filtro, usuario: null, idUsuario: 0 });
          setGuardaResponsable({ ...guardaResponsable, idUsuarioResponsable: 0 });
          fnNotification([
            "El usuario reasignado no podrá ser igual al usuario actual",
            "error",
            true,
           ]);
  return;
        }
      }
      

      if(idEstatus===7 && item.fechaTerminoAtencion){
        setisDisabledBtnAsignacion(true)
        setFiltro({ ...filtro, usuario: null, idUsuario: 0 });
        setGuardaResponsable({ ...guardaResponsable, idUsuarioResponsable: 0 });
      }else{
        setisDisabledBtnAsignacion(false)
        setFiltro({ ...filtro, usuario: event, idUsuario: event.value });
      setGuardaResponsable({
        ...guardaResponsable,
        idUsuarioResponsable: event.value,
      });
      }
  
  
  }
  }



  const handleChangeSelect = (event) => {
  
    if (event) {

      var _lista = dtDataTable;
      var subLista = _lista.filter((c) => c.seleccionado === true);
      if (subLista.length === 1) {
     
        
        DisabledBtnAsignacion(subLista,event)

      }else{
      setisDisabledBtnAsignacion(false)
      setFiltro({ ...filtro, usuario: event, idUsuario: event.value });
      setGuardaResponsable({
        ...guardaResponsable,
        idUsuarioResponsable: event.value,
      });
      }

    } else {
      setisDisabledBtnAsignacion(false)
      setFiltro({ ...filtro, usuario: null, idUsuario: 0 });
      setGuardaResponsable({ ...guardaResponsable, idUsuarioResponsable: 0 });
    }
  };
  const handleChangeSelectFilter = (event, type, prop) => {
    if (event) {
      setFilter({ ...filter, [type]: event[prop] });
    } else {
      if (type == "nivelPrioridad") {
        setFilter({ ...filter, [type]: "" });
      } else {
        setFilter({ ...filter, [type]: 0 });
      }
    }
  };

  const handlerManejaOnBlur = (event, type) => {
    let fechaTerget = event.target.value;
    if (type === "fechaTerminoAtencion") {
      if (fechaTerget === null || fechaTerget === "") {
        return;
      }
      if (filter.fechaInicioAtencion) {
        if (filter.fechaInicioAtencion > fechaTerget) {
          fnNotification([
            "La Fecha Termino no puede ser menor a la Fecha Inicio",
            "error",
            true,
          ]);
        }
      } else {
        if (fechaTerget !== null && fechaTerget !== "") {
          fnNotification([
            "Para seleccionar una Fecha Termino debes seleccionar una Fecha Inicio",
            "error",
            true,
          ]);
        }
      }
    }
    if (type === "fechaInicioAtencion") {
      if (filter.fechaTerminoAtencion) {
        if (filter.fechaTerminoAtencion < fechaTerget) {
          fnNotification([
            "La Fecha Inicio no puede ser mayor a la Fecha Termino",
            "error",
            true,
          ]);
        }
      }
    }
  };

  const handleChangeDate = (event, type) => {
    if (event) {
      setFilter({ ...filter, [type]: event.target.value });
    } else {
      setFilter({ ...filter, [type]: null });
    }
  };

  const onSearch = () => {
   
      
    if (filter.fechaInicioAtencion === "" || filter.fechaInicioAtencion === null) {
      setFilter({ ...filter, "fechaInicioAtencion": null });
      filter.fechaInicioAtencion = null;
    }else{

      
    if (  filter.fechaTerminoAtencion === null || filter.fechaTerminoAtencion === "" ) {
        
      fnNotification([
        "La Fecha Término no es válida o no se a definido",
        "error",
        true,
      ]);   
      return;
    }

    if (filter.fechaInicioAtencion > filter.fechaTerminoAtencion) {
        
      fnNotification([
        "La Fecha Término no puede ser menor a la Fecha Inicio",
        "error",
        true,
      ]); 
      return;         
    }
    }


    if (filter.fechaTerminoAtencion === "" || filter.fechaTerminoAtencion ===null) {
      setFilter({ ...filter, "fechaTerminoAtencion": null });
      filter.fechaTerminoAtencion = null;
    }else{

      if (  filter.fechaInicioAtencion === null || filter.fechaInicioAtencion === "" ) {
          
        fnNotification([
          "La Fecha de Inicio no es válida o no se a definido",
          "error",
          true,
        ]);   
        return;
      }


    }

    toggleLoader(true);
    getInfo("seguimientoBuzones", filter, (response) =>
      LoadDTDataTable(response, true)
    );
  };
  const btnAction = () => {
    toggleLoader(true);
    //mandara aguardar elemento por elemento

    if (guardaResponsable.idUsuarioResponsable > 0) {
      const idUsuarioResponsable = guardaResponsable.idUsuarioResponsable;
      const seleccionados = dtOrigenSeg.filter((item) => item.seleccionado);
      
      //dtDataTable.filter((item) => item.seleccionado);

      let procesados = 0;
      const total = seleccionados.length;

      seleccionados.forEach((item) => {
        // Crear un nuevo objeto guardaResponsable

        const t = {
          ...guardaResponsable,
          idSeguimiento: item.idSeguimiento,
          personalAsignado: item.personalAsignado,
          idEstatus: item.idEstatus,
          idUsuarioResponsable: idUsuarioResponsable,
        };

        updateInfo("seguimientoBuzones", t, (response) => {
          procesados++;

          if (procesados === total) {
            setupdateSearch(true);
            getInfo("seguimientoBuzones", filter, (response) =>
              LoadDTDataTable(response, true)
            );
            setdtOrigenSeg([]);
          }
        });
      });

      window.$("#modalConfirmacion").modal("hide");

      setGuardaResponsable({
        ...guardaResponsable,
        idSeguimiento: 0,
        personalAsignado: "",
        idEstatus: 0,
        idUsuarioResponsable: 0,
      });
      setFiltro({ ...filtro, usuario: null, idUsuario: 0 });
    }
  };
  const btnCancelar = () => {
    window.$("#modalConfirmacion").modal("hide");
  };
  const btnConfirmacion = () => {
    
    
    let idUsuario= filtro.usuario.value?filtro.usuario.value:0;

    if(idUsuario){

      var _lista = dtDataTable;
      var subLista = _lista.filter((c) => c.seleccionado === true && c.idUsuarioResponsable !== idUsuario);
   
      if(subLista.length ===0){
        fnNotification([
          "El usuario reasignado no podrá ser igual al usuario actual",
          "error",
          true,
        ]);
      return;
      }
      subLista = subLista.filter((c) => c.seleccionado === true && c.idEstatus !== 3 && c.idEstatus !== 7 )
      .map(c => ({ ...c, nuevoNombreUsuario: filtro.usuario.label}));;



      setdtOrigenSeg(subLista);
      if(subLista.length>0){
        window.$("#modalConfirmacion").modal("show");
      }else{
        fnNotification([
          "No se cumple las condiciones para reasignar responsable",
          "error",
          true,
        ]);
      }    
    }
  };

  const cambioData = (_obj, event) => {
    var _lista = dtDataTable;

    if (event.target.checked) {
      var _index = _lista.findIndex(
        (c) => c.idSeguimiento === _obj.idSeguimiento
      );
      _lista[_index].seleccionado = true;

      var count = _lista.filter((c) => c.seleccionado === true).length;
      if (count > 1) {
        setisDisabledBtnAsignacion(false);
        setGuardaResponsable({ ...guardaResponsable, personalAsignado: "" });
      } else if (count === 1) {
        var itemArray = _lista.filter((c) => c.seleccionado === true);
        var item = itemArray[0];
        DisabledCheckBtnAsignacion(item);

        setGuardaResponsable({
          ...guardaResponsable,
          idSeguimiento: item.idSeguimiento,
          personalAsignado: item.personalAsignado,
          idEstatus: item.idEstatus,
          idUsuarioResponsable: 0,
        });
      } else {
        setGuardaResponsable({
          ...guardaResponsable,
          idSeguimiento: 0,
          personalAsignado: "",
          idEstatus: 0,
          idUsuarioResponsable: 0,
        });
      }
    } else {
      var _index2 = _lista.findIndex(
        (c) => c.idSeguimiento === _obj.idSeguimiento
      );
      _lista[_index2].seleccionado = false;
      var count2 = _lista.filter((c) => c.seleccionado === true).length;
      if (count2 === 1) {
        var itemArray = _lista.filter((c) => c.seleccionado === true);
        var item = itemArray[0];
        DisabledCheckBtnAsignacion(item);
        setGuardaResponsable({
          ...guardaResponsable,
          idSeguimiento: item.idSeguimiento,
          personalAsignado: item.personalAsignado,
          idEstatus: item.idEstatus,
          idUsuarioResponsable: 0,
        });
      } else {
        if (count2 > 1){
          setisDisabledBtnAsignacion(false);
        }

        setGuardaResponsable({
          ...guardaResponsable,
          idSeguimiento: 0,
          personalAsignado: "",
          idEstatus: 0,
          idUsuarioResponsable: 0,
        });
      }
    }

    //Busca si existe un checkbox entrue
    var countCheck = _lista.filter((c) => c.seleccionado === true).length;
    if (countCheck === 1) {
      // activamos lso botones de historico y visualizar pdf
      setHistoryReload(true);
    } else {
      setHistoryReload(false);
    }

    setDTDataTable(_lista);
    setModel({ ...model, usuarioSelect: null });
  };
  const clickClose = () => {
    window.$("#modalHistorico").modal("hide");
  };

  const clickClosePDF = () => {
    window.$("#modalPDFPreview").modal("hide");
    setPDFReload("");
  };

  function decodeUrl(url) {
    console.log(url.replace(/\\u0026/g, "&"));
    return url.replace(/\\u0026/g, "&");
  }
  const PreViewPDF = () => {
    var _list = dtDataTable;
    var _index = _list.findIndex((c) => c.seleccionado === true);
    if (_index >= 0) {
      toggleLoader(true);
      var _filter = {
        idSeguimiento: _list[_index].idSeguimiento,
        filtro_ambiente: ExisteSession.getAmbiente().idAmbiente,
        idUsuarioAct: ExisteSession.getInfoUsuario().idUsuario,
        token: ExisteSession.getInfoUsuario().token,
        connection: ExisteSession.conexion(),
      };
      getInfoGral(
        "seguimientoBuzones/documentoSeguimiento",
        _filter,
        (response) => {
          if (
            response.Data &&
            typeof response.Data.url !== "undefined" &&
            response.Data.url !== ""
          ) {
            setPDFReload(decodeUrl(response.Data.url));
          } else {
            fnNotification([
              "No existe documento para visualizar.",
              "info",
              true,
            ]);

            toggleLoader(false);
          }
          toggleLoader(false);
        }
      );
    } else {
      fnNotification(["No existe documento para visualizar.", "info", true]);
      toggleLoader(false);
    }
  };

  return (
    <>
      <Header
        title={inicializa.title + " " + Session.getInfoAmbiente()}
        breadcrum={inicializa.breadcrum}
      />

      <div className="lx-container container">
        <div className="panel panel-primary animated fadeInUp">
          <div className="panel-heading">
            <div className="row">
              <div className="col-md-6">
                <span>Monitor Seguimiento Buzones </span>
              </div>
            </div>
          </div>
          <div className="panel-body">
            <div className="row">
              <div className="col-md-12">
                <div
                  className="col-md-12"
                  style={{
                    borderBottom: "1px solid #157798",
                    paddingBottom: "10px",
                    minHeight: "45px",
                  }}
                >
                  <div className="col-md-9 col-sm-9"></div>
                  <div className="col-md-1 col-sm-1" style={{ float: "right" }}>
                    <ExportExcel
                      excelData={dtDataTable}
                      lisColumnaVisible={lisColumnaVisible}
                      fileName={fileName}
                    ></ExportExcel>
                    {/* <Buttons btnExport={true} btnExportAction={clickExport} btnSaveText={"Exportar"} btnExportClass={"btn btn-primary"} btnExportShowIcon={true} /> */}
                  </div>
                  <div className="col-md-2 col-sm-2">
                    <Buttons
                      btnHistory={history}
                      btnHistoryText={"Ver Historico"}
                      btnHistoryAction={MostrarHistorico}
                      btnHistoryClass={"btn btn-primary"}
                      btnHistoryShowIcon={true}
                      btnViewdisabled={false}
                      btnViewPdf={history}
                      btnViewPdfText={"Ver Pdf"}
                      btnViewPdfAction={PreViewPDF}
                      btnViewPdfClass={"btn btn-primary"}
                      btnViewPdfShowIcon={true}
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-12" style={{ paddingTop: "20px" }}>
                <div className="row">
                  <div className="col-md-2">
                    <div className="form-group">
                      <label>Razón Social</label>
                      <Select
                        className=""
                        id="idEmpresa"
                        isClearable
                        isSearchable
                        name="idEmpresa"
                        getOptionLabel={(option) => `${option.nombreCorto}`}
                        getOptionValue={(option) => `${option.ID_empresa}`}
                        onChange={(e) => {
                          handleChangeSelectFilter(
                            e,
                            "idEmpresa",
                            "ID_empresa"
                          );
                        }}
                        options={dtRazonSocial}
                      />
                    </div>
                  </div>
                  <div className="col-md-2">
                    <div className="form-group">
                      <label>Fecha Inicio</label>
                      <input
                        type="date"
                        id="fechaInicioAtencion"
                        className="form-control"
                        onChange={(e) =>
                          handleChangeDate(e, "fechaInicioAtencion")
                        }
                        onBlur={(e) =>
                          handlerManejaOnBlur(e, "fechaInicioAtencion")
                        }
                        value={filter.fechaInicioAtencion}
                      />
                    </div>
                  </div>
                  <div className="col-md-2">
                    <div className="form-group">
                      <label>Fecha Termino</label>
                      <input
                        type="date"
                        id="fechaTerminoAtencion"
                        className="form-control"
                        onBlur={(e) =>
                          handlerManejaOnBlur(e, "fechaTerminoAtencion")
                        }
                        onChange={(e) =>
                          handleChangeDate(e, "fechaTerminoAtencion")
                        }
                        value={filter.fechaTerminoAtencion}
                      />
                    </div>
                  </div>
                  <div className="col-md-2">
                    <div className="form-group">
                      <label>Prioridad</label>
                      <Select
                        className=""
                        id="nivelPrioridad"
                        isClearable
                        isSearchable
                        name="nivelPrioridad"
                        getOptionLabel={(option) => `${option.descripcion}`}
                        getOptionValue={(option) => `${option.valor}`}
                        onChange={(e) => {
                          handleChangeSelectFilter(
                            e,
                            "idNivelPrioridad",
                            "valor"
                          );
                        }}
                        options={dtPrioridad}
                      />
                    </div>
                  </div>
                  <div className="col-md-2">
                    <div className="form-group">
                      <label>Estatus</label>
                      <Select
                        className=""
                        id="idEstatus"
                        isClearable
                        isSearchable
                        name="idEstatus"
                        getOptionLabel={(option) => `${option.descripcion}`}
                        getOptionValue={(option) => `${option.valor}`}
                        options={dtEstatus}
                        onChange={(e) => {
                          handleChangeSelectFilter(
                            e,
                            "idEstatus",
                            "valor",
                            "estatus"
                          );
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-md-2" style={{ paddingTop: "25px" }}>
                    <button
                      type="button"
                      rel="tooltip"
                      className="btn btn-primary"
                      data-placement="top"
                      title="Buscar"
                      onClick={onSearch}
                    >
                      <i className="fa fa-search" alt=""></i>
                    </button>
                  </div>
                </div>
              </div>
              <div className="col-md-12">
                <GeneralTable
                  columns={tableColumns}
                  data={dtDataTable}
                  action={undefined}
                  doubleClick={dblClickComp}
                />
              </div>
              <div className="col-md-12">
                <div className="row">
                  <div className="col-md-3">
                    <div className="form-group">
                      <label>Responsable Actual</label>
                      <input
                        className=" css-yk16xz-control"
                        type="text"
                        style={{ width: "100%" }}
                        value={guardaResponsable.personalAsignado}
                        disabled
                      />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label>Reasignacion de responsable</label>
                      <Select
                        className=""
                        id="idUsuario"
                        isClearable
                        isSearchable
                        name="usuario"
                        onChange={handleChangeSelect}
                        options={filtro.usuarioList}
                        value={filtro.usuario}
                        menuPlacement="top"
                      />
                    </div>
                  </div>
                  <div className="col-md-6"></div>
                  {filtro.usuario &&
                  dtDataTable.findIndex((c) => c.seleccionado == true) >= 0 ? (
                    <div
                      className="col-md-2 col-sm-10"
                      style={{
                        paddingTop: "25px",
                        paddingBottom: "25px",
                        paddingRight: "0px !important",
                      }}
                    >
                      <button
                        type="button"
                        rel="tooltip"
                        disabled={isDisabledBtnAsignacion}
                        className={"button button-primary"}
                        style={{ float: "right", marginRight: "-10px" }}
                        onClick={btnConfirmacion}
                        data-placement="top"
                        title={"Reasignar"}
                      >
                        Reasignar &nbsp;
                        <i className={"fa fa-save"} alt=""></i>
                      </button>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="modalConfirmacion"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="modalConfirmacionLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content">
            <div className="modal-header mhead">
              <button
                onClick={btnCancelar}
                type="button"
                className="close text-white"
                data-dismiss="modal"
                aria-label="Close"
              >
                <i className="fa fa-times-circle"></i>
              </button>
              <span
                className="modal-title title-modal-custom-color title-modal-custom"
                id="modalConfirmacionLabel"
              >
                ¿Desea hacer efectiva la Reasignación?
              </span>
            </div>
            <div className="modal-body">
            <div className="row">
                  <div className="col-md-12">
                  <GeneralTable
                  columns={tableColumnsUsuarios}
                  data={dtOrigenSeg}
                  action={undefined}
                  doubleClick={undefined}
                  updateSearch = {updateSearch}
                />
                </div>
            </div>
              <div className="row">
                <div className="col-md-3"></div>
                <div className="col-md-3">
                  <button
                    type="button"
                    rel="tooltip"
                    className={"button button-primary"}
                    onClick={btnAction}
                    data-placement="top"
                    title={"Aceptar"}
                  >
                    Aceptar
                  </button>
                </div>
                <div className="col-md-3">
                  <button
                    type="button"
                    rel="tooltip"
                    className={"button button-secondary"}
                    onClick={btnCancelar}
                    data-placement="top"
                    title={"Aceptar"}
                  >
                    Cancelar
                  </button>
                </div>
                <div className="col-md-3"></div>
              </div>
            </div>
            <div className="modal-footer"></div>
          </div>
        </div>
      </div>
      <ModalHistorico clickClose={clickClose} data={dtHistorico} />
      {pdfFileUrl !== "" ? (
        <PdfPreview
          clickClose={clickClosePDF}
          url={pdfFileUrl}
          showmodal={ShowModalPDF}
        />
      ) : (
        ""
      )}
    </>
  );
};
export default MonitorSeguimientoBuzones;
