//#region Administracion de Vistas
const inicializa = {
    title: 'Archivos Dispersion',
    breadcrum: [{ nombre: 'Facturacion' }, { nombre: 'Archvios de Dispersion' }]
}
const tableColumns = [
    {
        Header: "ID Archivo",
        accessor: 'idArchivo',
    },
    {
        Header: "Banco",
        accessor: 'banco',
    },
    {
        Header: "Código",
        accessor: 'codigoArchivo',
    },
    {
        Header: "Nombre",
        accessor: 'nombreArchivo',
    },
    {
        Header: "Posicion Fija",
        // accessor: 'posicionfija',
        Cell: k => (
            <div className="border-checkbox-section">
                <div className="border-checkbox-group border-checkbox-group-success">
                    <input
                    onChange={() => {}}
                        checked={k.row.original.posicionFija}
                        className="border-checkbox"
                        type="checkbox"
                        id={"chkAPlicarPF" + k.row.original.identificador}
                    />
                    <label className="border-checkbox-label"
                        htmlFor={"chkAPlicarPF" + k.row.original.identificador}>&nbsp;</label>
                </div>
            </div>)
    },
    {
        Header: "Separador",
        accessor: 'separador',
    },
    {
        Header: "Formato Fecha",
        accessor: 'formatoFecha',
    },
    {
        Header: "Rellena Ceros",
        // accessor: 'rellenaceros',
        Cell: k => (
            <div className="border-checkbox-section">
                <div className="border-checkbox-group border-checkbox-group-success">
                    <input
                    onChange={() => {}}
                        checked={k.row.original.rellenoCeros}
                        className="border-checkbox"
                        type="checkbox"
                        id={"chkAPlicarRC" + k.row.original.identificador}
                    />
                    <label className="border-checkbox-label"
                        htmlFor={"chkAPlicarRC" + k.row.original.identificador}>&nbsp;</label>
                </div>
            </div>)
    },
    {
        Header: "Punto Decimal",
        // accessor: 'puntodecimal',
        Cell: k => (
            <div className="border-checkbox-section">
                <div className="border-checkbox-group border-checkbox-group-success">
                    <input
                    onChange={() => {}}
                        checked={k.row.original.puntoDecimal}
                        className="border-checkbox"
                        type="checkbox"
                        id={"chkAPlicarPD" + k.row.original.identificador}
                    />
                    <label className="border-checkbox-label"
                        htmlFor={"chkAPlicarPD" + k.row.original.identificador}>&nbsp;</label>
                </div>
            </div>)
    },
    {
        Header: "Estatus",
        accessor: 'estatus',
    },
    {
        Header: "Tipos de Archivo",
        accessor: 'tipoExtension',
    },
    { 
        Header: "Detalle",
        accessor: "btnAction",
    }
]

const tableColumnsDetail =   [
    {
        Header: 'Linea',
        accessor: 'linea'
    },
    {
        Header: 'Codigo de Linea',
        accessor: 'codigoLinea'
    },
    {
        Header: 'Tipo de Linea',
        accessor: 'tipoLinea'
    },
    {
        Header: 'Campo',
        accessor: 'campo'
    },
    {
        Header: 'Descripción',
        accessor: 'descripcion'
    },
    {
        Header: 'Tipo campo',
        accessor: 'tipoCampo'
    },
    {
        Header: 'Origen de dato',
        accessor: 'origenDato'
    },
    {
        Header: 'Valor Constante',
        accessor: 'valorConstante'
    },
    {
        Header: 'Variable',
        accessor: 'variable'
    },
    {
        Header: 'Longitud máxima',
        accessor: 'longitud'
    },
    {
        Header: 'Posición desde',
        accessor: 'posicionDesde'
    },
    {
        Header: 'Posición Hasta',
        accessor: 'posicionHasta'
    }
]


const tableData = [
    {
        identificador: 1,
        banco: "BANCO NACINAL DE MEXICO",
        codigo: "BANAMEX",
        nombre: "BANAMEX",
        posicionfija: true,
        separador: "",
        formatofecha: "ddMMyyyy",
        rellenaceros: false,
        puntodecimal: true,
        estatus: "Habilitado",
        tipodearchivo: ".xsd",
        bancoId: 1,
        rutaArchivoId:1,     
        separadorId:1,     
        estatusId:1,     
        formatofechaId:1,     
        tipodearchivoId:1,     
    },
    {
        identificador: 2,
        banco: "Santander",
        codigo: "Santander",
        nombre: "Santander",
        posicionfija: false,
        separador: "",
        formatofecha: "ddMMyyyy",
        rellenaceros: true,
        puntodecimal: false,
        estatus: "Habilitado",
        tipodearchivo: ".xsd",
        bancoId: 2,
        rutaArchivoId:2,     
        separadorId:2,     
        estatusId:2,     
        formatofechaId:2,     
        tipodearchivoId:2,     
    },
    {
        identificador: 3,
        banco: "BANCOMER",
        codigo: "BANCOMER",
        nombre: "BANCOMER",
        posicionfija: true,
        separador: "",
        formatofecha: "ddMMyyyy",
        rellenaceros: false,
        puntodecimal: true,
        estatus: "Habilitado",
        tipodearchivo: ".xsd",
        bancoId: 3,
        rutaArchivoId:3,     
        separadorId:3,     
        estatusId:3,     
        formatofechaId:3,     
        tipodearchivoId:3,     
    }
]

//#endregion



export { tableColumns, inicializa, tableData ,tableColumnsDetail}