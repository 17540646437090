import React,{ Component } from "react";

class BtnGeneraFactura extends Component{

    render(){
        return (
           
            <button 
            type="button" 
            rel="tooltip" 
            className="button button-primary button-circle button-small"
            data-toggle="tooltip" 
            data-placement="top" 
            title="Genera Factura">
                <i className="fa fa-file-excel-o" alt="Genera Factura"></i>
            </button>

        )
    }

}

export default BtnGeneraFactura;