import React, { Fragment, useEffect, useState } from 'react';
import GeneralTable from '../../../Components/GeneralTable';
import { getInfo, saveInfo, updateInfo, deleteInfo } from '../../../ApiRequests/req_CRUD'
import { ExisteSession, toggleLoader } from '../../../ApiRequests/general'
import { fnNotification } from '../../../ApiRequests/Notification'
import { idOrigenWeb } from '../../../Constants/application'
import Header from '../../../Components/Header';
import { inicializa, tableColumns, tableData, tableColumnsDetail } from './resources'
import Modal from './ModalDispersion.jsx'
import Buttons from '../../../Components/Buttons/Buttons'
import Session from '../../../Application/session'
import ModalDispersionConfiguracion from './ModalDispersionConfiguracion.jsx';


const Dispersion = () => {
    const [model, setModel] = useState({
        idArchivo: 0,
        type: true,
        selectView: null,
        configuracionSelected: null,
        apps: 1,
        typeAction: 'inicia',
        filtro_aplicacion: 38,
        filtro_ambiente: ExisteSession.getAmbiente().idAmbiente,
        idOrigen: idOrigenWeb,
        idUsuarioAct: ExisteSession.getInfoUsuario().idUsuario,
        token: ExisteSession.getInfoUsuario().token,
        connection: ExisteSession.conexion(),
    });

    const [selecctions, setSelecction] = useState({
        Bancos: [],
        idBanco: null,
        RutaArchivo: [],
        rutaArchivoId: null,
        Separador: [],
        separadorId: null,
        FormatoFecha: [],
        idFormatoFecha: null,
        TipoArchivo: [],
        idTipoExtension: null,
        Estatus: [],
        idEstatus: null,
        configurations: [],

        TiposLineas: [],
        idTipoLinea: null,
        TiposCampos: [],
        idTipoCampo: null,
        OrigenesDatos: [],
        idOrigenDato: null,
        Variables: [],
        idVariable: null,
    });
    const [reload, setReload] = useState(true);
    const [dataTableUs, setDataTableUs] = useState([]);
    const [dataTableUsConfig, setDataTableUsConfig] = useState([]);
    const [calssTabPrincipal, setClassTabPrincipal] = useState('active')
    const [classTabContP, setClassTabContP] = useState('tab-pane active')
    const [calssTabDet, setClassTabDet] = useState('')
    const [classTabContDet, setClassTabContDet] = useState('tab-pane')
    const [showTab, setShowTab] = useState(false)
    const [saveButtonShow, setSaveButtonShow] = useState(false)

    useEffect(() => {
        toggleLoader(true);
        getInfo("Catalogos/catBancos", model, (response) => Load(response, "banco", "idbanco", "Bancos"));
        getInfo("Catalogos/catCriterios", {
            criterio: "pathArchDispersion",
            filtro_ambiente: ExisteSession.getAmbiente().idAmbiente,
            idOrigen: idOrigenWeb,
            idUsuarioAct: ExisteSession.getInfoUsuario().idUsuario,
            token: ExisteSession.getInfoUsuario().token,
            connection: ExisteSession.conexion()
        }, (response) => Load(response, "descripcion", "valor", "RutaArchivo"));
        getInfo("Catalogos/catGenerales", {
            tabla: "conArchivosDispersionTbl",
            columna: "idSeparador",
            filtro_ambiente: ExisteSession.getAmbiente().idAmbiente,
            idOrigen: idOrigenWeb,
            idUsuarioAct: ExisteSession.getInfoUsuario().idUsuario,
            token: ExisteSession.getInfoUsuario().token,
            connection: ExisteSession.conexion()
        }, (response) => Load(response, "descripcion", "valor", "Separador"));
        getInfo("Catalogos/catGenerales", {
            tabla: "conArchivosDispersionTbl",
            columna: "idTipoExtension",
            filtro_ambiente: ExisteSession.getAmbiente().idAmbiente,
            idOrigen: idOrigenWeb,
            idUsuarioAct: ExisteSession.getInfoUsuario().idUsuario,
            token: ExisteSession.getInfoUsuario().token,
            connection: ExisteSession.conexion()
        }, (response) => Load(response, "descripcion", "valor", "TipoArchivo"));
        getInfo("Catalogos/catGenerales", {
            tabla: "conArchivosDispersionTbl",
            columna: "idFormatoFecha",
            filtro_ambiente: ExisteSession.getAmbiente().idAmbiente,
            idOrigen: idOrigenWeb,
            idUsuarioAct: ExisteSession.getInfoUsuario().idUsuario,
            token: ExisteSession.getInfoUsuario().token,
            connection: ExisteSession.conexion()
        }, (response) => Load(response, "descripcion", "valor", "FormatoFecha"));
        getInfo("Catalogos/catGenerales", {
            tabla: "conArchivosDispersionTbl",
            columna: "idEstatus",
            filtro_ambiente: ExisteSession.getAmbiente().idAmbiente,
            idOrigen: idOrigenWeb,
            idUsuarioAct: ExisteSession.getInfoUsuario().idUsuario,
            token: ExisteSession.getInfoUsuario().token,
            connection: ExisteSession.conexion()
        }, (response) => Load(response, "descripcion", "valor", "Estatus"));
        getInfo("Catalogos/catGenerales", {
            tabla: "conArchivoDispersionDetTbl",
            columna: "idTipoLinea",
            filtro_ambiente: ExisteSession.getAmbiente().idAmbiente,
            idOrigen: idOrigenWeb,
            idUsuarioAct: ExisteSession.getInfoUsuario().idUsuario,
            token: ExisteSession.getInfoUsuario().token,
            connection: ExisteSession.conexion()
        }, (response) => Load(response, "descripcion", "valor", "TiposLineas"));
        getInfo("Catalogos/catGenerales", {
            tabla: "conArchivoDispersionDetTbl",
            columna: "idTipoCampo",
            filtro_ambiente: ExisteSession.getAmbiente().idAmbiente,
            idOrigen: idOrigenWeb,
            idUsuarioAct: ExisteSession.getInfoUsuario().idUsuario,
            token: ExisteSession.getInfoUsuario().token,
            connection: ExisteSession.conexion()
        }, (response) => Load(response, "descripcion", "valor", "TiposCampos"));
        getInfo("Catalogos/catGenerales", {
            tabla: "conArchivoDispersionDetTbl",
            columna: "idOrigenDato",
            filtro_ambiente: ExisteSession.getAmbiente().idAmbiente,
            idOrigen: idOrigenWeb,
            idUsuarioAct: ExisteSession.getInfoUsuario().idUsuario,
            token: ExisteSession.getInfoUsuario().token,
            connection: ExisteSession.conexion()
        }, (response) => Load(response, "descripcion", "valor", "OrigenesDatos"));
        getInfo("Catalogos/catGenerales", {
            tabla: "conArchivoDispersionDetTbl",
            columna: "idVariable",
            filtro_ambiente: ExisteSession.getAmbiente().idAmbiente,
            idOrigen: idOrigenWeb,
            idUsuarioAct: ExisteSession.getInfoUsuario().idUsuario,
            token: ExisteSession.getInfoUsuario().token,
            connection: ExisteSession.conexion()
        }, (response) => Load(response, "descripcion", "valor", "Variables"));

        getInfo("Facturacion/ArchivosDispersion", {
            apps: 1,
            typeAction: 'inicia',
            filtro_aplicacion: 38,
            filtro_ambiente: ExisteSession.getAmbiente().idAmbiente,
            idOrigen: idOrigenWeb,
            idUsuarioAct: ExisteSession.getInfoUsuario().idUsuario,
            token: ExisteSession.getInfoUsuario().token,
            connection: ExisteSession.conexion(),
        }, (response) => LoadTable(response));
        if (model.idArchivo && model.idArchivo != 0) {
            getInfo("Facturacion/ArchivosDispersionDet", model, (response) => LoadTableConfig(response));
        }
        // LoadTable();
        // toggleLoader(false);
    }, [reload])

    const LoadTable = (response) => {
        // console.log("response table" + _table, response);
        if (response)
            // if (_table || _table == 1)
            //     setSelecction({
            //         ...selecctions,
            //         configurations: selecctions.configurations = response
            //     });
            // else
            setDataTableUs(response);
        // setDataTableUs(tableData);
        toggleLoader(false);
    }
    const showModal = () => {
        if (saveButtonShow) {
            setModel({
                ...model,
                configuracionSelected: null,
                type: true,
                idArchivo: model.idArchivo
            });
            window.$('#modalDispersionConfiguracion').modal('show');
        }
        else {
            setModel({
                ...model,
                selectView: null,
                type: true,
                idArchivo: 0
            });
            window.$('#modalDispersion').modal('show');
        }

    }

    const handleDoubleClick = (originals, tableValues) => {
        setModel({
            ...model,
            selectView: originals,
            type: false,
            typeAction: '',
            idArchivo: originals.idArchivo
        });
        window.$('#modalDispersion').modal('show');
    }

    const saveDataUser = dataNewUser => {
        toggleLoader(true);
        if (model.type) {
            saveInfo("Facturacion/ArchivosDispersion", dataNewUser, (response) => responseSave(response));
        }
        else {
            updateInfo("Facturacion/ArchivosDispersion", dataNewUser, (response) => responseSave(response));
        }
    }
    const clickDelete = (dataNewUser) => {
        toggleLoader(true);
        deleteInfo("Facturacion/ArchivosDispersion", dataNewUser, (response) => responseDelete(response));
    }


    const responseSave = (response) => {

        setModel({
            ...model,
            selectView: null,
            type: true,
            idArchivo: 0
        });
        setReload(!reload);
        window.$('#modalDispersion').modal('hide');
        toggleLoader(false);
    }
    const responseDelete = (response) => {
        setModel({
            ...model,
            selectView: null,
            type: true,
            idArchivo: 0
        });
        setReload(!reload);
        window.$('#modalDispersion').modal('hide');
        toggleLoader(false);
    }

    const actionClose = () => {
        setModel({
            ...model,
            selectView: null,
            type: true,
            idArchivo: 0
        });
        window.$('#modalDispersion').modal('hide');
    }

    const Load = (response, _label, _value, _array, _showsLabel) => {
        var _load = [];
        if (response && response.length > 0) {
            if (_showsLabel) {
                for (let index = 0; index < response.length; index++) {
                    let label = response[index][_label]
                    _showsLabel.forEach(element => {
                        label += " - " + response[index][element]
                    });
                    _load.push({ value: response[index][_value], label: label });
                }
            }
            else {
                for (let index = 0; index < response.length; index++)
                    _load.push({ value: response[index][_value], label: response[index][_label] });
            }
        }
        setSelecction({
            ...selecctions,
            [_array]: selecctions[_array] = _load
        });
    }

    function onlyUnique(value, index, self) {
        return self.indexOf(value) === index;
    }

    const actionBtn = (val) => {
        setModel({
            ...model,
            selectView: null,
            idArchivo: model.idArchivo = val.idArchivo
        });
        setTimeout(() => {
            getInfo("Facturacion/ArchivosDispersionDet", model, (response) => LoadTableConfig(response));
        }, 100);
    }


    const LoadTableConfig = (response) => {
        if (response)
            setDataTableUsConfig(response);
        setSaveButtonShow(true);
        setShowTab(true);
        setClassTabPrincipal('');
        setClassTabContP('tab-pane');
        setClassTabDet('active');
        setClassTabContDet('tab-pane active');
        toggleLoader(false);
    }
    const clickSave = () => {

    }
    const handleDoubleClickDetail = (originals, tableValues) => {
        setModel({
            ...model,
            configuracionSelected: originals,
            type: false,
            typeAction: '',
            idArchivo: originals.idArchivo
        });
        window.$('#modalDispersionConfiguracion').modal('show');
    }
    const saveConfiguration = (dataNewUser) => {
        toggleLoader(true);
        if (model.type) {
            saveInfo("Facturacion/ArchivosDispersionDet", dataNewUser, (response, _type, _estatus) => responseSaveConfiguration(response, _type, _estatus));
        }
        else {
            updateInfo("Facturacion/ArchivosDispersionDet", dataNewUser, (response, _type, _estatus) => responseSaveConfiguration(response, _type, _estatus));
        }
    }

    const responseSaveConfiguration = (response, _type, _estatus) => {
        if (_estatus) {
            setModel({
                ...model,
                configuracionSelected: null,
                type: true,
            });
            setReload(!reload);
            window.$('#modalDispersionConfiguracion').modal('hide');
        }

        toggleLoader(false);
    }

    const deleteConfiguration = (dataNewUser) => {
        toggleLoader(true);
        deleteInfo("Facturacion/ArchivosDispersionDet", dataNewUser, (response, _type, _estatus) => responseDeleteConfiguration(response, _type, _estatus));
    }

    const responseDeleteConfiguration = (response, _type, _estatus) => {
        setModel({
            ...model,
            configuracionSelected: null,
            type: true,
        });
        setReload(!reload);
        window.$('#modalDispersionConfiguracion').modal('hide');
        toggleLoader(false);
    }
    const closeConfiguration = () => {
        setModel({
            ...model,
            configuracionSelected: null,
            type: true
        });
        setReload(!reload);
        window.$('#modalDispersionConfiguracion').modal('hide');
        toggleLoader(false);
    }

    const principalTab = () => {
        setSaveButtonShow(false)
    }
    const secondaryTab = () => {
        setSaveButtonShow(true)
    }
    return (
        <Fragment>
            <Header title={inicializa.title + ' ' + Session.getInfoAmbiente()} breadcrum={inicializa.breadcrum} />

            <div className="lx-container container">
                <div className="panel panel-primary animated fadeInUp">
                    <div className="panel-heading">
                        <div className="row">
                            <div className="col-md-6">
                                <span>Mantenimiento Archivos Dispersion</span>

                            </div>
                            <div className="col-md-6 btnF">
                                <Buttons btnAdd={true} btnAddAction={showModal} btnAddText={true} btnAddShowIcon={true}

                                />

                            </div>

                        </div>
                    </div>
                    <div className="panel-body">
                        <ul className="nav nav-pills nav-justified ar-nav-pills center-block margin-bottom">
                            <li className={calssTabPrincipal} onClick={principalTab}>
                                <a href="#Dispersion" data-toggle="tab" aria-expanded="true">
                                    <i className="fa fa-tasks"></i> Dispersion
                                </a>
                            </li>
                            {
                                showTab &&
                                <li className={calssTabDet} onClick={secondaryTab}>
                                    <a href="#Detalles" data-toggle="tab" aria-expanded="false">
                                        <i className="fa fa-code"></i> Detalles
                                    </a>
                                </li>
                            }
                        </ul>
                        <div className="tab-content margin-top">
                            <div className={classTabContP} id="Dispersion">
                                <GeneralTable
                                    columns={tableColumns}
                                    // updateSearch={updateTableSearch} 
                                    data={dataTableUs}
                                    action={undefined}
                                    actionBtn={actionBtn}
                                    doubleClick={handleDoubleClick}
                                />
                            </div>
                            <div className={classTabContDet} id="Detalles">
                                <GeneralTable
                                    columns={tableColumnsDetail}
                                    data={dataTableUsConfig}
                                    doubleClick={handleDoubleClickDetail}
                                />
                            </div>
                        </div>


                    </div>
                </div>

            </div>
            <Modal model={model} saveDataUser={saveDataUser} actionClose={actionClose}
                clickDelete={clickDelete} selecctions={selecctions} setSelecction={setSelecction} />
            <ModalDispersionConfiguracion model={model} saveDataUser={saveConfiguration}
                actionClose={closeConfiguration} clickDelete={deleteConfiguration}
                selecctions={selecctions} setSelecction={setSelecction} />
        </Fragment>

    );
}

export default Dispersion;