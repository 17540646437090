// import { ExisteSession } from "../ApiRequests/general";
// import { getInfo } from "../ApiRequests/req_CRUD";


const HandlerSession = {
    setLogin: (val) => {

        let _time = new Date();
        let _objLogin = {
            Session_Usuario: val,
            Time: _time.getTime(),
            Time2: _time.getTime() + 180000
        }
        // console.log("valor ", _objLogin);

        localStorage.setItem("setLogin", JSON.stringify(_objLogin));
    },
    getLogin: () => {
        let _objLogin = JSON.parse(localStorage.getItem('setLogin'));
        // console.log("valor _objLogin ", _objLogin);
        // console.log("valor time 2", new Date(_objLogin.Time2));
        let _actual = _objLogin.Time2;
        // console.log("valor obtener ", _actual);
        let _time = new Date();
        if (!_objLogin || _actual < _time.getTime())
            return null;
        else
            return _objLogin.Session_Usuario;
    },
    setTimeLogin: () => {
        // getInfo("Catalogos/catCriterios", {
        //     criterio: "SESIONWEB",
        //     filtro_ambiente: ExisteSession.getAmbiente().idAmbiente,
        //     idUsuarioAct: ExisteSession.getInfoUsuario().idUsuario,
        //     token: ExisteSession.getInfoUsuario().token,
        //     connection: ExisteSession.conexion()
        // }, (response) => {
        //     if (response && response[0].valorAdicional) {
        let response = JSON.parse(localStorage.getItem("setTimeLogin"))
        let _ourTime = response.valorAdicional;
        let _ourTimeDate = _ourTime.split(":");
        let _hours = parseInt(_ourTimeDate[0], 10) * 3600000;
        let _minutes = parseInt(_ourTimeDate[1], 10) * 60000;

        let _objLogin = JSON.parse(localStorage.getItem('setLogin'));

        let _time = new Date();

        let _objLoginLocal = {
            Session_Usuario: _objLogin.Session_Usuario,
            Time: _time.getTime(),
            Time2: _time.getTime() + _hours + _minutes
        }

        localStorage.setItem("setLogin", JSON.stringify(_objLoginLocal));
        //     }
        //   });
        // console.log("Entro");
        // console.log("hambiente", data)
        // console.log("salio");

    }
}


export { HandlerSession }