const inicializa = {
    title: 'Buzón Tributario',
    breadcrum: [{ nombre: 'Buzón Tributario' }, { nombre: 'Monitor Seguimiento Buzones' }]
}
const cambioData = (_obj, event, lista) => {
    console.log("event", lista);
    console.log("event", event.target.checked);
    lista.data.forEach(c => {
        c.seleccionado = false;        
    });
    if (event.target.checked) {
        _obj.seleccionado = true;
    }
    // if (tipoFecha) {
    //     console.log("event", event.target.value);
    //     _obj[propiedad] = event.target.value;
    //     _obj['editado'] = true;
    // }
    // else {
    //     _obj[propiedad] = event.target.value;
    //     _obj['editado'] = true;
    // }
}
const tableColumns = [
    {
        Header: "Seleccionar",
        label: "Activo ",
        Cell: k => (
            <div className="border-checkbox-section">
                <div className="border-checkbox-group border-checkbox-group-success">
                    <input
                        onChange={(event) => { cambioData(k.row.original, event,  k) }}
                        checked={k.row.original.seleccionado}
                        className="border-checkbox"
                        type="checkbox"
                        id={"chkAPlicar-" + k.row.original.idSeguimiento}
                    />
                    <label className="border-checkbox-label"
                        htmlFor={"chkAPlicar-" + k.row.original.idSeguimiento}>&nbsp;</label>
                </div>
            </div>)
    },
    {
        Header: "Razón Social",
        accessor: 'razonSocial',
    },
    {
        Header: "Tipo Empresa",
        accessor: 'tipoEmpresa',
    },
    {
        Header: "Prioridad",
        accessor: 'nivelPrioridad',
    },
    {
        Header: "Descripción de requerimiento",
        accessor: 'descripcionRequerimiento',
    },
    {
        Header: "Fecha de notificación",
        accessor: 'fechaNotificacion',
    },
    {
        Header: "Fecha de Lectura Notificación",
        accessor: 'fechaLecturaNotificacion',
    },
    {
        Header: "Fecha inicio de atención",
        accessor: 'fechaInicioAtencion',
    },
    {
        Header: "Plazo Limite",
        accessor: 'plazoLimite',
    },
    {
        Header: "Fecha de Termino",
        accessor: 'fechaTerminoAtencion',
    },
    {
        Header: "Estatus",
        accessor: 'estatus',
    },
    {
        Header: "Comentarios de personal",
        accessor: 'comentario',
    },
    {
        Header: "Personal Asignado",
        accessor: 'personalAsignado',
    }
]



const tableData = [
    {
        'razonSocial': 'uno',
        'tipoEmpresa': 'dos',
        'prioridad': 'tres',
        'descripciondrequerimiento': 'cuatro',
        'fechadenotificacion': 'cinco',
        'fechaLecturaNotificacion': 'seis',
        'fechaInicioAtencion': 'siete',
        'plazoLimite': 'ocho',
        'fechaTermino': 'nueve',
        'estatus': 'diez',
        'comentariosPersonal': 'once',
        'personalAsignado': 'doce',
    }
]

//#endregion



export { tableColumns, inicializa, tableData }