import React, { Fragment, useEffect, useState } from 'react';
import Header from '../../../../Components/Header';
import {inicializa,tableColumns} from './resources'
import Buttons from '../../../../Components/Buttons/Buttons'
import GeneralTable from '../../../../Components/GeneralTable'
import {getInfo,saveInfo,updateInfo, deleteInfo} from '../../../../ApiRequests/req_CRUD'
import {ExisteSession,toggleLoader} from '../../../../ApiRequests/general'
import {fnNotification} from '../../../../ApiRequests/Notification'
import ModalPerfilVistas from './ModalPerfilVistas'


 const PerfilesVistas = ({handleClickRefresh}) => {
    const [dataModal,setDataModal] = useState({
        type:false,
    })
 
    const [relVistaAcicon, setRelVistaAcicon] = useState([])
    const [relComponente, setRelComponente] = useState([])
    // const [show,setShow] = useState(false)
    const [relation,setRelation] = useState(false)
    const [dataTablePErfil, setDataTablePErfil] = useState([])
    const [dataInicio] = useState({
        stateI : '',
        filtro_aplicacion :38,
        filtro_ambiente : ExisteSession.getAmbiente().idAmbiente,
        idUsuarioAct : ExisteSession.getInfoUsuario().idUsuario,
        token : ExisteSession.getInfoUsuario().token,
        connection : ExisteSession.conexion(),
     })
    useEffect(() =>{
        getInfo('Seguridad/PerfilVistas',dataInicio, (response) =>{
            setDataTablePErfil(response)
        })
    },[dataInicio])
    const showModal = () =>{
        // console.log('datos')
        // setShow(true)
        setDataModal({
            ...dataModal,
            type : true,
     
          })
          let origin = {
            idPerfil : 0,
            descripcion: '', 
            idEstatus: false,
          }
        getDataRel(origin,true)

    } 
    const handleDoubleClick = (originals) => {
        setDataModal({
            ...dataModal,
            type : false,
            idPerfil:originals.idPerfil,
            descripcion: originals.descripcion,
            idEstatus: originals.idEstatus,
        })
        getDataRel(originals,false)
      
    }

    const getDataRel = (originals,typeM) => {
        console.log("inforecibida",originals);
        const dataInfo={
            type : typeM,
            idPerfil:originals.idPerfil,
            descripcion: originals.descripcion,
            idEstatus: originals.idEstatus,
            filtro_aplicacion :38,
            filtro_ambiente : ExisteSession.getAmbiente().idAmbiente,
            idUsuarioAct : ExisteSession.getInfoUsuario().idUsuario,
            token : ExisteSession.getInfoUsuario().token,
            connection : ExisteSession.conexion(),
        }
        getInfo('Seguridad/RelPerfilVistaAccion',dataInfo, (response) =>{
            setRelVistaAcicon(response)
            getInfo('Seguridad/RelPerfilVistaComponentes',dataInfo, (response) =>{
                setRelComponente(response)
            })
            window.$('#modalPerfilesVistas').modal('show')

        })
    }

    const saveProfiler = (data,callback) =>{
        console.log('data',data)
        if(data.descripcion !== undefined && data.descripcion !== null && data.descripcion !== ''){
            toggleLoader(true)
            if(data.isType){       
            console.log('Guarda')

                saveInfo('Seguridad/PerfilVistas',data, (responseSave,type,success) => {
                    actionClose()
                    if(success){
                        callback()

                        getInfo('Seguridad/PerfilVistas',dataInicio, (responseG) =>{
                        setDataTablePErfil(responseG)
                        toggleLoader(false)
                        setRelation(true)
                        
                    })   
                    }
                })
            }else{
            console.log('Edita')

                updateInfo('Seguridad/PerfilVistas',data, (rsp,type,rSuccess) => {
                    actionClose()
                    toggleLoader(false)
                    if(rSuccess) {
                            getInfo('Seguridad/PerfilVistas',dataInicio, (responseGe) =>{
                        setDataTablePErfil(responseGe)
                        // toggleLoader(false)
                        setRelation(true)
                        if(data.idPerfil === ExisteSession.getPermisos()[0].idPerfil){
                            let dataInit = {
                                IsMenu : true,
                                idUsuarioAct: ExisteSession.getInfoUsuario().idUsuario,
                                idUsuario: ExisteSession.getInfoUsuario().idUsuario,
                                token: ExisteSession.getInfoUsuario().token,
                                connection: ExisteSession.conexion(),
                            }
                            getInfo("Menu/Menu", dataInit, (response) => {
                                // console.log(response);
                                toggleLoader(false)
                                if(response !== undefined && response !== null){
                                // window.location.replace(`${process.env.PUBLIC_URL}/PerfilesVistas`);
                    
                                
                                localStorage.setItem('Permisos',JSON.stringify(response[0]))
                    
                                localStorage.setItem('MenuSession',JSON.stringify(response[1]))
                                console.log("respuesta al editar",response);
                                handleClickRefresh(Math.random())                   
                                
                            }
                            });
                        } 
                            }) 
                        callback()
  
                    }else {
                        console.log('Este es erroneo')
                    }                   
                })
            }

        }else fnNotification(['Error: El campo de descripcion no puede ser vacio' , 'error', false]) 
    }
    
    const actionClose = () => {
        // console.log('Sig colse')
        setDataModal({
            ...dataModal,
            type : false,
            descripcion: '',
            idEstatus: false,
     
          })
        window.$('#modalPerfilesVistas').modal('hide');
     
     
    }
    const clickDelete = ({idPerfil}) => {
        let dataSave = {
            idPerfil: idPerfil,
            filtro_aplicacion :38,
            filtro_ambiente : ExisteSession.getAmbiente().idAmbiente,
            idUsuarioAct : ExisteSession.getInfoUsuario().idUsuario,
            token : ExisteSession.getInfoUsuario().token,
            connection : ExisteSession.conexion(),
        }
        deleteInfo('Seguridad/PerfilVistas',dataSave, (response) => {
            // console.log(response)
            actionClose()
            getInfo('Seguridad/PerfilVistas',dataInicio, (response) =>{
                setDataTablePErfil(response)
                let dataInit = {
                    IsMenu : true,
                    idUsuario: ExisteSession.getInfoUsuario().idUsuario,
                    idUsuarioAct: ExisteSession.getInfoUsuario().idUsuario,
                    token: ExisteSession.getInfoUsuario().token,
                    connection: ExisteSession.conexion(),
                }
                getInfo("Menu/Menu", dataInit, (response) => {
                    console.log("respuesta al eliminar",response);
                    localStorage.setItem('MenuSession',JSON.stringify(response[1]))
                    if(response[0].IsPerfil) localStorage.setItem('Permisos',JSON.stringify(response[0]))
                    else localStorage.setItem('Permisos',JSON.stringify(response[0]))
                    toggleLoader(false)
                });
            })
            
        })

    }
    return (
        <Fragment>
             {/* <Header title={inicializa.title + ' ' + Session.getInfoAmbiente()} breadcrum={inicializa.breadcrum}/> */}
            <Header title={inicializa.title} breadcrum={inicializa.breadcrum}/>
            <div className="lx-container  container">
                <div className="panel panel-primary animated fadeInUp">
                    <div className="panel-heading">
                        <div className="row">
                            <div className="col-md-6">
                                <span>Perfiles</span>                   
                            </div>
                            <div className="col-md-6 btnF">
                                <Buttons btnAdd={true} btnAddAction={showModal} btnAddText={'Agregar'} btnAddShowIcon={true}/>

                                {/* <Buttons btnAdd={true} btnAddAction={showModal} /> */}
                            </div>
                        </div>
                    </div>
                    <div className="panel-body">
                             <GeneralTable
                                columns={tableColumns}
                                // updateSearch={updateTableSearch} 
                                data={dataTablePErfil} 
                                action={undefined} 
                                doubleClick={handleDoubleClick}
                             />
                    </div> 
                </div>
            </div>
            <ModalPerfilVistas 
                dataModal={dataModal}
                saveProfiler={saveProfiler} 
                actionClose={actionClose} 
                clickDelete={clickDelete}
                relVistaAcicon={relVistaAcicon}
                relation={relation}
                relComponente={relComponente}
                />
        </Fragment>
    );
};

export default PerfilesVistas;