import React, {Component} from 'react'

class InfoCliente extends Component{
    render(){
        const {DatosCliente} = this.props;
        return (
            <div className="panel-group" id="accordionCliente">
                <div className="panel panel-info-dark">
                    <div className="panel-heading panel-heading-link">
                        <a data-toggle="collapse" data-parent="#accordionCliente" href="#cliente">
                        Datos del Cliente
                        </a>
                    </div>
                    <div id="cliente" className="panel-collapse collapse in">
                        <div className="panel-body">
                            <div className="col-md-8">
                                <input 
                                className="form-control" 
                                disabled
                                ref={inputElement => DatosCliente.nombre = inputElement} 
                                value={DatosCliente.nombre} />
                            </div>
                            <div className="col-md-4">
                                <input 
                                className="form-control" 
                                disabled
                                ref={inputElement => DatosCliente.rfc = inputElement} 
                                value={DatosCliente.rfc}/>
                            </div>
                            <div className="col-md-7 margin-top-20">
                                <input 
                                className="form-control" 
                                disabled
                                ref={inputElement => DatosCliente.direccion = inputElement} 
                                value={DatosCliente.rfc}/>
                            </div>
                            <div className="col-md-5 margin-top-20">
                                <input 
                                className="form-control" 
                                disabled
                                ref={inputElement => DatosCliente.colonia = inputElement} 
                                value={DatosCliente.rfc}/>
                            </div>
                            <div className="col-md-4 margin-top-20">
                                <input 
                                className="form-control" 
                                disabled
                                ref={inputElement => DatosCliente.municipio = inputElement} 
                                value={DatosCliente.rfc}/>
                            </div>
                            <div className="col-md-4 margin-top-20">
                                <input 
                                className="form-control" 
                                disabled
                                ref={inputElement => DatosCliente.estado = inputElement} 
                                value={DatosCliente.rfc}/>
                            </div>
                            <div className="col-md-4 margin-top-20">
                                <input 
                                className="form-control" 
                                disabled
                                ref={inputElement => DatosCliente.cp = inputElement} 
                                value={DatosCliente.rfc}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default InfoCliente;