import React ,{ Component  } from "react";
import {sistema} from '../../Constants/application'
import {fechas} from '../../ApiRequests/general'
import Moment from 'react-moment'
import InfoPedido  from "./InfoPedido";
import InfoCliente from "./InfoCliente";
import InfoFactura from "./InfoFactura";
import Buttons from "../Buttons/Buttons";

class FormFacturacionElectronica extends Component{

    state = {
        idFiscal : 'BAB000491',
        numFactura : 491,
        DatosCliente : {
            rfc : 'APZ13040118X8',
            nombre : 'AGENCIA DE PROMOCIONES ZUMILF, S.A DE C.V',
            direccion : 'AV. DEL PARQUE CHAPULTEPEC N. 101',
            colonia : 'DELPARQUE',
            municipio : 'NAUCALPAN DE JUAREZ',
            estado: 'México, D.F.',
            cp : '53398'
        },
        DatosFactura : {
            formaPago : 'Transferencia Electrónica de fondos.',
            condicion : 'CREDITO A 7 DIAS',
            metodoPago : 'Pago en parcialidades o diferido',
            fechaFactura : '05/03/2020',
            usoCFDI : 'Gastos en general',
            version : 'v3.3'
        },
        DatosPedido : {
            noPedido : '518 491',
            idFiscal : 1,
            sucursal : 'Blent & Bontt Consultors',
            moneda : 'Pesos',
            SubTotal: 31034.48,
            TotalIva: 4965.5168,
            TotalNeto : 35999.9968
        }
    }

    render(){
        const {idFiscal, numFactura, DatosCliente, DatosFactura, DatosPedido} = this.state;

        return (
            <div className="panel panel-primary animated fadeInUp">
                <div className="panel-heading">
                    {sistema}
                    <div className="col-md-2 pull-right">
                        Fecha: &nbsp;                        
                        <strong>
                        <Moment format="DD/MM/YYYY">
                            {fechas.current()}
                        </Moment>
                        </strong>
                    </div>
                </div>
                <div className="panel-body">
                    <div className="row">
                        <div className="col-md-4">
                            <Buttons btnFactura={true} btnView={true} bntCopy={true} btnMail={true} btnClientes={true}/>
                        </div>
                        <div className="col-md-8">
                            <div className="col-md-6 form-group">
                                <label className="col-ms-4 col-md-4 control-label text-right">ID Fiscal: </label>
                                <div className="col-sm-8 col-md-8">
                                    <input 
                                    type="text" 
                                    className="form-control" 
                                    id='idFiscal'
                                    name='idFiscal'
                                    onChange={e => this.setState({idFiscal : e.target.value})}
                                    ref={inputElement => this.idFiscal = inputElement} 
                                    value={idFiscal}
                                    disabled
                                    />
                                </div>                                
                            </div>
                            <div className="col-md-6 form-group">
                                <label className="col-ms-4 col-md-4 control-label text-right"># Factura: </label>
                                <div className="col-sm-8 col-md-8">
                                    <input 
                                    type="text" 
                                    className="form-control" 
                                    id='numFactura'
                                    name='numFactura'
                                    onChange={e => this.setState({numFactura : e.target.value})}
                                    ref={inputElement => this.numFactura = inputElement} 
                                    value={numFactura}
                                    disabled
                                    />
                                </div>   
                                
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-4">
                            <InfoPedido DatosPedido={DatosPedido}/>
                        </div>
                        <div className="col-md-8">
                            <InfoCliente DatosCliente={DatosCliente} />
                            <hr className="dotted" />                    
                        </div>
                    </div>
                    <InfoFactura DatosFactura={DatosFactura}/>

                   
                </div>
            </div>
        )
    }
}

export default FormFacturacionElectronica;