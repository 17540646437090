import React, {Fragment,useState,useEffect} from 'react';
import Header from '../../../Components/Header'
import Session from '../../../Application/session'
import Buttons from '../../../Components/Buttons/Buttons'
import GeneralTable from '../../../Components/GeneralTable';
import ModalMasivaDisponibilidad from './ModalMasivaDisponibilidad'
import Select from 'react-select'
import{ExisteSession, toggleLoader } from '../../../ApiRequests/general'
import{columnsMasivaDisponibilidadTbl,columnsMasivaDisponibilidadErroresTbl,inicializa,tableData} from './resources'
// import{getMasivaDisponibilidad,getFiltros,saveMasivaDisponibilidad,deleteMasivaDisponibilidad,getLimpiaMasivaDisponibilidad,donwnloadHelpDocument,cargarArchivo, donwnloadErrores} from './Crud'
import{getLimpiaMasivaDisponibilidad,donwnloadHelpDocument,cargarArchivo} from './Crud'
import{ CSVLink } from 'react-csv'


const CargaMasivaDisponibilidad = ()  => {

    const [typeMasivaDisponibilidad, setTypeMasivaDisponibilidad]      = useState('')
    const [archivos,setArchivos] = useState(null)   
    // const [dataTableMasivaDisponibilidad,setDataTableMasivaDisponibilidad]     = useState([])
    const [dataTableMasivaDisponibilidadErrores,setDataTableMasivaDisponibilidadErrores]     = useState([])
    // const [listEmpresasFront,setListEmpresasFront] = useState([])
    // const [listCuentas,setListCuentas]             = useState([])
    // const [listCuentasC,setListCuentasC]           = useState([])
    // const [isEmpresa,setIsEmpresa]                 = useState(false)
    // const [dataSaved,setDatasSaved]                = useState({

    //                                                             empresa:[],
    //                                                             empresaValue:0,
    //                                                             cuenta:[],
    //                                                             cuentaValue:0,
    //                                                             importe:0,
    //                                                             nuevoImporte:0,
    //                                                             isSaved:true,
    //                                                             secuencia:0,
    //                                                             fechaVigencia:null,

    //                                                         })

const csvReport = {
    filename: 'ErroresMasivaDisponibilidad.csv',
    data: dataTableMasivaDisponibilidadErrores
};                                                         


    useEffect(() => {

        //toggleLoader(true)

        // getMasivaDisponibilidad({
            
        //     typeMasivaDisponibilidad: 'CargaMasivaDisponibilidad'
        // },
        // collection =>{

        //     toggleLoader(false)
        //     setDataTableMasivaDisponibilidadErrores(collection)
        //     //setDataTableMasivaDisponibilidad(collection)


        //  })

       
    },[])



    // const showModalUpdate = (original,values) =>{

    //             let IdEmpresa = original.idEmpresa
    //             let optionEmpresa = listEmpresasFront.find(E => E.value === IdEmpresa)
    //             setIsEmpresa(true)
    //     setDatasSaved({
    //             ...dataSaved,
    //             secuencia:original.secuencia,
    //             empresa:optionEmpresa,
    //             empresaValue:original.idEmpresa,
    //             cuenta:listCuentas.find(C => C.value === original.idCuenta),
    //             cuentaValue:original.idCuenta,
    //             importe:original.importe,
    //             nuevoImporte: original.nuevoImporte === 0 || original.nuevoImporte === null ? 0 : original.nuevoImporte,
    //             fechaVigencia:original.fechaVigencia,
    //             isSaved:false,

    //         })
    //         window.$('#modalMasivaDisponibilidadFront').modal('show')

    //         getFiltros({
    //             idUser:ExisteSession.getInfoUsuario().idUsuario,
    //             typeFilter:"Cuentas",
    //             idCompania:  original.idEmpresa
    //             }, collection =>{
    //             toggleLoader(false)

    //             setListCuentasC(collection)
    //         })
    // }

    // const showModalAdd = () =>{

    //     setIsEmpresa(false)
    //         setDatasSaved({
    //             ...dataSaved,
    //             empresa:[],
    //             empresaValue:0,
    //             cuenta:[],
    //             cuentaValue:0,
    //             importe:0,
    //             nuevoImporte:0,
    //             isSaved:true,
    //             secuencia:0,
    //             fechaVigencia:null,

    //         })
    //         setListCuentasC([])
    //         window.$('#modalMasivaDisponibilidadFront').modal('show')
    // }

    // const handleChange = (e,name) => {

    //     setDatasSaved({
    //         ...dataSaved,

    //         [name] : name === 'importe' ||  name === 'nuevoImporte' ? e.target.value : e

    //     })



    //     if(name === 'empresa'){
    //         toggleLoader(true)

    //         setDatasSaved({
    //             ...dataSaved,
    //             cuenta: null,
    //             empresa: e
    //         })
    //         getFiltros({
    //             idUser:ExisteSession.getInfoUsuario().idUsuario,
    //             typeFilter:"Cuentas",
    //             idCompania: e.value
    //             }, collection =>{
    //             toggleLoader(false)

    //             setListCuentasC(collection)

    //         })
    //     }

    // }



    // const clickSave = () => {

        
    //         toggleLoader(true)

    //             saveMasivaDisponibilidad({
    //                 typeMasivaDisponibilidad: 'CargaMasivaDisponibilidad',
    //                 idType:                 dataSaved.isSaved,
    //                 secuencia:              dataSaved.secuencia,
    //                 idEmpresa :             dataSaved.empresa.value,
    //                 idCuenta :              dataSaved.cuenta.value,
    //                 importe:                dataSaved.importe,
    //                 nuevoImporte:           dataSaved.nuevoImporte,
    //                 fechaVigencia:          dataSaved.fechaVigencia,
    //             },response =>{

    //                     getMasivaDisponibilidad({
    //                         typeMasivaDisponibilidad: 'CargaMasivaDisponibilidad',
    //                     }, collection =>{
    //                             setDataTableMasivaDisponibilidad(collection)
    //                             toggleLoader(false)
    //                             clickLimpia()

    //                         })
    //             })
       
    // }

    // const clickDelete = () => {
    //     toggleLoader(true)

    //     deleteMasivaDisponibilidad({

    //                 typeMasivaDisponibilidad: 'CargaMasivaDisponibilidad',
    //                 idType:                 dataSaved.isSaved,
    //                 secuencia:              dataSaved.secuencia,
    //                 idEmpresa :             dataSaved.empresa.value,
    //                 idCuenta :              dataSaved.cuenta.value,
    //                 importe:                dataSaved.importe,
    //                 nuevoImporte:           dataSaved.nuevoImporte,
    //                 fechaVigencia:          dataSaved.fechaVigencia,

    //     },response=>{
    //         getMasivaDisponibilidad({
    //             typeMasivaDisponibilidad: 'CargaMasivaDisponibilidad',
    //         }, collection =>{
    //                 setDataTableMasivaDisponibilidad(collection)
    //             })
    //             toggleLoader(false)
    //             clickLimpia()
    //     })
    // }


    const clickLimpia = () => {
            toggleLoader(true)


            getLimpiaMasivaDisponibilidad({
                            typeMasivaDisponibilidad: 'CargaMasivaDisponibilidad',
                        }, collection =>{
                                //setDataTableMasivaDisponibilidad(collection)
                                setDataTableMasivaDisponibilidadErrores(collection)                                                              
                                toggleLoader(false)

                            })


    }

    const downloadHelp = () => {
        
        toggleLoader(true)
        donwnloadHelpDocument({
                        typeMasivaDisponibilidad: 'CargaMasivaDisponibilidad',
                        archivo: 'Ayuda_CargaMasiva_Disponibilidad.csv',
                    }, collection =>{
                            
                            toggleLoader(false)

                        })


    }

    const downloadPlantilla = () => {
        
        toggleLoader(true)
        donwnloadHelpDocument({
                        typeMasivaDisponibilidad: 'CargaMasivaDisponibilidad',
                        archivo: 'DisponibilidadCuentasMasiva.csv',
                    }, collection =>{
                            
                            toggleLoader(false)

                        })


    }

    const subirArchivos=e=>{              
            setArchivos(e);        
    }



    const insertarArchivo = () => {
debugger;
        toggleLoader(true)        
        cargarArchivo({ 
            typeMasivaDisponibilidad: 'CargaMasivaDisponibilidad',
            
        },  {archivo: archivos,},
        collection =>{
            if(collection)
                setDataTableMasivaDisponibilidadErrores(collection)
            toggleLoader(false)       
            
            })
    }

    

    return (
        
        <Fragment>
             <Header title={inicializa.title + ' ' + Session.getInfoAmbiente()} breadcrum={inicializa.breadcrum}/>
            <div className="container">
                <div className="panel panel-primary animated fadeInUp">
                    <div className="panel-heading">
                        <div className="row">
                            <div className="col-md-6">
                                <span>Carga Masiva de Disponibilidad</span>
                            </div>
                            
                            <div className="col-md-6 btnF">
                                <Buttons
                                btnUpload={true} btnUploadAction={insertarArchivo} btnUploadText={" Cargar Archivo"} btnUploadShowIcon={true}
                                btnCancel={true} btnCancelAction={clickLimpia} btnCancelText={" Cancelar"} btnCancelShowIcon={true}                                
                                Buttons btnExport={true} btnExportAction={downloadPlantilla} btnExportText='Importar' btnExportShowIcon={true}
                                Buttons btnAyuda={true} btnAyudaAction={downloadHelp} btnAyudaText={'Ayuda'} btnAyudaShowIcon={true}/>
                            </div>
                            
                           
                        </div>
                        <div className="row">
                            <div className="col-md-12">                                
                                    <input type="file" name='files' onChange={(e) => subirArchivos(e.target.files)} accept=".csv" />                               
                            </div>
                        </div>

                    </div>
                    <div >                       
                    </div>
                    
                    <div className="nav nav-pills nav-justified ar-nav-pills center-block margin-bottom" style={{alignContent:'center', alignItems:'center', textAlign:'center'}}>
                        <div className="row">
                            <div className="col-md-11">
                                <span>Errores</span>
                            </div>
                            <div className="col-md-1 btnF">
                           
                            <CSVLink {...csvReport}><Buttons                               
                                Buttons btnExport={true} btnExportText='Descarga Errores' btnExportShowIcon={true} /></CSVLink>
                                
                            </div>
                        </div>
                    </div>
                    <div className="panel-body">
                             <GeneralTable
                                columns={columnsMasivaDisponibilidadErroresTbl}
                                data={dataTableMasivaDisponibilidadErrores}
                                action={undefined}
                                doubleClick={undefined}
                                
                             />
                    </div>
                </div>
            </div>
            
        </Fragment>
    );
}

export default CargaMasivaDisponibilidad;