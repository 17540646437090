import React,{Fragment} from 'react'
import Header from '../Components/Header'
import FormPolizas from '../Components/FormPolizas';
import Session from '../Application/session'

const FacturacionElectronica = () =>{
    const inicializa = {
        title : 'PÓLIZAS ' + Session.getInfoAmbiente(),
        breadcrum : [{nombre: 'Contabilidad'},{nombre: 'Generación de Pólizas'}]
    }

        return(
            <Fragment>
                <Header title={inicializa.title} breadcrum={inicializa.breadcrum}/>
                <div className="lx-container container">
                    <FormPolizas />
                </div>
            </Fragment>
        )
}

export default FacturacionElectronica