import React, { Fragment, useEffect, useState} from 'react';
import Buttons from '../Buttons/Buttons'




const ModalViewer = ({docTo}) =>{
    const [docToBase64,setdocToBase64] = useState(null)
    const [typeDocto,setTypeDocto] = useState('');
    const [fileName,setFileName]=useState('');
    const extensionesExcel = ['xlsx','xls'];
    const onExport=()=>{
        if(docToBase64!=null){
        const linkSource = docToBase64.Data[0].mime;
        const downloadLink = document.createElement("a");
        const fileName = docToBase64.Data[0].fileName;
    
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
        }
    }
    const cancel = () =>{
        setFileName('');
        setTypeDocto('')
        setdocToBase64(null,()=>{window.$('#modalViewer').modal('hide')});
        
        
    }
    const obtenerExtension=(nombreArchivo)=> {
        return nombreArchivo.slice((nombreArchivo.lastIndexOf('.') - 1 >>> 0) + 2);
      }
      
   
    useEffect(()=>{
        setdocToBase64(null);
        debugger;
        if(docTo!=null){
            const {Code,Data}=docTo;
            if(Data!=null){
                if(Data.length>0){
                    var file=Data[0].fileName;
                    setFileName(file);
                    file=obtenerExtension(file);
                    setTypeDocto(file.toString().toLowerCase());
                }
            }
        }
      setdocToBase64(docTo);
    },[docTo])
    if(typeDocto=='pdf'){
    return(
        <Fragment>
            <div className="modal fade" id="modalViewer" tabIndex="-1" role="dialog" aria-labelledby="modalViewer" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-lg" role="document" style={{ maxWidth: '80%' }}>
                    <div className="modal-content">
                        <div className="modal-header mhead">
                            <div className="row">
                            <div className="col-md-4 ">
                                </div>
                                <div className="col-md-4 ">
                                    <h5 className="modal-title text-center"  style={{color:'white',fontWeight:'bold'}}  id="modalDetalleLabel">Visor</h5>
                                </div>
                                <div className="col-md-4">
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="modal-body"> 
                        <div style={{ width: '100%', height: '80vh' }}>
                            {
                                docToBase64!=null?
                                <object
                                    data={docToBase64!=null?docToBase64.Data[0].mime:'#'}
                                    type="application/pdf"
                                    width="100%"
                                    height="100%"
                                >
                                    <p>El visor de PDF no puede mostrar este PDF.</p>
                                </object>
                                :<div></div>
                        }
                        </div>
                        </div>  
                        <div className="modal-footer">
                        <Buttons 
                                btnExport={true}
                                btnExportText={'Descargar'}
                                btnExportAction={onExport}
                                btnExportShowIcon={true} 
                                btnCancel={true}
                                btnCancelId='btnCancelar'
                                btnCancelText={'Cerrar'}
                                btnCancelShowIcon={true}
                                btnCancelAction={cancel}
                               
                                />
                        </div>  
                    </div>    
                </div>    
            </div>    
        </Fragment>
    )
    }
   
    if(typeDocto==''){
        return(
            <Fragment>
                <div className="modal fade" id="modalViewer" tabIndex="-1" role="dialog" aria-labelledby="modalViewer" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered modal-lg" role="document" style={{ maxWidth: '80%' }}>
                        <div className="modal-content">
                            <div className="modal-header mhead">
                                <div className="row">
                                    <div className="col-md-6">
                                    <h5 className="modal-title" id="modalDetalleLabel">Visor</h5>
                                    </div>
                                    <div className="col-md-6">
                                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-body"> 
                            <div style={{ width: '100%', height: '80vh' }}>
                            <p>El visor de PDF no puede mostrar este PDF.</p>
                            </div>
                            </div>  
                            <div className="modal-footer">
                                <Buttons 
                                btnExport={true}
                                btnExportText={'Descargar'}
                                btnExportAction={onExport}
                                btnExportShowIcon={true} 
                                btnCancel={true}
                                btnCancelId='btnCancelar'
                                btnCancelText={'Cerrar'}
                                btnCancelShowIcon={true}
                                btnCancelAction={cancel}
                               
                                />
                                 
                            </div>  
                        </div>    
                    </div>    
                </div>    
            </Fragment>
        )
    }
}
export default ModalViewer;