import React, {  useEffect, useState } from 'react';
import Buttons from '../../../Components/Buttons/Buttons'
import Select from 'react-select';

function ModalComponentes({clickAddComp,clickCloseComp,dataTableComponentes,infoComponente,clickDeleteC,typeModal}) {
    const [itemSelect, setItemSelect] = useState([])
    const [dataSelect, setDataSelect] = useState([])
    const [dataDesc, setDataDesc] = useState('')
    useEffect(() => {
        console.log(dataTableComponentes)
       
        if(typeModal){
            setDataDesc(infoComponente.Description)
            setItemSelect(infoComponente.TypeComp)
        }else{
            setDataDesc('')
            setItemSelect([])
        }
        let newdataSelect = []
        dataTableComponentes.forEach(item => {
            newdataSelect.push({'value': item.idTipoComp, 'label': item.descripcion})
        })
        setDataSelect(newdataSelect)
    },[dataTableComponentes,infoComponente,typeModal])
    return (
        <div className="modal fade" id="modalAddComponent" tabIndex="-1" role="dialog" aria-labelledby="modalAddComponentLabel" aria-hidden="true">
        <div className="modal-dialog modal-lg" role="document">
            <div className="modal-content">
                <div className="modal-header mhead">
                    <button onClick={clickCloseComp} type="button" className="close text-white" data-dismiss="modal" aria-label="Close">
                        {/* <span aria-hidden="true">&times;</span> */}
                        <i className="fa fa-times-circle"></i>
                    </button>
                    <span className="modal-title title-modal-custom-color title-modal-custom" id="modalAddComponentLabel">
                       {typeModal ? 'Edita Componente' : 'Agregar Componente'}
                    </span>
                </div>
                <div className="modal-body">
                    <div className="row">
                    <div className="col-md-2"></div>
                    <div className="col-md-8">
                        <div className="row">
                            <div className="col-md-4">Descripción</div>
                            <div className="col-md-8">
                                <input 
                                type="text" 
                                className="form-control"
                                onChange={e => setDataDesc(e.target.value)}
                                value={dataDesc}
                                />
                                </div>
                        </div><br />
                        <div className="row">
                            <div className="col-md-4">Tipo de componente</div>
                            <div className="col-md-8">
                            <Select
                                className=""
                                id="typeComponent"
                                isSearchable
                                name="typeComponent"
                                onChange={(e) => setItemSelect(e)}
                                options={dataSelect}
                                value={itemSelect}
                            />
                            </div>
                        </div>

                    </div>
                    <div className="col-md-2"></div>
                    </div>
                </div>
                <div className="modal-footer">


                    <Buttons
                        btnAdd={true} btnAddAction={() => clickAddComp({'Description': dataDesc, 'Component':itemSelect})} btnAddText={typeModal ? "Modificar" : "Agregar"} btnAddClass={"btn btn-primary"} btnAddShowIcon={true}
                        btnDelete={typeModal} btnDeleteAction={clickDeleteC} btnDeleteText={"Eliminar"} btnDeleteShowIcon={true}
                        btnCancel={true} btnCancelAction={clickCloseComp} btnCancelText={" Cancelar"} btnCancelShowIcon={true} />                  
                </div>
            </div>
        </div>
    </div >
    );
}

export default ModalComponentes;