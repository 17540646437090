import * as FileSaver from 'file-saver';
import XLSX from 'sheetjs-style';
// import Buttons from '../../../Components/Buttons/Buttons'
import Buttons from '../Buttons/Buttons'

const ExportExcel = ({ excelData, fileName,lisColumnaVisible }) => {
    const filetype = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    const fileExtencion = ".xlsx"

    const exportToExcel = async () => {
        let dataToExport = excelData;
        if (lisColumnaVisible && lisColumnaVisible.length > 0) {
            // Filtrar y renombrar las columnas basadas en lisColumnaVisible
            dataToExport = excelData.map(item => {
                let newItem = {};
                lisColumnaVisible.forEach(col => {
                    newItem[col.header] = item[col.key];
                });
                return newItem;
            });
        }

        const ws = XLSX.utils.json_to_sheet(dataToExport);
        const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], { type: filetype });
        FileSaver.saveAs(data, fileName + fileExtencion);
    }
    return (<>
        <Buttons btnExport={true} btnExportAction={exportToExcel} btnSaveText={"Exportar"} btnExportClass={"btn btn-primary"} btnExportShowIcon={true} />
    </>)
}

export default ExportExcel;