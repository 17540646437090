//#region Administracion de Vistas
const inicializa = {
    title : 'Administracion vistas',
    breadcrum : [{nombre: 'Administración'},{nombre: 'Vistas'}]
}
const tableColumns = [
    { 
        Header: "Descripción",
        accessor: 'descripcion',
    },
    { 
        Header: "Descripción corta",
        accessor: 'descripcionCorta',
    },
    { 
        Header: "Descripción menú",
        accessor: 'descripcionMenu',
    },
    { 
        Header: "Vista",
        accessor: 'descripcionTooltip',
    },
    { 
        Header: "Orden",
        accessor: 'orden',
    },
    { 
        Header: "Url",
        accessor: 'url',
    },
    { 
        Header: "Estatus",
        accessor: 'estatus',
    }
    
]



const tableData = []

//#endregion

//#region  Acciones
const inicializaAcciones = {
    title : 'Acciones',
    breadcrum : [{nombre: 'Acciones'},{nombre: 'Acciones'}]
}
const tableColumnsAcciones = [
    { 
        Header: "Identificador",
        accessor: 'idAccion',
    },
    { 
        Header: "Descripción",
        accessor: 'descripcion',
    }   
]


const tableDataAcciones = []
//#endregion

export { tableColumns, inicializa, tableData, inicializaAcciones,tableColumnsAcciones,tableDataAcciones }