import React, { Component, useState, useEffect,useRef } from 'react'
import { useTable, usePagination, useSortBy } from 'react-table'

const EditableRow = ({
    row,
    doubleClick, // This is a custom function that we supplied to our table instance
}) => {
    // We need to keep and update the state of the cell normally

    // console.log(row)
    // const onDoubleClick = () =>{
    //     actionDoubleClick(row)
    // }  

    return null
}

const EditableCell = ({
    value: initialValue,
    row: { index },
    column: { id },
    getSetData 
    //updateMyData, // This is a custom function that we supplied to our table instance
  }) => {
    // We need to keep and update the state of the cell normally
    const [value, setValue] = useState(initialValue)
    const textInput = useRef(null);
    const onChange = e => {
        // console.log('27 ==> e.target ',e.target)
        // console.log('28 ==> e.target.value ',e.target.value)
        // console.log('29 ==> id ',id)
       
        getSetData(index, id, e.target.value)
        setValue(e.target.value)
    }
    const onChangePorcent = e => {
        const esValido = e.target.validity.valid;
        if (esValido) {
        let value = '0';
        if(e.target.value.split('.').length < 3 ){
            
        e.target.value === '' ? value = '0' :
        e.target.value.substring(0,2) === '0.' ? value = e.target.value :
        e.target.value.substring(0,1) === '0' ? value = e.target.value.substring(1,3) :
        value = e.target.value
        if(value === '') value = '0'
        setValue(value)
        getSetData(index, id, value)
        }
         
        }

    }
    const handleClick = (e) => {
        textInput.current.focus();
    }
    // We'll only update the external data when the input is blurred
    const onBlur = () => {
        // console.log('34 ==> index ',index)
        // console.log('32 ==> id ',id)
        // console.log('33 ==> value ',value)
        //updateMyData(index, id, value)
    }
    // If the initialValue is changed external, sync it up with our state
    React.useEffect(() => {
        // console.log('41 =>',initialValue)
        setValue(initialValue)
    }, [initialValue])
    switch(id){
        case 'span':
            return <span>{value}</span>
        case 'cbxDynamic':
            return <span>{value}</span>
        case 'inputNumber':
            return <input type="number" value={value} style={{border:'none'}} onChange={onChange} onBlur={onBlur} />
        case 'inputPorcent':
            return<div onClick={handleClick} className="row">
                    {/* <div className="col-md-3"></div> */}
                    <div className="col-xs-3">
                    <input ref={textInput} value={value} style={{width: '40px', border:'none'}} pattern="[0-9\.?]{0,5}" onChange={onChangePorcent} onBlur={onBlur} />
                    </div>
                    <div className="col-xs-1">
                    <span>%</span>
                    </div>
                    
                </div>
        default:
            return <input value={value} style={{border:'none'}}  onChange={onChange} onBlur={onBlur} />
    }
    
  }

const defaultColumn = {
    // Cell: EditableCell,
    Row: EditableRow,
}
const defaultColumnC = {
    Cell: EditableCell,
    // Row: EditableRow,
}


function TableReact({ columns, data,isTableEditable, getSetData,doubleClick,isNotPagination, hiddenColumnsIDs, pageIndexEx, onChangePage,spanBtn,actionBtn,handleChangeChkAll,paramsCheckAll,handleChangeChkbx, compTbl,isCompTable}) {
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state: { pageIndex, pageSize },
    } = useTable(
        {
            columns,
            data,
            initialState: { pageIndex: onChangePage ? pageIndexEx : 0, hiddenColumns: hiddenColumnsIDs ? hiddenColumnsIDs : [] },
            defaultColumn :isTableEditable ? defaultColumnC : defaultColumn,
            getSetData
        },
        useSortBy,
        usePagination
    )

    const [statusAll, setStatusAll] = useState(false)
    // const [dataRowCheck, setDataRowCheck] = useState(null)

    useEffect(() => {
        if (data.length > 0) {
            if (data[0].CBXAll && statusAll) console.log('Hacer checkall false')
        }

        if(isNotPagination) setPageSize(Number(200))

    },[statusAll,data,setPageSize,isNotPagination])
    if(pageIndex !== pageIndexEx && onChangePage)
        onChangePage(pageIndex)

    const fnNextPag = setPage => {
    }
    const handleChekAll = isAll => {
        setStatusAll(isAll)
        handleChangeChkAll(isAll)

    }
    const handleChCkB = (row,status,origin) => {
        let indx = page.findIndex(r => r.id === origin.row.id)
        let newPage = [...page]
        newPage[indx].original.idEstatus = status
        // setDataRowCheck(origin.row.id +'-'+ status)
        handleChangeChkbx(row,status,origin)
        if(pageIndex > 0){
            setTimeout(()=>{gotoPage(pageIndex-1);},200)
            setTimeout(()=>{gotoPage(pageIndex);},200)
        }
        else{
            console.log("pageCount",pageCount);
            if(pageCount>0){
                setTimeout(()=>{gotoPage(pageIndex+1);},200)
                setTimeout(()=>{gotoPage(pageIndex);},200)
            }
        }
    }
    const changedat = () => { }
    // Render the UI for your table
  

    return (
        <>
            <table {...getTableProps()} className="table table-striped table-bordered nowrap">
                <thead>

                    {
                        headerGroups.map(headerGroup => (
                            <tr {...headerGroup.getHeaderGroupProps()}>

                                {headerGroup.headers.map((column, j) => (
                                    <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                                        {
                                            column.id === "CheckAll" ? (
                                                <div className={paramsCheckAll.classDivSection !== undefined ? paramsCheckAll.classDivSection : "border-checkbox-section"}>
                                                    <div className={paramsCheckAll.classDivCbxGroup !== undefined ? paramsCheckAll.classDivCbxGroup : "border-checkbox-group border-checkbox-group-success"} style={paramsCheckAll.padding !== undefined ? { padding: paramsCheckAll.padding } : {}}>
                                                        <input
                                                            onChange={e => handleChekAll(e.target.checked)}
                                                            checked={statusAll}
                                                            className={paramsCheckAll.classCbx !== undefined ? paramsCheckAll.classCbx : "border-checkbox"}
                                                            type="checkbox"
                                                            id={"chkAply"}
                                                        />
                                                        {paramsCheckAll.title !== undefined ?
                                                            <label className={paramsCheckAll.classLabel !== undefined ? paramsCheckAll.classLabel : "border-checkbox-label"}
                                                                htmlFor={"chkAply"}>{paramsCheckAll.title}</label> :
                                                            <label className={paramsCheckAll.classLabel !== undefined ? paramsCheckAll.classLabel : "border-checkbox-label"}
                                                                htmlFor={"chkAply"}>&nbsp;</label>

                                                        }
                                                    </div>
                                                </div>


                                            )
                                                : column.render('Header')
                                        }
                                        {/* Add a sort direction indicator */}
                                        <span>
                                            {column.isSorted
                                                ? column.isSortedDesc
                                                    ? <i className="zmdi zmdi-chevron-down"></i>
                                                    : <i className="zmdi zmdi-chevron-up"></i>
                                                : ''}
                                        </span>
                                    </th>
                                ))}
                            </tr>
                        ))}
                </thead>

                {

                    data.length > 0
                        ?
                        <tbody className="text-center" {...getTableBodyProps()}>

                            {page.map((row, i) => {
                                prepareRow(row)
                                // console.log('Row ',row)
                                return (
                                    <tr {...row.getRowProps()} onDoubleClick={e => doubleClick(row.original, row.values)}>
                                        {row.cells.map(cell => {
                                                        // console.log('176 ',cell.row.original)
                                                        // console.log('185  ',cell)
                                            
                                            return (
                                            
                                             cell.column.elipsis ?
                                                ( <td {...cell.getCellProps()}
                                                    style={{
                                                        maxWidth: cell.column.width,
                                                        whiteSpace: 'nowrap',
                                                        overflow: 'hidden',
                                                        textOverflow: 'ellipsis',
                                                        textAlign: 'left',
                                                        position: 'relative'
                                                    }}
                                                >
                                                    {cell.render('Cell')}
                                                </td>) :
                                                (
                                                <td {...cell.getCellProps()}>
                                                    { 
                                                        
                                                        cell.column.id === "btnAction" 
                                                        ?
                                                            <div key={i}  onClick={e => actionBtn(cell.row.values)}>{cell.column.label ? cell.column.label : "Detalle"}</div>                                                                                                                                   
                                                        :
                                                            cell.column.Header === "CheckRow" 
                                                        ?
                                                            <div key={i} className="border-checkbox-section" >
                                                                <div className="border-checkbox-group border-checkbox-group-success">
                                                                <input
                                                                    onChange={e => handleChCkB(cell.row.original, e.target.checked, cell)}
                                                                    checked={cell.row.original.idEstatus}
                                                                    className="border-checkbox"
                                                                    type="checkbox"
                                                                    id={"chkN" + cell.row.original.idVista + cell.row.original.idAccion}
                                                                />

                                                                <label className="border-checkbox-label"
                                                                    htmlFor={"chkN" + cell.row.original.idVista + cell.row.original.idAccion}>&nbsp;</label>
                                                                </div>
                                                            </div>                                                                                                                                   
                                                        :
                                                        cell.render('Cell')
                                                    } 
                                                </td>
                                                    )
                                            )

                                        })}
                                    </tr>
                                )
                            })}
                        </tbody>
                        :
                        <tbody className="text-center" {...getTableBodyProps()}>
                            <tr>
                                <td colSpan={columns.length}>
                                    NO SE ENCONTRO INFORMACIÓN
                                </td>
                            </tr>
                        </tbody>

                }


            </table>

            {
                /**paginacion */
            }
            {
            isNotPagination ? <></> :
            <div className="row">
                <div className="col-md-2">
                    <select className="form-control"
                        value={pageSize}
                        onChange={e => {
                            setPageSize(Number(e.target.value))
                        }}
                    >
                        {[10, 20, 30, 40, 50].map(pageSize => (
                            <option key={pageSize} value={pageSize}>
                                {pageSize}
                            </option>
                        ))}
                    </select>
                </div>

                <div className="dataTables_paginate paging_simple_numbers col-md-8 pagn" id="basic-btn_paginate">
                    <ul className="pagination stpagn">
                        <li onClick={e => fnNextPag(page)}
                            className={!canPreviousPage ? "paginate_button page-item previous disabled libtnI" : "paginate_button page-item previous libtnI"}>
                            <button aria-controls="basic-btn"
                                data-dt-idx="3"
                                tabIndex="0"
                                className="page-link btnIn" onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                                <i className="ti-angle-double-left">{"<<"}</i>
                            </button>{' '}
                        </li>
                        <li className={!canPreviousPage ? "paginate_button page-item previous disabled" : "paginate_button page-item previous"} id="basic-btn_previous">
                            <button
                                aria-controls="basic-btn"
                                data-dt-idx="0"
                                tabIndex="0"
                                className="page-link btnIn"
                                onClick={() => previousPage()} disabled={!canPreviousPage}>Anterior</button>
                        </li>
                        <li className="paginate_button page-item active">
                            {/* <span className="btnNumP">{pageIndex + 1}</span> */}
                            <input
                                //   type="button"
                                className="btnNumP"
                                value={pageIndex + 1}
                                onChange={changedat}


                            />

                            {/* <a href="!#" aria-controls="basic-btn" data-dt-idx="1" tabIndex="0" className="page-link">
                            </a> */}
                        </li>
                        <li className={!canNextPage ? "paginate_button page-item next disabled" : "paginate_button page-item next"} id="basic-btn_next">
                            <button
                                aria-controls="basic-btn"
                                data-dt-idx="2"
                                tabIndex="0"
                                className="page-link btnIn"
                                onClick={() => { nextPage() }} disabled={!canNextPage}>Siguiente</button>
                        </li>
                        <li
                            onClick={e => fnNextPag(page)}
                            className={!canNextPage ? "paginate_button page-item next disabled" : "paginate_button page-item next"}>
                            <button aria-controls="basic-btn"
                                data-dt-idx="4"
                                tabIndex="0"
                                className="page-link btnIn"
                                onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
                                <i className="ti-angle-double-right">{">>"}</i>
                            </button>
                        </li>
                    </ul>
                </div>
                <div className="dataTables_info col-md-2" id="basic-btn_info" role="status" aria-live="polite">
                    <span className="pull-right">
                        Página {' '}
                        <strong>
                            {pageIndex + 1} de {pageOptions.length}
                        </strong>{' '}
                    </span>
                </div>
            </div>
            
            
           }
           {
                isCompTable ? <div>{compTbl}</div> : <></>
            }
        </>
    )
}



export default class GeneralTable extends Component {

    constructor(props) {
        super(props)

        this.state = {
            displayedData: props.data,
            fromSearch: false,
            keyword: ""
        }
    }

    search = (keyword, data, columns) => {
        let newDisplayedData = []

        data.forEach(item => {
            for (const column of columns) {
                if (item[column.accessor]) {
                    if (item[column.accessor].toString().toUpperCase().includes(keyword.toUpperCase())) {
                        newDisplayedData.push(item);
                        break;
                    }
                }
            }
        });

        return newDisplayedData;
    }

    handleInputChange = e => {

        const { columns, data } = this.props
        const keyword = e.target.value

        let newDisplayedData = this.search(keyword, data, columns)

        this.setState({ displayedData: newDisplayedData, fromSearch: true, keyword: keyword })
    }
    static gatTez(prop,state){
        console.log('entra ok ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++',state)
        return null;

    }

    static getDerivedStateFromProps(props, state) {
        //  El componente recibe actualizaciones desde fuera, 
        //  por ello debemos asegurar que fromSearch se mantenga true cuando exista un valor de busqueda,
        //  de esa forma abra consistencia en el estado de la aplicacion
        if (state.fromSearch === true && state.keyword === "")
            return { fromSearch: false }

        //  cada vez que el componente es actualizado, displayedData adquiere el valor del prop,
        //  pero unicamente cuando no hay una busqueda activa, esto es para mantener el estado de la aplicacion
        if (props.data !== state.displayedData && state.keyword === "") {
            // console.log('++++ actualiza 3', props.data)
            return {
                displayedData: props.data
            }
        }

        // esto indica que habia una busqueda activa y se registro una alta, baja o cambio
        if (props.updateSearch && state.keyword !== "") {
            // console.log('++++ actualiza 2')
            let newDisplayedData = []

            props.data.forEach(item => {
                for (const column of props.columns) {
                    if (item[column.accessor]) {
                        if (item[column.accessor].toString().toUpperCase().includes(state.keyword.toUpperCase())) {
                            newDisplayedData.push(item);
                            break;
                        }
                    }
                }
            });

            return {
                displayedData: newDisplayedData
            }
        }

        return null;
    }

    render() {
        const { columns, doubleClick,getSetData, isTableEditable,hiddenColumnsIDs,isNotPagination, onChangePage, pageIndex,actionBtn,spanBtn,handleChangeChkAll ,paramsCheckAll,handleChangeChkbx,paramButton, buttonClick,isCompTable,compTbl,isCpmpBtn,compnnt} = this.props;
        const { displayedData } = this.state;
        // console.log('++++ actualiza 4', this.props.data)
   
        return (
            <>
                <div className="row">
                    <div className="col-md-5">
                        {paramButton !== undefined && <button type="button" onClick={buttonClick} className={paramButton.classBtn}>{paramButton.title}</button>}
                        {isCpmpBtn && compnnt()}
                    </div>
                    <div className="col-md-3"></div>
                    <div className="col-md-4 stySearch">
                        <input
                            className="form-control"
                            placeholder="Buscar"
                            type="text"
                            onChange={(e) => this.handleInputChange(e)}
                            ref={inputElement => this.inputSearch = inputElement}
                            value={this.state.inputSearch}

                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12 overflow-auto table-responsive">
                        <TableReact columns={columns} data={displayedData}
                            hiddenColumnsIDs={hiddenColumnsIDs}
                            pageIndexEx={pageIndex}
                            onChangePage={onChangePage}
                            doubleClick={doubleClick} action={this.props.doubleClick}
                            actionBtn={actionBtn}
                            handleChangeChkAll={handleChangeChkAll}
                            paramsCheckAll={paramsCheckAll !== undefined ? paramsCheckAll : []}
                            isTableEditable={isTableEditable}
                            isNotPagination={isNotPagination}
                            spanBtn={spanBtn}
                            getSetData={getSetData}
                            isCompTable={isCompTable}
                            compTbl={compTbl}
                            handleChangeChkbx={handleChangeChkbx}
                            />
                            {/* <br />
                            <br />
                            <br />
                            <br />  */}
                            
                    </div>
                </div>
            </>
        )
    }

}