import { getInfo, saveInfo, updateInfo, deleteInfo } from '../../../ApiRequests/req_CRUD'
import { ExisteSession } from '../../../ApiRequests/general'

const getMotivosCorreo = (request,addCollection) => {
    request = parametersReuest(request)
    getInfo("motivoscorreo",request,(collection) =>{
        addCollection(collection)
    })

}

const saveMotivosCorreo = (request,addCollection) => {
    request = parametersReuest(request)
    request.typeSaved ?
    saveInfo("motivoscorreo",request,(response,type,success) =>{
        addCollection({response,type,success})
    })
    :
    updateInfo("motivoscorreo",request,(response,type,success) =>{
        console.log(response,type,success)
        addCollection({response:response[0],type,success})
    })

}
const deleteMotivoscorreo = (request,deleteCollection) => {
    request = parametersReuest(request)
    deleteInfo("motivoscorreo",request,(response,type,success) =>{
        console.log(response,type,success)
        deleteCollection({response:response[0],type,success})
    })
}

const parametersReuest = request =>{
    request.idUsuarioAct = ExisteSession.getInfoUsuario().idUsuario
    request.token = ExisteSession.getInfoUsuario().token
    request.connection = ExisteSession.conexion()
    return request;
}
export{
    getMotivosCorreo,
    saveMotivosCorreo,
    deleteMotivoscorreo
}