import { getInfo,getInfoErase, downloadHelp,cargaArvhivo,saveInfo,updateInfo, deleteInfo } from '../../../ApiRequests/req_CRUD'
import { ExisteSession, toggleLoader } from '../../../ApiRequests/general'
import { fnNotification } from '../../../ApiRequests/Notification'


const getEmpresas = (request,_callBack) =>{
    request = prepareRequest(request)
    getInfo('Contabilidad/PagoImpuestos/GetEmpresas',request, (res) =>{
        _callBack(res)
    })
}
const getCuentas = (request,_callBack) =>{
    request = prepareRequest(request)
    getInfo('Catalogos/CatCuentasBancarias',request, (res) =>{
        _callBack(res)
    })
}
const _UpdateCuenta = (request,_callBack) =>{
    request = prepareRequest(request)
    debugger
    updateInfo('Catalogos/CatCuentasBancarias',request,(res,t,success) =>{
        console.log('request',request)
        console.log('res',res)
        console.log('t',t)
        console.log('success',success)
        debugger
        _callBack(res,t,success)
    })
}

const prepareRequest = request => {
    request.idUsuarioAct = ExisteSession.getInfoUsuario().idUsuario
    // request.token = ExisteSession.getInfoUsuario().token
    request.connection = ExisteSession.conexion()

    return request;
}
export { getEmpresas,getCuentas,_UpdateCuenta}