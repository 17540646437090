import React, {useState, useEffect} from 'react';
import Buttons from '../../../../Components/Buttons/Buttons';
import TabPerfiles from './TabPerfiles'
import TabVistaAccion from './TabVistaAccion'
import TabComponentes from './TabComponentes'
import {ExisteSession} from '../../../../ApiRequests/general'
// import { tableData } from '../../Usuarios/resources';


function ModalPerfilVistas({ saveProfiler, dataModal, actionClose, clickDelete, relVistaAcicon,relComponente }) {
    const [description,setDescription]        = useState('')
    const [isChecked,setIschecked]            = useState(false)
    const [isCheckedAll,setIscheckedAll]      = useState(false)
    const [isCheckAll,setIscheckAll]          = useState(false)
    const [idPerfil,setIdPerfil]              = useState(0)
    const [dataTable, setDataTable]           = useState([])
    const [dataTblOrigin, setDataTblOrigin]   = useState([])
    const [dataComponente, setDataComponente] = useState(relComponente)
    const [dataNewRel, setDataNewRel]         = useState([])
    useEffect(() =>{
        setDataTable(relVistaAcicon)
        // setDataTblOrigin(relVistaAcicon.filter(x => x.idPerfil === idPerfil && x.idEstatus === true))
        setDataTblOrigin(relVistaAcicon.filter(x => x.idEstatus === true))

    },[relVistaAcicon])
    const clickClose = () => {
        actionClose()
        setIschecked(false)
        setDescription('')
    }
    const handleChange = e => {
        setDescription(e.target.value)
    }
    let dataRel = []

    const handlechecked = e => {setIschecked(e.target.checked)}
    const clickSave = () => {
        let isType = dataModal.type

        if(isCheckedAll){
            if(isCheckAll){
                dataTblOrigin.forEach(element => {
                    let newRInx = dataNewRel.findIndex(x => x.idPerfil === element.idPerfil && x.idVista === element.idVista &&  x.idAccion === element.idAccion)
                    dataNewRel.splice(newRInx,1)
                })              
            }
        }

        let dataSave = {
            idPerfil:idPerfil,
            descripcion: description,
            idEstatus: isChecked,
            ListRelPerfilVistaTbl: dataNewRel,
            isType:isType,
            filtro_ambiente : ExisteSession.getAmbiente().idAmbiente,
            idUsuarioAct : ExisteSession.getInfoUsuario().idUsuario,
            token : ExisteSession.getInfoUsuario().token,
            connection : ExisteSession.conexion(),
        }        
        saveProfiler(dataSave, () => {
            setDataNewRel([])
            setIscheckedAll(false)
        })
    }
    const clickDeleteLocal = () => {clickDelete({idPerfil})}
    useEffect(() => {
        if(!dataModal.type){
            setIdPerfil(dataModal.idPerfil)
            setIschecked(dataModal.idEstatus)
            setDescription(dataModal.descripcion)
        }
    },[dataModal])
    const handleChangeChkAll = (status) => {
        setIscheckedAll(true)
        setIscheckAll(status)
        let newRelData = []
        let dataAux = [...dataTable]
        dataAux.forEach(element =>{ 
            element.idEstatus = status
            if(status) newRelData.push({'idPerfil': idPerfil,'idVista' :element.idVista,'idAccion': element.idAccion,'Estatus': status})
            else if(element.idPerfil > 0) newRelData.push({'idPerfil': idPerfil,'idVista' :element.idVista,'idAccion': element.idAccion,'Estatus': status})  
        })
        setDataNewRel(newRelData)
    }
    const handleChangeChkbx = (rowVal,statusRow,_rows) => {
        let rowP = dataNewRel.find(x => x.idVista === rowVal.idVista &&  x.idAccion === rowVal.idAccion)
        let newRInx = dataNewRel.findIndex(x => x.idPerfil === dataModal.idPerfil && x.idVista === rowVal.idVista &&  x.idAccion === rowVal.idAccion)
        if(rowP === undefined){
        dataRel.push(dataModal.idPerfil)
        dataNewRel.push({'idPerfil': dataModal.idPerfil,'idVista' :rowVal.idVista,'idAccion': rowVal.idAccion,'Estatus': statusRow})
        }
        else{  
            if(isCheckedAll){
                    let newData = dataTable.find(x => x.idPerfil === idPerfil && x.idVista === rowVal.idVista && x.idAccion === rowVal.idAccion)
                    if(rowVal.idEstatus){
                        if(isCheckAll) dataNewRel.push({'idPerfil': dataModal.idPerfil,'idVista' :rowVal.idVista,'idAccion': rowVal.idAccion,'Estatus': statusRow})
                        else dataNewRel.splice(newRInx,1)
                        
                    }else{
                        newData ? dataNewRel[newRInx].Estatus = false : dataNewRel.splice(newRInx,1)                  
                    }                          
            }else{
                let newRInx = dataNewRel.findIndex(x => x.idPerfil === dataModal.idPerfil && x.idVista === rowVal.idVista &&  x.idAccion === rowVal.idAccion && rowVal.idEstatus === !statusRow)
                dataNewRel.splice(newRInx,1)
            }
        }
    }
    const handleChangeChk = (rowVal,statusRow,_rows) => {
        let rowP = relVistaAcicon.find(x => x.idEstatus === statusRow && x.idVista === rowVal.idVista &&  x.idAccion === rowVal.idAccion)
        if(rowP === undefined){
            dataRel.push(dataModal.idPerfil)
            dataNewRel.push({'idPerfil': dataModal.idPerfil,'idVista' :rowVal.idVista,'idAccion': rowVal.idAccion,'Estatus': statusRow})
            
        }else{
            let newRInx = dataNewRel.findIndex(x => x.idPerfil === dataModal.idPerfil && x.idVista === rowVal.idVista &&  x.idAccion === rowVal.idAccion && rowVal.idEstatus === !statusRow)
            dataNewRel.splice(newRInx,1)
        }
    }
    const changeChkComp = (rowVal,statusRow,_rows) =>{
    }
    return (
        <div className="modal fade" id="modalPerfilesVistas" tabIndex="-1" role="dialog" aria-labelledby="modalPerfilesVistas" aria-hidden="true">
            <div className="modal-dialog modal-lg" role="document">
                <div className="modal-content">
                    <div className="modal-header mhead">
                    <button onClick={clickClose} type="button" className="close text-white" data-dismiss="modal" aria-label="Close">
                            {/* <span aria-hidden="true">&times;</span> */}
                            <i className="fa fa-times-circle"></i>
                        </button>
                        <span className="modal-title title-modal-custom-color title-modal-custom" id="modalAdministradorVistaLabel">
                            {!dataModal.type  ? "Editar Perfil " : "Agregar Perfil"}
                        </span>                       
                    </div>
                    <div className="modal-body">
                        
                                <div className="col-md-12">
                                    <ul className="nav nav-pills nav-justified ar-nav-pills center-block margin-bottom">
                                        <li className="active">
                                            <a href="#Perfiles" data-toggle="tab" aria-expanded="true">
                                                <i className="fa fa-user"></i> Perfiles
                                            </a>
                                        </li>
                                        <li className="">
                                            <a href="#Vista-Accion" data-toggle="tab" aria-expanded="false">
                                                <i className="fa fa-code"></i> Vista-Accion
                                            </a>
                                        </li>
                                        <li className="">
                                            <a href="#Vista-Componentes" data-toggle="tab" aria-expanded="false">
                                                <i className="fa fa-codepen"></i> Vista-Componentes
                                            </a>
                                        </li>
                                    </ul>

                                    <div className="tab-content margin-top">
                                        <div className="tab-pane active" id="Perfiles">
                                            <TabPerfiles handleChange={handleChange} description={description} dataModal={dataModal} handlechecked={handlechecked} isChecked={isChecked}/>
                                        </div>
                                        <div className="tab-pane" id="Vista-Accion">
                                            <TabVistaAccion relVistaAcicon={dataTable} handleChangeChk={handleChangeChk} handleChangeChkAll={handleChangeChkAll} handleChangeChkbx={handleChangeChkbx}  />
                                        </div>
                                        <div className="tab-pane" id="Vista-Componentes">
                                            <TabComponentes dataComponente={relComponente} changeChkComp={changeChkComp} />
                                        </div>
                                    </div>

                                </div>
                                
                    </div>
                    <div className="modal-footer">
                        <Buttons
                            btnSave={true} btnSaveAction={clickSave} btnSaveText={!dataModal.type ? "Guardar cambios" : " Guardar"}  btnSaveShowIcon={true} typeSaved={dataModal.type}
                            btnDelete={!dataModal.type} btnDeleteAction={clickDeleteLocal} btnDeleteText={" Eliminar"} btnDeleteShowIcon={true}
                            btnCancel={true} btnCancelAction={clickClose} btnCancelText={" Cancelar"} btnCancelShowIcon={true}/>
                                                    
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ModalPerfilVistas;