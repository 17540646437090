import React, { useState, useEffect, Fragment } from 'react';
import { tableColumns, inicializa } from './resources'
import { getTypeCompanies, saveTypeCompanies, deleteTypeCompanies } from './service'
import GeneralTable from '../../../Components/GeneralTable';
import Header from '../../../Components/Header';
import Session from '../../../Application/session'
import Buttons from '../../../Components/Buttons/Buttons'
import ModalTipoCompanias from './ModalTipoCompanias'
import { fnNotification } from '../../../ApiRequests/Notification'

const CatTipoCompanias = () => {
    const [dataCompanies, setDataCompanies] = useState([])
    const [isUpdate, setIsUpdate] = useState(false)
    const [idTipoCompania, setIdTipoCompania] = useState('')
    const [codigo, setCodigo] = useState('')
    const [descripcion, setDescription] = useState('')
    const [idEstatus, setIdEstatus] = useState(false)


    useEffect(() => {
        getTypeCompanies({}, collection => {
            setDataCompanies(collection)
        })

    }, [])
    const showModal = () => {
        setIsUpdate(false)
        window.$('#modalTipoComapia').modal('show')

    }
    const handleDoubleClick = (originals, tableValues) => {
        console.log('originals ', originals)
        console.log('tableValues ', tableValues)
        setIdTipoCompania(originals.idTipoCompania)
        setCodigo(originals.codigoTipoCompania)
        setDescription(originals.descripcion)
        setIdEstatus(originals.idEstatus)
        window.$('#modalTipoComapia').modal('show')
        setIsUpdate(true)

    }
    const clickClose = () => {
        window.$('#modalTipoComapia').modal('hide')
        setCodigo('')
        setDescription('')
        setIdEstatus(false)
    }

    const clickSave = () => {
        if (codigo && descripcion) {
            saveTypeCompanies({
                typeSaved: !isUpdate,
                idTipoCompania: parseInt(idTipoCompania ? idTipoCompania : "0"),
                codigoTipoCompania: codigo,
                descripcion: descripcion,
                idEstatus: idEstatus,
            }, ({ response, type, success }) => {
                if (success) {
                    if (type === 'save') {
                        let newCollection = [...dataCompanies];
                        newCollection.push(response)
                        setDataCompanies(newCollection)
                    }
                    if (type === 'update') {
                        let newCollection = [...dataCompanies];
                        let indx = newCollection.findIndex(x => x.idTipoCompania === response.idTipoCompania)
                        newCollection.splice(indx, 1)
                        newCollection.push(response)
                        newCollection.sort((a, b) => {
                            if (a.name > b.name) return 1;
                            if (a.idTipoCompania < b.idTipoCompania) return -1;
                            return 0;
                        })
                        console.log('newCollection ', newCollection)
                        setDataCompanies(newCollection)
                    }
                    clickClose()
                }
            })
        }
        else{            
            fnNotification(['Error: '+ (!codigo ? " El codigo es necesario. ":"" ) + (!descripcion ? " La descripcion es necesaria.":""), 'error', true])
        }
    }
    const clickDelete = () => {
        console.log('Borrar registro')
        deleteTypeCompanies({
            idTipoCompania: idTipoCompania
        }, ({ response, type, success }) => {
            if (success) {
                let newCollection = [...dataCompanies];
                let indx = newCollection.findIndex(x => x.idTipoCompania === response.idTipoCompania)
                console.log('88 indx ', indx)
                newCollection.splice(indx, 1)
                console.log('90 newCollection ', newCollection)
                setDataCompanies(newCollection)
                clickClose()
            }
        })
    }
    const handleChange = (e) => {
        if (e.target.name === 'codigo') setCodigo(e.target.value)
        if (e.target.name === 'descripcion') setDescription(e.target.value)
        if (e.target.name === 'Estatus') setIdEstatus(e.target.checked)
    }
    return (
        <Fragment>
            <Header title={inicializa.title + ' ' + Session.getInfoAmbiente()} breadcrum={inicializa.breadcrum} />
            <div className="lx-container container">
                <div className="panel panel-primary animated fadeInUp">
                    <div className="panel-heading">
                        <div className="row">
                            <div className="col-md-6">
                                <span>Catalogo de tipo compañias</span>

                            </div>
                            <div className="col-md-6 btnF">
                                <Buttons btnAdd={true} btnAddAction={showModal} btnAddText={'Agregar'} btnAddShowIcon={true} />
                            </div>

                        </div>
                    </div>
                    <div className="panel-body">
                        <GeneralTable
                            columns={tableColumns}
                            // updateSearch={updateTableSearch} 
                            data={dataCompanies}
                            action={undefined}
                            doubleClick={handleDoubleClick}
                        />
                    </div>
                </div>
            </div>
            <ModalTipoCompanias
                isUpdate={isUpdate}
                clickClose={clickClose}
                clickDelete={clickDelete}
                clickSave={clickSave}
                handleChange={handleChange}
                codigo={codigo}
                descripcion={descripcion}
                idEstatus={idEstatus}
            />
        </Fragment>

    );
};

export default CatTipoCompanias;