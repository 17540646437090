
var Session = {
    getInfoUsuario: function(){
        let objSesionUsuario = JSON.parse(localStorage.getItem('Session_Usuario'));
        return objSesionUsuario;
    },
    getInfoAmbiente: () => {
        let idAmbiente = JSON.parse(localStorage.getItem('Ambiente')).idAmbiente
        let ambientes = JSON.parse(localStorage.getItem('ambientes'))
        return ambientes.find(x => x.idAmbiente === idAmbiente).ambiente
    },
    remove: function(){
        localStorage.removeItem('ambientes');
        localStorage.removeItem('conexion');
        localStorage.removeItem('sian');
        localStorage.removeItem('Session_Usuario');
        localStorage.removeItem('Ambiente');

    }

}

export default Session