import React, { useEffect } from 'react';
import Session from '../Application/session'
import {ExisteSession} from '../ApiRequests/general'
import { HandlerSession } from '../Config/HandlerSession';
import { getInfo } from '../ApiRequests/req_CRUD';

const Inicio = () =>{
    const {nombre} = Session.getInfoUsuario();
    const ambiente = Session.getInfoAmbiente();
    const menuN = ExisteSession.getMenu();
    const permisos = ExisteSession.getPermisos();
    useEffect(() => {
        getInfo("Catalogos/catCriterios", {
            criterio: "SESIONWEB",
            filtro_ambiente: ExisteSession.getAmbiente().idAmbiente,
            idUsuarioAct: ExisteSession.getInfoUsuario().idUsuario,
            token: ExisteSession.getInfoUsuario().token,
            connection: ExisteSession.conexion()
        }, (response) => {            
            if (response && response[0].valorAdicional) {
                localStorage.setItem("setTimeLogin", JSON.stringify(response[0]));
            }
        });
    }, [])
    // console.log(menuN);
    // console.log(permisos);
            
            return (
            <div className="container">
                <div className="row mt20">
                    <div className="col-xs-12">
                        <br />
                        <h1 className="htitle slogan text-center animated bounceInLeft animation-delay-12" >
                            BIENVENIDO {nombre}
                            
                        </h1>
                        <h2 className="environment htitle slogan text-center animated bounceInLeft animation-delay-12">
                           Ambiente {ambiente}
                        </h2>
                        
                    </div>
                    
                    <div className="col-md-8 col-md-offset-2">
                        <section className="animated fadeInDown animation-delay-8 ">
                            <img src="assets/img/SIAN_LOGO-01.png" className="img-responsive imageborder" alt="SIAN" />                            
                        </section>
                    </div>
                </div>
            </div>

        )
}
export default Inicio;

