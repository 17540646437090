import {APISeguridad, APISIAN, Cryp,toggleLoader} from './general';
import axios from 'axios';
import Session from '../Application/session'
import {fnNotification} from '../ApiRequests/Notification'
import { HandlerSession } from '../Config/HandlerSession';


//Función que valida Usuario y Contraseña en BD de Seguridad
var resp = null;
var respA = null;
function ValidaLogin (paramObjLogin, _callback){

    resp = []

    var datos = Cryp.encriptar(paramObjLogin),
    bodyFormData = new FormData();
    bodyFormData.set('data', datos);
    
    localStorage.removeItem('Session_Usuario');
    localStorage.removeItem('ambientes');
    localStorage.removeItem('conexion');
    localStorage.removeItem('Ambiente');
    localStorage.removeItem('sian');

    axios({
        method: 'post',
        url: APISeguridad +'login/validar',
        data: bodyFormData
    }).then(function (response) {
        if(response.status === 200){
            if(response.data.code !== 200){
                toggleLoader(false)
                fnNotification(['Error : '+response.data.message,'error',true])
            }else{
                console.log(response.data.data[0])
                var datosSession = response.data.data[0];
                localStorage.setItem('Session_Usuario', JSON.stringify(datosSession));
                HandlerSession.setLogin(datosSession);
                let arrayAplicaciones = response.data.data[0].aplicaciones;
                let objAPP = arrayAplicaciones.find(x => x.aplicacion.toUpperCase() === 'SIAN WEB');

                resp.push({LoginOk : true})
                if(objAPP === undefined || objAPP === null){
                    fnNotification(['USUARIO NO TIENE ACCESO A SIAN','error',true])
                    
                    Session.remove();
                    resp.push(null)
                }else{
                    let objUsuario = { idAplicacion: objAPP.idAplicacion, 
                                    idUsuarioAct: datosSession.idUsuario, 
                                    token: datosSession.token}

                    // getAmbientes(objUsuario);
                    resp.push(objUsuario)
                    
                }
            }
        }else{
            fnNotification(['Error: '+response.statusText,'error',true])
        }
    }).catch(function (error) {
    }).then(() => _callback(resp));
}
function ValidaLogin2 (paramObjLogin, _callback){
    resp = []

    var datos = Cryp.encriptar(paramObjLogin),
    bodyFormData = new FormData();
    bodyFormData.set('data', datos);
    
    localStorage.removeItem('Session_Usuario');
    localStorage.removeItem('ambientes');
    localStorage.removeItem('conexion');
    localStorage.removeItem('Ambiente');
    localStorage.removeItem('sian');
    var datosSession = {
        "idUsuario": "1",
        "claveUsuario": "admin@triplei.mx",
        "nombre": "ADMINISTRADOR",
        "aP": "ADMINISTRADOR",
        "aM": "admin@triplei.mx",
        "idTipoUsuario": null,
        "correo": "emmanuel.mendoza@3ti.mx",
        "idOrigen": "1",
        "descConexion": "Web",
        "token": "AF276C53CD0C47029D3876690",
        "rfc": "_",
        "telefono": null,
        "cambiarPassword": "0",
        "aplicaciones": [
            {
                "idAplicacion": "58",
                "aplicacion": "Expedientes"
            },
            {
                "idAplicacion": "11",
                "aplicacion": "Maniobras"
            },
            {
                "idAplicacion": "6",
                "aplicacion": "Notificaciones"
            },
            {
                "idAplicacion": "17",
                "aplicacion": "Prestamos"
            },
            {
                "idAplicacion": "4",
                "aplicacion": "Reclutamiento y Selección"
            },
            {
                "idAplicacion": "38",
                "aplicacion": "SIAN WEB"
            },
            {
                "idAplicacion": "40",
                "aplicacion": "SICCORP"
            },
            {
                "idAplicacion": "51",
                "aplicacion": "SICCORP_TMP"
            },
            {
                "idAplicacion": "35",
                "aplicacion": "Soporte Integrado"
            },
            {
                "idAplicacion": "34",
                "aplicacion": "Team"
            }
        ]
    }
    console.log('datosSession',datosSession)
    localStorage.setItem('Session_Usuario', JSON.stringify(datosSession));
    HandlerSession.setLogin(datosSession);

    let objUsuario = { idAplicacion: 38, 
        idUsuarioAct: 1, 
        token: 'F3C75BB9473C4250B42324997'}
    resp.push({LoginOk : true})
    resp.push(objUsuario)
_callback(resp)

    // axios({
    //     method: 'post',
    //     url: APISeguridad +'login/validar',
    //     data: bodyFormData
    // }).then(function (response) {
    //     if(response.status === 200){
    //         if(response.data.code !== 200){
    //             toggleLoader(false)
    //             fnNotification(['Error : '+response.data.message,'error',true])
    //             HandlerSession.setLogin('0');

    //         }else{
    //             var datosSession = response.data.data[0];
    //             localStorage.setItem('Session_Usuario', JSON.stringify(datosSession));
    //             HandlerSession.setLogin(datosSession);
    //             let arrayAplicaciones = response.data.data[0].aplicaciones;
    //             let objAPP = arrayAplicaciones.find(x => x.aplicacion.toUpperCase() === 'SIAN WEB');
    //             resp.push({LoginOk : true})
    //             if(objAPP === undefined || objAPP === null){
    //                 fnNotification(['USUARIO NO TIENE ACCESO A SIAN','error',true])
                    
    //                 Session.remove();
    //                 resp.push(null)
    //             }else{
    //                 let objUsuario = { idAplicacion: objAPP.idAplicacion, 
    //                                 idUsuarioAct: datosSession.idUsuario, 
    //                                 token: datosSession.token}

    //                 // getAmbientes(objUsuario);
    //                 resp.push(objUsuario)
                    
    //             }
    //         }
    //     }else{
    //         fnNotification(['Error: '+response.statusText,'error',true])
    //     }
    // }).catch(function (error) {
    // }).then(() => _callback(resp));
}
function getAmbientes2(paramObjUsuario, _CallbackAmbientes){
    var datos = Cryp.encriptar(paramObjUsuario),
    bodyFormData = new FormData();
    bodyFormData.set('data', datos);
    let amb = [
        {
            "idAmbiente": "1",
            "ambiente": "Ambiente Desarrollo"
        },
        {
            "idAmbiente": "4",
            "ambiente": "Ambiente SIANDEV CLOUD"
        }
    ]
    
    localStorage.setItem('ambientes', JSON.stringify(amb));
    // respA.push({'resp' : "Ok"})
    _CallbackAmbientes(resp)
    // axios({
    //     method: 'post',
    //     url: APISeguridad +'login/ambientes',
    //     data: bodyFormData
    // }).then(function (response) {
    //     if(response.status === 200){
    //         if(response.data.code !== 200){
    //             toggleLoader(false)
    //             fnNotification(['Error: '+response.data.message,'error',true])
    //         }else{
    //             if(response.data.code === 200){
    //                 let ambientes = response.data.data;
    //                 console.log(ambientes)
    //                 console.log(datos)
    //                 localStorage.setItem('ambientes', JSON.stringify(ambientes));
    //                 respA.push({resp : "Ok"})
    //             }else{
    //                 fnNotification(['Error: '+response.data.message,'error',true])

    //             }        
    //         }
    //     }else{
    //         fnNotification(['Error: '+response.statusText,'error',true])
    //     }
    // }).catch(function (error) {
    // }).then(() => _CallbackAmbientes(respA));
}

function getAmbientes(paramObjUsuario, _CallbackAmbientes){
    var datos = Cryp.encriptar(paramObjUsuario),
    bodyFormData = new FormData();
    bodyFormData.set('data', datos);
    axios({
        method: 'post',
        url: APISeguridad +'login/ambientes',
        data: bodyFormData
    }).then(function (response) {
        if(response.status === 200){
            if(response.data.code !== 200){
                toggleLoader(false)
                fnNotification(['Error: '+response.data.message,'error',true])
            }else{
                if(response.data.code === 200){
                    let ambientes = response.data.data;
                    console.log(ambientes)
                    console.log(datos)
                    localStorage.setItem('ambientes', JSON.stringify(ambientes));
                    respA.push({resp : "Ok"})
                }else{
                    fnNotification(['Error: '+response.data.message,'error',true])

                }        
            }
        }else{
            fnNotification(['Error: '+response.statusText,'error',true])
        }
    }).catch(function (error) {
    }).then(() => _CallbackAmbientes(respA));
}

function getAmbiente(paramObjAmbiente,_CallbackAmbiente){
    var datos = Cryp.encriptar(paramObjAmbiente),
    bodyFormData = new FormData();
    bodyFormData.set('data', datos);
    console.log(paramObjAmbiente.typeRequest);
    var _responseData = null;

    debugger

    axios({
        method: 'post',
        url: APISeguridad +'login/ambSelect',
        data: bodyFormData
    }).then(function (response) {
    if(response.data.code !== 200){
        // toggleLoader(false)
        fnNotification(['Error: '+response.data.message,'error',true])
    }else{
        if(response.status === 200){
            if(response.data.code === 200){
                let cnxCrypt = Cryp.encriptar(response.data.data[0]);
                localStorage.setItem('conexion', cnxCrypt);                
                localStorage.setItem('Ambiente',JSON.stringify(paramObjAmbiente))
                if(paramObjAmbiente.typeRequest) localStorage.setItem('sian', true);
                console.log(response.data)
                _responseData = response.data;
                console.log(paramObjAmbiente.typeRequest);
                console.log(response.data.data);
                console.log(response.data.data[0]);

                debugger
                // toggleLoader(false)
                // window.location.replace(`${process.env.PUBLIC_URL}/Inicio`);
                // setTimeout(function(){ window.location.replace(`${process.env.PUBLIC_URL}/Inicio`); });

                //validaUsuarioSIAN(paramObjAmbiente, cnxCrypt);
            }else{
                fnNotification(['Error: '+response.data.message,'error',true])
            }        
        }else{
            fnNotification(['Error: '+response.statusText,'error',true])
        }
    }
    }).catch(function (error) {
    }).then(() => _CallbackAmbiente(_responseData));;
}
function getAmbiente2(paramObjAmbiente,_CallbackAmbiente){
    var datos = Cryp.encriptar(paramObjAmbiente),
    bodyFormData = new FormData();
    bodyFormData.set('data', datos);
    console.log(paramObjAmbiente.typeRequest);
    const dtCnns = {
        "URL": "",
        "baseDeDatos": "SIAN_GASTOS",
        "direccionServidor": "192.168.14.50\\Desarrollo",
        "idUsuarioBd": "USUARIO001",
        "passwordBd": "1898EA03C41C4BF",
        "puerto": "1433"
    }
    let cnxCrypt = Cryp.encriptar(dtCnns);

    var _responseData = {
        "code": 200,
        "message": "ok",
        "count": 1,
        "data": [
            {
                "URL": "",
                "baseDeDatos": "SIAN_GASTOS",
                "direccionServidor": "192.168.14.50\\Desarrollo",
                "idUsuarioBd": "USUARIO001",
                "passwordBd": "1898EA03C41C4BF",
                "puerto": "1433"
            }
        ]
    };
    localStorage.setItem('conexion', cnxCrypt);                
    localStorage.setItem('Ambiente',JSON.stringify(paramObjAmbiente))
    localStorage.setItem('sian', true);
    debugger
    _CallbackAmbiente(_responseData)
    // axios({
    //     method: 'post',
    //     url: APISeguridad +'login/ambSelect',
    //     data: bodyFormData
    // }).then(function (response) {
    // if(response.data.code !== 200){
    //     // toggleLoader(false)
    //     fnNotification(['Error: '+response.data.message,'error',true])
    // }else{
    //     if(response.status === 200){
    //         if(response.data.code === 200){
    //             let cnxCrypt = Cryp.encriptar(response.data.data[0]);
    //             localStorage.setItem('conexion', cnxCrypt);                
    //             localStorage.setItem('Ambiente',JSON.stringify(paramObjAmbiente))
    //             if(paramObjAmbiente.typeRequest) localStorage.setItem('sian', true);
    //             _responseData = response.data;
    //             console.log(paramObjAmbiente.typeRequest);
    //             console.log(response.data.data);
    //             console.log(response.data.data[0]);

    //             debugger
    //             // toggleLoader(false)
    //             // window.location.replace(`${process.env.PUBLIC_URL}/Inicio`);
    //             // setTimeout(function(){ window.location.replace(`${process.env.PUBLIC_URL}/Inicio`); });

    //             //validaUsuarioSIAN(paramObjAmbiente, cnxCrypt);
    //         }else{
    //             fnNotification(['Error: '+response.data.message,'error',true])
    //         }        
    //     }else{
    //         fnNotification(['Error: '+response.statusText,'error',true])
    //     }
    // }
    // }).catch(function (error) {
    // }).then(() => _CallbackAmbiente(_responseData));;
}

function validaUsuarioSIAN(objUsuario, cnx){
    axios({
        method: 'post',
        url: APISIAN +'seguridad/validar',
        data: { objUsuario , cnx }
    }).then(function (response) {
        if(response.status === 200){
            if(response.data.code !== 200){
                fnNotification(['Error: '+response.data.message,'error',true])
            }else{
                setTimeout(function(){ window.location.replace(`${process.env.PUBLIC_URL}/Inicio`); });
            }
        }else{
            fnNotification(['Error: '+response.statusText,'error',true])
        }
    }).catch(function (error) {
        fnNotification(['Error: '+error.message,'error',true])

    });
}

export {ValidaLogin, getAmbientes, getAmbiente, validaUsuarioSIAN,ValidaLogin2,getAmbientes2,getAmbiente2}