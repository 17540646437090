import React, {Fragment, useState}from 'react';
import { BrowserRouter as Router} from 'react-router-dom';
import ItemMenuNav from './ItemMenuNav'
import Menu from './Menu'
import Session from '../../Application/session'
import {ExisteSession} from '../../ApiRequests/general'
const MenuNavBar = () =>{
    const [styleBtn, setStyleBtn] = useState('icon-ar icon-ar-white icon-ar-inverse icon-ar-circle')
    const [refreshM, setRefreshM] = useState(null)
    const handleClickRefresh = nAl => {
        // this.setState({sidebar: !this.state.sidebar});
        console.log(refreshM)
        setRefreshM(nAl)
    }
    const exit = e =>{
        e.preventDefault()
        Session.remove();
        setTimeout(function(){ window.location.replace(`${process.env.PUBLIC_URL}/`); }, 100);
    }
    const onclickItem = (id) =>{
        // e.preventDefault() 
    }
    const onMouseE = () => setStyleBtn('icon-ar icon-ar-white icon-ar-square icon-ar-inverse') 
    const onMouseD = () => setStyleBtn('icon-ar icon-ar-white icon-ar-inverse icon-ar-circle')
    return (
        <Fragment>
            <Router>
                <nav className="navbar navbar-default navbar-dark yamm navbar-static-top" role="navigation" id="header">
                    <div className="container">                 
                        {/*                     
                        <div className="pull-right">                           
                            <a href="#!" id="btnClickSlideBar" 
                            className="sb-icon-navbar s-toggle-right"
                            onClick={handleClickSideBar}>
                            </a>
                        </div> */}
                        <div className="collapse navbar-collapse" id="bs-example-navbar-collapse-1">
                            <div className="pull-left" >   
                                <a id="ar-brand" className="navbar-brand" href="/Inicio">
                                        <img 
                                        src={`${process.env.PUBLIC_URL}/assets/img/logoSIAN/letra40.png`}
                                        style={{width:'120px'}}
                                        alt='SIAN'
                                        />
                                </a>                        
                            </div> 
                            <ul className="nav navbar-nav navbar-left">
                                {/* <li>                                     
                                    <a id="ar-brand" className="navbar-brand" href="/Inicio">
                                        <img 
                                        src={`${process.env.PUBLIC_URL}/assets/img/logoSIAN/letra40.png`}
                                        style={{width:'120px'}}
                                        alt='SIAN'
                                        />
                                    </a>
                                </li> */}
                                {
                                    ExisteSession.getMenu().map(menu=>{
                                        return <ItemMenuNav key={menu.id} menu={menu} onclickItem={onclickItem}/>
                                    })
                                }
                                {/* <li>
                                    <a  href="#!" onClick={e => exit(e)}  >&nbsp;&nbsp; SALIR</a>
                                </li> */}
                            </ul>
                            {/* <div className={styleBtnCont} title="SALIR"  onClick={e => exit(e)} onMouseEnter={onMouseE} onMouseLeave={onMouseD}> 
                                <div className={styleBtnContTxt}>
                                    <span className={styleBtnTxt}>SALIR</span>  <span className={styleBtn}><i class="fa fa-sign-out"></i></span>
                                </div>
                            </div>    */}
                            <div className='pull-right btn-Exit-St' title="SALIR"  onClick={e => exit(e)} onMouseEnter={onMouseE} onMouseLeave={onMouseD}> 
                                <span className={styleBtn}><i className="fa fa-sign-out"></i></span>
                            </div>   
                        </div>                   
                    </div>                 
                </nav>
                <Menu handleClickRefresh={handleClickRefresh}/>          
            </Router>
        </Fragment>
    )



}
export default MenuNavBar