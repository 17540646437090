import React, {Fragment,useState,useEffect} from 'react';
import Header from '../../Components/Header'
import Session from '../../Application/session'
import Buttons from '../../Components/Buttons/Buttons'
import GeneralTable from '../../Components/GeneralTable';
import ModalTesDisponibilidad from './ModalTesDisponibilidad'
import Select from 'react-select'
import{ExisteSession, toggleLoader } from '../../ApiRequests/general'
import{columnsDisponibilidadTbl,inicializa,tableData} from './resources'
import{getDisponibilidad,getFiltros,saveDisponibilidad,deleteDisponibilidad,validaForm} from './Crud'

const ConfiguracionDisponibilidad = ()  => {    
    
    const [typeDisponibilidad, setTypeMatriz]      = useState(0)   
    const [dataTableDisponibilidad,setDataTableDisponibilidad]     = useState([])
    const [listEmpresasFront,setListEmpresasFront] = useState([])   
    const [listCuentas,setListCuentas]             = useState([])
    const [listCuentasC,setListCuentasC]           = useState([])
    const [isEmpresa,setIsEmpresa]                 = useState(false)
    const [dataSaved,setDatasSaved]                = useState({
                                                                
                                                                empresa:[],
                                                                empresaValue:0,
                                                                cuenta:[],
                                                                cuentaValue:0,                                                                
                                                                importe:0,
                                                                nuevoImporte:0,                                                                
                                                                isSaved:true,
                                                                secuencia:0,
                                                                fechaVigencia:null,

                                                            })
    

    useEffect(() => {
       
        toggleLoader(true)

        getDisponibilidad({
            
            typeDisponibilidad: 'tesDisponibilidad'
            
        }, 
        collection =>{
            toggleLoader(false)
            setDataTableDisponibilidad(collection)      
            

        })        

        getFiltros({            
            idUser:ExisteSession.getInfoUsuario().idUsuario,
            typeFilter:"Empresas",
            tabla:'conMatrizTbl',
            columna:'idTipoConfiguracion'
            
            }, 
            collection =>{
            toggleLoader(false)
            if(collection !== undefined && collection !== null && collection.length > 0){                
                setListEmpresasFront(collection[0].ListEmpresas)                
            }
            getFiltros({
                idUser:ExisteSession.getInfoUsuario().idUsuario,
                typeFilter:"Cuentas",                
                }, collection =>{
                toggleLoader(false)
                setListCuentas(collection)
            })

        })

    },[])

    

    const showModalUpdate = (original,values) =>{
        
                let IdEmpresa = original.idEmpresa
                let optionEmpresa = listEmpresasFront.find(E => E.value === IdEmpresa)                
                setIsEmpresa(true) 
        setDatasSaved({
                ...dataSaved,
                secuencia:original.secuencia,
                empresa:optionEmpresa,
                empresaValue:original.idEmpresa,
                cuenta:listCuentas.find(C => C.value === original.idCuenta),
                cuentaValue:original.idCuenta,               
                 importe:original.importe,                
                nuevoImporte: original.nuevoImporte === 0 || original.nuevoImporte === null ? 0 : original.nuevoImporte,
                fechaVigencia:original.fechaVigencia,  
                isSaved:false, 
                
            })
            window.$('#modalDisponibilidadFront').modal('show')
         
            getFiltros({
                idUser:ExisteSession.getInfoUsuario().idUsuario,
                typeFilter:"Cuentas",                
                idCompania:  original.idEmpresa
                }, collection =>{
                toggleLoader(false)
                            
                setListCuentasC(collection)
            })
    }

    const showModalAdd = () =>{   
        
        setIsEmpresa(false)          
            setDatasSaved({
                ...dataSaved,                
                empresa:[],
                empresaValue:0,
                cuenta:[],
                cuentaValue:0,                
                importe:0, 
                nuevoImporte:0,               
                isSaved:true, 
                secuencia:0,
                fechaVigencia:null,
                
            })
            setListCuentasC([])
            window.$('#modalDisponibilidadFront').modal('show')        
    }

    const handleChange = (e,name) => {
        
        setDatasSaved({            
            ...dataSaved,
            
            [name] : name === 'importe' ||  name === 'nuevoImporte' ? e.target.value : e 
            
        })

       
        
        if(name === 'empresa'){
            toggleLoader(true)
           
            setDatasSaved({
                ...dataSaved,
                cuenta: null,
                empresa: e
            })
            getFiltros({
                idUser:ExisteSession.getInfoUsuario().idUsuario,
                typeFilter:"Cuentas",                
                idCompania: e.value
                }, collection =>{
                toggleLoader(false)
                         
                setListCuentasC(collection)
               
            })
        }
        
    }

   

    const clickSave = () => {
        
        if(validaForm(dataSaved)) 
        {
            toggleLoader(true)
            
                saveDisponibilidad({
                    typeDisponibilidad: 'tesDisponibilidad',
                    idType:                 dataSaved.isSaved,                   
                    secuencia:              dataSaved.secuencia,
                    idEmpresa :             dataSaved.empresa.value,                    
                    idCuenta :              dataSaved.cuenta.value, 
                    importe:                dataSaved.nuevoImporte === 0 || dataSaved.nuevoImporte === null ? dataSaved.importe : dataSaved.nuevoImporte,                   
                    // importe:                dataSaved.importe,
                    nuevoImporte:           dataSaved.nuevoImporte,
                    fechaVigencia:          dataSaved.fechaVigencia,
                },response =>{
                      
                        getDisponibilidad({                           
                            typeDisponibilidad: 'tesDisponibilidad',
                        }, collection =>{                                
                                setDataTableDisponibilidad(collection)
                                toggleLoader(false)
                                clickClose()

                            })
                })
        }
    }

    const clickDelete = () => {
        toggleLoader(true)        
        
        deleteDisponibilidad({
            
                    typeDisponibilidad: 'tesDisponibilidad',
                    idType:                 dataSaved.isSaved,                   
                    secuencia:              dataSaved.secuencia,
                    idEmpresa :             dataSaved.empresa.value,                    
                    idCuenta :              dataSaved.cuenta.value,                    
                    importe:                dataSaved.importe,
                    nuevoImporte:           dataSaved.nuevoImporte,
                    fechaVigencia:          dataSaved.fechaVigencia,

        },response=>{
            getDisponibilidad({
                typeDisponibilidad: 'tesDisponibilidad',
            }, collection =>{                    
                    setDataTableDisponibilidad(collection)
                })
                toggleLoader(false)
                clickClose()
        })
    }
    
    
    const clickClose = ()  =>  window.$('#modalDisponibilidadFront').modal('hide')

    
    return (
        <Fragment>
             
             <Header title={inicializa.title + ' ' + Session.getInfoAmbiente()} breadcrum={inicializa.breadcrum}/>
            <div className="lx-container container">
                <div className="panel panel-primary animated fadeInUp">
                    <div className="panel-heading">
                        <div className="row">
                            <div className="col-md-6">
                                <span>Disponibilidad de Cuentas</span>                   
                            </div>
                            <div className="col-md-6 btnF">                                
                                <Buttons btnAdd={true} btnAddAction={e => showModalAdd()} btnAddText='Agregar' btnAddShowIcon={true}/>
                                
                            </div>
                        </div>
                    </div>
                    <div className="panel-body">
                             <GeneralTable
                                columns={columnsDisponibilidadTbl}                                
                                data={dataTableDisponibilidad} 
                                action={undefined} 
                                doubleClick={(original,values) => showModalUpdate(original,values)} 
                             />
                    </div> 
                </div>
            </div>
            <ModalTesDisponibilidad
                    listEmpresas={listEmpresasFront}                
                    listCuentas={listCuentasC}
                    isEmpresa={isEmpresa}                             
                    dataSaved={dataSaved}                    
                    handleChangeFront={handleChange}                   
                    clickSave={clickSave}
                    clickDelete={clickDelete}
                    clickClose={clickClose}                    
                    textTypeSaved={"Disponibilidad de Cuentas"}
                    
                />
        </Fragment>
    );
}

export default ConfiguracionDisponibilidad;