import React from 'react'
import { ToastProvider,useToasts } from 'react-toast-notifications'

export const ToastTrigger = ({ content, type, oculta }) => {
const { addToast } = useToasts()
    return (
            <div id="btnShowToast" onClick={() => addToast(content, {
                                                                     appearance: type,
                                                                     autoDismiss: oculta,
                                                                     
                                                                    })}>
            </div>
        )
}
function NotificationTrigger(props)  {
        let Message = props.message
        return (
                <ToastProvider>
                    <ToastTrigger content={Message} type = {props.type} oculta={props.autohide}/>
                </ToastProvider>     
        )
}
export default NotificationTrigger