import React, {useState, useEffect} from 'react';
import Buttons from '../../../Components/Buttons/Buttons';

function ModalAdministradorAcciones({ saveDataUser, dataModal, actionClose, clickDelete }) {
    const [descript,setdescript] = useState('')    
    const [idAccion,setIdAccion] = useState(0)
    const clickClose = () => {
        actionClose()        
        setdescript('')
    }
    const handleChange = e => {
        console.log(e.target.value)
        setdescript(e.target.value)
    }
    
    const clickSave = () => {
        // debugger;
        let isType = dataModal.type
        saveDataUser({idAccion,descript,isType})
    }
    const clickDeleteLocal = () => {clickDelete({idAccion})}
    console.log(dataModal)
    console.log(dataModal.type)
    useEffect(() => {
        if(!dataModal.type){
            setIdAccion(dataModal.idAccion)            
            setdescript(dataModal.descripcion)
        }
    },[dataModal])
    return (
        <div className="modal fade" id="modalAdministradorAcciones" tabIndex="-1" role="dialog" aria-labelledby="modalAdministradorAcciones" aria-hidden="true">
            <div className="modal-dialog modal-lg" role="document">
                <div className="modal-content">
                <div className="modal-header mhead">
                    <button onClick={clickClose} type="button" className="close text-white" data-dismiss="modal" aria-label="Close">                            
                            <i className="fa fa-times-circle"></i>
                        </button>
                        <span className="modal-title title-modal-custom-color title-modal-custom" id="modalAdministradorAccionesLabel">
                            {!dataModal.type  ? "Editar Acción " : "Agregar Acción"}
                        </span>                       
                </div>                    
                    <div className="modal-body">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Descripción</label>
                                        <input
                                        className="form-control"
                                        id="descripcion"
                                        name="descripcion"
                                        onChange={handleChange}
                                        value={descript}                                        
                                        placeholder="Descripción" />
                                    </div>                        
                                </div>                                
                            </div>
                            </div>
                        
                        
                    </div>
                    <div className="modal-footer">


                        <Buttons
                            // btnAdd={true} btnAddAction={clickSave} btnAddText={!dataModal.type ? " Aceptar" : " Aceptar"} btnAddClass={"btn btn-primary"} btnAddShowIcon={true}
                            btnSave={true} btnSaveAction={clickSave} btnSaveText={!dataModal.type ? "Guardar cambios" : " Guardar"}  btnSaveShowIcon={true} typeSaved={dataModal.type}
                            btnDelete={!dataModal.type} btnDeleteAction={clickDeleteLocal} btnDeleteText={" Eliminar"} btnDeleteShowIcon={true}
                            btnCancel={true} btnCancelAction={clickClose} btnCancelText={" Cancelar"} btnCancelShowIcon={true}/>
                            
                        {/* {!model.type
                            ?
                            <button type="button" onClick={clickDeleteLocal} className="btn btn-danger" data-dismiss="modal">Elminar</button>
                            :
                            <></>
                        }
                        <button type="button" onClick={clickClose} className="btn btn-secondary" data-dismiss="modal">Cerrar</button>
                        <button type="button" className="btn btn-primary" onClick={clickSave}>{!model.type ? "Modificar" : "Guardar"}</button> */}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ModalAdministradorAcciones;