import React from "react";
const BtnSave = ({ btnSaveAction, btnSaveClass, btnSaveText, btnSaveIconClass, btnSaveShowIcon }) => {

    return (
        
        <button
            className={btnSaveClass ? btnSaveClass : "btn btn-primary button-circle button-small"}
            onClick={btnSaveAction}
            title={btnSaveText
                ?
                btnSaveText
                :
                "Guardar"}
        >
            {btnSaveShowIcon
                ?
                <i className={btnSaveIconClass ? btnSaveIconClass : "fa fa-save"} alt=""></i>
                :
                <></>}
            
        </button>
    )
}
export default BtnSave