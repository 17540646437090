import React,{useState, useEffect, Fragment} from 'react';
import {tableColumns,inicializa} from './resource'
import {
    getMotivosCorreo,
    saveMotivosCorreo,
    deleteMotivoscorreo

} from './services'
import GeneralTable from '../../../Components/GeneralTable';
import Header from '../../../Components/Header';
import Session from '../../../Application/session'
import Buttons from '../../../Components/Buttons/Buttons'
import ModalMotivoCarreo from './ModalMotivoCarreo';
import {fnNotification} from '../../../ApiRequests/Notification';
import {ExisteSession, toggleLoader} from '../../../ApiRequests/general'

const CatMotivosCorreo = () => {
    const [dataSource,setDataSource]   = useState([])
    const [isUpdate,setIsUpdate]             = useState(false)
    const [titulo,setTitulo]                 = useState('')
   // const [descripcion,setDescription]       = useState('')
    const [idEstatus,setIdEstatus]           = useState(false)
    const [idMotivo,setidMotivo]             = useState(0)
    
    useEffect(() => {
        toggleLoader(true);
        getMotivosCorreo({}, collection=>{
            setDataSource(collection)
        });
       setTimeout(() => {
        toggleLoader(false);
       }, 1000); 
       
        
    },[])
    const showModal = () => {
        setIsUpdate(false);
        setIdEstatus(true);
        window.$('#modalMotivoCorreo').modal('show')

    }
    const handleDoubleClick = (originals, tableValues) => {  
      setidMotivo(originals.idMotivo)
        setTitulo(originals.titulo)
        setIdEstatus(originals.idEstatus)
        window.$('#modalMotivoCorreo').modal('show')
        setIsUpdate(true)
    }
    const clickClose = () => {
        window.$('#modalMotivoCorreo').modal('hide')
        setTitulo('')
        setidMotivo(0)
        setIdEstatus(false)
    }
   
    const clickSave = () => {
        if(!validateData())return;
        toggleLoader(true);
        saveMotivosCorreo({
            typeSaved: !isUpdate,
            idMotivo: idMotivo,
            titulo : titulo,
            idEstatus: idEstatus,
        },({response,type,success}) =>{
            if(success) {
                if(type === 'save'){
                    
                    getMotivosCorreo({}, collection=>{
                        setDataSource(collection)
                    })
                }
                if(type === 'update'){
                    
                    getMotivosCorreo({}, collection=>{
                        setDataSource(collection)
                    })
                }                   
                clickClose()
                toggleLoader(false);
            } else{
                toggleLoader(false);
            }           
        })
      
    }
    const clickDelete = () => {
        toggleLoader(true);
        deleteMotivoscorreo({
            idMotivo:idMotivo
        },({response,type,success})=>{
            if(success) {
                
                getMotivosCorreo({}, collection=>{
                    setDataSource(collection)
                })
                clickClose();
                toggleLoader(false);
            }else{
                toggleLoader(false);
            }
        })
    }
    const handleChange = (e) => {
        if(e.target.name === 'txtTitulo') setTitulo(e.target.value)
        if(e.target.name === 'Estatus') setIdEstatus(e.target.checked)        
    }
    const validateData=()=>{
        if(isNullOrWhiteSpace(titulo)){
            fnNotification(['El Título es obligatorio.', 'warning', true]);
           return false;
        }else{
            if(titulo.toString().trim().length>100){
            fnNotification(['El Título no debe de contener mas de 100 carácteres.', 'warning', true]);
            return false;
            }
        }
        if(!contieneLetrasEspaciosAcentos(titulo)){
            fnNotification(['El Título solo debe de contener letras,signos de puntuación y acentos.', 'warning', true]);
            return false;
        }
        return true;
    } 
    const contieneLetrasEspaciosAcentos=(cadena) =>{
        var regex = /^[a-zA-Z\sáéíóúÁÉÍÓÚüÜñÑ,.]+$/;
        return regex.test(cadena);
    }
    const isNullOrWhiteSpace=(val)=>{
        var i=0;
      
        if(val==undefined)
        i++;
        if(val==null)
        i++;

        if(val!=null && val!=undefined){
            if(val.toString().trim()=='')
            i++;

        }

        if(i>0)
         return true;
        else
        return false;

    }
    return (
        <Fragment>
        <Header title={inicializa.title + ' ' + Session.getInfoAmbiente()} breadcrum={inicializa.breadcrum}/>
        <div className="lx-container container">
        <div className="panel panel-primary animated fadeInUp">
            <div className="panel-heading">
                <div className="row">
                    <div className="col-md-6">
                        <span>Catalogo de Motivos de Correo</span>                   

                    </div>
                    <div className="col-md-6 btnF">
                        <Buttons btnAdd={true} btnAddAction={showModal} btnAddText={'Agregar'} btnAddShowIcon={true}/>
                    </div>

                </div>
                </div>
                <div className="panel-body">
                    <GeneralTable
                        columns={tableColumns}
                          // updateSearch={updateTableSearch} 
                        data={dataSource} 
                        action={undefined} 
                        doubleClick={handleDoubleClick}
                    />
                </div>  
        </div>
      </div> 
        <ModalMotivoCarreo
        isUpdate={isUpdate}
        clickClose={clickClose}
        clickDelete={clickDelete}
        clickSave={clickSave}
        handleChange={handleChange}
        idMotivo={idMotivo}
        titulo={titulo}
       
        idEstatus={idEstatus}
        />
      </Fragment>
      
    );
};

export default CatMotivosCorreo;