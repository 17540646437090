import React from 'react';
import Buttons from '../../../Components/Buttons/Buttons'
import TabUsuario from './TabUsuario'
import TabVistas from './TabVistas';
import TabMotivosCorreo from './TabMotivosCorreo';
const ModalUsuario = (props) => {
  //{saveDataUser,model,dataRelPerfilVista,actionClose,deleteUser,dataPerfiles,idUsuario,loadRelVistaAccion,resetPerfil,isResetPerfil}
  const status = false;
  const {tabUser,tabContUser,tabVista,tabContVista,tabMotivosCorreo,tabContMotivosCorreo} = props.dataTabs.calasTabs
    return (
        <div className="modal fade" id="modalUsuario" tabIndex="-1" role="dialog" aria-labelledby="modalUsuarioLabel" aria-hidden="true">
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content">
            <div className="modal-header mhead">
              <div className="row">
                <div className="col-md-6">
                  <h5 className="modal-title" style={{color: 'white'}} id="modalUsuarioLabel">{!props.model.type ? "Edita Usuario " : "Agrega usuario"}</h5>
                </div>
                <div className="col-md-6">
                  <button onClick={props.clickClose} type="button" className="close" data-dismiss="modal" aria-label="Close">
                      <span aria-hidden="true">&times;</span>
                  </button>                   
                </div>
              </div>
            </div>
            <div className="modal-body">
                <div className="col-md-12">
                    <ul className="nav nav-pills nav-justified ar-nav-pills center-block margin-bottom">
                        <li className={tabUser}>
                            <a href="#Usuario" data-toggle="tab" >
                                <i className="fa fa-user"></i> Usuario
                            </a>
                        </li>
                        <li className={tabVista}>
                            <a href="#Vistas" data-toggle="tab" >
                                <i className="fa fa-code"></i> Vista
                            </a>
                        </li>
                        {!props.model.type ?
                          <li className={tabMotivosCorreo}>
                              <a href="#Motivos" data-toggle="tab" >
                                  <i className="fa fa-code"></i> Motivos Correo
                              </a>
                          </li>:''
                        }
                        {/* <li className="">
                            <a href="#Vista-Componentes" data-toggle="tab" aria-expanded="false">
                                <i className="fa fa-codepen"></i> Vista-Componentes
                            </a>
                        </li> */}
                    </ul>

                    <div className="tab-content margin-top">
                      <div className={tabContUser} id="Usuario">
                          <TabUsuario 
                          model={props.model}
                          datUser={[]} 
                          dataPerfiles={props.dataPerfiles}
                          handleChange={props.handleChangefrmUser} 
                          status={status} 
                          isStatus={props.model.idEstatus}
                          handlechecked={props.handlechecked}
                          itemPerfil={props.model.idPerfil}/>
                      </div>
                      <div className={tabContVista} id="Vistas">
                          <TabVistas 
                          dataRelPerfilVista={props.dataRelPerfilVista} 
                          handleChangeChkAll1={props.handleChangeChkAll1} 
                          handleChangeChk={props.handleChangeChk} 
                          handleChangeChkbx={props.handleChangeChkbx}
                          />
                      </div>
                      {!props.model.type ?
                      <div className={tabContMotivosCorreo} id="Motivos">
                          <TabMotivosCorreo 
                          dataRelMotivosCorreo={props.dataRelMtvCorreo} 
                          handleChangeChkAllMtv={props.handleChangeChkAllMtv} 
                          handleChangeChk={props.handleChangeChk} 
                          handleChangeChkbxMtv={props.handleChangeChkbxMtv}
                          
                          />
                      </div>:''
                    }
                    </div>
                </div>                
            </div>

            <div className="modal-footer">
              <Buttons
                btnSave={true} btnSaveAction={props.saveDataUser} btnSaveText={!props.model.type ? "Guardar cambios" : " Guardar"} btnSaveShowIcon={true}             
                btnCancel={true} btnCancelAction={props.clickClose} btnCancelText={" Cancelar"} btnCancelShowIcon={true}/>
            </div>
          </div>
        </div>
      </div>
    );
};

export default ModalUsuario;