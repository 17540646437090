import React,{Fragment, useEffect, useState} from 'react';
import {inicializa,tableColumns,tableColumnsDet} from './resources'
import ModalMovimientos from './ModalMovimientos';
import ModalConceptos from './ModalConceptos'
import Header from '../../../Components/Header';
import Session from '../../../Application/session'
import Container from '../../../Components/Container/ContainerInitial'
import Buttons from '../../../Components/Buttons/Buttons';
import GeneralTable from '../../../Components/GeneralTable';
import {
    getMovimientos,
    saveMovimiento,
    updateMovimiento,
    deleteMovimiento,
    getConceptos,
    saveConcepto,
    updateConcepto,
    deleteConcepto
} from './service'
const TiposMovimientos = () => {
    const [calssTabPrincipal,setClassTabPrincipal]     = useState('active')
    const [calssTabDet,setClassTabDet]                 = useState('')
    const [classTabContP,setClassTabContP]             = useState('tab-pane active')
    const [classTabContDet,setClassTabContDet]         = useState('tab-pane')
    const [isSaved,setIsSaved]                         = useState(true)
    const [isSavedConcepto,setIsSavedConcepto]         = useState(true)
    
    const [fecha,setFecha]                             = useState('')
    const [ingreso,setIngreso]                         = useState('')
    const [egreso,setEgreso]                           = useState('')
    const [beneficiario,setBeneficiario]               = useState('')
    const [concepto,setConcepto]                       = useState('')
    const [empresa,setEmpresa]                         = useState('')
    const [cuenta,setCuenta]                           = useState('')
    const [conceptos,setConceptos]                     = useState([])
    const [movimientos,setMovimientos]                 = useState([])
    const [idTipoMovimiento,setIdTipoMovimiento]       = useState(null)
    const [descripcion,setDescripcion]                 = useState('')
    const [descripcionConcepto,setDescripcionConcepto] = useState('')
    const [isChange,setisChange]                       = useState(false)
    const [isConcepto,setIsConcepto]                   = useState(false)
    const [idConceptoMovBancario,setIdConceptoMovBancario]  = useState(null)


    useEffect(() => {
            getMovimientos({

            }, response => {
                setMovimientos(response)
            })
            if(isChange){
             getConceptos({
                idTipoMovimiento
            },response =>{
                setConceptos(response)
                setIsSaved(false)
            }) }
    },[isChange,idTipoMovimiento])
    const showModal = () => {
        isConcepto ?
        showModalConcepto() : 
        showModalMovimientos()

    }
    const showModalMovimientos = () =>{
        setIsSaved(true)
        setDescripcion('')
        window.$('#modalMovimientos').modal('show')

    }
    const showModalUpdate = (original,values) =>{
        setIdTipoMovimiento(original.idTipoMovimiento)
        setisChange(false)
        setDescripcion(original.descripcion)
        setIsSaved(false)
        window.$('#modalMovimientos').modal('show')

    }
    const clickClose = () =>{
        isConcepto ? 
        window.$('#modalConceptos').modal('hide') : 
        window.$('#modalMovimientos').modal('hide')
    }
    const changeTab = () =>{
        setIsConcepto(false)
        setClassTabPrincipal('active')
        setClassTabContP('tab-pane active')
        setClassTabDet('')
        setClassTabContDet('tab-pane')
    }
    const changeTabDet = () =>{
        setIsConcepto(true) 

    }
    const actionBtn = (a) =>{
        setIsConcepto(true)
        setIdTipoMovimiento(a.idTipoMovimiento)
        setisChange(true)
        setClassTabPrincipal('')
        setClassTabContP('tab-pane')
        setClassTabDet('active')
        setClassTabContDet('tab-pane active')
    }
    const changeInfo = (val,name) =>{
        if(name === 'descripcion') setDescripcion(val)
    }
    const savedInfo = () =>{
        isSaved ?
        saveMovimiento({
            descripcion
        },(response,type,estatus) => {
            callback(response,'save',estatus)
        })
        :
        updateMovimiento({
            idTipoMovimiento,
            descripcion
        },(response,type,estatus) => {
            callback(response[0],'update',estatus)

        })
    }
    const deleteMov = () => {
        deleteMovimiento({
            idTipoMovimiento
        },(response,type,estatus) => {
            callback(idTipoMovimiento,'delete',estatus)

        })
    }
    const callback = (data,type,estatus) => {
        let auxMov      = [...movimientos];
        let auxConcepto = [...conceptos];
        switch(type){
            case 'save':
                if(estatus)  auxMov.push(data);
                break;
            case 'update':
                // auxMov.push(data);
                if(estatus)  auxMov.find(x => x.idTipoMovimiento === data.idTipoMovimiento).descripcion = data.descripcion
                
                break;
            case 'delete':
                let indx = auxMov.findIndex(x => x.idTipoMovimiento === data)
                if(estatus)  auxMov.splice(indx, 1);
                    break;
            case 'saveConcepto':
                if(estatus) auxConcepto.push(data);
                break;
            case 'updateConcepto':
                if(estatus)  auxConcepto.find(x => x.idConceptoMovBancario === data.idConceptoMovBancario).DescripcionConcepto = data.DescripcionConcepto
                
                break;
            case 'deleteConcepto':
                let indxc = auxConcepto.findIndex(x => x.idConceptoMovBancario === data)
                if(estatus)  auxConcepto.splice(indxc, 1);
                    break;
                default:

        }
        if(isConcepto){
            setConceptos(auxConcepto)
            window.$('#modalConceptos').modal('hide')

        }else{
            setMovimientos(auxMov)
            window.$('#modalMovimientos').modal('hide')
        }
        

    }

    const showModalConcepto = () =>{
        setIsSavedConcepto(true)
        setDescripcionConcepto('')
        window.$('#modalConceptos').modal('show')
    }
    const showModalUpdateConcepto = (original,values) =>{
        setIdConceptoMovBancario(original.idConceptoMovBancario)
        setisChange(false)
        setDescripcionConcepto(original.DescripcionConcepto)
        setIsSavedConcepto(false)
        window.$('#modalConceptos').modal('show')

    }
    const changeInfoConcepto= (val,name) =>{
        if(name === 'descripcionConcepto') setDescripcionConcepto(val)
    }

    const savedInfoConcepto = () =>{
        isSavedConcepto ?
        saveConcepto({
            idTipoMovimiento,
            DescripcionConcepto:descripcionConcepto
        },(response,type,estatus) => {
            callback(response,'saveConcepto',estatus)
        })
        :
        updateConcepto({
            idTipoMovimiento,
            idConceptoMovBancario,
            DescripcionConcepto:descripcionConcepto
        },(response,type,estatus) => {
            callback(response[0],'updateConcepto',estatus)

        })

    }
    const clickDeleteConcepto=() => {
        deleteConcepto({
            idTipoMovimiento,
            idConceptoMovBancario
        },(response,type,estatus) => {
            callback(idConceptoMovBancario,'deleteConcepto',estatus)
        })
    }
  
    const btnH = () => <>
        <Buttons 
                btnAdd  = {true} btnAddAction  = {() => showModal()}  btnAddShowIcon={true}
            />
    </>

const contBody = () => <>
        <ul className="nav nav-pills nav-justified ar-nav-pills center-block margin-bottom">
            <li className={calssTabPrincipal} onClick={changeTab}>
                <a href="#tiposMovimientos" data-toggle="tab" aria-expanded="true">
                    <i className="fa fa-building"></i> Tipos movimientos
                </a>
            </li>
            {
            idTipoMovimiento !== null ? 
            <li className={calssTabDet} onClick={changeTabDet}>
                <a href="#detalle" data-toggle="tab" aria-expanded="true">
                    <i className="fa fa-building"></i> Conceptos
                </a>
            </li>:<></>
            }
        </ul>
        <div className="tab-content margin-top">
            <div className={classTabContP} id="tiposMovimientos">
                <div className="row">
                    <div className="col-md-2"></div>
                    <div className="col-md-8">
                    <GeneralTable
                            columns={tableColumns}
                            data={movimientos}
                            action={undefined}
                            doubleClick={(original,values) => showModalUpdate(original,values)} 

                            actionBtn={(a,b) => actionBtn(a,b)}

                            // isCpmpBtn={true}
                            // compnnt={compnntCarga}
                    />
                    </div>
                <div className="col-md-2"></div>
                </div>
            </div>
            <div className={classTabContDet} id="detalle">
            <div className="row">
                    <div className="col-md-2"></div>
                    <div className="col-md-8">
                    <GeneralTable
                            columns={tableColumnsDet}
                            data={conceptos}
                            action={undefined}
                            doubleClick={(original,values) => showModalUpdateConcepto(original,values)} 

                            // isCpmpBtn={true}
                            // compnnt={compnntCarga}
                    />
                    </div>
                <div className="col-md-2"></div>
                </div>
            </div>
        </div>

    </>
    return (
       <Fragment>
        <Header title={inicializa.title + ' ' + Session.getInfoAmbiente()} breadcrum={inicializa.breadcrum}/>
        <Container 
            title={inicializa.title}
            btnH={btnH()}
            contBody={contBody()}
            spaceTitle='6'
            spaceBtn='6'
        />
        Concepto
        <ModalMovimientos 

        clickClose={() => clickClose()}
        clickSave={() => savedInfo()}
        clickDelete={() => deleteMov()}
        changeInfo={(val,name) => changeInfo(val,name)}
        isSaved={isSaved}
        descripcion={descripcion}
        textTypeSaved={"Movimientos"}
        />
        <ModalConceptos 
        clickClose={() => clickClose()}
        clickSaveConcepto={() => savedInfoConcepto()}
        clickDeleteConcepto={() => clickDeleteConcepto()}
        changeInfoConcepto={(val,name) => changeInfoConcepto(val,name)}
        isSavedConcepto={isSavedConcepto}
        textTypeSavedConcepto={"Concepto"}
        descripcionConcepto={descripcionConcepto}
        />
        </Fragment>
    );
};

export default TiposMovimientos;