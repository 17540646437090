import React, {Fragment, useEffect, useState, useRef} from 'react';
import {inicializa,tableColumns, tableColumnsConcepto } from './resources'
import {insertDataTable,getInfoMovimientos,getValidaFecha} from './service'
import {fnNotification} from '../../../ApiRequests/Notification'
import {toggleLoader} from '../../../ApiRequests/general'

import Header from '../../../Components/Header';
import Session from '../../../Application/session'
import Container from '../../../Components/Container/ContainerInitial'
import Buttons from '../../../Components/Buttons/Buttons';
import GeneralTable from '../../../Components/GeneralTable';
import ModalErrores from './ModalErrores'
import * as XLSX from 'xlsx'

const CargaMovimientosBancarios = () => {

    const [name, setName]                  = useState("");
    const [selectedFile, setSelectedFile]  = useState('');
    const [fechaConsulta,setFechaConsulta] = useState('');
    const [isInitial,setIsInitial]         = useState(true);
    const [movimientos,setMovimientos]     = useState([]);
    const [erroresTbl,setErroresTbl]       = useState([]);
    const [isConfirmatio,setIsConfirmation]= useState(false);

    // const [isError,setIsError]             = useState([]);
    let textInput = useRef(null);

    useEffect(() => {
        let today = new Date()
        let date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
        
        if(isInitial){
            let auxDate = isInitial ? date : fechaConsulta
            getInfoMovimientos({
                fecha: auxDate
            },resp => {
                setMovimientos(resp)
                let nAuxm = auxDate.split('-')[1].length === 1 ? '0'+ auxDate.split('-')[1] : auxDate.split('-')[1];
                let nAuxd = auxDate.split('-')[2].length === 1 ? '0'+ auxDate.split('-')[2] : auxDate.split('-')[2];
                let nAuxDate = auxDate.split('-')[0] + '-'+ nAuxm + '-' +nAuxd

                setFechaConsulta(nAuxDate)
                setIsInitial(false)
            })
        }
    },[fechaConsulta,isInitial]);

    const onChangeFile = (e) =>{
        let positionFormat = e.target.files[0].name.split('.').length-1
        let formatFile = e.target.files[0].name.split('.')[positionFormat]
        if(formatFile === 'xlsx' || formatFile === 'xls'){ 
            setSelectedFile(e.target.files)
           
        }
        else{
            textInput.current.value = "";
            fnNotification(['Error solo se pueden cargar archivos de tipo excel', 'error', true])
        }


    }
    const loadFile = () =>{
        // console.log("selectedFile" , selectedFile)
        console.log("selectedFile.length" , selectedFile.length)
        // console.log("selectedFile.archivo" , selectedFile[0])

        if(selectedFile.length > 0){
            console.log('Entra')


            toggleLoader(true)
            getValidaFecha({

            },{archivo: selectedFile,},respons =>{
                console.log('response',respons)
                toggleLoader(false)
                if(respons.length > 0){
                if(respons[0].consecutivoError === 0){ 
                    if(respons[0].FlagFecha !== undefined && respons[0].FlagFecha !== null){
                        respons[0].FlagFecha === 2 ? showModalConfirm() :  sendFile();

                    }
                }else{
                    setErroresTbl(respons)
                    window.$('#modalMovError').modal('show')
                    toggleLoader(false)
                }
                
                }
            })
            

        }else fnNotification(['No se ha seleccionado ningun archivo', 'error', true])
      
    }
    const sendFile = () => {

        toggleLoader(true)
        insertDataTable({
        
        }, {archivo: selectedFile,},resp => {
            console.log("resp" ,resp)
            textInput.current.value = "";
            setIsConfirmation(false);
            if(resp.length > 0){
                if(resp[0].consecutivoError === 0){ 

                    fnNotification(['El archivo ha sido cargado con éxito', 'success', true])
                    let dte = new Date(resp[0].mensaje)
                    setFechaConsulta(resp[0].mensaje)
                    window.$('#modalMovError').modal('hide')
                    if(resp[0].mensaje !== undefined && resp[0].mensaje !== null){
                        getInfoMovimientos({
                            fecha:dte 
                        },resp => {
                            console.log("resp" ,resp)
                            setMovimientos(resp)
                            toggleLoader(false)
                        })
                    }
                }
                else {
                setErroresTbl(resp)
                // window.$('#modalMovError').modal('hide')
                window.$('#modalMovError').modal('show')
                toggleLoader(false)
                }

            }
        }
        )

    }
    const showModalConfirm = () => {
        setIsConfirmation(true)
        window.$('#modalMovError').modal('show')


    }

    const clickClose = () =>{
        setIsConfirmation(false)
        window.$('#modalMovError').modal('hide')
        textInput.current.value = "";     

    }

    const searchDataTbl = (e) =>{
        console.log("fecha",e.target.value)
        toggleLoader(true)
        setFechaConsulta(e.target.value)
        getInfoMovimientos({
                fecha: e.target.value

        },resp=>{
            setMovimientos(resp)
        toggleLoader(false)

        })
       

        
    }

    const btnH = () => <>
    <div className="row">

            <div className="col-md-5">
            </div>

            <div className="col-md-5">

            </div>
          
            <div className="col-md-2 posittion-right">
           
            </div>
        </div>
        
    </>
    const compnntCarga = () =><>
        <div className="row">
            <div className="col-md-4">
            <input
            type="date"
            className="form-control" 
            value={fechaConsulta} 
            onChange={(e) => searchDataTbl(e)}
            // max={fechaConsulta}
            />

            </div>
            {/* <div className="col-md-1"></div> */}

            <div className="col-md-4">
            <input type="file" onChange={(e) => onChangeFile(e) } 
             accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet , application/vnd.ms-excel"
             ref={textInput}/>
            </div>

            <div className="col-md-3"></div>
            <div className="col-md-1 aling-right">
            <Buttons 
                btnAdd  = {true} btnAddAction  = {() => loadFile()} btnAddText="Cargar" btnAddShowIcon={true} btnAddIconClass="fa fa-upload"
            />
            </div>

            
        </div>
    </>
    const contBody = () => <>
        
        <div className="row">
            <div className="col-md-12">
            <GeneralTable
                    columns={tableColumnsConcepto}
                    data={movimientos}
                    action={undefined}
                    doubleClick={(original,values) => {}} 
                    isCpmpBtn={true}
                    compnnt={compnntCarga}

            />
            </div>
        </div>
    </>
    return (
        <Fragment >
        <Header title={inicializa.title + ' ' + Session.getInfoAmbiente()} breadcrum={inicializa.breadcrum}/>
        <Container 
            title={inicializa.title}
            btnH={btnH()}
            contBody={contBody()}
            spaceTitle='6'
            spaceBtn='6'
        />
        {/* <ModalProgIngersos
            listEmpresas={listEmpresas}                
            listCuentas={listCuentasC}
            dataSaved={dataSaved}
        //    isEmpresa={isEmpresa}                         
            handleChange={handleChange}
           clickSave={clickSave}
           clickDelete={clickDelete}
           clickClose={clickClose}  
        textTypeSaved={"Ingreso programado"}

        /> */}


    <ModalErrores clickClose={clickClose} dataTblError={erroresTbl} isConfirmatio={isConfirmatio} clickSend={() =>sendFile()} />
   
    </Fragment>
    );
};

export default CargaMovimientosBancarios;