import React, { Fragment, useEffect, useState } from "react";
import Select from "react-select";
import GeneralTable from "../../../Components/GeneralTable";
import {
  getInfo,
  saveInfo,
  updateInfo,
  deleteInfo,
  getInfoGral,
} from "../../../ApiRequests/req_CRUD";
import { ExisteSession, toggleLoader } from "../../../ApiRequests/general";
import { idOrigenWeb } from "../../../Constants/application";
import Header from "../../../Components/Header";
import { inicializa, tableData, tableDataLocal } from "./resources";
import Buttons from "../../../Components/Buttons/Buttons";
import Session from "../../../Application/session";
import ModalHistorico from "./modalHistorico";
import ExportExcel from "../../../Components/ExportExcel/ExportExcel";
import { fnNotification } from "../../../ApiRequests/Notification";
import Moment from "react-moment";
import moment from "moment";
import PdfPreview from "../../PDFPreview/OpenPdfButton";
const SeguimientoBuzonTributario = () => {
  const [model, setModel] = useState({
    usuarioSelect: null,
    idEstatus: 1,
    filtro_aplicacion: 38,
    filtro_ambiente: ExisteSession.getAmbiente().idAmbiente,
    idOrigen: idOrigenWeb,
    idUsuarioAct: ExisteSession.getInfoUsuario().idUsuario,
    token: ExisteSession.getInfoUsuario().token,
    connection: ExisteSession.conexion(),
  });
  const [filter, setFilter] = useState({
    idEmpresa: 0,
    fechaInicioAtencion: null,
    fechaTerminoAtencion: null,
    nivelPrioridad: "",
    idNivelPrioridad: 0,
    idEstatus: 0,
    filtro_aplicacion: 38,
    filtro_ambiente: ExisteSession.getAmbiente().idAmbiente,
    idOrigen: idOrigenWeb,
    idUsuarioAct: ExisteSession.getInfoUsuario().idUsuario,
    token: ExisteSession.getInfoUsuario().token,
    connection: ExisteSession.conexion(),
  });
  const generateFileName = (baseName) => {
    const formattedDate = moment().format("DDMMYY_HHmmss");
    return `${baseName}_${formattedDate}`;
  };
  const baseFileName = "SeguimientoBuzones";
  const fileName = generateFileName(baseFileName);
  const [dtDataTable, setDTDataTable] = useState([]);
  const [dtCloneDataTable, setdtcloneDataTable] = useState([]);
  const [dtHistorico, setDTHistorico] = useState([]);
  const [dtRazonSocial, setDTRazonSocial] = useState([]);
  const [dtPrioridad, setDTPrioridad] = useState([]);
  const [dtEstatus, setDTEstatus] = useState([]);
  const [dtEstatusEdicion, setDTEstatusEdicion] = useState([]);
  const [reload, setReload] = useState(true);
  const [editarCampos, seteditarCampos] = useState(false);
  const [history, setHistoryReload] = useState(false);
  const [pdfFileUrl, setPDFReload] = useState("");
  const [updateSearch, setupdateSearch] = useState(false);
  const lisColumnaVisible = [
    { key: 'razonSocial', header: 'Razón Social' },
    { key: 'tipoEmpresa', header: 'Tipo Empresa' },
    { key: 'nivelPrioridad', header: 'Prioridad' },
    { key: 'tipoNotificacion', header: 'Tipo Notificación' },
    { key: 'descripcionRequerimiento', header: 'Descripción de Requerimiento' },
    { key: 'fechaNotificacion', header: 'Fecha de Notificación' },
    { key: 'fechaLecturaNotificacion', header: 'Fecha de Lectura Notificación' },
	  { key: 'fechaInicioAtencion', header: 'Fecha Inicio atención' },
	  { key: 'plazoLimite', header: 'Plazo Límite' },
	  { key: 'fechaTerminoAtencion', header: 'Fecha de Término' },
	  { key: 'estatus', header: 'Estatus' },
	  { key: 'comentario', header: 'Comentarios de Personal' },
	  { key: 'personalAsignado', header: 'Personal Asignado' }	
  ];

  const tableColumns = [
    {
      Header: "Seleccionar",
      label: "Activo ",
      Cell: (k) => (
        <div className="border-checkbox-section">
          <div className="border-checkbox-group border-checkbox-group-success">
            <input
              onChange={(event) => {
                cambioSeleccionado(k.row.original, event, k);
              }}
              checked={k.row.original.seleccionado}
              className="border-checkbox"
              type="checkbox"
              id={"chkAPlicar-" + k.row.original.idSeguimiento}
            />
            <label
              className="border-checkbox-label"
              htmlFor={"chkAPlicar-" + k.row.original.idSeguimiento}
            >
              &nbsp;
            </label>
          </div>
        </div>
      ),
    },
    {
      Header: "Documento",
      accessor: "tienePDF",
      Cell: (k) =>
        k.row.original.tienePDF ? (
          <>
            <span>Si</span>
          </>
        ) : (
          <>
            <span>No</span>
          </>
        ),
    },
    {
      Header: "Razón Social",
      accessor: "razonSocial",
    },
    {
      Header: "Tipo Empresa",
      accessor: "tipoEmpresa",
    },

    {
      Header: "Prioridad",
      accessor: "nivelPrioridad",
    },
    {
      Header: "Tipo Notificación",
      accessor: "tipoNotificacion",
    },
    {
      Header: "Descripción de Requerimiento",
      accessor: "descripcionRequerimiento",
    },
    {
      Header: "Fecha de notificación",
      accessor: "fechaNotificacion",
      Cell: (k) =>
        k.row.original.fechaNotificacion ? (
          <>
            <span>
              <Moment format="YYYY-MM-DD">
                {k.row.original.fechaNotificacion}
              </Moment>
            </span>
          </>
        ) : (
          <>
            <span></span>
          </>
        ),
    },
    {
      Header: "Fecha de Lectura Notificación",
      accessor: "fechaLecturaNotificacion",
      Cell: (k) =>
        k.row.original.fechaLecturaNotificacion ? (
          <>
            <span>
              <Moment format="YYYY-MM-DD">
                {k.row.original.fechaLecturaNotificacion}
              </Moment>
            </span>
          </>
        ) : (
          <>
            <span></span>
          </>
        ),
    },
    {
      Header: "Fecha Inicio Atención",
      Cell: (k) =>
        k.row.original.editando &&
        !k.row.original.fechaInicioAtencion &&
        !k.row.original.fechaTerminoAtencion &&
        k.row.original.idEstatus !== 7  && k.row.original.idEstatus !== 3? (
          <div className="form-group">
            <input
              onChange={(event) => {
                cambioData(k.row.original, event, "fechaInicioAtencion", true);
              }}
              className="form-control"
              type="date"
              id={"inpFechaInicioAtencion-" + k.row.original.idSeguimiento}
              defaultValue={
                k.row.original.fechaInicioAtencion
                  ? getDateFormatIso(k.row.original.fechaInicioAtencion)
                  : null
              }
            />
            <label
              htmlFor={"inpFechaInicioAtencion-" + k.row.original.idSeguimiento}
            >
              &nbsp;
            </label>
          </div>
        ) : k.row.original.fechaInicioAtencion ? (
          <>
            <span>
              <Moment format="YYYY-MM-DD">
                {k.row.original.fechaInicioAtencion}
              </Moment>
            </span>
          </>
        ) : (
          <>
            <span></span>
          </>
        ),
    },
    {
      Header: "Plazo Limite",
      accessor: "plazoLimite",
      Cell: (k) =>
        k.row.original.plazoLimite ? (
          <>
            <span>
              <Moment format="YYYY-MM-DD">{k.row.original.plazoLimite}</Moment>
            </span>
          </>
        ) : (
          <>
            <span></span>
          </>
        ),
    },
    {
      Header: "Fecha de Termino",
      Cell: (k) =>
        k.row.original.editando &&
      k.row.original.fechaInicioAtencion &&
        k.row.original.idEstatus !== 0 &&
        !k.row.original.fechaTerminoAtencion &&
        k.row.original.idEstatus !== 7 && k.row.original.idEstatus !== 3 ? (
          <div className="form-group">
            <input
              onChange={(event) => {
                cambioData(k.row.original, event, "fechaTerminoAtencion", true);
              }             
            
            }
            onBlur={(event) => {
             handlerManejaSeguimientoOnBlur(k.row.original,event, "fechaTerminoAtencion",true)
            }             
          
          }
            
              className="form-control"
              type="date"
              id={"inpFechaTerminoAtencion-" + k.row.original.idSeguimiento}
              defaultValue={
                k.row.original.fechaTerminoAtencion
                  ? getDateFormatIso(k.row.original.fechaTerminoAtencion)
                  : null
              }
            />
            <label
              htmlFor={
                "inpFechaTerminoAtencion-" + k.row.original.idSeguimiento
              }
            >
              &nbsp;
            </label>
          </div>
        ) : k.row.original.fechaTerminoAtencion ? (
          <>
            <span>
              <Moment format="YYYY-MM-DD">
                {k.row.original.fechaTerminoAtencion}
              </Moment>
            </span>
          </>
        ) : (
          <>
            <span></span>
          </>
        ),
    },
    {
      Header: "Estatus",
      accessor: "estatus",
      Cell: (k) =>
        k.row.original.editando &&
        k.row.original.idEstatus !== 0 &&
        !k.row.original.fechaTerminoAtencion &&
        k.row.original.idEstatus !== 7 && k.row.original.idEstatus !==8 ? (
          <div className="statusEdicion">
            <Select
              className="statusEdicion"
              id={"idEstatus-" + k.row.original.idSeguimiento}
              isClearable
              isSearchable
              defaultValue={getValueDefault(k.row.original)}
              name={"idEstatus-Name" + k.row.original.idSeguimiento}
              getOptionLabel={(option) => `${option.descripcion}`}
              getOptionValue={(option) => `${option.valor}`}
              onChange={(e) => {
                cambioselectData(k.row.original, e, "idEstatus");
              }}
              options={dtEstatusEdicion}
            />
          </div>
        ) : k.row.original.idEstatus !== 0 ? (
          <>
           <div className="statusEdicion"  >
           <span>{k.row.original.estatus}</span>
           </div>
            
          </>
        ) : (
          <>
            <div className="statusEdicion"  >
           <span></span>
           </div>
          </>
        ),
    },
    {
      Header: "Comentarios de Personal",
      Cell: (k) =>
        k.row.original.editando && k.row.original.idEstatus !== 7 && k.row.original.idEstatus !== 3 ? (
          <div className="form-group">
            <input
              onChange={(event) => {
                cambioData(k.row.original, event, "comentario");
              }}
              className="form-control"
              type="text"
              id={"inpComentario-" + k.row.original.idSeguimiento}
              Value={
                k.row.original.comentario ? k.row.original.comentario : null
              }
            />
            <label htmlFor={"inpComentario-" + k.row.original.idSeguimiento}>
              &nbsp;
            </label>
          </div>
        ) : (
          <>
            <span>{k.row.original.comentario}</span>
          </>
        ),
    },
    {
      Header: "Personal Asignado",
      accessor: "personalAsignado",
    },
  ];

  useEffect(() => {
    toggleLoader(true);
    getInfo("Catalogos/catEmpresas", model, (response) =>
      LoadCmbRazonSocial(response)
    );

    getInfo(
      "Catalogos/catCriterios",
      {
        criterio: "prioridadBuzon",
        filtro_ambiente: ExisteSession.getAmbiente().idAmbiente,
        idUsuarioAct: ExisteSession.getInfoUsuario().idUsuario,
        token: ExisteSession.getInfoUsuario().token,
        connection: ExisteSession.conexion(),
      },
      (response) => LoadCmbPrioridad(response)
    );

    getInfo(
      "Catalogos/catGenerales",
      {
        // tabla: "contSeguimientoBuzonesTbl",
        tabla: "contSeguimientoBuzonesTbl",
        columna: "idEstatus",
        filtro_ambiente: ExisteSession.getAmbiente().idAmbiente,
        idUsuarioAct: ExisteSession.getInfoUsuario().idUsuario,
        token: ExisteSession.getInfoUsuario().token,
        connection: ExisteSession.conexion(),
      },
      (response) => LoadCmbEstatus(response)
    );

    getInfo("seguimientoBuzones", filter, (response) =>
      LoadDTDataTable(response)
    );
  },
   [reload]);

  const LoadCmbRazonSocial = (response) => {
    if (response) setDTRazonSocial(response);
    toggleLoader(false);
  };
  const LoadCmbPrioridad = (response) => {
    if (response) setDTPrioridad(response);
  };
  const LoadCmbEstatus = (response) => {
    if (response) {
      LoadEstatsEdicion(response);
      setDTEstatus(response);
    }
  };

  const valoresDeseados = [3, 5, 6, 8];
  const LoadEstatsEdicion = (response) => {
    if (response) {
      const resultado = response.filter((item) =>
        valoresDeseados.includes(item.valor)
      );
      setDTEstatusEdicion(resultado);
    }
  };

  const LoadDTDataTable = (response) => {
  
    if (response) {
      response.forEach((c) => {
        c.editando = false;
        c.seleccionado = false;
      });
      setDTDataTable(response);
      setdtcloneDataTable(response);
    }

    toggleLoader(false);
  };

  const handleChangeSelectFilter = (event, type, prop) => {
    if (event) {
      setFilter({ ...filter, [type]: event[prop] });
    } else {
      if (type == "nivelPrioridad") {
        setFilter({ ...filter, [type]: "" });
      } else {
        setFilter({ ...filter, [type]: 0 });
      }
    }
  };

  const handlerManejaOnBlur = (event,type) =>{
    let fechaTerget = event.target.value;
     
    if (type === "fechaTerminoAtencion") { 
      if (fechaTerget === null || fechaTerget === "") {
        return;
      }
      if (filter.fechaInicioAtencion) {
        if (filter.fechaInicioAtencion > fechaTerget) {
          fnNotification([
            "La Fecha Termino no puede ser menor a la Fecha Inicio",
            "error",
            true,
          ]);          
        }
      } else {
        fnNotification([
          "Para seleccionar una Fecha Termino debes seleccionar una Fecha Inicio",
          "error",
          true,
        ]);        
      }
    }
    if (type === "fechaInicioAtencion") {
      if (filter.fechaTerminoAtencion) {
        if (filter.fechaTerminoAtencion < fechaTerget) {
          fnNotification([
            "La Fecha Inicio no puede ser mayor a la Fecha Termino",
            "error",
            true,
          ]);          
        }
      }
    }
  }

  const handleChangeDate = (event, type) => {
     if (event) {
      setFilter({ ...filter, [type]: event.target.value });
    } else {
       
      setFilter({ ...filter, [type]: null });
    }
  };
  const onSearch = () => {
    toggleLoader(true);
    setTimeout(() => {
       
      if (filter.fechaInicioAtencion === "" || filter.fechaInicioAtencion === null) {
        setFilter({ ...filter, "fechaInicioAtencion": null });
        filter.fechaInicioAtencion = null;
      }else{

        
      if (  filter.fechaTerminoAtencion === null || filter.fechaTerminoAtencion === "" ) {
         
        fnNotification([
          "La Fecha Término no es válida o no se a definido",
          "error",
          true,
        ]);   
        toggleLoader(false);
        return;
      }

      if (filter.fechaInicioAtencion > filter.fechaTerminoAtencion) {
         
        fnNotification([
          "La Fecha Término no puede ser menor a la Fecha Inicio",
          "error",
          true,
        ]); 
        toggleLoader(false);
        return;         
      }
      }


      if (filter.fechaTerminoAtencion === "" || filter.fechaTerminoAtencion ===null) {
        setFilter({ ...filter, "fechaTerminoAtencion": null });
        filter.fechaTerminoAtencion = null;
      }else{

        if (  filter.fechaInicioAtencion === null || filter.fechaInicioAtencion === "" ) {
           
          fnNotification([
            "La Fecha de Inicio no es válida o no se a definido",
            "error",
            true,
          ]);   
          toggleLoader(false);
          return;
        }


      }

      getInfo("seguimientoBuzones", filter, (response) =>
        LoadDTDataTable(response)
      );
    }, 1000);
  };
  const clickClose = () => {
    window.$("#modalHistorico").modal("hide");
  };

  const clickClosePDF = () => {
    window.$("#modalPDFPreview").modal("hide");
    setPDFReload("");
  };

  const dblClickComp = (originals, tableValues) => {
    var _lista = dtDataTable;
    var _index = _lista.findIndex(
      (c) => c.idSeguimiento === originals.idSeguimiento
    );
    if (_index >= 0) {
      _lista[_index].editando = true;
      _lista[_index].editado = true;
    }
    setDTDataTable(_lista);
    seteditarCampos(true);
    setModel({ ...model, usuarioSelect: true });
  };

  const Guardar = () => {
  
    var _encontrados = dtDataTable.filter((c) => c.editado === true);
    if (_encontrados.length > 0) {
      toggleLoader(true);
      let procesados = 0;
      const total = _encontrados.length;
      _encontrados.forEach((c) => {
        c.filtro_ambiente = ExisteSession.getAmbiente().idAmbiente;
        c.idUsuarioAct = ExisteSession.getInfoUsuario().idUsuario;
        c.token = ExisteSession.getInfoUsuario().token;
        c.connection = ExisteSession.conexion();
        c.nivelPrioridad = "";
        updateInfo("seguimientoBuzones", c, (response) => {
          procesados++;

          if (procesados === total) {
          
            console.log(procesados)
            // Aquí es donde se ejecutan las funciones después de procesar el último elemento
            setModel((prevModel) => ({ ...prevModel, usuarioSelect: null }));
            seteditarCampos(false);
            // Ejecutar las funciones adicionales aquí
            setupdateSearch(true);
            getInfo("seguimientoBuzones", filter, (response) =>
            LoadDTDataTable(response)
            );
          }
        });
      });
     
     

    } else {
      fnNotification(["No se encontraron elementos editados", "info", true]);
    }
  };
  
  const getDateFormat = (fecha) => {
    var _split = fecha.split("T");
    return _split[0];
  };

  const getDateFormatIso = (fecha) => {
    var _split = fecha.replace("T", " ");
    return _split;
  };
  const cambioData = (_obj, event, propiedad, tipoFecha) => {
    if (tipoFecha) {
      _obj[propiedad] = event.target.value;
      _obj["editado"] = true;
    } else {
      _obj[propiedad] = event.target.value;
      _obj["editado"] = true;
    }
  };

  const handlerManejaSeguimientoOnBlur = (_obj, event, propiedad, tipoFecha) => {
    
    let fechaTerget = event.target.value;
    
   
    let fechaInicioAtencion =_obj.fechaInicioAtencion;
    if (propiedad === "fechaTerminoAtencion") { 
      if (fechaTerget === null || fechaTerget === "") {
        return;
      }
      if (fechaInicioAtencion) {
        if (fechaInicioAtencion > fechaTerget) {
          fnNotification([
            "La Fecha Termino no puede ser menor a la Fecha Inicio Atencion",
            "error",
            true,
          ]);          
        }
      } else {
        fnNotification([
          "Para seleccionar una Fecha Termino debes seleccionar una Fecha Inicio Atencion",
          "error",
          true,
        ]);        
      }
    }
    /*
    if (type === "fechaInicioAtencion") {
      if (filter.fechaTerminoAtencion) {
        if (filter.fechaTerminoAtencion < fechaTerget) {
          fnNotification([
            "La Fecha Inicio no puede ser mayor a la Fecha Termino",
            "error",
            true,
          ]);          
        }
      }
    }*/
    


  };



  const cambioselectData = (_obj, event, propiedad) => {
   
    if (event !== null && event.valor !== 0) {
      _obj[propiedad] = event.valor;
      _obj["editado"] = true;
    } else {

      var _lista = dtCloneDataTable;
      var _index = _lista.findIndex(
        (c) => c.idSeguimiento === _obj.idSeguimiento
      );
      if (_index >= 0) {
        _obj[propiedad] = _lista[_index].idEstatus;
        
      }
      
      _obj["editado"] = true;
    }
  };

  const cambioSeleccionado = (_obj, event) => {
    var _lista = dtDataTable;
    _lista.forEach((c) => (c.seleccionado = false));
    if (event.target.checked) {
      var _index = _lista.findIndex(
        (c) => c.idSeguimiento === _obj.idSeguimiento
      );
      _lista[_index].seleccionado = true;
    }
    var count = _lista.filter((c) => c.seleccionado === true).length;
    if (count === 1) {
      setHistoryReload(true);
      // setModel({ ...model, usuarioSelect: true })
    } else {
      setHistoryReload(false);
      //setModel({ ...model, usuarioSelect: false })
    }

    setDTDataTable(_lista);
    setModel({ ...model, usuarioSelect: false });
  };

  const Cancelar = () => {
    var _list = dtDataTable;
    _list.forEach((c) => {
      c.editando = false;
      c.editado = false;
      c.seleccionado = false;
    });
    setDTDataTable(_list);
    setModel({ ...model, usuarioSelect: null });
    seteditarCampos(false);
  };

  const MostrarHistorico = () => {
    var _list = dtDataTable;
    var _index = _list.findIndex((c) => c.seleccionado === true);
    if (_index >= 0) {
      toggleLoader(true);
      var _filter = {
        idSeguimiento: _list[_index].idSeguimiento,
        filtro_ambiente: ExisteSession.getAmbiente().idAmbiente,
        idUsuarioAct: ExisteSession.getInfoUsuario().idUsuario,
        token: ExisteSession.getInfoUsuario().token,
        connection: ExisteSession.conexion(),
      };
      getInfoGral("seguimientoBuzones/getHistorico", _filter, (response) => {
        const sortedBitacoras = response.Data.sort((a, b) => new Date(b.fechaAct) - new Date(a.fechaAct));
        setDTHistorico(sortedBitacoras);
        window.$("#modalHistorico").modal("show");
        toggleLoader(false);
      });
    } else {
      fnNotification([
        "No hay registro seleccionado para visualizar el historico.",
        "info",
        true,
      ]);
    }
  };

  const ShowModalPDF = () => {
    window.$("#modalPDFPreview").modal("show");
    toggleLoader(false);
  };

  const getValueDefault = (original) => {
    return { valor: original.idEstatus, descripcion: original.estatus };
  };

  function decodeUrl(url) {
    console.log(url.replace(/\\u0026/g, "&"));
    return url.replace(/\\u0026/g, "&");
  }
  const PreViewPDF = () => {
    var _list = dtDataTable;
    var _index = _list.findIndex((c) => c.seleccionado === true);
    if (_index >= 0) {
      toggleLoader(true);
      var _filter = {
        idSeguimiento: _list[_index].idSeguimiento,
        filtro_ambiente: ExisteSession.getAmbiente().idAmbiente,
        idUsuarioAct: ExisteSession.getInfoUsuario().idUsuario,
        token: ExisteSession.getInfoUsuario().token,
        connection: ExisteSession.conexion(),
      };
      getInfoGral(
        "seguimientoBuzones/documentoSeguimiento",
        _filter,
        (response) => {
          if (
            response.Data &&
            typeof response.Data.url !== "undefined" &&
            response.Data.url !== ""
          ) {
            setPDFReload(decodeUrl(response.Data.url));
          } else {
            fnNotification([
              "No existe documento para visualizar.",
              "info",
              true,
            ]);
            toggleLoader(false);
          }
        }
      );
    } else {
      fnNotification(["No existe documento para visualizar.", "info", true]);
      toggleLoader(false);
    }
  };

  return (
    <Fragment>
      <Header
        title={inicializa.title + " " + Session.getInfoAmbiente()}
        breadcrum={inicializa.breadcrum}
      />

      <div className="lx-container container">
        <div className="panel panel-primary animated fadeInUp">
          <div className="panel-heading">
            <div className="row">
              <div className="col-md-6">
                <span>Seguimiento Buzon Tributario </span>
              </div>
            </div>
          </div>
          <div className="panel-body">
            <div className="row">
              <div className="col-md-12">
                <div
                  className="col-md-12"
                  style={{
                    borderBottom: "1px solid #157798",
                    paddingBottom: "10px",
                    minHeight: "45px",
                  }}
                >
                  <div className="col-md-7 col-sm-7"></div>

                  <div className="col-md-1 col-sm-1">
                    <ExportExcel
                      excelData={dtDataTable}
                      lisColumnaVisible={lisColumnaVisible}
                      fileName={fileName}
                    ></ExportExcel>
                  </div>
                  <div className="col-md-2 col-sm-2">
                    <Buttons
                      btnHistory={history}
                      btnHistoryText={"Ver Historico"}
                      btnHistoryAction={MostrarHistorico}
                      btnHistoryClass={"btn btn-primary"}
                      btnHistoryShowIcon={true}
                      btnViewdisabled={false}
                      btnViewPdf={history}
                      btnViewPdfText={"Ver Pdf"}
                      btnViewPdfAction={PreViewPDF}
                      btnViewPdfClass={"btn btn-primary"}
                      btnViewPdfShowIcon={true}
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-12" style={{ paddingTop: "20px" }}>
                <div className="row">
                  <div className="col-md-2">
                    <div className="form-group">
                      <label>Razón Social</label>
                      <Select
                        className=""
                        id="idEmpresa"
                        isClearable
                        isSearchable
                        name="idEmpresa"
                        getOptionLabel={(option) => `${option.nombreCorto}`}
                        getOptionValue={(option) => `${option.ID_empresa}`}
                        onChange={(e) => {
                          handleChangeSelectFilter(
                            e,
                            "idEmpresa",
                            "ID_empresa"
                          );
                        }}
                        options={dtRazonSocial}
                      />
                    </div>
                  </div>
                  <div className="col-md-2">
                    <div className="form-group">
                      <label>Fecha Inicio</label>
                      <input
                        type="date"
                        id="fechaInicioAtencion"
                        className="form-control"
                        onChange={(e) =>
                          handleChangeDate(e, "fechaInicioAtencion")
                        }
                        onBlur={(e) =>
                          handlerManejaOnBlur(e, "fechaInicioAtencion")
                        }
                        value={filter.fechaInicioAtencion}
                      />
                    </div>
                  </div>
                  <div className="col-md-2">
                    <div className="form-group">
                      <label>Fecha Termino</label>
                      <input
                        type="date"
                        id="fechaTerminoAtencion"
                        className="form-control"
                        onChange={(e) =>
                          handleChangeDate(e, "fechaTerminoAtencion")
                        }
                        onBlur={(e) =>
                          handlerManejaOnBlur(e, "fechaTerminoAtencion")
                        }
                        value={filter.fechaTerminoAtencion}
                      />
                    </div>
                  </div>
                  <div className="col-md-2">
                    <div className="form-group">
                      <label>Prioridad</label>
                      <Select
                        className=""
                        id="nivelPrioridad"
                        isClearable
                        isSearchable
                        name="nivelPrioridad"
                        getOptionLabel={(option) => `${option.descripcion}`}
                        getOptionValue={(option) => `${option.valor}`}
                        onChange={(e) => {
                          handleChangeSelectFilter(
                            e,
                            "idNivelPrioridad",
                            "valor"
                          );
                        }}
                        options={dtPrioridad}
                      />
                    </div>
                  </div>
                  <div className="col-md-2">
                    <div className="form-group">
                      <label>Estatus</label>
                      <Select
                        className=""
                        id="idEstatus"
                        isClearable
                        isSearchable
                        name="idEstatus"
                        getOptionLabel={(option) => `${option.descripcion}`}
                        getOptionValue={(option) => `${option.valor}`}
                        onChange={(e) => {
                          handleChangeSelectFilter(e, "idEstatus", "valor");
                        }}
                        options={dtEstatus}
                      />
                    </div>
                  </div>
                  <div className="col-md-2" style={{ paddingTop: "25px" }}>
                    <button
                      type="button"
                      rel="tooltip"
                      className="btn btn-primary"
                      data-placement="top"
                      title="Buscar"
                      onClick={onSearch}
                    >
                      <i className="fa fa-search" alt=""></i>
                    </button>
                  </div>
                </div>
              </div>
              <div className="col-md-12">
                <GeneralTable
                  columns={tableColumns}
                  data={dtDataTable}
                  action={undefined}
                  doubleClick={dblClickComp}
                  updateSearch = {updateSearch}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <ModalHistorico clickClose={clickClose} data={dtHistorico} />
      {pdfFileUrl !== "" ? (
        <PdfPreview
          clickClose={clickClosePDF}
          url={pdfFileUrl}
          showmodal={ShowModalPDF}
        />
      ) : (
        ""
      )}

      <div className="row">
        <div className="col-md-2 col-sm-2 floating-div">
          <Buttons
            btnSave={editarCampos}
            btnSaveText={"Guardar"}
            btnSaveAction={Guardar}
            btnSaveClass={"btn btn-primary"}
            btnSaveShowIcon={true}
            btnCancel={editarCampos}
            btnCancelText={"Cancelar"}
            btnCancelAction={Cancelar}
            btnCancelClass={"btn btn-primary"}
            btnCancelShowIcon={true}
          />
        </div>
      </div>
    </Fragment>
  );
};
export default SeguimientoBuzonTributario;
