const inicializa = {
    title : 'Motivos de Correo',
    breadcrum : [{nombre: 'Catalogos'},{nombre: 'Motivos de Correo'}]
}


const tableColumns = [
    { 
        Header: "Título",
        accessor: 'titulo',
    },
    { 
        Header: "Estatus",
        accessor: 'activo',
    },

    
]

export{
    tableColumns,
    inicializa
}