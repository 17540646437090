import React from 'react';

function TabPerfiles({handleChange,description,dataModal,handlechecked,isChecked}) {

    return (
        <div className="row">
        <div className="col-md-6">
            <div className="form-group">
                <label>Descripción</label>                  
                <input
                    type="text"
                    className="form-control"
                    id="descripcion"
                    name="descripcion"
                    onChange={handleChange}
                    value={description}
                    disabled={!dataModal.type}
                    placeholder="Descripción"
                />               
        </div>                        
    </div>
    <div className="col-md-6">
    <div className="form-group">
        <label>Estatus</label>
        <div className="border-checkbox-section">
            <div className="border-checkbox-group border-checkbox-group-success">
                        <input
                            onChange={e => {handlechecked(e)}}
                            checked={isChecked}
                            className="border-checkbox"
                            type="checkbox"
                            id="chkEstatus"
                        />
                        <label className="border-checkbox-label"
                            htmlFor={"chkEstatus"}>&nbsp;</label>
            </div>
        </div>
    </div>
</div>

        </div>
  
    );
}

export default TabPerfiles;