import React, { Fragment, useEffect, useState } from 'react';
import GeneralTable from '../../../Components/GeneralTable';
import { getInfo, saveInfo, updateInfo, deleteInfo } from '../../../ApiRequests/req_CRUD'
import { ExisteSession, toggleLoader } from '../../../ApiRequests/general'
import { fnNotification } from '../../../ApiRequests/Notification'
import { idOrigenWeb } from '../../../Constants/application'
import Header from '../../../Components/Header';
import { inicializa, tableColumns } from './resources'
import Modal from './ModalAdministradorVista.jsx'
import ModalComponentes from './ModalComponentes'
import Buttons from '../../../Components/Buttons/Buttons'
import Session from '../../../Application/session'

const AdministradorVistas = () => {
    const [model, setModel] = useState({
        type: true,
        selectView: null,
        apps: 1,
        typeAction: 'inicia',
        filtro_aplicacion: 38,
        filtro_ambiente: ExisteSession.getAmbiente().idAmbiente,
        idOrigen: idOrigenWeb,
        idUsuarioAct: ExisteSession.getInfoUsuario().idUsuario,
        token: ExisteSession.getInfoUsuario().token,
        connection: ExisteSession.conexion(),
        idVista: 0
    });
    const [reload, setReload] = useState(true);

    const [dataTableUs, setDataTableUs] = useState([])
    const [dataTableAcciones, setDataTableAcciones] = useState([])
    const [dataTableComponentes, setDataTableComponentes] = useState([])
    const [relTableComponentes, setRelTableComponentes] = useState([])
    const [idCompT, setIdCompT] = useState(0)
    const [infoComponente, setInfoComponente] = useState({
        Description: '',
        TypeComp: []
    })
    const [typeModal, setTypeModal] = useState(false)
    const [allViewP, setAllViewP] = useState([])

    let dataInit = {
        type: 0,
        idVista: model.idVista,
        idUsuarioAct: ExisteSession.getInfoUsuario().idUsuario,
        token: ExisteSession.getInfoUsuario().token,
        connection: ExisteSession.conexion(),
    }

    useEffect(() => {
        let dtNew = {
            type: true,
            selectView: null,
            apps: 1,
            typeAction: 'inicia',
            filtro_aplicacion: 38,
            filtro_ambiente: ExisteSession.getAmbiente().idAmbiente,
            idOrigen: idOrigenWeb,
            idUsuarioAct: ExisteSession.getInfoUsuario().idUsuario,
            token: ExisteSession.getInfoUsuario().token,
            connection: ExisteSession.conexion(),
            idVista: 0
        }
        getInfo("Administrador/Vistas", dtNew, (response) => LoadTable(response));
    }, [])

    const LoadTable = (response) => {
        var vist = []
        response.forEach(element => {
            if (!element.tipo) vist.push({ "value": element.idVista, "label": element.descripcionTooltip })
        })
        setAllViewP(vist)
        // localStorage.setItem('VistaPadre', JSON.stringify(vist));
        console.log("76 AdministradorVista ", response);
        setDataTableUs(response);
    }
    const LoadActions = (response,_obj) => {
        setDataTableAcciones(response);
        if(_obj){        
            getInfo("Administrador/RelVistasComponentes", _obj, (response) => {              
                setRelTableComponentes(response)
                window.$('#modalAdministradorVista').modal('show');
            })
        }
        else{
            setRelTableComponentes([]);
            window.$('#modalAdministradorVista').modal('show');
        }
    }
    const showModal = () => {
        setModel({
            ...model,
            selectView: null,
            type: true,
            idVista: 0
        });
        setTimeout(() => {
            getInfo("Administrador/RelVistaAccion", {
                filtro_aplicacion: 38,
                filtro_ambiente: ExisteSession.getAmbiente().idAmbiente,
                idOrigen: idOrigenWeb,
                idUsuarioAct: ExisteSession.getInfoUsuario().idUsuario,
                token: ExisteSession.getInfoUsuario().token,
                connection: ExisteSession.conexion(),
                idVista: 0
            }, (response) => LoadActions(response));
        }, 500);
        // window.$('#modalAdministradorVista').modal('show');
    }

    const handleDoubleClick = (originals, tableValues) => {
        console.log("doble click",originals.idVista);
        setModel({
            ...model,
            selectView: originals,
            type: false,
            typeAction: '',
            idVista: originals.idVista
        });
        setTimeout(() => {
            var _bjt = {
                filtro_aplicacion: 38,
                filtro_ambiente: ExisteSession.getAmbiente().idAmbiente,
                idOrigen: idOrigenWeb,
                idUsuarioAct: ExisteSession.getInfoUsuario().idUsuario,
                token: ExisteSession.getInfoUsuario().token,
                connection: ExisteSession.conexion(),
                idVista: originals.idVista
            };
            getInfo("Administrador/RelVistaAccion",_bjt , (response) => LoadActions(response,_bjt));
        },1000);
    }

    const saveDataUser = dataNewUser => {
        console.log(dataNewUser)
        if (model.type) {
            saveInfo("Administrador/Vistas", dataNewUser, (response) => responseSave(response));
        }
        else {
            updateInfo("Administrador/Vistas", dataNewUser, (response) => responseSave(response));
        }
    }
    const clickDelete = (dataNewUser) => {
        deleteInfo("Administrador/Vistas", dataNewUser, (response) => responseDelete(response));
    }


    const responseSave = (response) => {
        setModel({
            ...model,
            selectView: null,
            type: true,
            idVista: 0
        });
        let dtNew = {
            type: true,
            selectView: null,
            apps: 1,
            typeAction: 'inicia',
            filtro_aplicacion: 38,
            filtro_ambiente: ExisteSession.getAmbiente().idAmbiente,
            idOrigen: idOrigenWeb,
            idUsuarioAct: ExisteSession.getInfoUsuario().idUsuario,
            token: ExisteSession.getInfoUsuario().token,
            connection: ExisteSession.conexion(),
            idVista: 0
        }
        getInfo("Administrador/Vistas", dtNew, (response) => LoadTable(response));

        setReload(!reload);
        window.$('#modalAdministradorVista').modal('hide');
    }
    const responseDelete = (response) => {
        setModel({
            ...model,
            selectView: null,
            type: true,
            idVista: 0
        });
        let dtNew = {
            type: true,
            selectView: null,
            apps: 1,
            typeAction: 'inicia',
            filtro_aplicacion: 38,
            filtro_ambiente: ExisteSession.getAmbiente().idAmbiente,
            idOrigen: idOrigenWeb,
            idUsuarioAct: ExisteSession.getInfoUsuario().idUsuario,
            token: ExisteSession.getInfoUsuario().token,
            connection: ExisteSession.conexion(),
            idVista: 0
        }
        getInfo("Administrador/Vistas", dtNew, (response) => LoadTable(response));
        setReload(!reload);
        window.$('#modalAdministradorVista').modal('hide');
    }

    const actionClose = () => {
        setModel({
            ...model,
            type: false,
            typeAction: ''
        });
        window.$('#modalAdministradorVista').modal('hide');
    }
    const btnClick = () => {
        console.log('Click al modal componente')
        let dataInfo = {
            type: 1,
            filtro_ambiente: ExisteSession.getAmbiente().idAmbiente,
            idOrigen: idOrigenWeb,
            idUsuarioAct: ExisteSession.getInfoUsuario().idUsuario,
            token: ExisteSession.getInfoUsuario().token,
            connection: ExisteSession.conexion(),
        }
        getInfo("Administrador/RelVistasComponentes", dataInfo, (response) => {
            setDataTableComponentes(response)
        })
        window.$('#modalAddComponent').modal('show');

    }
    const clickAddComp = dataComp => {
        toggleLoader(true)
        let dataInfo = {
            idVista: model.idVista,
            idComponente: idCompT,
            idTipoComp: dataComp.Component.value,
            descripcion: dataComp.Description,
            idUsuarioAct: ExisteSession.getInfoUsuario().idUsuario,
            token: ExisteSession.getInfoUsuario().token,
            connection: ExisteSession.conexion(),
        }
        if (dataComp.Component.value !== undefined) {
            !typeModal ?
                saveInfo("Administrador/RelVistasComponentes", dataInfo, (response) => {
                    getInfo("Administrador/RelVistasComponentes", dataInit, (resp) => {
                        setRelTableComponentes(resp)
                    })

                    clickCloseComp()
                }) :
                updateInfo("Administrador/RelVistasComponentes", dataInfo, (response) => {
                    getInfo("Administrador/RelVistasComponentes", dataInit, (resp) => {
                        setRelTableComponentes(resp)
                    })
                    clickCloseComp()
                })
        }
        else {
            fnNotification(['Error: Seleccionar tipo de componente', 'error', true])
        }
    }
    const clickCloseComp = () => {
        setTypeModal(false)
        toggleLoader(false)
        window.$('#modalAddComponent').modal('hide');
    }
    const hDblClickComp = (originals, tableValues) => {
        setIdCompT(originals.idComponente)
        btnClick()
        setInfoComponente({
            ...infoComponente,
            Description: originals.componente,
            TypeComp: { 'value': originals.idTipoComp, 'label': originals.descripcion },
        })
        setTypeModal(true)
        window.$('#modalAddComponent').modal('show');

    }
    const clickDeleteC = id => {
        let dataInfo = {
            idVista: model.idVista,
            idComponente: idCompT,
            idUsuarioAct: ExisteSession.getInfoUsuario().idUsuario,
            token: ExisteSession.getInfoUsuario().token,
            connection: ExisteSession.conexion(),
        }
        deleteInfo("Administrador/RelVistasComponentes", dataInfo, (response) => {
            getInfo("Administrador/RelVistasComponentes", dataInit, (resp) => {
                setRelTableComponentes(resp)
            })
            clickCloseComp()
        })
    }
    return (
        <Fragment>
            <Header title={inicializa.title + ' ' + Session.getInfoAmbiente()} breadcrum={inicializa.breadcrum} />

            <div className="lx-container container">
                <div className="panel panel-primary animated fadeInUp">
                    <div className="panel-heading">
                        <div className="row">
                            <div className="col-md-6">
                                <span>Administrador Vistas</span>

                            </div>
                            <div className="col-md-6 btnF">
                                <Buttons btnAdd={true} btnAddAction={showModal} btnAddText={true} btnAddShowIcon={true} />

                                {/* <button type="button" className="btn btn-primary" onClick={showModal}>Agregar</button> */}
                            </div>

                        </div>
                    </div>
                    <div className="panel-body">
                        <GeneralTable
                            columns={tableColumns}
                            // updateSearch={updateTableSearch} 
                            data={dataTableUs}
                            action={undefined}
                            doubleClick={handleDoubleClick}

                        />
                    </div>

                </div>

            </div>
            <Modal
                model={model}
                saveDataUser={saveDataUser}
                actionClose={actionClose}
                clickDelete={clickDelete}
                acciones={dataTableAcciones}
                modAccions={setDataTableAcciones}
                btnClick={btnClick}
                relTableComponentes={relTableComponentes}
                hDblClickComp={hDblClickComp}
                allViewP={allViewP}
            />
            <ModalComponentes clickAddComp={clickAddComp} clickCloseComp={clickCloseComp} dataTableComponentes={dataTableComponentes} infoComponente={infoComponente} clickDeleteC={clickDeleteC} typeModal={typeModal} />
        </Fragment>

    );
}

export default AdministradorVistas;