import React, { Fragment, useEffect, useState,useRef } from 'react';
import Select from 'react-select';
import GeneralTable from '../../../Components/GeneralTable';
import { getInfo, saveInfo, updateInfo, deleteInfo, getInfoGral } from '../../../ApiRequests/req_CRUD'
import { ExisteSession, toggleLoader } from '../../../ApiRequests/general'
import { idOrigenWeb } from '../../../Constants/application'
import Header from '../../../Components/Header';
import { inicializa } from './resources'
import Buttons from '../../../Components/Buttons/Buttons'
import Session from '../../../Application/session'
import ListView from '../../../Components/ListView/ListView'
import Checkbox from 'antd/lib/checkbox/Checkbox';
import { fnNotification } from '../../../ApiRequests/Notification'
import ModalErrores from './ModalErrores'

const RelacionUsuarioEmpresa = () => {
    const [model, setModel] = useState({
        usuarioSelect: null,
        idEstatus: 1,
        filtro_aplicacion: 38,
        filtro_ambiente: ExisteSession.getAmbiente().idAmbiente,
        idOrigen: idOrigenWeb,
        idUsuarioAct: ExisteSession.getInfoUsuario().idUsuario,
        token: ExisteSession.getInfoUsuario().token,
        connection: ExisteSession.conexion(),
    });
    const [reload, setReload] = useState(true);
    const [isDisabledAplicar, setIsDisabledAplicar] = useState(true);
    const [seleccionarOrigen, setSeleccionarOrigen] = useState(false);
    const [seleccionarDestino, setSeleccionarDestino] = useState(false);
    const [dtUsuarios, setDTUsuarios] = useState([]);
    const [dtUsuariosOriginal, setDTUsuariosOriginal] = useState([]);
    const [dtOrigen, setDTOrigen] = useState({ data: [] });
    const [dtDestino, setDTDestino] = useState({ data: [] });
    const [dtOrigenMod, setDTOrigenMod] = useState({ data: [] });
    const [dtDestinoMod, setDTDestinoMod] = useState({ data: [] });

    const [dtOrigenPrincipal, setDTOrigenPrincipal] = useState({ data: [] });
    const [dtDestinoPrincipal, setDTDestinoPrincipal] = useState({ data: [] });
    const [isChecked, setIsChecked] = useState(false);
    const [erroresTbl,setErroresTbl]       = useState([]);
    const [isConfirmatio,setIsConfirmation]= useState(false);
    const [dtUsuario,setDtUsuario] = useState({dt:{}});

    let textInput = useRef(null);
    useEffect(() => {
        toggleLoader(true);
        getInfo("Usuario", model, (response) => LoadCmbUsuario(response));
    }, [reload])

    const LoadCmbUsuario = (response) => {

        if (response)
            setDTUsuarios(response);
        toggleLoader(false);
    }
    const LoadListaEmpresa = (response) => {

        setIsDisabledAplicar(true);
        if (response && response.length > 0) {
            setDTUsuariosOriginal(response);
            response.forEach(Element => { Element.seleccionado = false });
            response.forEach(Element => { Element.mostrar = true });
            var origen = response.filter(c => c.asignado === 0);
            var destino = response.filter(c => c.asignado === 1);
            setDTOrigenMod({ ...dtOrigenMod, data: origen });
            setDTDestinoMod({ ...dtDestinoMod, data: destino });
            setDTOrigenPrincipal({ ...dtOrigenPrincipal, data: origen });
            setDTDestinoPrincipal({ ...dtDestinoPrincipal, data: destino });
        }
        else{
            setDTUsuariosOriginal([]);
            setDTOrigenMod({ ...dtOrigenMod, data: [] });
            setDTDestinoMod({ ...dtDestinoMod, data: [] });

            setDTOrigenPrincipal({ ...dtOrigenPrincipal, data: []  });
            setDTDestinoPrincipal({ ...dtDestinoPrincipal, data: [] });
        }
        toggleLoader(false);
    } 

    const LoadListaEmpresaCompare = (datosOrigen) => {
          
        if (datosOrigen && datosOrigen.length > 0) {
            var origen = datosOrigen.filter(c => c.asignado === 0);
            var destino = datosOrigen.filter(c => c.asignado === 1);

            var origenLocal = dtOrigenMod.data;
            var destinocLocal = dtDestinoMod.data;

            var origenEnviar = [];
            var destinoEnviar = [];

            origenLocal.forEach(c => {
                var _data = origen.find(ele => ele.idEmpresa === c.idEmpresa);
                if (!_data)
                    origenEnviar.push(c);
            });
            destinocLocal.forEach(c => {
                var _data = destino.find(ele => ele.idEmpresa === c.idEmpresa);
                if (!_data)
                    destinoEnviar.push(c);

            });
            var arrayErorres =[];
            var arrayOrigen = [];
            origenEnviar.forEach(c => { arrayOrigen.push(c.idEmpresa) });
            var arrayDestino = [];
            destinoEnviar.forEach(c => { arrayDestino.push(c.idEmpresa) });


            
            if (arrayOrigen.length > 0) {
                var _saveData = {
                    idUsuario: model.idUsuario,
                    empresas: JSON.stringify({ idEmpresa: arrayOrigen }),
                    tipoAccion: 0,
                    filtro_aplicacion: 38,
                    filtro_ambiente: ExisteSession.getAmbiente().idAmbiente,
                    idOrigen: idOrigenWeb,
                    idUsuarioAct: ExisteSession.getInfoUsuario().idUsuario,
                    token: ExisteSession.getInfoUsuario().token,
                    connection: ExisteSession.conexion(),
                }
                getInfoGral("UsuariosEmpresa/procesa", _saveData, (response) => {
                    if (response.Code !== 200) {
                        var _dt = {
                            idUsuario: model.idUsuario,
                            idEstatus: 1,
                            filtro_aplicacion: 38,
                            filtro_ambiente: ExisteSession.getAmbiente().idAmbiente,
                            idOrigen: idOrigenWeb,
                            idUsuarioAct: ExisteSession.getInfoUsuario().idUsuario,
                            token: ExisteSession.getInfoUsuario().token,
                            connection: ExisteSession.conexion()
                        };
                        getInfo("UsuariosEmpresa", _dt, (response) => LoadListaEmpresa(response));
                        fnNotification([response.Message, 'error', true])
                    }
                    if (response.Code === 200) {
                        fnNotification([response.Message, 'success', true])
                    }
                   
                        window.$('#modalConfirmacion').modal('hide');
                    

                    for (var i = 0; i < response.Data.length; i++) {
                        arrayErorres.push(response.Data[i]);
                    }                        
                    
                    if(arrayErorres.length>0){
                        
                        setErroresTbl(arrayErorres)
                    }
                    
                })
            }
            else{
                window.$('#modalConfirmacion').modal('hide');
               // fnNotification(['No hubo cambios a desasignar empresas', 'info', true])
            }
            if (arrayDestino.length > 0) {
                var _saveDataDestino = {
                    idUsuario: model.idUsuario,
                    empresas: JSON.stringify({ idEmpresa: arrayDestino }),
                    tipoAccion: 1,
                    filtro_aplicacion: 38,
                    filtro_ambiente: ExisteSession.getAmbiente().idAmbiente,
                    idOrigen: idOrigenWeb,
                    idUsuarioAct: ExisteSession.getInfoUsuario().idUsuario,
                    token: ExisteSession.getInfoUsuario().token,
                    connection: ExisteSession.conexion(),
                }
                window.$('#modalConfirmacion').modal('hide');
                getInfoGral("UsuariosEmpresa/procesa", _saveDataDestino, (response) => {
                    if (response.Code !== 200) {
                       
                        getInfo("UsuariosEmpresa", dtUsuario.dt, (response) => LoadListaEmpresa(response));
                        fnNotification([response.Message, 'error', true])



                    }
                    if (response.Code === 200) {
                        console.log('dtUsuario')
                        console.log(dtUsuario)
                        getInfo("UsuariosEmpresa", dtUsuario.dt, (response) => LoadListaEmpresa(response));
                        for (var i = 0; i < response.Data.length; i++) {
                            arrayErorres.push(response.Data[i]);
                        } 
                        if(arrayErorres.length>0){
                            
                            setErroresTbl(arrayErorres);
                            window.$('#modalMovError').modal('show');
                           
                        }else if(arrayErorres.length === 0){
                            fnNotification([response.Message, 'success', true]);
                        }
                       
                    }
                    window.$('#modalConfirmacion').modal('hide');                  
                })
            }
            else{
               // fnNotification(['No hubo cambios para asignar nuevas empresas', 'info', true])
            }
        }
        setIsDisabledAplicar(true);
        toggleLoader(false);
    }

    const btnAction = () => {

        LoadListaEmpresaCompare(dtUsuariosOriginal);

    //getInfo("UsuariosEmpresa", model, (response) => LoadListaEmpresaCompare(response));
    }
    const btnCancelar = () => {
        window.$('#modalConfirmacion').modal('hide');
    }
    const btnConfirmacion = () => {
        window.$('#modalConfirmacion').modal('show');
    }
    const clickCloseError = () =>{
      
        window.$('#modalMovError').modal('hide')    

    }

    const clickPasar = (tipo) => {
          
        let valor=true;
        if (tipo) {
            var _local = dtOrigenMod.data;
            var _localOrigen = dtOrigen.data;
            var _data = dtOrigenMod.data.filter(Element => Element.seleccionado === true);

            var _localPasar = dtDestino.data;
            var _localPasarMostrar = dtDestinoMod.data;
            _data.forEach(element => {
                var _index = _local.findIndex(el => el.idEmpresa === element.idEmpresa);
                var _item = _local[_index];
                _item.seleccionado = false;
                _localPasar.push(_item);
                _localPasarMostrar.push(_item);
                _local.splice(_index, 1);

                var _index2 = _localOrigen.findIndex(el => el.idEmpresa === element.idEmpresa);
                if (_index2 => 0) {
                    _localOrigen.splice(_index2, 1);
                }
            });

           


            setDTOrigen({ ...dtOrigen, data: _localOrigen });
            setDTOrigenMod({ ...dtOrigenMod, data: _local });
            setDTDestino({ ...dtDestino, data: _localPasar });
            setDTDestinoMod({ ...dtDestinoMod, data: _localPasarMostrar });
        }
        else {
            var _local = dtDestinoMod.data;
            var _localOrigen = dtDestino.data;
            var _data = dtDestinoMod.data.filter(Element => Element.seleccionado == true);

            var _localPasar = dtOrigen.data;
            var _localPasarMostrar = dtOrigenMod.data;
            _data.forEach(element => {
                var _index = _local.findIndex(el => el.idEmpresa == element.idEmpresa);
                var _item = _local[_index];
                _item.seleccionado = false;
                _localPasar.push(_item);
                _localPasarMostrar.push(_item);
                _local.splice(_index, 1);

                var _index2 = _localOrigen.findIndex(el => el.idEmpresa == element.idEmpresa);
                if (_index2 => 0) {
                    _localOrigen.splice(_index2, 1);
                }
            });

          


            setDTDestino({ ...dtDestino, data: _localOrigen });
            setDTDestinoMod({ ...dtDestinoMod, data: _local });
            setDTOrigen({ ...dtOrigen, data: _localPasar });
            setDTOrigenMod({ ...dtOrigenMod, data: _localPasarMostrar });
        }

        BotonAplicarDisabled();

    }

    const BotonAplicarDisabled = () =>{
          

        
        var origen = dtUsuariosOriginal.filter(c => c.asignado === 0);
        var destino = dtUsuariosOriginal.filter(c => c.asignado === 1);
       


        var origenLocal = dtOrigenMod.data;
        var destinocLocal = dtDestinoMod.data;

        var origenEnviar = [];
        var destinoEnviar = [];

        origenLocal.forEach(c => {
            var _data = origen.find(ele => ele.idEmpresa === c.idEmpresa);
            if (!_data)
                origenEnviar.push(c);
        });
        destinocLocal.forEach(c => {
            var _data = destino.find(ele => ele.idEmpresa === c.idEmpresa);
            if (!_data)
                destinoEnviar.push(c);

        });
        var arrayErorres =[];
        var arrayOrigen = [];
        origenEnviar.forEach(c => { arrayOrigen.push(c.idEmpresa) });
        var arrayDestino = [];
        destinoEnviar.forEach(c => { arrayDestino.push(c.idEmpresa) });


        if(origenEnviar.length > 0 || destinoEnviar.length > 0 ){
            setIsDisabledAplicar(false)
        }else{
            setIsDisabledAplicar(true)
        }
        
        

    }

    const cambiaSeleccionado = (tipo, evento, registro) => {
        if (tipo) {
            var local = dtOrigenMod.data;
            var _data = dtOrigenMod.data.findIndex(Element => Element.idEmpresa == registro.idEmpresa);
            var _estatus = local[_data].seleccionado;
            local[_data].seleccionado = !_estatus;
            setDTOrigenMod({ ...dtOrigenMod, data: local });
        }
        else {
            var local = dtDestinoMod.data;
            var _data = dtDestinoMod.data.findIndex(Element => Element.idEmpresa == registro.idEmpresa);
            var _estatus = local[_data].seleccionado;
            local[_data].seleccionado = !_estatus;
            setDTDestinoMod({ ...dtDestinoMod, data: local });
        }
    }

    const handleChange = (tipo, evento) => {
       
        if (evento.target.value) {
            if (tipo) {
              
                var _data = dtOrigenMod.data.filter(Element => !Element.empresa.toLowerCase().includes(evento.target.value.toLowerCase()));
                if (_data) {
                    _data.forEach(Element => { dtOrigenMod.data.find((element) => element.idEmpresa == Element.idEmpresa).mostrar = false });
                    var _aux = dtOrigenMod.data;
                    setDTOrigenMod(
                        { ...dtOrigenMod, data: _aux });
                }
            }
            else {
               
                var _data = dtDestinoMod.data.filter(Element => !Element.empresa.toLowerCase().includes(evento.target.value.toLowerCase()));
                if (_data) {
                    _data.forEach(Element => { dtDestinoMod.data.find((element) => element.idEmpresa == Element.idEmpresa).mostrar = false });
                    var _aux = dtDestinoMod.data;
                    setDTDestinoMod({ ...dtDestinoMod, data: _aux });
                }
            }
        }
        else {
            if (tipo) {
                dtOrigenMod.data.forEach(Element => { Element.mostrar = true });
                var _auxOrigen = dtOrigenMod.data;
                setDTOrigenMod({ ...dtOrigenMod, data: _auxOrigen });
            }
            else {
                dtDestinoMod.data.forEach(Element => { Element.mostrar = true });
                var _auxDestino = dtDestinoMod.data;
                setDTDestinoMod({ ...dtDestinoMod, data: _auxDestino });
            }
        }
    }

    const handleChangeSelect = (event) => {
        if (event) {
            setModel({ ...model, usuarioSelect: event, idUsuario: event.idUsuario })
            setIsDisabledAplicar(true);
            toggleLoader(true);
            var _dt = {
                idUsuario: event.idUsuario,
                idEstatus: 1,
                filtro_aplicacion: 38,
                filtro_ambiente: ExisteSession.getAmbiente().idAmbiente,
                idOrigen: idOrigenWeb,
                idUsuarioAct: ExisteSession.getInfoUsuario().idUsuario,
                token: ExisteSession.getInfoUsuario().token,
                connection: ExisteSession.conexion()
            };
            setDtUsuario({dt:_dt});
            getInfo("UsuariosEmpresa", _dt, (response) => LoadListaEmpresa(response));
        }
        else {
            setModel({ ...model, usuarioSelect: null })
            LoadListaEmpresa(null);
        }
    }

    const clickChange = (tipo, evento, registro) => {
        
        registro.seleccionado = false;
        if (tipo) {
            var local = dtOrigenMod.data;
            var _data = dtOrigenMod.data.findIndex(Element => Element.idEmpresa == registro.idEmpresa);

            var _buscar = dtOrigen.data.findIndex(Element => Element.idEmpresa == registro.idEmpresa);
          
            if (_buscar >= 0) {
                var auxDel = dtOrigen.data;
                auxDel.splice(_buscar, 1);
                setDTOrigen({ ...dtOrigen, data: auxDel });
            }
            local.splice(_data, 1);
            setDTOrigenMod({ ...dtOrigenMod, data: local });

            var _aux = dtDestinoMod.data;
            _aux.push(registro);
            setDTDestinoMod({ ...dtDestinoMod, data: _aux });

            var _aux2 = dtDestino.data;
            _aux2.push(registro);
            setDTDestino({ ...dtDestino, data: _aux2 });
        }
        else {
            var local = dtDestinoMod.data;
            var _data = dtDestinoMod.data.findIndex(Element => Element.idEmpresa == registro.idEmpresa);

            var _buscar = dtDestino.data.findIndex(Element => Element.idEmpresa == registro.idEmpresa);
            if (_buscar >= 0) {
                var auxDel = dtDestino.data;
                auxDel.splice(_buscar, 1);
                setDTDestino({ ...dtDestino, data: auxDel });
            }
            local.splice(_data, 1);
            setDTDestinoMod({ ...dtDestinoMod, data: local });

            var _aux = dtOrigenMod.data;
            _aux.push(registro);
            setDTOrigenMod({ ...dtOrigenMod, data: _aux })

            var _aux2 = dtOrigen.data;
            _aux2.push(registro);
            setDTOrigen({ ...dtOrigen, data: _aux2 });
        }

        BotonAplicarDisabled();

    }

    const checkAll = (tipo, event) => {

        if (tipo) {
            var _event = !seleccionarOrigen;
            var _data = dtOrigenMod.data;
            _data.forEach(c => c.seleccionado = _event);
            setDTOrigenMod({ ...dtOrigenMod, data: _data });
            setSeleccionarOrigen(_event);
        }
        else {
            var _event = !seleccionarDestino;
            var _data = dtDestinoMod.data;
            _data.forEach(c => c.seleccionado = _event);
            setDTDestinoMod({ ...dtOrigenMod, data: _data });
            setSeleccionarDestino(_event);
        }
    }
    return (
        <Fragment>
            <Header title={inicializa.title + ' ' + Session.getInfoAmbiente()} breadcrum={inicializa.breadcrum} />

            <div className="lx-container container">
                <div className="panel panel-primary animated fadeInUp">
                    <div className="panel-heading">
                        <div className="row">
                            <div className="col-md-6">
                                <span>Mant. Relación Usuarios - Empresa </span>
                            </div>

                        </div>
                    </div>
                    <div className="panel-body">
                        <div className='row'>
                            <div className='col-md-3'>
                                <div className="form-group">
                                    <label>Usuario</label>
                                    <Select
                                        className=""
                                        id="idUsuario"
                                        isClearable
                                        isSearchable
                                        name="usuario"
                                        getOptionLabel={option => `${option.usuario}`}
                                        getOptionValue={option => `${option.idUsuario}`}
                                        onChange={handleChangeSelect}
                                        options={dtUsuarios}
                                    />
                                </div>
                            </div>
                            <div className='col-md-6'></div>
                            {
                                model.usuarioSelect
                                    ?
                                    <div className='col-md-2 col-sm-10' style={{ paddingTop: "25px", paddingBottom: "25px", paddingRight: '0px !important' }}>                                     
                                        <button
                                            type="button"
                                            rel="tooltip"
                                            className={"button button-primary"}
                                            style={{ float: 'right', marginRight: "-10px" }}
                                            onClick={btnConfirmacion}
                                            disabled={isDisabledAplicar}
                                            data-placement="top"
                                            title={"Aplicar Cambios"}>
                                            Aplicar Cambios &nbsp;
                                            <i className={"fa fa-save"} alt=""></i>
                                        </button>
                                    </div>
                                    :
                                    ""
                            }
                        </div>
                        <ListView
                            tituloOrigen={"Empresas Disponibles"}
                            tituloDestino={"Empresas Asignadas"}
                            Origen={dtOrigenMod}
                            Destino={dtDestinoMod}
                            cambiaSeleccionado={cambiaSeleccionado}
                            clickChange={clickChange}
                            handleChange={handleChange}
                            clickPasar={clickPasar}
                            checkAll={checkAll}
                            seleccionarOrigen={seleccionarOrigen}
                            seleccionarDestino={seleccionarDestino}
                        ></ListView>
                    </div>
                </div>
            </div>
            <div className="modal fade" id="modalConfirmacion" tabIndex="-1" role="dialog" aria-labelledby="modalConfirmacionLabel" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header mhead">
                            <button onClick={btnCancelar} type="button" className="close text-white" data-dismiss="modal" aria-label="Close">
                                <i className="fa fa-times-circle"></i>
                            </button>
                            <span className="modal-title title-modal-custom-color title-modal-custom" id="modalConfirmacionLabel">
                                ¿Desea hacer efectivo la asignación de empresas?
                            </span>
                        </div>
                        <div className="modal-body">
                            <div className='row'>
                                <div className='col-md-3'>
                                </div>
                                <div className='col-md-3'>
                                    <button
                                        type="button"
                                        rel="tooltip"
                                        className={"button button-primary"}
                                        onClick={btnAction}
                                        data-placement="top"
                                        title={"Aceptar"}>
                                        Aceptar
                                    </button>
                                </div>
                                <div className='col-md-3'>
                                    <button
                                        type="button"
                                        rel="tooltip"
                                        className={"button button-secondary"}
                                        onClick={btnCancelar}
                                        data-placement="top"
                                        title={"Aceptar"}>
                                        Cancelar
                                    </button>
                                </div>
                                <div className='col-md-3'>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                        </div>
                    </div>
                </div>
            </div >
            {erroresTbl ? <ModalErrores clickClose={clickCloseError} dataTblError={erroresTbl} /> :''
            }
        </Fragment>

    );
}

export default RelacionUsuarioEmpresa;