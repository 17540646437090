import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { ExisteSession } from '../../../ApiRequests/general'
import Buttons from '../../../Components/Buttons/Buttons';
import GeneralTable from '../../../Components/GeneralTable';
import { idOrigenWeb } from '../../../Constants/application'

const ModalDispersionConfiguracion = ({ saveDataUser, model, actionClose, clickDelete, selecctions, setSelecction }) => {

    const [Register, setModel] = useState({
        idArchivo: 0,
        linea: 0,
        idTipoLinea: 0,
        codigoLinea: "",
        campo: 0,
        descripcion: "",
        idTipoCampo: 0,
        idOrigenDato: 0,
        valorConstante: "",
        idVariable: 0,
        longitud: 0,
        posicionDesde: 0,
        posicionHasta: 0,

        configuracionSelected: null,

        tipo: false,
        tipoConfiguracion: false,
        filtro_aplicacion: 38,
        filtro_ambiente: ExisteSession.getAmbiente().idAmbiente,
        idUsuarioAct: ExisteSession.getInfoUsuario().idUsuario,
        token: ExisteSession.getInfoUsuario().token,
        connection: ExisteSession.conexion(),
    });


    useEffect(() => {
        if (model && model.configuracionSelected) {
            setModel({
                ...Register,
                idArchivo: model.configuracionSelected.idArchivo,
                linea: model.configuracionSelected.linea,
                idTipoLinea: model.configuracionSelected.idTipoLinea,
                codigoLinea: model.configuracionSelected.codigoLinea,
                campo: model.configuracionSelected.campo,
                descripcion: model.configuracionSelected.descripcion,
                idTipoCampo: model.configuracionSelected.idTipoCampo,
                idOrigenDato: model.configuracionSelected.idOrigenDato,
                valorConstante: model.configuracionSelected.valorConstante,
                idVariable: model.configuracionSelected.idVariable,
                longitud: model.configuracionSelected.longitud ? model.configuracionSelected.longitud : 0,
                posicionDesde: model.configuracionSelected.posicionDesde ? model.configuracionSelected.posicionDesde : 0,
                posicionHasta: model.configuracionSelected.posicionHasta ? model.configuracionSelected.posicionHasta : 0,
            });
            setSelecction({
                ...selecctions,
                idTipoLinea: selecctions.idTipoLinea = selecctions.TiposLineas.find(value => value.value == model.configuracionSelected.idTipoLinea),
                idTipoCampo: selecctions.idTipoCampo = selecctions.TiposCampos.find(value => value.value == model.configuracionSelected.idTipoCampo),
                idOrigenDato: selecctions.idOrigenDato = selecctions.OrigenesDatos.find(value => value.value == model.configuracionSelected.idOrigenDato),
                idVariable: selecctions.idVariable = selecctions.Variables.find(value => value.value == model.configuracionSelected.idVariable),
            })
        }
        else {
            setModel({
                ...Register,
                idArchivo: model.idArchivo,
                linea: 0,
                idTipoLinea: 0,
                codigoLinea: "",
                campo: 0,
                descripcion: "",
                idTipoCampo: 0,
                idOrigenDato: 0,
                valorConstante: "",
                idVariable: 0,
                longitud: 0,
                posicionDesde: 0,
                posicionHasta: 0,
                tipo: false
            });
            setSelecction({
                ...selecctions,
                idTipoLinea: null,
                idTipoCampo: null,
                idOrigenDato: null,
                idVariable: null,
            });
        }
    }, [model])

    const handleChange = (event) => {
        setModel({ ...Register, [event.target.name]: Register[event.target.name] = event.target.value })
    }
    const handleChangeSelect = (event, _property) => {
        if (event) {
            setModel({ ...Register, [_property]: Register[_property] = event.value });
            setSelecction({ ...selecctions, [_property]: selecctions[_property] = event });
        }
        else {
            setModel({ ...Register, [_property]: Register[_property] = 0 });
            setSelecction({ ...selecctions, [_property]: selecctions[_property] = null });
        }
    }


    const clickSave = () => saveDataUser(Register)
    const clickDeleteLocal = () => clickDelete(Register)
    const clickClose = () => actionClose()

    return (
        <div className="modal fade" id="modalDispersionConfiguracion" tabIndex="-1" role="dialog" aria-labelledby="modalDispersionmodalDispersionConfiguracionLabel" aria-hidden="true">
            <div className="modal-dialog modal-lg" role="document">
                <div className="modal-content">
                    <div className="modal-header mhead">
                        <button onClick={clickClose} type="button" className="close text-white" data-dismiss="modal" aria-label="Close">
                            {/* <span aria-hidden="true">&times;</span> */}
                            <i className="fa fa-times-circle"></i>
                        </button>
                        <span className="modal-title title-modal-custom-color title-modal-custom" id="modalDispersionmodalDispersionConfiguracionLabel">
                            {!model.tipoConfiguracion ? "Editar Configuracion Dispersion " : "Agregar Configuracion Dispersion"}
                        </span>
                    </div>
                    <div className="modal-body">
                        <div className="row">
                            <div className="col-md-4">
                                <div className="form-group">
                                    <label>Id Archivo</label>
                                    <input
                                        className="form-control"
                                        id="idArchivo"
                                        name="idArchivo"
                                        onChange={() => { }}
                                        value={Register.idArchivo}
                                        placeholder="idArchivo"
                                        maxLength="100"
                                        disabled="disabled" />
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-group">
                                    <label>Linea:</label>
                                    <input
                                        type="number"
                                        className="form-control"
                                        id="linea"
                                        name="linea"
                                        onChange={handleChange}
                                        value={Register.linea}
                                        placeholder="linea"
                                        maxLength="100" />

                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-group">
                                    <label>Tipo de Linea:</label>
                                    <Select
                                        className=""
                                        id="idTipoLinea"
                                        isClearable
                                        isSearchable
                                        name="idTipoLinea"
                                        onChange={(event) => handleChangeSelect(event, "idTipoLinea")}
                                        options={selecctions.TiposLineas}
                                        value={selecctions.idTipoLinea}
                                    />
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-group">
                                    <label>Codigo Linea:</label>
                                    <input
                                        className="form-control"
                                        id="codigoLinea"
                                        name="codigoLinea"
                                        onChange={handleChange}
                                        value={Register.codigoLinea}
                                        placeholder="codigoLinea" />
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-group">
                                    <label>Campo:</label>
                                    <input
                                        type="number"
                                        className="form-control"
                                        id="campo"
                                        name="campo"
                                        onChange={handleChange}
                                        value={Register.campo}
                                        placeholder="campo" />
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-group">
                                    <label>Descripcion:</label>
                                    <input
                                        className="form-control"
                                        id="descripcion"
                                        name="descripcion"
                                        onChange={handleChange}
                                        value={Register.descripcion}
                                        placeholder="descripcion" />
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-group">
                                    <label>Tipo de Campo:</label>
                                    <Select
                                        className=""
                                        id="idTipoCampo"
                                        isClearable
                                        isSearchable
                                        name="idTipoCampo"
                                        onChange={(event) => handleChangeSelect(event, "idTipoCampo")}
                                        options={selecctions.TiposCampos}
                                        value={selecctions.idTipoCampo}
                                    />
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-group">
                                    <label>Origen Datos:</label>
                                    <Select
                                        className=""
                                        id="idOrigenDato"
                                        isClearable
                                        isSearchable
                                        name="idOrigenDato"
                                        onChange={(event) => handleChangeSelect(event, "idOrigenDato")}
                                        options={selecctions.OrigenesDatos}
                                        value={selecctions.idOrigenDato}
                                    />
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-group">
                                    <label>Valor Constante:</label>
                                    <input
                                        className="form-control"
                                        id="valorConstante"
                                        name="valorConstante"
                                        onChange={handleChange}
                                        value={Register.valorConstante}
                                        placeholder="valorConstante" />
                                </div>
                            </div>

                        </div>
                        <div className="row">
                            <div className="col-md-4">
                                <div className="form-group">
                                    <label>Variable:</label>
                                    <Select
                                        className=""
                                        id="idVariable"
                                        isClearable
                                        isSearchable
                                        name="idVariable"
                                        onChange={(event) => handleChangeSelect(event, "idVariable")}
                                        options={selecctions.Variables}
                                        value={selecctions.idVariable}
                                    />
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-group">
                                    <label>Longitud:</label>
                                    <input
                                        type="number"
                                        className="form-control"
                                        id="longitud"
                                        name="longitud"
                                        onChange={handleChange}
                                        value={Register.longitud}
                                        placeholder="longitud" />
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-group">
                                    <label>Posicion Desde:</label>
                                    <input
                                        type="number"
                                        className="form-control"
                                        id="posicionDesde"
                                        name="posicionDesde"
                                        onChange={handleChange}
                                        value={Register.posicionDesde}
                                        placeholder="posicionDesde" />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-4">
                                <div className="form-group">
                                    <label>Posicion Hasta:</label>
                                    <input
                                        type="number"
                                        className="form-control"
                                        id="posicionHasta"
                                        name="posicionHasta"
                                        onChange={handleChange}
                                        value={Register.posicionHasta}
                                        placeholder="posicionHasta" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <Buttons
                            // btnAdd={true} btnAddAction={clickSave} btnAddText={!model.type ? " Modificar" : " Guardar"} btnAddClass={"btn btn-primary"} btnAddShowIcon={true}
                            btnSave={true} btnSaveAction={clickSave} btnSaveText={!model.tipoConfiguracion ? " Modificar" : " Guardar"} btnSaveClass={"btn btn-primary button-circle button-small"} btnSaveShowIcon={true} typeSaved={model.tipoConfiguracion}
                            btnDelete={!model.tipoConfiguracion} btnDeleteAction={clickDeleteLocal} btnDeleteText={" Eliminar"} btnDeleteShowIcon={true}
                            btnCancel={true} btnCancelAction={clickClose} btnCancelText={" Cancelar"} btnCancelShowIcon={true} />

                    </div>
                </div>
            </div>
        </div >
    );
}
export default ModalDispersionConfiguracion;