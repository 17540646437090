//#region Administracion de Vistas
const inicializa = {
    title: 'Generacion Archivos Dispersion',
    breadcrum: [{ nombre: 'Facturacion' }, { nombre: 'Generacion Archvios de Dispersion' }]
}


const tableColumns = [
    {
        Header: "Tesorera",
        accessor: 'empresa',
    },
    {
        Header: "Banco",
        accessor: 'banco',
    },
    {
        Header: "Cuenta",
        accessor: 'numeroDeCuenta',
    },
    {
        Header: "Importe",
        accessor: 'importe',
      
    },
    {
        Header: "Seleccionar",
        Cell: k => (
            <div className="border-checkbox-section">
                <div className="border-checkbox-group border-checkbox-group-success">
                    <input
                    onChange={() => {}}
                        checked={k.row.original.posicionFija}
                        className="border-checkbox"
                        type="checkbox"
                        id={"chkAPlicarPF" + k.row.original.identificador}
                    />
                    <label className="border-checkbox-label"
                        htmlFor={"chkAPlicarPF" + k.row.original.identificador}>&nbsp;</label>
                </div>
            </div>)
    }
]



//#endregion



export { tableColumns, inicializa}