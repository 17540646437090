import React, {Fragment} from 'react';
import Header from '../Components/Header'

const test = () => {
   
    return (
        <Fragment>
            Hola
        </Fragment>
    );

};


export default test;