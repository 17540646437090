import React, { Component } from "react";

class InfoFactura extends Component{
    render(){
        const {DatosFactura} = this.props;
        return (
            <div className="panel-group" id="accordionFactura">
                <div className="panel panel-info-dark">
                    <div className="panel-heading panel-heading-link">
                        <a data-toggle="collapse" data-parent="#accordionFactura" href="#factura">
                        Datos de la Factura
                        </a>
                    </div>
                    <div id="factura" className="panel-collapse collapse in">
                        <div className="panel-body">
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label className="col-md-4 coltrol-label text-right">Forma de Pago: </label>
                                        <div className="col-md-8">
                                            <input 
                                            id="formaDePago"
                                            className="form-control"
                                            // readOnly
                                            onChange={(e) => this.setState(DatosFactura.formaPago = e.target.value)}
                                            ref={inputElement => DatosFactura.formaPago = inputElement} 

                                            type="text"
                                            value={DatosFactura.formaPago}
                                             /> 
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label className="col-md-3 coltrol-label text-right">Condición:</label>
                                        <div className="col-md-9">
                                            <input 
                                            id="condicion"
                                            className="form-control"
                                            onChange={(e) => this.setState(DatosFactura.condicion = e.target.value)}
                                            ref={inputElement => DatosFactura.condicion = inputElement} 
                                            type="text"
                                            value={DatosFactura.condicion}
                                             /> 
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 margin-top-20">
                                <div className="form-group">
                                    <label className="col-md-4 coltrol-label text-right">Método de Pago: </label>
                                    <div className="col-md-8">
                                        <input 
                                        id="metodoDePago"
                                        className="form-control"
                                        onChange={(e) => this.setState(DatosFactura.metodoPago = e.target.value)}
                                        ref={inputElement => DatosFactura.metodoPago = inputElement} 
                                        type="text"
                                        value={DatosFactura.metodoPago} 
                                        /> 
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 margin-top-20">
                                <div className="form-group">
                                    <label className="col-md-4 coltrol-label text-right">Fecha de Facturación: </label>
                                    <div className="col-md-8">
                                        <input 
                                        id="fechaFact"
                                        className="form-control"
                                        onChange={(e) => this.setState(DatosFactura.fechaFactura = e.target.value)}
                                        ref={inputElement => DatosFactura.fechaFactura = inputElement} 
                                        type="text"
                                         value={DatosFactura.fechaFactura} 
                                        /> 
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 margin-top-20">
                                <div className="form-group">
                                    <label className="col-md-4 coltrol-label text-right">Uso CFDI: </label>
                                    <div className="col-md-8">
                                        <input 
                                        id="usoCFDI"
                                        className="form-control"
                                        onChange={(e) => this.setState(DatosFactura.usoCFDI = e.target.value)}
                                        ref={inputElement => DatosFactura.usoCFDI = inputElement} 
                                        type="text"
                                         value={DatosFactura.usoCFDI} 
                                        /> 
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 margin-top-20">
                                <div className="form-group">
                                    <label className="col-md-4 coltrol-label text-right">Versión: </label>
                                    <div className="col-md-8">
                                        <input 
                                        id="version"
                                        className="form-control"
                                        onChange={(e) => this.setState(DatosFactura.version = e.target.value)}
                                        ref={inputElement => DatosFactura.version = inputElement} 
                                        type="text"
                                         value={DatosFactura.version} 
                                        /> 
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <hr className="dotted" />                    
            </div>
        )
    }
}

export default InfoFactura;