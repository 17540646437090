// import { getInfo, saveInfo, updateInfo, deleteInfo, getInfoErase, downloadHelp,cargaArvhivo, downloadErroresM } from '../../../ApiRequests/req_CRUD'
import { getInfo,getInfoErase, downloadHelp,cargaArvhivo,saveInfo,updateInfo, deleteInfo, getInfoGral } from '../../../ApiRequests/req_CRUD'
import { ExisteSession, toggleLoader } from '../../../ApiRequests/general'
import { fnNotification } from '../../../ApiRequests/Notification'



const donwnloadHelpDocument = (request, addCollection) => {   
    console.log(request)
    request = prepareRequest(request)
    downloadHelp('Tesoreria/'+request.typeHelp ,request,(respons) =>  {        
        addCollection(respons)
    })
}
const getFiltros = (request, addVistaAccionHandler) => {
    
    request = prepareRequest(request)
    // console.log('Tesoreria/FiltroDisponibilidad',request);
    getInfoGral('Tesoreria/FiltroDisponibilidad/'+request.typeFilter,request, (res) =>{
        addVistaAccionHandler(res.Data)
    })
}
const getDataTable = (request, addCollectionResp) => {
    request = prepareRequest(request)
    getInfo('Tesoreria/ProgramacionIngresos' ,request,(respons) =>  {        
        addCollectionResp(respons)
    })
}
const insertDataTable = (request, addCollectionResp) => {
    request = prepareRequest(request)
    saveInfo('Tesoreria/ProgramacionIngresos' ,request,(respons) =>  {        
        addCollectionResp(respons)
    })
}
const updateDataTable = (request, addCollectionResp) => {
    request = prepareRequest(request)
    updateInfo('Tesoreria/ProgramacionIngresos' ,request,(respons) =>  {        
        addCollectionResp(respons)
    })
}

const deleteRow = (request, addCollectionResp) => {
    request = prepareRequest(request)
    deleteInfo('Tesoreria/ProgramacionIngresos' ,request,(respons) =>  {        
        addCollectionResp(respons)
    })
}
const cargarArchivo = (request, archivo, addCollection) => {
    // debugger;
    let response;
    let t = ''; 
    
    
    if(archivo.archivo!== null) {
        
        request = prepareRequest(request)

        var data = new FormData()

        data.append('files', archivo.archivo[0])
        data.append('cargarCSVRequest', JSON.stringify(request))

        cargaArvhivo('Tesoreria/ProgramacionIngresos' ,data, request,(respons) =>  {        
        addCollection(respons)
    })

    }
    else
    {
        
        t = t === '' ? ` Es necesario seleccionar un archivo .csv ` : t + ` Es necesario seleccionar un archivo .csv \n`
        // response = null; 
        !response && fnNotification([`Error ` + t   , 'error', true])    
        addCollection(response)
        return response

    }
    
    

}
const prepareRequest = request => {
    request.idUsuarioAct = ExisteSession.getInfoUsuario().idUsuario
    request.token = ExisteSession.getInfoUsuario().token
    request.connection = ExisteSession.conexion()

    return request;
}




export { 
    // getMasivaDisponibilidad,
    getFiltros,   
    insertDataTable, 
    updateDataTable,
    cargarArchivo,
    // saveMasivaDisponibilidad,
    // deleteMasivaDisponibilidad,
    // getLimpiaMasivaDisponibilidad,
    deleteRow,
    donwnloadHelpDocument,
    // cargarArchivo   
    getDataTable 
}