import { deleteInfo, getInfo,saveInfo} from '../../../ApiRequests/req_CRUD'
import { ExisteSession } from '../../../ApiRequests/general'


const getUsers = (request, addCollectionHandler) => {
    request = prepareRequest(request)
    getInfo('Seguridad/Usuarios',request,(collection) =>  {
        addCollectionHandler(collection)
    })

}
const getRelVoid = (request, addVistaAccionHandler) => {
    request = prepareRequest(request)
    getInfo('Administracion/RelVistaUsuario',request, (res) =>{
        addVistaAccionHandler(res)
    })
}
const getRelPVistaAccion = (request, addVistaAccionHandler) => {
    request = prepareRequest(request)
    getInfo('Seguridad/RelPerfilVistaAccion',request, (res) =>{
        addVistaAccionHandler(res)
    })
}
const getPerfiles = (request, addPerfilesVista) => {
    request = prepareRequest(request)
    getInfo('Seguridad/PerfilVistas',request, (res) =>{
        let newDataPerfiles = []
           res.forEach(element =>{
            element.idEstatus && newDataPerfiles.push({'value':element.idPerfil,'label':element.descripcion})
          })
        addPerfilesVista(newDataPerfiles)
    })   
}
const fnSaveUser = (request, addResponseUser) =>{
    request = prepareRequest(request)
    addResponseUser(request)
}


const prepareRequest = request => {
    request.idUsuarioAct = ExisteSession.getInfoUsuario().idUsuario
    request.token = ExisteSession.getInfoUsuario().token
    request.connection = ExisteSession.conexion()
debugger;
    return request;
}
const getRelMotivosUsuario = (request, collection) => {
    request = prepareRequest(request)
    getInfo('usuariosmotivo',request, (res) =>{
        collection(res)
    })
}
const saveMotivosCorreo = (request,addCollection) => {
    request = prepareRequest(request)
    saveInfo("usuariosmotivo",request,(response,type,success) =>{
        addCollection({response,type,success})
    })
  

}
const deleteMotivosCorreo = (request,addCollection) => {
    request = prepareRequest(request)
    deleteInfo("usuariosmotivo",request,(response,type,success) =>{
        addCollection({response,type,success})
    })
  

}
export { 
    getUsers  ,
    getRelVoid ,
    getRelPVistaAccion,
    getPerfiles,
    fnSaveUser,
    getRelMotivosUsuario,
    saveMotivosCorreo,
    deleteMotivosCorreo
}