import React,{Fragment} from 'react';
import Buttons from '../../../Components/Buttons/Buttons'

const ModalMotivoCarreo = ({
    isUpdate,
    clickClose,
    clickDelete,
    clickSave,
    handleChange,
    titulo,
    idEstatus,
    idMotivo

}) => {
    return (
        <div className="modal fade" id="modalMotivoCorreo" tabIndex="-1" role="dialog" aria-labelledby="modalMotivoCorreoLabel" aria-hidden="true">
            <div className="modal-dialog " role="document">
                <div className="modal-content">
                    <div className="modal-header mhead">
                        <div className="row">
                            <div className="col-md-6">
                                <h5 className="modal-title" style={{color: 'white'}} id="modalUsuarioLabel">{isUpdate ? "Edita Motivo Correo" : "Agrega Motivo Correo"}</h5>
                            </div>
                            <div className="col-md-6">
                                <button onClick={clickClose} type="button" className="close text-white" data-dismiss="modal" aria-label="Close">
                                    <i className="fa fa-times-circle"></i>
                                </button>                    
                            </div>
                        </div>
                    </div>
                    <div className="modal-body">
                        <div className="row"> 
                            <div className="col-md-1"></div> 
                            <div className="col-md-10"> 
                                <div className="row">
                                    <div className="col-md-3">
                                        <label>Título</label>
                                    </div>
                                    <div className="col-md-9">
                                        <div className="form-group">
                                            <input 
                                                type="text"
                                                className="form-control" 
                                                id="txtTitulo"
                                                name="txtTitulo"
                                                onChange={handleChange}
                                                value={titulo}
                                                placeholder="Título" />
                                        </div>
                                    </div>
                                </div>
                                {isUpdate && 
                        <Fragment>                        
                            <div className="row">
                                <div className="col-md-3">
                                    <label>Estatus</label>
                                </div>
                                <div className="col-md-9">
                                    <div className="form-group">
                                        <div className="border-checkbox-section">
                                            <div className="border-checkbox-group border-checkbox-group-success">
                                                <input
                                                    onChange={e => {handleChange(e)}}
                                                    checked={idEstatus}
                                                    name="Estatus"
                                                    className="border-checkbox"
                                                    type="checkbox"
                                                    id="chkEstatus"
                                                />
                                                <label className="border-checkbox-label"
                                                        htmlFor={"chkEstatus"}>&nbsp;</label>
                                            </div>
                                        </div>
                                    </div>  
                                </div>
                            </div>          
                        </Fragment>
                        }                 
                            </div>                
                        </div>                       
                    </div>
                    <div className="modal-footer">
                        <Buttons
                        btnSave={true} btnSaveAction={clickSave} btnSaveText={'guarda'} btnSaveShowIcon={true}   
                        btnDelete={isUpdate} btnDeleteAction={clickDelete} btnDeleteText={" Eliminar"} btnDeleteShowIcon={true}
                        btnCancel={true} btnCancelAction={clickClose} btnCancelText={" Cancelar"} btnCancelShowIcon={true}/>
                    </div>
                </div>
            </div>
        </div>
   
    );
};

export default ModalMotivoCarreo;