import { getInfo, saveInfo, updateInfo, deleteInfo, getInfoGral } from '../../../ApiRequests/req_CRUD'
import { ExisteSession, toggleLoader } from '../../../ApiRequests/general'
import { fnNotification } from '../../../ApiRequests/Notification'

// const getMatriz = (request, addCollection) => {
//     request = prepareRequest(request)
//     getInfo('Facturacion/'+request.typeMatriz ,request,(collection) =>  {
//         addCollection(collection)
//     })
// }

const getMatriz = (request, addCollection) => {
    request = prepareRequest(request)
    getInfo('Facturacion/'+request.typeMatriz ,request,(collection) =>  {
        addCollection(collection)
        
    })
}
const getFiltros = (request, addVistaAccionHandler) => {
    request = prepareRequest(request)
    console.log("getFiltros request",request);
    getInfoGral('Facturacion/FiltroMatriz/'+request.typeFilter,request, (res) =>{
        addVistaAccionHandler(res.Data)
    })
}
const saveMatriz = (request,addCollectionMatriz) => {
    request = prepareRequest(request)
    request.idTypeSaved ?
    saveInfo('Facturacion/'+request.typeMatriz ,request,(respons,typeAction,status) =>  {
        addCollectionMatriz(respons)
    }):
    updateInfo('Facturacion/'+request.typeMatriz ,request,(respons,typeAction,status) =>  {
        addCollectionMatriz(respons)

    })

}
const saveNDetail = (request, addCollection) => {
    request = prepareRequest(request)
    getInfo('Facturacion/'+request.typeMatriz ,request,(collection) =>  {
        addCollection(collection)
        
    })
}
const deleteMatriz = (request,addCollecti) => {
    request = prepareRequest(request)
   
    deleteInfo('Facturacion/'+request.typeMatriz ,request,(respons) =>  {
        addCollecti(respons)
    })
    
    
    console.log(request)

}

const validaForm = (request, isDetail,) => {

    let response = true;
    let t = ''; 
    if(request.empresa !== undefined && request.empresa !== null)
    {
        if(request.empresa.value === 0 || request.empresa.value === undefined || request.empresa.value === null){ 
            t = t === '' ? `Empresa no puede ser vacio ` : t + ` Empresa no puede ser vacio \n`
            response = false;   
        }
    }else{
        t = t === '' ? `Empresa no puede ser vacio ` : t + ` Empresa no puede ser vacio \n`
        response = false; 
    }
    if(request.cuenta !== undefined && request.cuenta !== null)
    {
        if(request.cuenta.value  === 0 || request.cuenta.value === undefined  || request.cuenta.value  === null){        
            t = t === '' ? `Cuenta no puede ser vacio ` : t + ` Cuenta no puede ser vacio \n`
            response = false; 
        };
    }else{
        t = t === '' ? `Cuenta no puede ser vacio ` : t + ` Cuenta no puede ser vacio \n`
        response = false; 
    }

    if(!isDetail){  
        if(request.layout !== undefined && request.layout !== null)
        {     
            if(request.layout.value  === 0 || request.layout.value === undefined  || request.layout.value  === null){
                t = t === '' ? `Layout no puede ser vacio` : t + ` Layout no puede ser vacio `        
                response = false;   
            }
        }else{
            t = t === '' ? `Layout no puede ser vacio` : t + ` Layout no puede ser vacio `        
            response = false; 
        }
    
    }
    else{      
        if(parseInt(request.prorrateo)  < 1  || request.prorrateo === undefined  || request.prorrateo === undefined  || request.prorrateo  === null) {
            t = t === '' ? `prorrateo no puede ser menor a 1 ` : t + ` Prorrateo no puede ser menor a 1 \n`
            response = false; 
        }
    }

    
    
    
    
    
    !response && fnNotification([`Error ` + t   , 'error', true])    
    
    return response
}
const errorRow = (message,status) => fnNotification([message    , status ? 'success' : 'error', true])
const succesRow = (message) => fnNotification([message    , 'success', true])
   
const prepareRequest = request => {
    request.idUsuarioAct = ExisteSession.getInfoUsuario().idUsuario
    request.token = ExisteSession.getInfoUsuario().token
    request.connection = ExisteSession.conexion()

    return request;
}

export { 
    getMatriz,
    getFiltros,
    validaForm,
    saveMatriz,
    saveNDetail,
    deleteMatriz,
    errorRow,
    succesRow
}