const inicializa = {
    title : 'Carga Masiva de Disponibilidad',
    breadcrum : [{nombre: 'Facturación'},{nombre: 'Carga Masiva Disponibilidad'}]
}
const requestUs = {
    idUsuario : 0
}

const columnsMasivaDisponibilidadTbl = [
    { 
        Header: "Empresa",
        accessor: 'nombre_largo',
    },
    { 
        Header: "Banco",
        accessor: 'banco',
    },
    { 
        Header: "Cuenta Bancaria",
        accessor: 'numerodecuenta',
    },
    { 
        Header: "Disponible",
        accessor: "importeFormat",
    }, 
    { 
        Header: "Nueva Disponibilidad",
        accessor: "nuevoImporteFormat",
    }, 
    { 
        Header: "Fecha de Vigencia",
        accessor: 'fechaFormat',
    },   
    
]

// const columnsMasivaDisponibilidadErroresTbl = [
//     { 
//         Header: "Empresa",
//         accessor: 'nombre_largo',
//     },
//     { 
//         Header: "Banco",
//         accessor: 'banco',
//     },
//     { 
//         Header: "Cuenta Bancaria",
//         accessor: 'numerodecuenta',
//     },
//     { 
//         Header: "Disponible",
//         accessor: "importeFormat",
//     }, 
//     { 
//         Header: "Nueva Disponibilidad",
//         accessor: "nuevoImporteFormat",
//     }, 
//     { 
//         Header: "Fecha de Vigencia",
//         accessor: 'fechaFormat',
//     },   
//     { 
//         Header: "Error",
//         accessor: 'error',
//     },  
//     { 
//         Header: "Mensaje",
//         accessor: 'mensaje',
//     },  
// ]
const columnsMasivaDisponibilidadErroresTbl = [
    { 
        Header: "idEmpresa",
        accessor: 'idEmpresa',
    },
    { 
        Header: "Empresa",
        accessor: 'nombre_largo',
    },
    { 
        Header: "RFC",
        accessor: 'rfc',
    },
    { 
        Header: "Número de Cuenta",
        accessor: 'numerodecuenta',
    },
    { 
        Header: "Banco",
        accessor: 'banco',
    },
    { 
        Header: "Disponible",
        accessor: 'importeFormat',
    },    
    { 
        Header: "Fecha de Vigencia",
        accessor: 'fechaFormat',
    },   
    { 
        Header: "Error",
        accessor: 'error',
    },  
    { 
        Header: "Mensaje",
        accessor: 'mensaje',
    },  
]

const tableData = []

export { columnsMasivaDisponibilidadTbl,columnsMasivaDisponibilidadErroresTbl,inicializa,tableData }  

