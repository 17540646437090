import React, { Fragment, useEffect, useState } from 'react';
import GeneralTable from '../../../Components/GeneralTable';
import {getInfo,saveInfo,updateInfo} from '../../../ApiRequests/req_CRUD'
import {ExisteSession, toggleLoader} from '../../../ApiRequests/general'
import {fnNotification} from '../../../ApiRequests/Notification'
import { idOrigenWeb } from '../../../Constants/application'
import Header from '../../../Components/Header';
import {
  inicializa,
  tableColumns,
  calasTabs,
  swichTabs,
} from './resources'
import Modal from './ModalUsuario'
import Buttons from '../../../Components/Buttons/Buttons'
import Session from '../../../Application/session'
import {
  getUsers,
  getRelVoid,
  getRelPVistaAccion,
  getPerfiles,
  fnSaveUser,
  getRelMotivosUsuario,
  saveMotivosCorreo,
  deleteMotivosCorreo
} from './Service'

const Usuarios = ({handleClickRefresh}) => {
const [dataTableUs,setDataTableUs]            = useState([])
const [dataRelViewUs,setDataRelViewUs]        = useState([])
const [dataRelViewUsO,setDataRelViewUsO]      = useState([])
const [dataRelMotivosCorreo,setDataRelMotivosCorreo] = useState([]);
const [dataPerfiles,setDataPerfiles]          = useState([])
const [dataNewRel, setDataNewRel]             = useState([])
const [dataNewRelMtv, setDataNewRelMtv]             = useState([])
const [idPerfilAnterior, setIdPerfilAnterior] = useState(0)
// const [isStatus,setIsStatus]               = useState(false)
const [ischeckAll,setIscheckAll]              = useState(0)
const [ischeckAllMtv,setIscheckAllMtv]              = useState(0)
const [ performSearch, setPerformSearch ]     = useState(false)
const [ statusO, setStatusO ]                 = useState(false)


const [model,setModel] = useState({
        type: false,
        idUsuario : '',
        correo : '',
        nombre : '',
        apeP : '',
        apeM : '',
        apps : 1,
        contrasenia : '',
        idPerfil:0,
        idEstatus: false,
        idOrigen : idOrigenWeb,
})
const [dataTabs, setDataTabs] = useState({calasTabs:calasTabs})

  useEffect(() => {
    toggleLoader(true)
    getUsers({
        filtro_ambiente : ExisteSession.getAmbiente().idAmbiente,
        filtro_aplicacion :38,
        apps : 1,
        idOrigen : idOrigenWeb,
      }, 
      collection =>{
        console.log(collection)
        let newList = []
        if(newList !== null && newList !== undefined){
          collection.forEach(element => { 
            element.idEstatus = element.activo === '1' ? true : false 
            element.activo = element.activo === '1' ? 'activo' : 'no activo' 
            newList.push(element)
          })
        }
        console.log(newList)
        setDataTableUs(newList)
        toggleLoader(false)

    })
  },[])
  const showModal = () =>{
    window.$('#modalUsuario').modal('show')
    getRelVoid({ idUsuario: ExisteSession.getInfoUsuario().idUsuario}, collection =>{
      // console.log("collection",collection);
       
      const newList = collection.map((item) => {       
          const updatedItem = {
            ...item,
            idEstatus: false,
          }
          return updatedItem;
      });      
             
       setDataRelViewUs(newList)
       getPerfiles({},
        collection =>{
          setDataPerfiles(collection)
        })
      })
    setModel({
      ...model,
      type:true,
      correo : '',
      nombre : '',
      apeP : '',
      apeM : '',
      idPerfil:'',
    })
    setDataTabs({...dataTabs, calasTabs:calasTabs})

  }
  const actionClose = () => {
    getUsers({
      filtro_ambiente : ExisteSession.getAmbiente().idAmbiente,
      token: ExisteSession.getInfoUsuario().token,
      filtro_aplicacion :38,
      apps : 1,
      idOrigen : idOrigenWeb,
    }, 
    collection =>{
      let newList = []
      if(newList !== null && newList !== undefined){
        collection.forEach(element => {
          element.idEstatus = element.activo === '1' ? true : false 
          element.activo = element.activo === '1' ? 'activo' : 'no activo'                  
          newList.push(element)
        })
      }
      setDataTableUs(newList)
      toggleLoader(false)
      setDataTabs({...dataTabs, calasTabs:swichTabs })
    })
    setDataTabs({...dataTabs, calasTabs:swichTabs })
    setModel({
      ...model,    
      idEstatus : false    

    })
    window.$('#modalUsuario').modal('hide')

  }
    
    const saveDataUser = (dataNewUser,endSave) =>{
      debugger;
      toggleLoader(true)
      console.log('Entra save')
      var regEx = /^(([^<>()\[\]\\.,;:\s@”]+(\.[^<>()\[\]\\.,;:\s@”]+)*)|(“.+”))@((\[[0–9]{1,3}\.[0–9]{1,3}\.[0–9]{1,3}\.[0–9]{1,3}])|(([a-zA-Z\-0–9]+\.)+[a-zA-Z]{2,}))$/
      if(!regEx.test(model.correo)){
        fnNotification([" Formato de correo no valido ", 'error', true]) 
        toggleLoader(false)
        return false
      }
        fnSaveUser(model.type ? {
          nombre : model.nombre,
          apeM: model.apeM,
          apeP: model.apeP,
          correo: model.correo,
          idperfil: parseInt(model.idPerfil ?model.idPerfil : 0),
          apps:"1",
          contrasenia:model.type ? '.' : '',
          idEstatus: model.idEstatus,
          idUsuario: model.idUsuario,
          idOrigen : idOrigenWeb.toString() + "",
          filtro_aplicacion :38,
          ambiente : ExisteSession.getAmbiente().idAmbiente,
        } : {
          nombre : model.nombre,
          apeM: model.apeM,
          apeP: model.apeP,
          correo: model.correo,
          idperfil: parseInt(model.idPerfil),
          apps:["1"],
          contrasenia:model.type ? '.' : '',
          idEstatus: model.idEstatus,
          idUsuario: parseInt( model.idUsuario),
          idOrigen : idOrigenWeb.toString() + "",
          filtro_aplicacion :38,
          ambiente : ExisteSession.getAmbiente().idAmbiente,
        }
        , resp =>{
          
          let newArrayRel = []
          var params=null;
          var params1=null
          console.log('Resp save',resp)
          console.log('Actualizado')
          debugger;
          if(!model.type){
            if(ischeckAll === 0 || ischeckAll === 1){
              dataRelViewUs.forEach(x =>{
                x.idEstatus && newArrayRel.push(x)
              })
            }else{newArrayRel = dataRelViewUs}
            
          }else{newArrayRel = dataNewRel}

          if(!model.type){
            let eliminar=[];
            let agregar=[];

            for(var i=0;i<dataNewRelMtv.length;i++){
                
              if(!dataNewRelMtv[i].exist && dataNewRelMtv[i].activo){
                  var item={
                    idMotivo:dataNewRelMtv[i].idMotivo
                  }
                  agregar.push(item);
                }
                    
                if(dataNewRelMtv[i].exist && !dataNewRelMtv[i].activo){
                  var item2={
                    idMotivo:dataNewRelMtv[i].idMotivo
                  }
                 
                    eliminar.push(item2);
                }
            }
            
            if(agregar.length>0){
              params={
                idUsuario:model.idUsuario,
                motivos:JSON.stringify(agregar)
              }
              // saveMotivosCorreo(params,({response,type,success}) =>{
              //   if(success) {


              //   }});
            }
            
            if(eliminar.length>0){
               params1={
                idUsuario:model.idUsuario,
                motivos:JSON.stringify(eliminar)
              }
              // deleteMotivosCorreo(params1,({response,type,success}) =>{
              //   if(success) {


              //   }});

            }

          }          

         
          let request ={}
          
          request={
            objUsuario: resp,
            objRelVistaUsuario: newArrayRel ,
            idPerfilAnterior: idPerfilAnterior,
            addMotivos:params,
            deleteMotivos:params1,
            ischeckAll:ischeckAll,
            isChangeStatus : statusO !== model.idEstatus ? true : false,
          }

           console.log('statu',model.idEstatus)
           console.log('statusO',statusO)
            console.log('rw',request)
            let paramResp = isSave(model)  
            paramResp.isOk ? saveUser(request) : getError(paramResp)
          
        })

      
      
    }
    const getError = (paramResp) => {
      toggleLoader(false)
      fnNotification(["El campo " + paramResp.dato + " es obligatorio", 'error', true]) 
    }
    const isSave = (request) =>{
      let response = {isOk : true, dato: ""};
      if(request.correo === "") response = {isOk : false, dato: "Correo"} 
      if(request.nombre === "") response = {isOk : false, dato: "Nombre"} 
      if(request.apeP   === "") response = {isOk : false, dato: "Apellido paterno"}  

      // console.log(response)
      return response;

    }
   
    const saveUser = (dtUser,endSave) =>{
      debugger;
      console.log(dtUser)
      model.type ? saveInfo('Seguridad/Usuarios' , dtUser, (response,endSave,succes) => _callBackSave(response,endSave,succes)) :
      updateInfo('Seguridad/Usuarios' , dtUser, (response,endSave,succes) => _callBackUpdate(response,endSave,succes))   

    }
   
    const _callBackSave = (response,endSave,succes) =>{
      setModel({
        ...model,        
        typeAction : 'inicia'
      })
      succes ? actionClose() : toggleLoader(false)

    }
    const _callBackUpdate = (response,endSave,succes) =>{
      if(response[0].idUsuario === ExisteSession.getInfoUsuario().idUsuario) refreshUser()
      setModel({
        ...model,
        
        typeAction : 'inicia'
      })
      if(succes){
        setPerformSearch(true)
        actionClose()
      }else toggleLoader(false)
    }
    const refreshUser = () =>{
      let dataInit = {
        IsMenu : true,
        idUsuarioAct: ExisteSession.getInfoUsuario().idUsuario,
        idUsuario: ExisteSession.getInfoUsuario().idUsuario,
        token: ExisteSession.getInfoUsuario().token,
        connection: ExisteSession.conexion(),
    }
    getInfo("Menu/Menu", dataInit, (response) => {
        // console.log(response);
        toggleLoader(false)
        if(response !== undefined && response !== null){
        // window.location.replace(`${process.env.PUBLIC_URL}/PerfilesVistas`);

        
        localStorage.setItem('Permisos',JSON.stringify(response[0]))

        localStorage.setItem('MenuSession',JSON.stringify(response[1]))
        
        handleClickRefresh(Math.random())                   
        
    }
    });
    }
    const handleDoubleClick = (originals, tableValues) =>{
      console.log(originals)
       
      if(originals.idPerfil === undefined ){
        originals.idPerfil=0;
      }
      setModel({
        ...model,
        type:false,
        nombre : originals.nombre,
        correo : originals.correo,
        apeP : originals.apellidoPat,
        apeM : originals.apellidoMat,
        idPerfil:originals.idPerfil,
        idUsuario: originals.idUsuario,
        idEstatus: originals.idEstatus,
      })
      // setStatusO()
      setStatusO(originals.idEstatus)
      setIdPerfilAnterior(originals.idPerfil)
      if(originals.idPerfil === 0){
        // console.log('Datos sin perfil >>> ', originals.idPerfil)
        console.log("perfil cero");

        getRelPVistaAccion({
          idPerfil:originals.idPerfil,
          idUsuario: originals.idUsuario,
        },
        collection =>{
           
          setDataRelViewUs(collection)
         
           
          getPerfiles({},
            collection =>{
              setDataPerfiles(collection)
            })
        })

      }else{
         
        getRelPVistaAccion({
          idPerfil:originals.idPerfil,
        },
        collection =>{
           
          setDataRelViewUs(collection)
          setDataRelViewUsO(collection)
           
          getPerfiles({},
            collection =>{
              setDataPerfiles(collection)
            })
        })
      }
      setDataTabs({...dataTabs, calasTabs:{}})
      console.log(originals.idEstatus)
      setDataNewRelMtv([]);
      getRelMotivosUsuario({idUsuario:originals.idUsuario},collection=>{
       
       if(collection.length>0){
        var estatus=true;
         for(var i=0;i<collection.length;i++){
              if(!collection[i].activo){
                  estatus=false;
                  break;
              }
         }
         setIscheckAllMtv(estatus);
        }
        setDataRelMotivosCorreo(collection);

      });
      setTimeout(() => {
        setDataTabs({...dataTabs, calasTabs:calasTabs})
        window.$('#modalUsuario').modal('show')
      }, 500);

      
    }
    const handleChangefrmUser = e =>{ 
      
      setModel({...model, [e.target.name] : e.target.value})
      if(e.target.name === 'idPerfil'){
        console.log(e.target.name,e.target)
        getRelPVistaAccion({idPerfil: e.target.value},collection =>{
           
            setDataRelViewUs(collection)})
      } 
    }
    
    const clickClose = () => {
      setModel({
        ...model,
        type:true,
        correo : '',
        nombre : '',
        apeP : '',
        apeM : '',
        idPerfil:0,
    
      })
     
      setDataTabs({...dataTabs, calasTabs:{} })
      setDataRelMotivosCorreo([]);
      setDataNewRelMtv([]);
      toggleLoader(true)
      window.$('#modalUsuario').modal('hide');
      setTimeout(() => {
        setDataTabs({...dataTabs, calasTabs:swichTabs})
        toggleLoader(false)
      }, 500);
      
    }
    const handleChangeChkAll1 = status => {
      let vSt = status ? 1 : 2
      setIscheckAll(vSt)
      
      setModel({
        ...model,
        idPerfil: 0,
      })
      let dataAux = [...dataRelViewUs]
      dataAux.forEach(x => {
        x.idEstatus = status
      })
    }
    const handleChangeChkbx = (rowVal,statusRow,_rows) => {
     
      let rowP = model.type ?
      dataNewRel.find(x => x.idVista === rowVal.idVista &&  x.idAccion === rowVal.idAccion) :
      dataNewRel.find(x => x.idEstatus === !statusRow && x.idVista === rowVal.idVista &&  x.idAccion === rowVal.idAccion) 
      setModel({
        ...model,
        idPerfil:0,
      })
      if(rowP === undefined){
       
          dataNewRel.push({'idPerfil': model.idPerfil,'idVista' :rowVal.idVista,'idAccion': rowVal.idAccion,'idEstatus': statusRow})
      }else{
            let newRInx = dataNewRel.findIndex(x => x.idPerfil === model.idPerfil && x.idVista === rowVal.idVista &&  x.idAccion === rowVal.idAccion && rowVal.idEstatus === !statusRow)
            dataNewRel.splice(newRInx,1)
      }
    }
    const handleChangeChkbxMtv = (rowVal,statusRow,_rows) => {

      var arr=dataRelMotivosCorreo;
      var arrtemp=dataNewRelMtv;
        setDataRelMotivosCorreo([])
        for(var i=0;i<arr.length;i++){
                if(arr[i].idMotivo==rowVal.idMotivo){
                  arr[i].activo=statusRow;
                   var index=arrtemp.findIndex(x=>x.idMotivo==rowVal.idMotivo);
                   if(index==-1)
                    arrtemp.push(arr[i]);
                  else{
                    for(var x=0;x<arrtemp.length;x++){
                        if(arrtemp[x].idMotivo==rowVal.idMotivo)
                        arrtemp[x].activo=statusRow;
                    }
                  }
                }
        }
        setDataNewRelMtv(arrtemp);
        setDataRelMotivosCorreo(dataRelMotivosCorreo);
        toggleLoader(true)
        setTimeout(()=>{refreshGrid()},500);

    }
    const handleChangeChkAllMtv = status => {
      let vSt = status ? 1 : 2
      setIscheckAllMtv(vSt)
     
      let dataAux = [...dataRelMotivosCorreo]
      var arr=dataRelMotivosCorreo;
      dataAux.forEach(x => {
        x.activo=status;
      })
      arr.forEach(x=>{
        x.activo=status
      })
      setDataNewRelMtv(arr);
    }
   
    const handlechecked = e => {
      console.log(e)
      console.log(e.target)
      // setIsStatus(e.target.checked)
      setModel({
        ...model,        
        idEstatus :e.target.checked
      })
    }
    const refreshGrid=()=>{
      
      var arr=dataRelMotivosCorreo;
      setDataRelMotivosCorreo([]);
      setDataRelMotivosCorreo(arr);
      toggleLoader(false)
    }

    return (
        <Fragment>
            <Header title={inicializa.title + ' ' + Session.getInfoAmbiente()} breadcrum={inicializa.breadcrum}/>
            
            <div className="lx-container  container">
              <div className="panel panel-primary animated fadeInUp">
                  <div className="panel-heading">
                    <div className="row">
                        <div className="col-md-6">
                            <span>Usuarios</span>
                        </div>
                        <div className="col-md-6 btnF">
                            <Buttons btnAdd={true} btnAddAction={showModal} btnAddText={true} btnAddShowIcon={true}/>
                        </div>

                    </div>
                    </div>
                    <div className="panel-body">
                            <GeneralTable
                                columns={tableColumns}
                                updateSearch={performSearch} 
                                data={dataTableUs} 
                                action={undefined} 
                                doubleClick={handleDoubleClick}
                            />
                    </div>  
              </div>
            </div> 
            {/* <Modal  model={model} dataRelPerfilVista={dataRelPerfilVista} saveDataUser={saveDataUser} actionClose={actionClose} dataPerfiles={dataPerfiles} idUsuario={idUsuario} loadRelVistaAccion={loadRelVistaAccion} resetPerfil={resetPerfil} isResetPerfil={isResetPerfil}/> */}
            <Modal 
              model={model}
              dataRelPerfilVista={dataRelViewUs} 
              dataRelMtvCorreo={dataRelMotivosCorreo}
              saveDataUser={saveDataUser} 
              actionClose={actionClose} 
              dataPerfiles={dataPerfiles} 
              dataTabs={dataTabs}
              handleChangefrmUser={handleChangefrmUser}
              handleChangeChk={() => {}}
              handleChangeChkAll1={handleChangeChkAll1}
              handleChangeChkbx={handleChangeChkbx}
              handleChangeChkAllMtv={handleChangeChkAllMtv}
              handleChangeChkbxMtv={handleChangeChkbxMtv}
              
            
              handlechecked={handlechecked}
              clickClose={clickClose}
              // isStatus={isStatus}
              />
        </Fragment>
    );
};


export default Usuarios;