import React, { Component } from "react";


class InfoPedido extends Component{

    render(){
        const {DatosPedido} = this.props;

        return(
            <div className="panel-group" id="accordionPedido">
                <div className="panel panel-info-dark">
                    <div className="panel-heading panel-heading-link">
                        <a data-toggle="collapse" data-parent="#accordionPedido" href="#pedido">
                        Datos del pedido
                        </a>
                    </div>
                    <div id="pedido" className="panel-collapse collapse in">
                        <div className="panel-body">
                            <div className="col-md-12">
                                No. Pedido :  &nbsp;
                                <strong>
                                    {DatosPedido.noPedido}
                                </strong>
                            </div>
                            <div className="col-md-12">
                                Id Fiscal :  &nbsp;
                                <strong>
                                    {DatosPedido.idFiscal}
                                </strong>
                            </div>
                            <div className="col-md-12">
                                Sucursal :  &nbsp;
                                <strong>
                                    {DatosPedido.sucursal.toUpperCase()}
                                </strong>
                            </div>
                            <div className="col-md-12">
                                Moneda :  &nbsp;
                                <strong>
                                    {DatosPedido.moneda}
                                </strong>
                            </div>
                            <div className="col-md-12">
                                Sub Total : &nbsp;
                                <strong>
                                    $ {DatosPedido.SubTotal}
                                </strong>
                            </div>
                            <div className="col-md-12">
                                Total IVA : &nbsp;
                                <strong>
                                    $ {DatosPedido.TotalIva}
                                </strong>
                            </div>
                            <div className="col-md-12">
                                Total Neto : &nbsp;
                                <strong>
                                    $ {DatosPedido.TotalNeto}
                                </strong>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default InfoPedido;