const inicializa = {
    title : 'Configuraciones',
    breadcrum : [{nombre: 'Conf.GRAL'},{nombre: 'Configuraciones'}]
}

const dataTable = [{
    nombre : 'Refresca menú'
}]

export {inicializa,dataTable}  
