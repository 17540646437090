import React,{Fragment,useState, useEffect} from 'react';
import {inicializa, columData, columDataMasiva} from './resources'
import {donwnloadHelpDocument,getFiltros,getDataTable,insertDataTable,deleteRow,updateDataTable,cargarArchivo} from './service'
import Header  from '../../../Components/Header';
import Container  from '../../../Components/Container/ContainerInitial';
import Session from '../../../Application/session'
import Buttons from '../../../Components/Buttons/Buttons';
import GeneralTable from '../../../Components/GeneralTable';
import { ExisteSession, toggleLoader } from '../../../ApiRequests/general';
import ModalProgIngersos from './ModalProgIngersos';
import {fnNotification} from '../../../ApiRequests/Notification'

const ProgramacionIngresos = () => {
    const [listEmpresas,setListEmpresas]       = useState([])  
    const [listCuentas,setListCuentas]         = useState([])
    const [archivos,setArchivos]               = useState(null)   
    const [typeTab,setTypeTab]                 = useState(false)    
    const [dataTable,setDataTable]             = useState([])
    const [dataTableMasiva,setDataTableMasiva] = useState([])
    const [listCuentasC,setListCuentasC]       = useState([])
    const fileInput                            = React.useRef();
    const [dataSaved,setDataSaved]             = useState({
        isSaved: false,
        idMovimiento:0,
        empresa: [],
        cuenta:  [],
        fecha:   '',
        importe: '',
    })

    useEffect(() => {
        getDataTable({
            TypeSpc:'Tbl'
        }, collection => {
            setDataTable(collection)
            getDataTable({
                TypeSpc:'TempTbl'
                
            }, collectionM =>{
                setDataTableMasiva(collectionM)
                getFiltros({            
                    idUser:ExisteSession.getInfoUsuario().idUsuario,
                    typeFilter:"Empresas",
                    tabla:'conMatrizTbl',
                    columna:'idTipoConfiguracion'
                    
                    }, 
                    collection =>{
                    toggleLoader(false)
                    if(collection !== undefined && collection !== null && collection.length > 0){                
                        setListEmpresas(collection[0].ListEmpresas)                
                    }
                    getFiltros({
                        idUser:ExisteSession.getInfoUsuario().idUsuario,
                        typeFilter:"Cuentas",                
                        }, collection =>{
                        toggleLoader(false)
                        setListCuentas(collection)
                    })
        
                })
            })
            
        })
        
    },[])
    const showModalAdd = () =>{
        window.$('#modalProgIngersos').modal('show')
        setDataSaved({
            ...dataSaved,
            isSaved : true,
        })
    }
    const subirArchivos=e=>{   
        setArchivos(e); 
        // setFileInput(e)   

    }
    const insertarArchivo = () => {
                toggleLoader(true)        
                cargarArchivo({ 
                },  {archivo: archivos,},
                collection =>{
                    // this.fileInput.value = "";
                    // setFileInput('')
                    fileInput.current.value = "";
                    setArchivos(null);    
                    console.log("collection", collection)
                    if(collection !== undefined && collection !== null)
                    {
                        if(collection.length > 0){
                            if(collection[0].MessageD !== undefined && collection[0].MessageD !== null){ 
                                fnNotification([collection[0].MessageD, 'success', true])
                                getDataTable({
                                    TypeSpc:'TempTbl'
                                    
                                }, collectionM =>{
                                    setDataTableMasiva(collectionM)
                                })
                            }

                        }
                    }

                    // if(collection)
                        // setDataTableMasivaDisponibilidadErrores(collection)
                    toggleLoader(false)       
                    
                    })
    }
    
    const btnH = () => 
    <Fragment>  
    <div className="row">
        <div className="col-md-9">                          
        {/* {typeTab && <input type="file" name='files' onChange={(e) => subirArchivos(e.target.files)} accept=".csv" /> }                                   */}
        </div>
        <div className="col-md-3">
            {/* <Buttons 
                btnAdd  = {!typeTab} btnAddAction  = {e => showModalAdd()} btnAddText='Agregar' btnAddShowIcon={true}
                btnAyuda= {typeTab} btnAyudaAction= {downloadHelp} btnAyudaText={'Ayuda'} btnAyudaShowIcon={true}  
                btnUpload={typeTab} btnUploadAction={insertarArchivo} btnUploadText={" Cargar Archivo"} btnUploadShowIcon={true}
            /> */}
             <Buttons 
                btnAdd  = {!typeTab} btnAddAction  = {e => showModalAdd()} btnAddText='Agregar' btnAddShowIcon={true}
            />
        </div>
    </div>
    </Fragment>    
    const cmpBtnAdd = () => 
    <div className="row">
        <div className="col-md-12">
            <Buttons 
                btnAdd  = {true} btnAddAction  = {e => showModalAdd()} btnAddText='Agregar' btnAddShowIcon={true}
            />
        </div>
    </div>

    const cmpBtnFile = () => 
    <div className="row">
         <div className="col-md-9">                          
        {typeTab && <input type="file" name='files' ref={fileInput} onChange={(e) => subirArchivos(e.target.files)} accept=".csv" /> }                                  
        </div>
        <div className="col-md-3">
            <Buttons 
                btnAyuda= {true} btnAyudaAction= {downloadHelp} btnAyudaText={'Ayuda'} btnAyudaShowIcon={true}  
                btnUpload={true} btnUploadAction={insertarArchivo} btnUploadText={" Cargar Archivo"} btnUploadShowIcon={true}
            />
        </div>
    </div>

    
    const contBody = () =>
    <div className="col-md-12">
        <ul className="nav nav-pills nav-justified ar-nav-pills center-block margin-bottom">
            <li className="active" onClick={() => setTypeTab(false)}>
                <a href="#ProgramaIngresos" data-toggle="tab" >
                    <i className="fa fa-dollar"></i> Programación de ingresos
                </a>
            </li>
            <li className="" onClick={() => setTypeTab(true)}>
                <a href="#ProgramaIngresosMasiva" data-toggle="tab" >
                    <i className="fa fa-dollar"></i> Programación de ingresos Masivo
                </a>
            </li>
        </ul>
        <div className="tab-content margin-top">
            <div className="tab-pane active" id="ProgramaIngresos">
            <GeneralTable
                    columns={columData}
                    data={dataTable}
                    action={undefined}
                    doubleClick={(original,values) => showModalUpdate(original,values)} 
                    // isCpmpBtn={true}
                    // compnnt={cmpBtnAdd}

            />
            </div>
            <div className="tab-pane" id="ProgramaIngresosMasiva">
            <GeneralTable
                    columns={columDataMasiva}
                    data={dataTableMasiva}
                    action={undefined}
                    doubleClick={() => {}}  
                    isCpmpBtn={true}
                    compnnt={cmpBtnFile}
            />
            </div>
        </div>
    </div>
    const showModalUpdate = (original,values) => {
        console.log(original)
        console.log(values)
        setDataSaved({
            ...dataSaved,
            isSaved : false,
            idMovimiento: original.idMovimiento,
            empresa : {value:original.idCompania,label:original.compania},
            cuenta : {value:original.idCuenta,label:original.banco+'-'+original.numeroDeCuenta},
            fecha : getDateA(original,values),
            importe : original.importe
        })
        window.$('#modalProgIngersos').modal('show')
        getFiltros({
            idUser:ExisteSession.getInfoUsuario().idUsuario,
            typeFilter:"Cuentas",                
            idCompania: original.idCompania
            }, collection =>{
            toggleLoader(false)            
            setListCuentasC(collection)
        })
    }
    const getDateA = (original,values) => {
        console.log(original)
        let f = original.FechaIngresoSt.split('/')[2] + '-' + original.FechaIngresoSt.split('/')[1] + '-' + original.FechaIngresoSt.split('/')[0]
        return f
    }
    const downloadHelp = () => {
        
        toggleLoader(true)
        donwnloadHelpDocument({
            typeHelp: 'ProgramacionIngresos',
            archivo: 'CargaMasiva_ProgramacionIngresos.csv',
                    }, collection =>{
                            
                            toggleLoader(false)

                        })


    }
    const handleChange = (e,name) => {
        if(name === 'empresa' || name === 'cuenta'){
            setDataSaved({
                ...dataSaved,
                [name]:e
            })
            if(name === 'empresa'){
                
                getFiltros({
                    idUser:ExisteSession.getInfoUsuario().idUsuario,
                    typeFilter:"Cuentas",                
                    idCompania: e.value
                    }, collection =>{
                    toggleLoader(false)            
                    setListCuentasC(collection)
                })
            }
        }else{
            setDataSaved({
                ...dataSaved,
                [name]:e.target.value
            })
        }
    }

    const clickSave = () => {
        if(dataSaved.isSaved){
            insertDataTable({
                idCompania : dataSaved.empresa.value,
                idCuenta : dataSaved.cuenta.value,
                fechaIngreso : dataSaved.fecha,
                importe : dataSaved.importe
    
            }, collection => {
                let dataNew = {
                    idMovimiento : collection.idMovimiento,
                    compania : listEmpresas.find(x => x.value === collection.idCompania).label,
                    banco : listCuentas.find(x => x.value === collection.idCuenta).label.split('-')[0],
                    numeroDeCuenta : listCuentas.find(x => x.value === collection.idCuenta).label.split('-')[1],
                    FechaIngresoSt : collection.FechaIngresoSt,
                    importe : collection.importe

                }
                let dataAux = [...dataTable]
                dataAux.push(dataNew)
                setDataTable(dataAux)
                window.$('#modalProgIngersos').modal('hide')
                setDataSaved({
                    ...dataSaved,
                    isSaved: false,
                    idMovimiento:0,
                    empresa: [],
                    cuenta:  [],
                    fecha:   '',
                    importe: '',
                    
                })

            })

        }else{
            updateDataTable({
                idMovimiento : dataSaved.idMovimiento,
                idCompania : dataSaved.empresa.value,
                idCuenta : dataSaved.cuenta.value,
                fechaIngreso : dataSaved.fecha,
                importe : dataSaved.importe
            }, collection =>{
                console.log("listEmpresas",listEmpresas);
                console.log("collection[0].idCompania",collection[0].idCompania);
                window.$('#modalProgIngersos').modal('hide')
                let dataAux = [...dataTable]
                let dataNew = {
                    idMovimiento : collection[0].idMovimiento,
                    compania : collection[0].idCompania ? listEmpresas.find(x => x.value === collection[0].idCompania).label:"",
                    banco :collection[0].idCuenta ? listCuentas.find(x => x.value === collection[0].idCuenta).label.split('-')[0]:"",
                    numeroDeCuenta : collection[0].idCuenta ?listCuentas.find(x => x.value === collection[0].idCuenta).label.split('-')[1]:"",
                    FechaIngresoSt : collection[0].FechaIngresoSt,
                    importe : collection[0].importe

                }
                let indx = dataAux.findIndex(x => x.idMovimiento === collection[0].idMovimiento)
                dataAux[indx] = dataNew;
                setDataTable(dataAux)
                setDataSaved({
                    ...dataSaved,
                    isSaved: false,
                    idMovimiento:0,
                    empresa: [],
                    cuenta:  [],
                    fecha:   '',
                    importe: '',
                    
                })

            })

        }
        


    }
    const clickClose = () => {
        window.$('#modalProgIngersos').modal('hide')
        setDataSaved({
            ...dataSaved,
            empresa: [],
            cuenta:  [],
            fecha:   '',
            importe: '',
            
        })

    }
    const clickDelete = () => {
        toggleLoader(true)            

        deleteRow({
            idMovimiento: dataSaved.idMovimiento

        }, response =>{
            response?.map(element => {
                let dataAux = [...dataTable]
                let indx = dataAux.findIndex(x => x.idMovimiento === element.idMovimient)
                dataAux.splice(indx,1);
                setDataTable(dataAux)
                setDataSaved({
                    ...dataSaved,
                    isSaved: false,
                    idMovimiento:0,
                    empresa: [],
                    cuenta:  [],
                    fecha:   '',
                    importe: '',
                    
                })
                return null
            })
            toggleLoader(false)   
            window.$('#modalProgIngersos').modal('hide')


        })
    }
    return (
        <Fragment >
            <Header title={inicializa.title + ' ' + Session.getInfoAmbiente()} breadcrum={inicializa.breadcrum}/>
            <Container 
                title={inicializa.title}
                btnH={btnH()}
                contBody={contBody()}
                spaceTitle='6'
                spaceBtn='6'
            />
            <ModalProgIngersos
                listEmpresas={listEmpresas}                
                listCuentas={listCuentasC}
                dataSaved={dataSaved}
            //    isEmpresa={isEmpresa}                         
                handleChange={handleChange}
               clickSave={clickSave}
               clickDelete={clickDelete}
               clickClose={clickClose}  
            textTypeSaved={"Ingreso programado"}

            />


        </Fragment>

    );
};

export default ProgramacionIngresos;