// import { getInfo, saveInfo, updateInfo, deleteInfo, getInfoErase, downloadHelp,cargaArvhivo, downloadErroresM } from '../../../ApiRequests/req_CRUD'
import { getInfoErase, downloadHelp,cargaArvhivo } from '../../../ApiRequests/req_CRUD'
import { ExisteSession, toggleLoader } from '../../../ApiRequests/general'
import { fnNotification } from '../../../ApiRequests/Notification'


// const getMasivaDisponibilidad = (request, addCollection) => {
    
    
//     request = prepareRequest(request)
//     getInfo('Tesoreria/'+request.typeMasivaDisponibilidad ,request,(respons) =>  {        
//         addCollection(respons)
//     })
// }
// const getFiltros = (request, addCollection) => {
    
//     request = prepareRequest(request)
//     getInfo('Tesoreria/FiltroDisponibilidad',request, (res) =>{
//         addCollection(res)
//     })
// }
// const saveMasivaDisponibilidad = (request,addCollection) => {
    
//     request = prepareRequest(request)
//     request.idType ?
//     saveInfo('Tesoreria/'+request.typeMasivaDisponibilidad ,request,(respons) =>  {
//         addCollection(respons)
//     }):
//     updateInfo('Tesoreria/'+request.typeMasivaDisponibilidad ,request,(respons) =>  {
//         addCollection(respons)
//     })
    
    

// }
// const deleteMasivaDisponibilidad = (request,addCollection) => {
//     request = prepareRequest(request)
   
//     deleteInfo('Tesoreria/'+request.typeMasivaDisponibilidad ,request,(respons) =>  {
//         addCollection(respons)
//     })
    
    
    

// }

const getLimpiaMasivaDisponibilidad = (request, addCollection) => {
    
    
    request = prepareRequest(request)
    getInfoErase('Tesoreria/'+request.typeMasivaDisponibilidad ,request,(respons) =>  {        
        addCollection(respons)
    })
}

const donwnloadHelpDocument = (request, addCollection) => {   
    
    request = prepareRequest(request)
    downloadHelp('Tesoreria/'+request.typeMasivaDisponibilidad ,request,(respons) =>  {        
        addCollection(respons)
    })
}

const cargarArchivo = (request, archivo, addCollection) => {
    debugger;
    let response = true;
    let t = ''; 
    
    
    if(archivo.archivo!== null) {
        
        request = prepareRequest(request)

        var data = new FormData()

        data.append('files', archivo.archivo[0])
        data.append('cargarCSVRequest', JSON.stringify(request))

        cargaArvhivo('Tesoreria/' + request.typeMasivaDisponibilidad ,data, request,(respons) =>  {        
        addCollection(respons)
    })

    }
    else
    {
        
        t = t === '' ? ` Es necesario seleccionar un archivo .csv ` : t + ` Es necesario seleccionar un archivo .csv \n`
        response = false; 
        !response && fnNotification([`Error ` + t   , 'error', true])    
        addCollection(response)
        return response

    }
    
    

}



const prepareRequest = request => {
    request.idUsuarioAct = ExisteSession.getInfoUsuario().idUsuario
    request.token = ExisteSession.getInfoUsuario().token
    request.connection = ExisteSession.conexion()

    return request;
}

export { 
    // getMasivaDisponibilidad,
    // getFiltros,    
    // saveMasivaDisponibilidad,
    // deleteMasivaDisponibilidad,
    getLimpiaMasivaDisponibilidad,
    donwnloadHelpDocument,
    cargarArchivo    
}