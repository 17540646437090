import { getInfo,saveInfo,updateInfo, deleteInfo } from '../../../ApiRequests/req_CRUD'
import { ExisteSession } from '../../../ApiRequests/general'
// import { fnNotification } from '../../../ApiRequests/Notification'

const getDataTable = (request, addCollectionResp) => {
    request = prepareRequest(request)
    getInfo(request.endPoint ,request,(respons) =>  {        
        addCollectionResp(respons)
    })
}

const saveDataInfo = (request, _addCollection) => {
    console.log(request)
    request = prepareRequest(request)
    saveInfo(request.endPoint ,request,(collection,type,status) => {
        _addCollection(collection,type,status)
    })
}
const updateDataInfo = (request, _addCollection) => {
    request = prepareRequest(request)
    updateInfo(request.endPoint ,request,(collection,type,status) => {
        _addCollection(collection,type,status)
    })
}

const deleteRow = (request, _addCollection) => {
    request = prepareRequest(request)
    deleteInfo(request.endPoint ,request,(collection,type,status) => {
        _addCollection(collection,type,status)
    })
}
const fnOpen = (_isOpen) => {
    // setIsOpen(_isOpen)
    // console.log('_isOpen',_isOpen)
    // _isOpen ? console.log('showModal') : console.log('closeModal')
    _isOpen ? window.$('#modalReglas').modal('show') : window.$('#modalReglas').modal('hide') 


}

const prepareRequest = request => {
    request.idUsuarioAct = ExisteSession.getInfoUsuario().idUsuario
    request.token = ExisteSession.getInfoUsuario().token
    request.connection = ExisteSession.conexion()

    return request;
}

export {
    getDataTable,
    saveDataInfo,
    updateDataInfo,
    deleteRow,
    fnOpen
}