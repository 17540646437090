import React,{ Component } from "react";

const BtnViewPdf = ({ btnViewPdfAction, btnViewPdfClass, btnViewPdfText, btnViewPdfIconClass, btnViewPdfShowIcon, btnViewdisabled }) => {

        return(
            <button 
            type="button" 
            rel="tooltip" 
           // className="button button-primary button-circle button-small"
            className={btnViewPdfClass ? btnViewPdfClass : "btn btn-info button-circle button-small"}  
            onClick={btnViewPdfAction}
            data-toggle="tooltip" 
            disabled={btnViewdisabled} // Aquí se añade la prop disabled
            data-placement="top" 
            style={{marginLeft: '5px'}}
            title=
                {btnViewPdfText
                    ?
                    btnViewPdfText
                    :
                    "Ver Preview PDF"}
            >
            {btnViewPdfShowIcon
                ?
                <i className={btnViewPdfIconClass ? btnViewPdfIconClass : "fa fa-file-pdf-o"} alt="Ver PDF"></i>
                :
                <></>} 
            </button>
        )
}
export default BtnViewPdf;
