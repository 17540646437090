const inicializa = {
    title : 'Configuración de Matriz',
    breadcrum : [{nombre: 'Facturación'},{nombre: 'Configutación de Matriz'}]
}
const requestUs = {
    idUsuario : 0
}
// const columsMatrizDetTblT = [
//     { 
//         Header: "Tesorera",
//         accessor: 'nombre_largo',
//     },
//     { 
//         Header: "Banco",
//         accessor: 'nombreBanco',
//     },
//     { 
//         Header: "cuenta",
//         accessor: 'numerodecuenta',
//     },
//     { 
//         Header: "Prorrateo",
//         accessor: 'prorrateoFormat',
//     },
//     { 
//         Header: "Fecha Vigencia",
//         accessor: 'fechaVigencia',
//     },
// ]
const columsMatrizDetTbl = [
    { 
        Header: "Dispersión",
        accessor: 'nombre_largo',
    },
    { 
        Header: "Banco",
        accessor: 'nombreBanco',
    },
    { 
        Header: "Giro",
        accessor: 'giro',
    },
    { 
        Header: "Marca",
        accessor: 'descripcionMarca',
    },
    { 
        Header: "cuenta",
        accessor: 'numerodecuenta',
    },
    { 
        Header: "Prorrateo",
        accessor: 'prorrateoFormat',
    },
    { 
        Header: "Fecha Vigencia",
        accessor: 'fechaVigencia',
    },
]

const columnsMatrizTblT = [
    { 
        Header: "Frente",
        accessor: 'nombre_largo',
    },
    { 
        Header: "Banco",
        accessor: 'nombreBanco',
    },
    { 
        Header: "Giro",
        accessor: 'giro',
    },
    { 
        Header: "Marca",
        accessor: 'descripcionMarca',
    },
    { 
        Header: "cuenta",
        accessor: 'numerodecuenta',
    },
    { 
        Header: "Archivo",
        accessor: 'nombreArchivo',
    },
    { 
        Header: "Estatus",
        accessor: 'Estatus',
    },
    { 
        Header: "Tesoreras",
        accessor: "btnAction",
    },  
]
const columnsMatrizTbl = [
    { 
        Header: "Tesorera",
        accessor: 'nombre_largo',
    },
    { 
        Header: "Banco",
        accessor: 'nombreBanco',
    },
    { 
        Header: "Giro",
        accessor: 'giro',
    },
    { 
        Header: "Marca",
        accessor: 'descripcionMarca',
    },
    { 
        Header: "cuenta",
        accessor: 'numerodecuenta',
    },
    { 
        Header: "Archivo",
        accessor: 'nombreArchivo',
    },
    { 
        Header: "Estatus",
        accessor: 'Estatus',
    },
    { 
        Header: "Dispersoras",
        accessor: "btnAction",
    }, 
]


const calasTabs = {
    tabUser : 'active',
    tabVista : '',
    tabContUser : 'tab-pane active',
    tabContVista : 'tab-pane',
    tabUexpanded : 'true',
    tabVexpanded : 'false'
}
const swichTabs = {
    tabUser : '',
    tabVista : 'active',
    tabContUser : '',
    tabContVista : '',
    tabUexpanded : '',
    tabVexpanded : ''
}
export {
     columnsMatrizTbl,
    //  columsMatrizDetTblT,
    //  columsMatrizDetTbl,
      columnsMatrizTblT,
      inicializa,
      calasTabs,swichTabs }  