import { getInfo, saveInfo, updateInfo, deleteInfo } from '../../../ApiRequests/req_CRUD'
import { ExisteSession, toggleLoader } from '../../../ApiRequests/general'
import { fnNotification } from '../../../ApiRequests/Notification'

const getMovimientos = (request, addCollection) => {
    request = prepareRequest(request)
    getInfo('Contabilidad/TipoMovimientos' ,request,(collection) =>  {
        addCollection(collection)
    })
}

const saveMovimiento = (request, _addCollection) => {
    request = prepareRequest(request)
    saveInfo('Contabilidad/TipoMovimientos' ,request,(collection,type,estatus) =>  {
        _addCollection(collection,type,estatus)
    })
}
const updateMovimiento = (request, addCollection) => {
    request = prepareRequest(request)
    updateInfo('Contabilidad/TipoMovimientos' ,request,(collection,type,estatus) =>  {
        addCollection(collection,type,estatus)
    })
}
const deleteMovimiento = (request, addCollection) => {
    request = prepareRequest(request)
    deleteInfo('Contabilidad/TipoMovimientos' ,request,(collection,type,estatus) =>  {
        addCollection(collection,type,estatus)
    })
}

const getConceptos = (request, addCollection) => {
    request = prepareRequest(request)
    getInfo('Contabilidad/ConceptoMovimientos' ,request,(collection) =>  {
        addCollection(collection)
    })
}

const saveConcepto = (request, _addCollection) => {
    request = prepareRequest(request)
    saveInfo('Contabilidad/ConceptoMovimientos' ,request,(collection,type,estatus) =>  {
        _addCollection(collection,type,estatus)
    })
}
const updateConcepto = (request, addCollection) => {
    request = prepareRequest(request)
    updateInfo('Contabilidad/ConceptoMovimientos' ,request,(collection,type,estatus) =>  {
        addCollection(collection,type,estatus)
    })
}
const deleteConcepto = (request, addCollection) => {
    request = prepareRequest(request)
    deleteInfo('Contabilidad/ConceptoMovimientos' ,request,(collection,type,estatus) =>  {
        addCollection(collection,type,estatus)
    })
}
const prepareRequest = request => {
    request.idUsuarioAct = ExisteSession.getInfoUsuario().idUsuario
    request.token = ExisteSession.getInfoUsuario().token
    request.connection = ExisteSession.conexion()

    return request;
}
export{
    getMovimientos,
    saveMovimiento,
    updateMovimiento,
    deleteMovimiento,
    getConceptos,
    saveConcepto,
    updateConcepto,
    deleteConcepto
}