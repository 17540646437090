import { getInfo, saveInfo, updateInfo, deleteInfo } from '../../../ApiRequests/req_CRUD'
import { ExisteSession } from '../../../ApiRequests/general'

const getTypeCompanies = (request,addCollection) => {
    request = parametersReuest(request)
    getInfo("Catalogos/catTipoCompanias",request,(collection) =>{
        addCollection(collection)
    })

}

const saveTypeCompanies = (request,addCollection) => {
    request = parametersReuest(request)
    request.typeSaved ?
    saveInfo("Catalogos/catTipoCompanias",request,(response,type,success) =>{
        addCollection({response,type,success})
    })
    :
    updateInfo("Catalogos/catTipoCompanias",request,(response,type,success) =>{
        console.log(response,type,success)
        addCollection({response:response[0],type,success})
    })

}
const deleteTypeCompanies = (request,deleteCollection) => {
    request = parametersReuest(request)
    deleteInfo("Catalogos/catTipoCompanias",request,(response,type,success) =>{
        console.log(response,type,success)
        deleteCollection({response:response[0],type,success})
    })
}

const parametersReuest = request =>{
    request.idUsuarioAct = parseInt(ExisteSession.getInfoUsuario().idUsuario?ExisteSession.getInfoUsuario().idUsuario:"0")
    request.token = ExisteSession.getInfoUsuario().token
    request.connection = ExisteSession.conexion()
    return request;
}
export{
    getTypeCompanies,
    saveTypeCompanies,
    deleteTypeCompanies
}