import React from 'react'
import ReactDom from 'react-dom';
import NotificationTrigger from '../Components/Notificaciones/NotificationTrigger'

function fnNotification(props){
    ReactDom.render(<NotificationTrigger message={props[0]} type={props[1]} autohide={props[2]}/> , document.getElementById('divNotification') )
    setTimeout(() => {
        document.getElementById('btnShowToast').click()        
    }, 1);
    return true
}

export { fnNotification}