import React from 'react';
import GeneralTable from './../../../../Components/GeneralTable'


function TabVistaAccion({relVistaAcicon,handleChangeChkAll,handleChangeChk,handleChangeChkbx}) {
    const paramsCheckAll = {
        title: 'Todas',
        padding: '0 0 0 40%',
        /*
        classDivSection: 'border-checkbox-section',
        classDivCbxGroup: 'border-checkbox-group border-checkbox-group-success',
        classCbx: 'border-checkbox'
        */
    }
    const columns = [    
        { 
            Header: "Vista",
            accessor: 'vista',
        },
        { 
            Header: "Accion",
            accessor: 'accion',
        },
        {
            id: "CheckAll",
            Header: "CheckRow",
        }
        // { 
        //     id: "chckAllVA",
        //     // Header: "CheckAll",
        //     Header: () => (
        //         <div className="border-checkbox-section">
        //             <div className="border-checkbox-group border-checkbox-group-success" style={{padding: '0 0 0 42%'}}>
        //             <input
        //                     onChange={e => ChkAllthis(e.target.checked)}
        //                     checked={chckAll}
        //                     className="border-checkbox"
        //                     type="checkbox"
        //                     id={"chkAllTblVA"}
        //                 />
        //                 <label className="border-checkbox-label"
        //                     htmlFor={"chkAllTblVA"}>&nbsp;Todos</label>
        //             </div>
        //         </div>

        //     ),
        //     Cell: k =>(
        //         <div className="border-checkbox-section">
        //             <div className="border-checkbox-group border-checkbox-group-success">
        //                 <input
        //                     onChange={e => handleChangeChkthis(k.row.original, e.target.checked, k)}
        //                     checked={k.row.original.idEstatus}
        //                     className="border-checkbox"
        //                     type="checkbox"
        //                     id={"chkAPlicar" + k.row.original.idVista + k.row.original.idAccion}
        //                 />
        //                 <label className="border-checkbox-label"
        //                     htmlFor={"chkAPlicar" + k.row.original.idVista + k.row.original.idAccion}>&nbsp;</label>
        //             </div>
        //         </div>)
        // }
    ]
    return (
        <div>
            <GeneralTable
                                columns={columns}
                                // updateSearch={updateTableSearch} 
                                data={relVistaAcicon} 
                                action={undefined} 
                                doubleClick={() =>{}}
                                handleChangeChkAll={handleChangeChkAll}
                                handleChangeChkbx={handleChangeChkbx}
                                paramsCheckAll={paramsCheckAll}
                                

                            />
        </div>
    );
}

export default TabVistaAccion;