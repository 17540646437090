import React,{Fragment} from 'react';
import Select from 'react-select';
import Buttons from '../../../Components/Buttons/Buttons'
const ModalProgIngersos = ({
    clickClose,
    listEmpresas,
    textTypeSaved,
    listCuentas,
    isEmpresa,   
    handleChange,    
    dataSaved,
    clickSave,
    clickDelete,
}) => {
    return (
        <Fragment>
        <div className="modal fade" id="modalProgIngersos" tabIndex="-1" role="dialog" aria-labelledby="modalProgIngersosLabel" aria-hidden="true">
            <div className="modal-dialog " role="document">
                <div className="modal-content">
                    <div className="modal-header mhead">
                        <div className="row">
                            <div className="col-md-6">
                                <h5 className="modal-title" style={{color: 'white'}} id="modalFrontLabel">{!dataSaved.isSaved? "Edita " + textTypeSaved : "Agrega " + textTypeSaved }</h5>
                                
                            </div>
                            <div className="col-md-6">
                                <button onClick={clickClose} type="button" className="close text-white" data-dismiss="modal" aria-label="Close">
                                    
                                    <i className="fa fa-times-circle"></i>

                                </button>  
                            </div>
                        </div>
                    </div>
                    <div className="modal-body" role="form">
                    <form className="form-horizontal">
                        <div className="form-group">
                            <label htmlFor="inputEmpresas" className="col-sm-3 control-label">Empresas</label>
                            <div className="col-sm-9">
                                <Select
                                    
                                    isDisabled={!dataSaved.isSaved}
                                    name='empresa'
                                    onChange={e => handleChange(e,'empresa' )}
                                    options={listEmpresas}
                                    value={dataSaved.empresa}
                                /> 
                            </div>
                        </div>
                        <div className="form-group">
                            <label htmlFor="inputEmpresas" className="col-sm-3 control-label">Cuenta</label>
                            <div className="col-sm-9">
                                <Select
                                    
                                    isDisabled={isEmpresa}
                                    name='cuenta'
                                    onChange={e => handleChange(e,'cuenta')}
                                    options={listCuentas}
                                    value={dataSaved.cuenta}
                                /> 
                            </div>
                        </div>
                        <div className="form-group">
                            <label htmlFor="inputEmpresas" className="col-sm-3 control-label">Fecha Ingreso</label>
                            <div className="col-sm-9">
                                    <input                                    
                                        type='date'                                                                       
                                        className="form-control"                                    
                                        onChange={e => handleChange(e,'fecha')}                                    
                                        value={dataSaved.fecha}
                                        min="0"
                                        disabled={isEmpresa}
                                    />  
                            </div>
                        </div>
                        <div className="form-group">
                            <label htmlFor="inputEmpresas" className="col-sm-3 control-label">Importe</label>
                            <div className="col-sm-9">                                   
                                    <input                                    
                                    type='number'                                                                       
                                    className="form-control"                                    
                                    onChange={e => handleChange(e,'importe')}                                    
                                    value={dataSaved.importe}
                                    min="0"
                                    disabled={isEmpresa}
                                    />                                
                            </div>
                        </div>                                                              
                    </form>
                    </div>
                    <div className="modal-footer">
                        <Buttons
                            btnSave={true} btnSaveAction={() => clickSave(1)} btnSaveText={!dataSaved.isSaved ? "Guardar cambios" : " Guardar"}  btnSaveShowIcon={true} typeSaved={!dataSaved.isSaved}
                            btnDelete={!dataSaved.isSaved} btnDeleteAction={clickDelete} btnDeleteText={" Eliminar"} btnDeleteShowIcon={true}
                            btnCancel={true} btnCancelAction={clickClose} btnCancelText={" Cancelar"} btnCancelShowIcon={true}/>
                    </div>
                </div>
            </div>
        </div>
            
        </Fragment>
    );
};

export default ModalProgIngersos;