import React, { Component } from "react";

class BtnSendMail extends Component{
    render(){
        return(
            
            <button 
            type="button" 
            rel="tooltip" 
            className="button button-primary button-circle button-small"
            data-toggle="tooltip" 
            data-placement="top" 
            style={{marginLeft: '5px'}}
            title="Enviar E-mail">
                <i className="fa fa-location-arrow" alt="Enviar E-mail"></i>
            </button>
        )
    }
}

export default BtnSendMail