import { getInfo, saveInfo, updateInfo, deleteInfo } from '../../../ApiRequests/req_CRUD'
import { ExisteSession } from '../../../ApiRequests/general'

const getParams = (request,addCollection) => {
    request = parametersReuest(request)
    getInfo("ConfiguracionGeneral/ConfigHistoricoEmpresaTbl",request,(collection) =>{
        addCollection(collection)
    })

}
const updateParams = (request,addCollection) => {
    request = parametersReuest(request)
    updateInfo("ConfiguracionGeneral/ConfigHistoricoEmpresaTbl",request,(collection,t,succes) =>{
        console.log(collection,t,succes)
        addCollection(collection,t,succes)
    })

}
const saveParams = (request,addCollection) => {
    // request = parametersReuest(request)
    // request.typeSaved ?
    // saveInfo("Catalogos/catTipoCompanias",request,(response,type,success) =>{
    //     addCollection({response,type,success})
    // })
    // :
    // updateInfo("Catalogos/catTipoCompanias",request,(response,type,success) =>{
    //     console.log(response,type,success)
    //     addCollection({response:response[0],type,success})
    // })

}
const deleteParams = (request,deleteCollection) => {
    // request = parametersReuest(request)
    // deleteInfo("Catalogos/catTipoCompanias",request,(response,type,success) =>{
    //     console.log(response,type,success)
    //     deleteCollection({response:response[0],type,success})
    // })
}

const parametersReuest = request =>{
    request.idUsuarioAct = ExisteSession.getInfoUsuario().idUsuario
    request.token = ExisteSession.getInfoUsuario().token
    request.connection = ExisteSession.conexion()
    return request;
}
export{
    getParams,
    saveParams,
    updateParams,
    deleteParams,
}