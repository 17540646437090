const endPoint = 'Catalogos/CatActividadesEconomicas'
const endPointFilter = 'Catalogos/CatActividadesEconomicas/Filter'
const endPointUrl = 'Catalogos/CatActividadesEconomicas/Url'
const inicializa = {
    title : 'Actividades económicas',
    breadcrum : [{nombre: 'Catalogos'},{nombre: 'Actividades económicas'}]
}
const columsActiv = [
    {   id:'codigoActividadEco',
    Header: ({dt}) => (
        <div>
            Clave
        </div>
    ),
    accessor: 'codigoActividadEco',
    Cell: k =>{ 
        return  <span>{k.row.original.codigoActividadEco}</span>
        
    }
    },
    {   id:'ActividadEco',
    Header: ({dt}) => (
        <div>
            Actividad económica
        </div>
    ),
    accessor: 'ActividadEco',
    Cell: k =>{ 
        return <div className="txtdAEE"><span>{k.row.original.ActividadEco}</span></div> 
    }
    }
    
    
    

    ]
export {inicializa,columsActiv,endPoint,endPointFilter,endPointUrl}