const inicializa = {
    title : 'Disponibilidad de Cuentas',
    breadcrum : [{nombre: 'Facturación'},{nombre: 'Disponibilidad de Cuentas'}]
}
const requestUs = {
    idUsuario : 0
}

const columnsDisponibilidadTbl = [
    { 
        Header: "Empresa",
        accessor: 'nombre_largo',
    },
    { 
        Header: "Banco",
        accessor: 'banco',
    },
    { 
        Header: "Cuenta Bancaria",
        accessor: 'numerodecuenta',
    },
    { 
        Header: "Disponible",
        accessor: "importeFormat",
    },    
    { 
        Header: "Fecha de Vigencia",
        accessor: 'fechaFormat',
    },    
]

const tableData = []

export { columnsDisponibilidadTbl,inicializa,tableData }  

