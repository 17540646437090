import React,{Fragment} from 'react';
import Select from 'react-select';
import Buttons from '../../../Components/Buttons/Buttons'

const ModalMatriz = ({
    clickClose,
    listEmpresas,
    textTypeSaved,
    listCuentas, 
    listLayouts,
    isDetail,
    isEmpresa,
    handleChangeFront,
    handlechecked,
    dataSaved,
    clickSave,
    clickDelete,
}) => {
    
    return (
        <Fragment>
        <div className="modal fade" id="modalMatrizFront" tabIndex="-1" role="dialog" aria-labelledby="modalMatrizFrontLabel" aria-hidden="true">
            <div className="modal-dialog " role="document">
                <div className="modal-content">
                    <div className="modal-header mhead">
                        <div className="row">
                            <div className="col-md-6">
                                <h5 className="modal-title" style={{color: 'white'}} id="modalFrontLabel">{!dataSaved.isSaved? "Edita" + textTypeSaved : "Agrega " + textTypeSaved }</h5>
                                
                            </div>
                            <div className="col-md-6">
                                <button onClick={clickClose} type="button" className="close text-white" data-dismiss="modal" aria-label="Close">
                                    {/* <span aria-hidden="true">&times;</span> */}
                                    <i className="fa fa-times-circle"></i>

                                </button>  
                            </div>
                        </div>
                    </div>
                    <div className="modal-body" role="form">
                    <form className="form-horizontal">
                        <div className="form-group">
                            <label htmlFor="inputEmpresas" className="col-sm-2 control-label">Empresas</label>
                            <div className="col-sm-10">
                                <Select
                                    // isMulti
                                    isDisabled={isEmpresa}
                                    name='empresa'
                                    onChange={e => handleChangeFront(e,'empresa' )}
                                    options={listEmpresas}
                                    value={dataSaved.empresa}
                                /> 
                            </div>
                        </div>
                        <div className="form-group">
                            <label htmlFor="inputEmpresas" className="col-sm-2 control-label">Cuenta</label>
                            <div className="col-sm-10">
                                <Select
                                    // isMulti
                                    // isDisabled={isEmpresa}
                                    name='cuenta'
                                    onChange={e => handleChangeFront(e,'cuenta')}
                                    options={listCuentas}
                                    value={dataSaved.cuenta}
                                /> 
                            </div>
                        </div>                      
                        {
                            !isDetail ?
                            <Fragment>
                                <div className="form-group">
                                    <label htmlFor="inputEmpresas" className="col-sm-2 control-label">Layout</label>
                                    <div className="col-sm-10">
                                        <Select
                                            // isMulti
                                            // isDisabled={cheCkAll}
                                            name='layout'
                                            onChange={e => handleChangeFront(e,'layout')}
                                            options={listLayouts}
                                            value={dataSaved.layout}
                                        /> 
                                    </div>
                                </div>
                                {

                                    !dataSaved.isSaved &&
                                    <div className="form-group">
                                        <label htmlFor="inputEstatus" className="col-sm-2 control-label">Estatus</label>
                                        <div className=" col-sm-10 border-checkbox-section">
                                            <div className="border-checkbox-group border-checkbox-group-success">
                                            <input
                                                onChange={e => {handlechecked(e)}}
                                                checked={dataSaved.status}
                                                className="border-checkbox"
                                                type="checkbox"
                                                id="chkEstatus"
                                            />
                                            <label className="border-checkbox-label"
                                                    htmlFor={"chkEstatus"}>&nbsp;</label>
                                        </div>
                                        </div>
                                    </div>
                                }
                            </Fragment> :
                            <Fragment>
                                <div className="form-group">
                                    <label htmlFor="inputEmpresas" className="col-sm-2 control-label">Prorrateo</label>
                                    <div className="col-sm-10">
                                        <input 
                                        type='number' 
                                        className="form-control"
                                        onChange={e => handleChangeFront(e,'prorrateo')}                                    
                                        value={dataSaved.prorrateo}
                                        min="0"
                                        />
                                    </div>
                                </div>
                            </Fragment>
                        }                      
                    </form>
                    </div>
                    <div className="modal-footer">
                        <Buttons
                            btnSave={true} btnSaveAction={() => clickSave(1)} btnSaveText={!dataSaved.isSaved ? "Guardar cambios" : " Guardar"}  btnSaveShowIcon={true} typeSaved={!dataSaved.isSaved}
                            btnDelete={!dataSaved.isSaved} btnDeleteAction={clickDelete} btnDeleteText={" Eliminar"} btnDeleteShowIcon={true}
                            btnCancel={true} btnCancelAction={clickClose} btnCancelText={" Cancelar"} btnCancelShowIcon={true}/>
                    </div>
                </div>
            </div>
        </div>
            
        </Fragment>
    );
};

export default ModalMatriz;